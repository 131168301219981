<template>
  <div class="block_wrap big_first_block day_night" @mousemove="mousePosition" id="daynight">
    <div class="day_night_img day_night_img2" v-if="dataInfo.image2">
      <div class="div_inner">
        <container>
          <div class="pos_rel">
            <div class="h1_top fs1 h1_big" :style="{paddingTop:paddingTop + 'px'}">
              <span class="sp1" v-if="dataInfo.topText1">{{dataInfo.topText1.value}}</span><span class="sp2" :class="isWindows ? 'spflex' : ''" v-if="dataInfo.topText2">{{dataInfo.topText2.value}}</span><span class="sp4" v-if="dataInfo.topText3">{{dataInfo.topText3.value}}</span><span class="sp3" v-if="dataInfo.topText4">{{dataInfo.topText4.value}}</span>
            </div>
          </div>
        </container>
        <img :src="getImage(dataInfo.image2, 'fullHd')" alt="">
      </div>
    </div>
    <div class="day_night_img day_night_img1" v-if="dataInfo.image1" :style="isMobile ? {right: 0, left: 0} : {right: 'calc(100% - ' + leftPosition + 'px'}" id="dayNightImg">
      <div class="div_inner">
        <container>
          <div class="pos_rel">
            <div class="h1_top fs1 h1_big" :style="{paddingTop:paddingTop + 'px'}">
              <span class="sp1" v-if="dataInfo.topText1">{{dataInfo.topText1.value}}</span><span class="sp2" :class="isWindows ? 'spflex' : ''" v-if="dataInfo.topText2">{{dataInfo.topText2.value}}</span><span class="sp4" v-if="dataInfo.topText3">{{dataInfo.topText3.value}}</span><span class="sp3" v-if="dataInfo.topText4">{{dataInfo.topText4.value}}</span>
            </div>
          </div>
        </container>
        <img :src="getImage(dataInfo.image1, 'fullHd')" alt="">
      </div>
    </div>
    <container class="pos_rel">
      <!--      event-none op-0-->
      <h1 class="event-none op-0 h1_big" ref="h1pos">
        <span class="sp1" v-if="dataInfo.topText1">{{dataInfo.topText1.value}}</span><span class="sp2" :class="isWindows ? 'spflex' : ''" v-if="dataInfo.topText2">{{dataInfo.topText2.value}}</span><span class="sp4" v-if="dataInfo.topText3">{{dataInfo.topText3.value}}</span><span class="sp3" v-if="dataInfo.topText4">{{dataInfo.topText4.value}}</span>
      </h1>
      <div class="div_sub fs4n" v-if="dataInfo.text" v-html="dataInfo.text.value"></div>
      <row v-if="dataInfo.listCard && dataInfo.listCard.length > 0">
        <column
            v-for="(item, index) in dataInfo.listCard"
            :key="item.title?.value"
            md="6"
            lg="3"
        >
          <div class="div_num  fs2">{{ item.title?.value }}</div>
          <div class="div_text fs4n" v-if="item.text" v-html="item.text?.value"></div>
        </column>
      </row>
    </container>
  </div>
</template>

<script>


export default {
  name: "main-day-night-en",
  props: {
    dataInfo: {
      type: Object,
      require: true
    }
  },
  data(){
    return {
      leftPosition: window. innerWidth/2,
      mLeft: 0,
      mRigth: 0,
      paddingTop: 100,
      isMobile: false,
      isWindows: false,
    }
  },
  methods: {
    mousePosition(event){
      this.leftPosition = event.clientX - 20;
    },
    mouseHeaderMove(event){
      const $header = document.querySelector('.site_header'),
          $dayNightImg = document.getElementById('dayNightImg');
      if($header && $dayNightImg && $header.getBoundingClientRect().bottom < $dayNightImg.getBoundingClientRect().bottom){

        $dayNightImg.style.right =  'calc(100% - ' + (event.clientX - 20) + 'px)';
      }
    },
    animateHeaderMove(){
      const dayNightImg = document.getElementById('dayNightImg');
      if (dayNightImg) {
        const container = dayNightImg.querySelector(".div_inner");
        container.style.position = "absolute";
        container.style.right = 0;
        dayNightImg.style.transitionProperty = "left";
        dayNightImg.style.transitionDuration = "5s";
        dayNightImg.style.transitionTimingFunction = "ease-in-out";
        dayNightImg.style.left = "100%";
        setTimeout(() => {
          dayNightImg.style.transitionProperty = "";
          dayNightImg.style.transitionDuration = "";
          dayNightImg.style.transitionTimingFunction = "";
          container.style.right = 0;
          dayNightImg.style.right = "100%";
          dayNightImg.style.left = "0";
          setTimeout(() => {
            container.style.right = "";
            dayNightImg.style.transitionProperty = "right";
            dayNightImg.style.transitionDuration = "5s";
            dayNightImg.style.transitionTimingFunction = "ease-in-out";
            dayNightImg.style.right = 0;
            setTimeout(() => {
              this.animateHeaderMove();
            }, 10000)
          }, 5000);
        }, 5000);
      }
    },
    mouseHeaderInit(){
      const $header = document.querySelector('.site_header');
      if($header) {
        if(this.isMobile){
          setTimeout(() => {
            this.animateHeaderMove();
          }, 5000);
        }else{
          $header.addEventListener("mousemove", this.mouseHeaderMove);
        }
      }
    }
  },
  mounted() {
    const isMobile = {
      Android: function() {
        return navigator.userAgent.match(/Android/i);
      },
      BlackBerry: function() {
        return navigator.userAgent.match(/BlackBerry/i);
      },
      iOS: function() {
        return navigator.userAgent.match(/iPhone|iPad|iPod/i);
      },
      Opera: function() {
        return navigator.userAgent.match(/Opera Mini/i);
      },
      Windows: function() {
        return navigator.userAgent.match(/IEMobile/i);
      },
      any: function() {
        return (isMobile.Android() || isMobile.BlackBerry() || isMobile.iOS() || isMobile.Opera() || isMobile.Windows());
      }
    };


    if (navigator.appVersion.indexOf("Win") != -1)
      this.isWindows = true;

    if(isMobile.any()){
      this.isMobile = true;
    }

    this.mouseHeaderInit();

    this.paddingTop = this.$refs.h1pos.getBoundingClientRect().top + window.scrollY;
    window.addEventListener("resize", () => {
      if(this.$refs?.h1pos?.offsetTop){
        this.paddingTop = this.$refs.h1pos.getBoundingClientRect().top + window.scrollY;;
      }
    });

  },
  beforeUnmount() {
    const $header = document.querySelector('.site_header');
    if($header) {
      $header.removeEventListener("mousemove", this.mouseHeaderMove);
    }
  }
}
</script>

<style lang="scss" scoped>
.day_night {
  //padding: 215px 0 42px 0;
  //min-height: 100vh;
  .day_night_img {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    overflow: hidden;
    background-color: #000106;
    /*display: flex;
    justify-content: flex-end;*/
    .div_inner {
      width: 100vw;
      min-width: 100vw;
      max-height: max-content;
      flex: none;
      position: relative;
      > .container {
        min-height: 0;
        padding-top: 0;
        padding-bottom: 0;
      }
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }
  .h1_top,
  h1 {
    display: flex;
    flex-direction: column;
    margin-bottom: 32px;
    white-space: nowrap;
    .spflex{
      display: flex;
    }
    .sp1 {
      margin-bottom: 1.2rem;
      max-height: 10rem;
      left: 0;
    }
    .sp2 {
      max-height: 10rem;
      text-align: left;
      position: relative;
      left: calc(50% - 46rem);
      .minus{
        margin-left: 45px;
      }
    }
    .sp3 {
      max-height: 10rem;
      text-align: right;
      padding-right: 22rem;
      position: relative;
      left: calc(50% - 60rem);
    }
    .sp4{
      max-height: 10rem;
      width: 101rem;
      text-align: center;
      position: relative;
      left: calc(50% - 40rem);
    }
    .font_matinee {
      //color: #ED850A;
      font-family: "CCMatinee";
      text-transform: none;
      font-variant-numeric: lining-nums proportional-nums;
      font-size: 1.786em;//250px
      font-style: normal;
      font-weight: 400;
      line-height: .547em;//136px
      letter-spacing: 5px;
    }
  }
  .h1_top {
    font-size: 10rem;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    margin: 0;
  }
  .div_num {
    //white-space: nowrap;
    margin-bottom: 5px;
  }
}

.big_first_block {
  .div_sub {
    max-width: 1080px;
  }
}

@media (max-width: 991px) {
  .day_night{
    h1, .h1_top{
      font-size: 6rem !important;
      margin-bottom: 0;
      .sp1{
        margin-bottom: 0;
        max-height: 7.2rem;
      }
      .sp2{
        max-height: 7.2rem;
        margin-bottom: 0;
        left: calc(50% - 34rem);
      }
      .sp3{
        max-height: 7.2rem;
        text-align: left !important;
        left: calc(50% - 30rem);
      }
      .sp4{
        text-align: left;
        max-height: 7.2rem;
        margin-bottom: 0;
        left: calc(50% - 32rem);
      }
    }
  }
  .minus{
    margin-left: 20px !important;
  }
}
@media (max-width: 767px) {
  .day_night{
    h1, .h1_top{
      font-size: 3.2rem !important;
      .sp1{
        max-height: 4.8rem;
        left: 0;
      }
      .sp2{
        max-height: 4.8rem;
        left: 0;
      }
      .sp3{
        max-height: 4.8rem;
        left: 0;
      }
      .sp4{
        max-height: 4.8rem;
        left: 0;
      }
    }
  }
  .minus{
    margin-left: 12px !important;
  }
}
</style>