<template>
  <cl-modal class="modalInfo modal_alert_measure_leave"
            :class="{'modal_alert_measure_leave__black': modalAlertDeleteApplicationParams.isBlackColor, 'modal_alert_measure_leave__btn_reverse': modalAlertDeleteApplicationParams.buttonsReverse}"
            :modelValue="modelValue"
            @update:modelValue="modelValue = $event"
            :close="modalClose"
            :close-fon-active="false"
  >
    <template v-slot:body>

<!--      Модалка -->
      <div v-if="!isDeleting && !isErrorShow && !isSuccessShow && modalAlertDeleteApplicationParams.icon" class="div_img">
        <img :src="getImage(modalAlertDeleteApplicationParams.icon)" alt="">
      </div>
      <div v-if="!isDeleting && !isErrorShow && !isSuccessShow && (modalAlertDeleteApplicationParams.title || modalAlertDeleteApplicationParams.text)" class="div_info">
        <h4 v-if="modalAlertDeleteApplicationParams.title" class="div_title">
          {{ modalAlertDeleteApplicationParams.title.value }}
        </h4>
        <div v-if="modalAlertDeleteApplicationParams.text" class="div_text fs16" v-html="modalAlertDeleteApplicationParams.text.value"></div>
      </div>
      <div v-if="!isDeleting && !isErrorShow && !isSuccessShow" class="div_btns">
        <cl-button v-if="modalAlertDeleteApplicationParams.btnCancelText" :type="modalAlertDeleteApplicationParams.btnCancelLightType ? 'light' : null" :img="modalAlertDeleteApplicationParams.btnCancelIcon ? modalAlertDeleteApplicationParams.btnCancelIcon.value.src : null" @click="modalClose">
          {{ modalAlertDeleteApplicationParams.btnCancelText.value }}
        </cl-button>
        <cl-button v-if="modalAlertDeleteApplicationParams.btnApplyText" :type="modalAlertDeleteApplicationParams.btnApplyLightType ? 'light' : null" :img="modalAlertDeleteApplicationParams.btnApplyIcon ? modalAlertDeleteApplicationParams.btnApplyIcon.value.src : null" @click="deleteApplication">
          {{ modalAlertDeleteApplicationParams.btnApplyText.value }}
        </cl-button>
      </div>

<!--      Модалка во время загрузки -->
      <div v-if="isDeleting && modalAlertDeleteApplicationParams.iconDeleting" class="div_img">
        <img :src="getImage(modalAlertDeleteApplicationParams.iconDeleting)" alt="">
      </div>
      <div v-if="isDeleting && (modalAlertDeleteApplicationParams.titleDeleting || modalAlertDeleteApplicationParams.textDeleting)" class="div_info">
        <h4 v-if="modalAlertDeleteApplicationParams.titleDeleting" class="div_title">
          {{ modalAlertDeleteApplicationParams.titleDeleting.value }}
        </h4>
        <div v-if="modalAlertDeleteApplicationParams.textDeleting" class="div_text fs16" v-html="modalAlertDeleteApplicationParams.textDeleting.value"></div>
      </div>
      <loader
          v-if="isDeleting"
          :animation-duration="3000"
          :size="60"
      ></loader>

<!--      Модалка при успешной работы сервера -->
      <div v-if="!isDeleting && isSuccessShow && modalAlertDeleteApplicationParams.iconSuccess" class="div_img">
        <img :src="getImage(modalAlertDeleteApplicationParams.iconSuccess)" alt="">
      </div>
      <div v-if="!isDeleting && isSuccessShow && (modalAlertDeleteApplicationParams.titleSuccess || modalAlertDeleteApplicationParams.textSuccess)" class="div_info">
        <h4 v-if="modalAlertDeleteApplicationParams.titleSuccess" class="div_title">
          {{ modalAlertDeleteApplicationParams.titleSuccess.value }}
        </h4>
        <div v-if="modalAlertDeleteApplicationParams.textSuccess" class="div_text fs16" v-html="modalAlertDeleteApplicationParams.textSuccess.value"></div>
      </div>
      <div v-if="!isDeleting && isSuccessShow" class="div_btns">
        <cl-button v-if="modalAlertDeleteApplicationParams.btnCancelTextSuccess" :type="modalAlertDeleteApplicationParams.btnCancelLightTypeSuccess ? 'light' : null" :img="modalAlertDeleteApplicationParams.btnCancelIconSuccess ? modalAlertDeleteApplicationParams.btnCancelIconSuccess.value.src : null" @click="modalClose">
          {{ modalAlertDeleteApplicationParams.btnCancelTextSuccess.value }}
        </cl-button>
      </div>

<!--      Модалка при ошибке с сервера -->
      <div v-if="!isDeleting && isErrorShow && modalAlertDeleteApplicationParams.iconError" class="div_img">
        <img :src="getImage(modalAlertDeleteApplicationParams.iconError)" alt="">
      </div>
      <div v-if="!isDeleting && isErrorShow && (modalAlertDeleteApplicationParams.titleError || modalAlertDeleteApplicationParams.textError)" class="div_info">
        <h4 v-if="modalAlertDeleteApplicationParams.titleError" class="div_title">
          {{ modalAlertDeleteApplicationParams.titleError.value }}
        </h4>
        <div v-if="modalAlertDeleteApplicationParams.textError" class="div_text fs16" v-html="modalAlertDeleteApplicationParams.textError.value"></div>
      </div>
      <div v-if="!isDeleting && isErrorShow" class="div_btns">
        <cl-button v-if="modalAlertDeleteApplicationParams.btnCancelTextError" :type="modalAlertDeleteApplicationParams.btnCancelLightTypeError ? 'light' : null" :img="modalAlertDeleteApplicationParams.btnCancelIconError ? modalAlertDeleteApplicationParams.btnCancelIconError.value.src : null" @click="modalClose">
          {{ modalAlertDeleteApplicationParams.btnCancelTextError.value }}
        </cl-button>
        <cl-button v-if="modalAlertDeleteApplicationParams.btnApplyTextError" :type="modalAlertDeleteApplicationParams.btnApplyLightTypeError ? 'light' : null" :img="modalAlertDeleteApplicationParams.btnApplyIconError ? modalAlertDeleteApplicationParams.btnApplyIconError.value.src : null" @click="deleteApplication">
          {{ modalAlertDeleteApplicationParams.btnApplyTextError.value }}
        </cl-button>
      </div>

    </template>
  </cl-modal>
</template>

<script>
import ClModal from "@/components/library/ClModal.vue";

export default {
  name: "modalAlertDeleteApplication",
  components: {ClModal},
  data() {
    return {
      /**
       * Состояние процесса удаления заявки
       * @type {boolean}
       * @default false
       */
      isDeleting: false,
      /**
       * Состояние, которое показывает получена ли ошибка с сервера
       * @type {boolean}
       * @default false
       */
      isErrorShow: false,
      /**
       * Состояние, которое показывает успешно ли выполнен запрос к серверу
       * @type {boolean}
       * @default false
       */
      isSuccessShow: false,
    }
  },
  props: {
    /**
     * Параметры для модалки
     */
    modalAlertDeleteApplicationParams: {
      type: Object,
    },
    /**
     * Гуид заявки которую необходимо удалить
     */
    modalAlertDeleteApplicationGuid: {
      type: String,
    },
    /**
     * Управление отображения модалки
     * @default false
     */
    modelValue: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    /**
     * Функция закрытия модалки без изменений
     */
    modalClose() {
      if(this.isSuccessShow) {
        this.$emit("closeModalAlertDeleteApplication", true);
      } else {
        this.$emit("closeModalAlertDeleteApplication", false);
      }
      this.resetData();
    },
    /**
     * Функция удаления заявки
     */
    deleteApplication() {
      if(!this.isDeleting) {
        this.isDeleting = true;
        this.isErrorShow = false;
        this.isSuccessShow = false;
        this.axios
            .post(`/api/data/draft-delete/one/${this.modalAlertDeleteApplicationGuid}`)
            .then(response => {
              this.isSuccessShow = true;
            })
            .catch(error => {
              this.isErrorShow = true;
            })
            .finally(() => {
              this.isDeleting = false;
            });
      }
    },
    /**
     * Функция обновления данных
     */
    resetData() {
      this.isDeleting = false;
      this.isErrorShow = false;
      this.isSuccessShow = false;
    }
  },
  beforeUnmount() {
    this.resetData();
  },
  emits: ['closeModalAlertDeleteApplication']
}
</script>

<style lang="scss" scoped>

</style>