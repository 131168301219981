<template>
    <div
        class="filter_tabs_el"
        :class="{'active': isActive}"
    >
      <cl-button
          type="light btn-tag"
          :class="{'active': isActive}"
          @click="isActive ? hide() : show(); $emit('changeTab', tabEl);"
      >
        <div v-if="tabEl.icoHtml" v-html="tabEl.icoHtml"></div>
        <span v-if="tabEl.icoHtml" class="sp_ico_text">{{ tabEl[this.label] }}</span>
        <template v-else>{{ tabEl[this.label] }}</template>

      </cl-button>
    </div>
</template>

<script>
export default {
    props: {
        tabEl: {
            type: Object,
            default: () => []
        },
        tabActive: {
            type: Array,
            default: () => []
        },
        label: {
            type: String,
            required: true,
            default: 'title'
        },
        tabKey: {
            type: String,
            default: 'type'
        },
        tabSample: {
            type: Boolean,
        },
        allBtn: {
          type: String
        },
        maxAmount: {
          type: String
        },
    },
    data() {
        return {
            isActive: false,
        }
    },
    methods: {
        hide() {
            if (this.tabSample) {
                this.tabActive.splice(0, this.tabActive.length);
            } else {
                this.tabActive.splice(this.tabActive.findIndex((el) => {
                    return el === this.tabEl[this.tabKey]
                }), 1)
            }
            this.isActive = false;
        },
        show() {
            if (this.tabSample) {
                this.tabActive.splice(0, this.tabActive.length);
                this.tabActive.push(this.tabEl[this.tabKey]);
            } else {
                this.tabActive.push(this.tabEl[this.tabKey]);
            }
            this.isActive = true;

            if(this.allBtn && this.tabActive.length == this.maxAmount) {
              this.tabActive.splice(0, this.maxAmount);
              //this.isActive = false;
            }
        },
        isActiveTabSample() {
          return this.tabActive.includes(this.tabEl[this.tabKey])
        },
    },
    watch: {
      tabActive: {
        deep: true,
        handler(newValue){
          //if (this.tabSample) {
            this.isActive = this.tabActive.includes(this.tabEl[this.tabKey]);
          //}
        },
      }
    },
    mounted() {
        if (this.tabActive.length) {
            this.isActive = this.tabActive.includes(this.tabEl[this.tabKey]);
        }
    }
}
</script>

<style scoped lang="scss">
.btn-tag.btn-light:not(.btn-disabled):focus {
    outline: none;
}
</style>