// alertMeasureLeaveRoute для вывода предупреждения перед переходом на другую страницу.
// Добавляется на компонент measure-form-component.
// alertMeasureLeaveRoute при открытой форме выполняет действие при переходе на другую страницу.
// Форма на странице. Чтобы добавить alertMeasureLeaveRoute на measure-form-component, нужно добавить:
//      import:
//          import mixinModalAlertMeasureLeaveRoute from "@/mixin/mixinModalAlertMeasureLeaveRoute";
//      mixin:
//          mixins: [mixinModalAlertMeasureLeaveRoute],
//      measure-form-component следующие props и emits:
//          v-bind="modalAlertMeasureLeaveRouteAttr()"
//          v-on="modalAlertMeasureLeaveRouteEmits()"

// ВАЖНО!!! Перехват перехода на другую страницу работает только для компонентов router-link. При использовании атрибута href данный перехват не работает!!!

// Позволительно использовать только на одну меру на странице, т.к. при любом переходе на другую страницу будет вызываться данная модалка.

// Пример в компоненте testForm.vue

import {onBeforeRouteLeave, onBeforeRouteUpdate} from "vue-router";
export default {
    data() {
        return {
            modalAlertMeasureLeaveRouteShow: false, // Показывать/скрывать модалку modalAlertMeasureLeaveRoute
            modalAlertMeasureLeaveRouteUrl: "", // Ссылка при переходе на другую страницу
            modalAlertMeasureLeaveRouteAvailable: true, // Давать/убирать доступ для перехода на другую страницу
        }
    },
    methods: {
        modalAlertMeasureLeaveRouteAttr() {
            return {
                'modal-alert-measure-leave-location-type': 'onPage', // Тип меры (на странице или в модалке)
                'modal-alert-measure-leave-show': this.modalAlertMeasureLeaveRouteShow // Атрибут отображения модалки
            };
        },
        modalAlertMeasureLeaveRouteEmits() {
            return {
                'modalAlertMeasureLeaveRouteCloseApply': this.modalAlertMeasureLeaveRouteCloseApply, // Emit с функцией закрытия модалки с переходом на другую страницу
                'modalAlertMeasureLeaveRouteCloseCancel': this.modalAlertMeasureLeaveRouteCloseCancel, // Emit с функцией закрытия модалки без перехода на другую страницу
                'modalAlertMeasureLeaveRouteAvailableOff': this.modalAlertMeasureLeaveRouteAvailableOff, // Emit с функцией отключения доступа перехода на другую страницу
                'modalAlertMeasureLeaveRouteAvailableOn': this.modalAlertMeasureLeaveRouteAvailableOn, // Emit с функцией включения доступа перехода на другую страницу
            };
        },
        modalAlertMeasureLeaveRouteCloseCancel() {
            this.modalAlertMeasureLeaveRouteShow = false; // Закрыть modalAlertMeasureLeaveRoute без перехода на другую страницу
        },
        modalAlertMeasureLeaveRouteCloseApply() {
            this.modalAlertMeasureLeaveRouteShow = false; // Закрыть modalAlertMeasureLeaveRoute
            this.modalAlertMeasureLeaveRouteAvailableOn(); // Дать доступ для перехода на другую страницу
            this.$router.push(this.modalAlertMeasureLeaveRouteUrl); // Перейти на другую страницу
        },
        modalAlertMeasureLeaveRouteAvailableOff() {
            if(this.$root.globalAlertData?.modalAlertMeasureLeave?.active) this.modalAlertMeasureLeaveRouteAvailable = false; // Закрыть доступ на другую страницу
        },
        modalAlertMeasureLeaveRouteAvailableOn() {
            this.modalAlertMeasureLeaveRouteAvailable = true; // Открыть доступ на другую страницу
        },
    },
    created() {
        onBeforeRouteUpdate((to, from, next) => {
            if(!this.modalAlertMeasureLeaveRouteAvailable) {
                this.modalAlertMeasureLeaveRouteUrl = to.fullPath; // Сохранить ссылку с переходом на другую страницу
                this.modalAlertMeasureLeaveRouteShow = true; // Показать модалку
                next(false); // Предотвратить переход
            } else {
                next(); // Совершить переход
            }
        }); // Функция перехвата обновления страницы

        onBeforeRouteLeave((to, from, next) => {
            if(!this.modalAlertMeasureLeaveRouteAvailable) {
                this.modalAlertMeasureLeaveRouteUrl = to.fullPath; // Сохранить ссылку с переходом на другую страницу
                this.modalAlertMeasureLeaveRouteShow = true; // Показать модалку
                next(false); // Предотвратить переход
            } else {
                next(); // Совершить переход
            }
        }); // Функция перехвата перехода на другую страницу
    },
}