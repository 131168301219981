import { createRouter, createWebHistory } from 'vue-router';
import store from "@/store";
import dynamicPageAction from "@/views/dynamicPageAction.vue";
import standartEL from "@/views/StandartEL.vue";
import formValidationTest from "@/components/FormValidationTest.vue";
import testTs from "@/views/testTs/testTs.vue"

const routes = [
  {
    path: "/",
    component: dynamicPageAction,
    meta: {
      title: "Московская КиноПлатформа"
    }
  },
  {
    path: "/:id",
    component: dynamicPageAction,
    meta: {
      title: "Московская КиноПлатформа"
    }
  },
  {
    path: "/:id/:post_id",
    component: dynamicPageAction,
    meta: {
      title: "Московская КиноПлатформа"
    }
  },
  {
    path: "/:id/:post_id/:post_id2",
    component: dynamicPageAction,
    meta: {
      title: "Московская КиноПлатформа"
    }
  },
  {
    path: "/:id/:post_id/:post_id2/:post_id3",
    component: dynamicPageAction,
    meta: {
      title: "Московская КиноПлатформа"
    }
  },
  {
    path: "/standart",
    component: standartEL,
    meta: {
      title: "Стандартные элементы"
    },
  },
  {
    path: "/standart1",
    component: formValidationTest,
    meta: {
      title: "Проверка формы"
    },
  },
  {
    path: "/testTs",
    component: testTs,
    meta: {
      title: "testTs"
    },
  },
]

/*const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})*/
const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (to.hash) {
      return {
        el: to.hash,
        behavior: 'smooth',
      }
    } else {
      return new Promise(() => {
        setTimeout(() => document.getElementById('app').scrollIntoView(), 0)
      })
    }

  }
})


router.beforeEach((to, from, next) => {

  /*if(to.meta && to.meta.requiresAuth && !store.getters.getIsAuthorization){
      document.getElementById('modalAuthShow').classList.remove('d-none');
      document.getElementById('modalAuthHref').setAttribute('data-href', '' + to.path + ';')
      return false;
  }*/
  const prefix = "-page";
  const classes = document.body.className.split(" ").filter(c => !c.includes(prefix));
  document.body.classList = classes.join(" ").trim();
  if (to.path === "/") {
    document.body.classList.add("main-page")
  } else {
    //document.body.classList.remove("main-page");
    document.body.classList.add(to.path.toLowerCase().split('/').pop() + prefix);
  }

  document.body.style.overflow = "";

  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (store.getters.getIsAuthorization) {
      titleHandler(to, from, next);
      return
    }
    // если не авторизован показывать модалку при переходе на авторизованную страницу
    /*document.getElementById('modalAuthShow').classList.remove('d-none');
    document.getElementById('modalAuthHref').onclick = function() {
      history.pushState(null, null, to.path);
      window.location.href = '/connect/mosru';
    };*/
    return false;
    //window.location.href = '/connect/mosru';
    //next('/')
  } else {
    titleHandler(to, from, next);
  }

});

const titleHandler = (to, from, next) => {
  // This goes through the matched routes from last to first, finding the closest route with a title.
  // e.g., if we have /some/deep/nested/route and /some, /deep, and /nested have titles,
  // /nested's will be chosen.
  const nearestWithTitle = to.matched.slice().reverse().find(r => r.meta && r.meta.title);

  // Find the nearest route element with meta tags.
  const nearestWithMeta = to.matched.slice().reverse().find(r => r.meta && r.meta.metaTags);

  const previousNearestWithMeta = from.matched.slice().reverse().find(r => r.meta && r.meta.metaTags);

  // If a route with a title was found, set the document (page) title to that value.
  if (nearestWithTitle) {
    document.title = nearestWithTitle.meta.title + ' - Московская КиноПлатформа';
  } else if (previousNearestWithMeta) {
    document.title = previousNearestWithMeta.meta.title + ' - Московская КиноПлатформа';
  }

  // Remove any stale meta tags from the document using the key attribute we set below.
  Array.from(document.querySelectorAll('[data-vue-router-controlled]')).map(el => el.parentNode.removeChild(el));

  // Skip rendering meta tags if there are none.
  if (!nearestWithMeta) return next();

  // Turn the meta tag definitions into actual elements in the head.
  nearestWithMeta.meta.metaTags.map(tagDef => {
    const tag = document.createElement('meta');

    Object.keys(tagDef).forEach(key => {
      tag.setAttribute(key, tagDef[key]);
    });

    // We use this to track which meta tags we create so we don't interfere with other ones.
    tag.setAttribute('data-vue-router-controlled', '');

    return tag;
  })
      // Add the meta tags to the document head.
      .forEach(tag => document.head.appendChild(tag));

  next();
}


export default router
