<template>
  <div class="blog_list" :class="{'blog_list_block': dataInfo.showMoreBlogs }">
    <div class="container">
      <h2 class="blog_title">
        Блог подписчиков
      </h2>
      <div class="blog_list_cont">
        <template v-for="(blog, index) in dataListBlog">
            <router-link :to="dataInfo.linkSelectBlog.value + '/' + blog.id " class="blog_list_elem">
              <div class="img_wrap">
                <template v-if="blog.logo && blog.logo.url !== null">
                  <img draggable="false" :src="blog.logo.url" alt="">
                </template>
                <template v-else>
                  <img draggable="false" class="noneImage" src="" alt="">
                </template>
              </div>
              <div class="block_authr block_auth2">
              <div class="auth_desc">
                <div class="logo_authr">
                  <template v-if="blog.author.logo !== null && blog.author.logo.url !== null">
                    <img :src="blog.author.logo.url" alt="">
                  </template>
                  <template v-else>
                    <div class="mask_logo">
                      {{ blog.author.name.split(" ").map((word) => word[0]).join('') }}
                    </div>
                  </template>
                </div>
                <div class="auth_stat">
                  <div class="auth_name" v-if="blog.author.name && blog.author">
                    {{ blog.author.name }}
                  </div>
                  <div class="auth_sub">
                    <div class="auth_date" v-if="blog.datePublication">
                      {{ $filters.ruDate4(new Date(blog.datePublication)) }}
                    </div>
                    <div class="auth_watch" v-if="blog.viewsCount !== null">
                      <img src="/img/ico/eye.svg" alt="">
                      {{ blog.viewsCount }}
                    </div>
                  </div>
                </div>
              </div>
              <div class="text_content" v-if="blog.name && blog.name !== null">
                {{ blog.name }}
              </div>
            </div>
          </router-link>
          <template v-if="(index == 7 || (dataListBlog.length <= 7 && index + 1 == dataListBlog.length)) && dataInfo.showMoreBlogs">
            <div class="btn-center" v-if="dataInfo.linkPageBlog && dataInfo.linkPageBlog !== null">
              <router-link class="btn btn-default" :to="dataInfo.linkPageBlog.value">
                Показать еще
              </router-link>
            </div>
          </template>
          <div v-if="index == 7 || (dataListBlog.length <= 7 && index + 1 == dataListBlog.length)" class="not_location">

            <h4 class="div_tit"> {{ dataInfo.bannerTittle.value }} </h4>
            <div class="div_text"> {{ dataInfo.bannerDescription.value }} </div>
            <div class="div_btn">
<!--                    <cl-button v-if="getIsAuthorization" @click="formShow = !formShow">Подать заявку</cl-button>-->
              <cl-button  @click="formShow = !formShow" type="grad">Стать автором</cl-button>
            </div>
              <cl-modal v-model="formShow" class="modal_form modal_blog" :closeFonActive="false" v-bind="modalAlertMeasureLeaveTopModalAttr()">
                <h3 class="modal-header">
                  Форма для авторов
                </h3>
                  <measure-form-component
                      :measureGuid="dataInfo.measureGuid.value"
                      :showMode="showMode"
                      :form-type="1"
                      @formTitle="formTitleFun"

                      v-bind="modalAlertMeasureLeaveAttr()"
                      v-on="modalAlertMeasureLeaveEmits()"
                  />
              </cl-modal>
          </div>
        </template>
      </div>
      <div class="btn-center" v-if="this.showBtnMore && !dataInfo.showMoreBlogs ">
        <cl-button type="default" @click="showMore">
          Показать еще
        </cl-button>
      </div>
    </div>
  </div>
</template>

<script>
import MeasureFormComponent from "@/components/measureForm.vue";
import ClModal from "@/components/library/ClModal.vue";
import mixinModalAlertMeasureLeave from "@/mixin/mixinModalAlertMeasureLeave";

export default {
  name: "blogList",
  components: {
    ClModal,
    MeasureFormComponent,
  },
  mixins:[mixinModalAlertMeasureLeave],
  props: {
    dataInfo: {
      type: Object,
      require: true
    },
  },
  data() {
    return {
      dataListBlog: this.dataInfo.blogList.items,
      showBtnMore: true,

      pagesShow: this.dataInfo.blogList.page,
      pagesLength: this.dataInfo.blogList.items.length,
      windowResBlogs: 7,

      ormTitle: null,
      formShow: false,
      showMode: 'inline',
    }
  },
  methods: {
    getListBlog(page) {
      this.isLoading = true;
      return this.axios
          .get(`/api/ajax/get/blog`, {params: { page: this.pagesShow, pageSize: this.pagesLength }})
          .then(response => {
            this.isLoading = false;
            this.dataListBlog.push(...response.data.items);
            this.param = response.data.pages;
          })
          .catch(error => {
            this.showError(error);
          });
    },
    showMore(){
      ++this.pagesShow;
      this.getListBlog(this.pagesShow);
      if (this.dataInfo.blogList.pages <= this.pagesShow){
        this.showBtnMore = false

      }
    },
    formTitleFun(name) {
      this.formTitle = name;
    },
    widthElemsHidden(){
      if (window.outerWidth <= 1024) {
        this.windowResBlogs = 5
      }
      if (window.outerWidth <= 991) {
        this.windowResBlogs = 3
      }
      if (window.outerWidth <= 767) {
        this.windowResBlogs = 2
      }
    },
    showMoreBtn(){
      if (this.dataInfo.blogList.total <= 16){
        this.showBtnMore = false
      }
    }
  },
  mounted() {
    this.widthElemsHidden(),
    this.showMoreBtn()
  },
}
</script>

<style lang="scss">
  .modal_blog{
    .modal-header{
      text-transform: uppercase;
    }
  }
  .blog_title{
    margin-bottom: 1.6rem;
  }
  .blog_list{
    margin-top: 140px;
    margin-bottom: 200px;
    &.blog_list_block{
      margin: 60px 0 0 ;
    }
    .blog_list_cont {
      display: flex;
      gap: 24px;
      flex-wrap: wrap;
    }
    &.block_auth2{

    }
    .blog_list_elem{
      width: calc(25% - 18px);
      border-radius: 8px;
      overflow: hidden;
      background: #17191C;
      .img_wrap{
        height: 250px;
        img{
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
        .noneImage{
          background: #d9d9d9;
        }
      }
      .text_content{
        margin-bottom: 16px;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        font-size: 1.8rem;
        p, ul, h1, h2, h3, li{
          margin-bottom: 0;
        }
        img{
          display: none;
        }
      }
      .logo_authr, .mask_logo{
        border-radius: 50%;
        overflow: hidden;
        width: 36px;
        height: 36px;
        img{
          width: 100%;
          height: 100%;
        }
      }
      .mask_logo{
        background: #ED850A;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }

    @media(max-width: 1025px){
      margin-top: 120px;
      .blog_list_elem{
        width: calc(33% - 16px);
      }
    }
    @media(max-width: 991px){
      margin-bottom: 80px;
      .blog_list_elem{
        width: calc(50% - 16px);
      }
    }
    @media(max-width: 767px){
      .blog_list_elem{
        width: calc(100%);
      }
    }
  }
  .btn-center{
    margin-top: 16px;
    display: flex;
    justify-content: center;
    width: 100%;
    @media(max-width: 991px){
      .btn{
        width: 100%;
      }
    }
  }
  .not_location {
    width: 100%;
    border-radius: 12px;
    border: 1px solid #ED850A;
    padding: 40px 40px 40px calc(32% + 24px);
    background: url(@/assets/img/blog_banner_illustration.svg) no-repeat left top;
    background-size: auto 100%;
    width: 100%;
    height: 318px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    .div_tit {
      margin-bottom: 8px;
      max-width: 75%;
    }
    .div_text {
      margin-bottom: 24px;
    }
    @media (max-width: 1024px) {
      .div_tit {
        font-size: 2.4rem;
        font-weight: 500;
        max-width: unset;
      }
      padding-left: 333px;
    }
    @media (max-width: 991px) {
      background: url(@/assets/img/blog_banner_illustration_mobile.svg) no-repeat left top;
      height: fit-content;
      background-position: left center;
      background-size: 309px 226px;
      height: fit-content;
      padding-top: 16px;
      padding-bottom: 16px;
      min-height: 220px;
      .div_tit {
        font-size: 2.1rem;
      }
      .div_text{
        font-size: 1.6rem;
        margin-bottom: 12px;
      }
      .btn{
        min-height: 46px;
      }
    }
    @media (max-width: 767px) {
      background-position: top center;
      padding: 230px 16px 16px 16px;
      text-align: center;
      .div_tit {
        font-size: 1.8rem;
      }
      .div_text {
        font-size: 1.4rem;
      }
    }
  }
  .breadcrumbs.breadcrumbs_abs .container{
    margin-top: 10px;
  }
</style>
