<template>
  <div class="sub_block_black_wrapper" v-if="dataInfo">
    <div class="kinopark_rules sub_block_black">
      <container>
        <h2 class="fs3" v-if="dataInfo.title">{{ dataInfo.title.value }}</h2>
        <div class="kinopark_rules__row">
          <div v-if="leftRules && leftRules.length > 0" class="kinopark_rules__column">
            <div v-for="rule of leftRules" class="kinopark_rule">
              <div v-if="rule.img" class="div_img">
                <img :src="getImage(rule.img, 'hd')" alt="">
              </div>
              <div v-if="rule.text" v-html="rule.text.value" class="div_text"></div>
            </div>
          </div>
          <div v-if="rightRules && rightRules.length > 0" class="kinopark_rules__column" :class="{'d-none': widthSite < 768 && !mobShow}">
            <div v-for="rule of rightRules" class="kinopark_rule">
              <div v-if="rule.img" class="div_img">
                <img :src="getImage(rule.img, 'hd')" alt="">
              </div>
              <div v-if="rule.text" v-html="rule.text.value" class="div_text"></div>
            </div>
          </div>
        </div>
        <div class="mob_btn d-md-none">
          <cl-button class=" w-100" type="light" @click="mobShow = !mobShow"><template v-if="!mobShow">Показать все</template><template v-else>Скрыть все</template></cl-button>
        </div>

      </container>
    </div>
  </div>

</template>

<script>
import Container from "@/components/library/Container.vue";
import ClButton from "@/components/library/ClButton.vue";
import {mapGetters} from "vuex";

export default {
  name: "kinoparkRules",
  components: {ClButton, Container},
  props: {
    dataInfo: {
      type: Object,
      require: true
    },
  },
  data(){
    return {
      mobShow: false
    }
  },
  computed: {
    leftRules() {
      return this.dataInfo && this.dataInfo.listRule && this.dataInfo.listRule.length > 0 ?
          this.dataInfo.listRule.filter((rule, index) => {
            return index <= Math.floor((this.dataInfo.listRule.length - 1) / 2);
          }) : {};
    },

    rightRules() {
      return this.dataInfo && this.dataInfo.listRule && this.dataInfo.listRule.length > 0 ?
          this.dataInfo.listRule.filter((rule, index) => {
            return index > Math.floor((this.dataInfo.listRule.length - 1) / 2);
          }) : {};
    },

    ...mapGetters([
      'widthSite'
    ])
  }
}
</script>

<style lang="scss" scoped>

.kinopark_rules {

  h2 {
    text-align: center;
    margin-bottom: 3.2rem;
    letter-spacing: 0.02em;
  }

  &__row {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 2.4rem;
  }

  &__column {
    display: flex;
    flex-direction: column;
    gap: 4.8rem
  }

  .mob_btn {
    padding-top: 40px;
  }

  @media (max-width: 1440px) {

  }

  @media (max-width: 991px) {

    h2 {
      font-size: 32px;
    }
    &__row {
      grid-template-columns: repeat(1, 1fr);
    }
    &__row, &__column {
      gap: 3.2rem;
    }
  }

  @media (max-width: 767px) {
    h2 {
      font-size: 24px;
    }
    &__row, &__column {
      gap: 2.4rem;
    }

    &__row {
      grid-template-columns: 1fr;
    }
  }
}

.kinopark_rule {
  display: flex;
  align-items: center;
  gap: 4.8rem;

  .div_img {
    flex: none;
    min-width: 4.8rem;
    min-height: 4.8rem;
  }

  .div_text {
    letter-spacing: 0.015em;
  }

  @media (max-width: 991px) {
    gap: 3.2rem;
  }

  @media (max-width: 767px) {
    gap: 2.4rem;
  }
}

</style>