<template>
  <div class="sub_block_black_wrapper">
    <div class="sub_block_black">
      <container>
          <div class="block-info-icons-list row">
            <div class="info col-lg-6">
              <h3 class="title" v-if="dataInfo.title">
                {{dataInfo.title.value}}
              </h3>
              <p class="desc" v-if="dataInfo.desc">{{dataInfo.desc.value}}</p>
              <div class="icons-list" v-if="dataInfo.iconsList">
                <div class="icon-item" v-for="icon of dataInfo.iconsList">
                  <img :src="icon.iconImage.value.src" alt="" v-if="icon.iconImage">
                  <span class="icon-text" v-if="icon.iconText">{{icon.iconText.value}}</span>
                </div>
              </div>
              <cl-button type="light" v-if="dataInfo.buttonText && dataInfo.buttonLink" @click="formShow = !formShow">{{dataInfo.buttonText.value}}</cl-button>
              <cl-modal v-model="formShow" class="modal_form" :closeFonActive="false" v-bind="modalAlertMeasureLeaveTopModalAttr(formShowParams)">
                <h4 class="modal-header" v-if="formTitle">
                  {{ formTitle }}
                </h4>
                <measure-form-component
                    v-if="formShowParams"
                    :measureGuid="dataInfo.buttonLink.value"
                    :showMode="showMode"
                    :formParams="measureFormParams"
                    :form-type="1"
                    @formTitle="formTitleFun"

                    v-bind="modalAlertMeasureLeaveAttr()"
                    v-on="modalAlertMeasureLeaveEmits()"
                />
              </cl-modal>
            </div>
            <div class="block-image col-lg-6">
              <img :src="dataInfo.image.value.src.hd.url" alt="" v-if="dataInfo.image">
            </div>
          </div>
      </container>
    </div>
  </div>
</template>

<script>
import MeasureFormComponent from "@/components/measureForm.vue";
import mixinModalAlertMeasureLeave from "@/mixin/mixinModalAlertMeasureLeave";
import ClModal from "@/components/library/ClModal.vue";

export default {
  name: "blockInfoIconsList",
  components: {ClModal, MeasureFormComponent},
  mixins:[mixinModalAlertMeasureLeave],
  props: {
    dataInfo: {
      type: Object,
      require: true
    },
  },
  data(){
    return{
      formTitle: null,
      formShow: false,
      formShowParams: true,
      showMode: 'inline',
      measureFormParams: {
        preSetParams: {},
        //'appendCss': '/override/modal_wh.css',
      },
    }
  },
  methods:{
    formTitleFun(name) {
      this.formTitle = name;
    },
  }

}
</script>

<style lang="scss" scoped>
  .block-info-icons-list{


    .info{
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: flex-start;
      .title{
        margin-bottom: 0.8rem;
        line-height: 1.5;
        text-transform: uppercase;
        font-weight: 400;
      }

      .desc{
        margin-bottom: 1.6rem;
        font-size: 1.8rem;
        font-weight: 400;
        line-height: 1.33;
        letter-spacing: 0.015em;
        text-align: left;

      }

      .icons-list{
        margin-bottom: 2.4rem;
        .icon-item{
          display: flex;
          gap: 0.8rem;
          margin-bottom: 1.6rem;

          color: #ACB0B9;
        }
      }
    }

    .block-image{
      align-self: stretch;

      img{
        border-radius: 1.6rem;
        width: 100%;
        height: 100%;
        object-fit: cover;
        max-height: 36.6rem;
      }
    }

    @media(max-width: 991px){
      padding:3.2rem;

      flex-direction: column-reverse;
      .info{
        .title{
          font-size: 3.2rem;
        }

        .btn{
          width: 100%;
        }
      }

      .block-image{
        padding: 0;
        max-height: 36rem;
        height: 36rem;
        overflow: hidden;
      }
    }

    @media(max-width: 768px){
      padding: 1.6rem;
      .info{
        .title{
          font-size: 2.4rem;
          text-align: center;
        }

        .desc{
          font-size: 1.6rem;
          text-align: center;
        }
      }
    }
  }
</style>