<template>
  <div v-if="dataInfo && Object.keys(dataInfo).length !== 0" class="services-cards">
    <container>
      <div v-if="dataInfo.title || dataInfo.text" class="div_top_info">
        <h2 v-if="dataInfo.title" class="div_top_info_tit fs3" :style="getCustomTitleColor(dataInfo)">{{dataInfo.title.value}}</h2>
        <div v-if="dataInfo.text" class="div_top_info_text" v-html="dataInfo.text.value" :style="getCustomTextColor(dataInfo)"></div>
      </div>
      <row v-if="dataInfo.cards && dataInfo.cards.length > 0">
        <column
            v-for="card in dataInfo.cards"
            :md="card.isBig ? 6 : 4"
        >
          <card card-type="services" :class="{'big_card': card.isBig}" :route-link="card.linkForm ? card.linkForm.value : ''" :style="getCustomBkgr(card)">
            <div v-if="card.title || card.text" class="div_info">
              <h4 v-if="card.title" class="div_tit" :style="getCustomTitleColor(card)">{{card.title.value}}</h4>
              <div v-if="card.text" class="div_text" v-html="card.text.value" :style="getCustomTextColor(card)"></div>
            </div>
            <div v-if="widthSite > 991 && card.imgDesktop" class="div_img">
              <img :src="getImage(card.imgDesktop)" alt="">
            </div>
            <div v-else-if="widthSite > 767 && card.imgTablet" class="div_img">
              <img :src="getImage(card.imgTablet)" alt="">
            </div>
            <div v-else-if="card.imgMobile" class="div_img">
              <img :src="getImage(card.imgMobile)" alt="">
            </div>
          </card>
        </column>
      </row>
    </container>
  </div>
</template>

<script>

import {mapGetters} from "vuex";

export default {
  name: "cardsServices",
  props: {
    /**
     * Данные из админки
     */
    dataInfo: {
      type: Object,
      require: true
    },
  },
  methods: {
    /**
     * Функция добавления кастомного бэкграунда карточке из админки
     *
     * На вход принимается:
     * 1. Наличие кастомного бэкграунда (isCustomBkgr) (true/false);
     * 2. Тип кастомного бэкграунда (customBkgType) (img - 1, css - 2);
     * 3. Кастомный бэкграунд в виде изображения (customBkgrImg);
     * 4. Кастомный бэкграунд в виде CSS (customBkgrCss).
     * @param {{isCustomBkgr: {value: boolean}, customBkgType: {value: string}, customBkgrImg: object, customBkgrCss: {valuse: string}}} dataCard - параметры карточки
     * @return {{backgroundImage: string}|{backgroundImage: string}|string|string|{background}|string}
     */
    getCustomBkgr(dataCard) {
      if(dataCard.isCustomBkgr && dataCard.customBkgType) {
        switch (dataCard.customBkgType.value) {
          case '1':
            if(dataCard.customBkgrImg) return this.getBkgrImage(dataCard.customBkgrImg);
            return '';
          case '2':
            if(dataCard.customBkgrCss) return {background: dataCard.customBkgrCss.value};
            return '';
          default:
            return '';
        }
      } else {
        return '';
      }
    },

    /**
     * Функция добавления кастомного бэкграунда карточке из админки
     *
     * На вход принимается:
     * 1. Наличие кастомного цвета загаловка (isCustomTitleColor) (true/false);
     * 2. Тип кастомного цвета загаловка (customTitleColorType) (hash - 1, css - 2);
     * 3. Кастомный цвет заголовка в виде хэша (customTitleColorPalette);
     * 4. Кастомный цвет заголовка в виде CSS (customTitleColorCss);
     * @param {{isCustomTitleColor: {value: boolean}, customTitleColorType: {value: string}, customTitleColorPalette: {value: string}, customTitleColorCss: {value: string}}} dataCard - параметры карточки
     * @return {{color}|string}
     */
    getCustomTitleColor(dataCard) {
      if(dataCard.isCustomTitleColor && dataCard.customTitleColorType) {
        switch (dataCard.customTitleColorType.value) {
          case '1':
            if(dataCard.customTitleColorPalette) return {color: dataCard.customTitleColorPalette.value};
            return '';
          case '2':
            if(dataCard.customTitleColorCss) return {color: dataCard.customTitleColorCss.value};
            return '';
          default:
            return '';
        }
      } else {
        return '';
      }
    },

    /**
     * Функция добавления кастомного цвета тексту из админки.
     *
     * На вход принимается:
     * 1. Наличие кастомного цвета текста (isCustomTextColor) (true/false);
     * 2. Тип кастомного цвета текста (customTextColorType) (hash - 1, css - 2);
     * 3. Кастомный цвет текста в виде хэша (customTextColorPalette);
     * 4. Кастомный цвет текста в виде CSS (customTextColorCss);
     * @param {{isCustomTextColor: {value: boolean}, customTextColorType: {value: string}, customTextColorPalette: {value: string}, customTextColorCss: {value: string}}} dataCard
     * @return {{color}|string}
     */
    getCustomTextColor(dataCard) {
      if(dataCard.isCustomTextColor && dataCard.customTextColorType) {
        switch (dataCard.customTextColorType.value) {
          case '1':
            if(dataCard.customTextColorPalette) return {color: dataCard.customTextColorPalette.value};
            return '';
          case '2':
            if(dataCard.customTextColorCss) return {color: dataCard.customTextColorCss.value};
            return '';
          default:
            return '';
        }
      } else {
        return '';
      }
    },
  },
  computed: {
    ...mapGetters([
      'widthSite'
    ]),
  }
}
</script>

<style lang="scss" scoped>
.services-cards {
  padding: 6rem 0;

  .div_top_info {
    margin-bottom: 2.4rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1.6rem;
  }

  .div_top_info_tit {
    text-align: center;
    letter-spacing: 0.02em;
    font-weight: 500;
  }

  .div_top_info_text {
    letter-spacing: 0.015em;
    text-align: center;
    max-width: 60rem;
    color: rgba(107, 114, 128, 1);
  }

  @media(max-width: 991px) {
    .div_top_info_tit {
      font-size: 3.2rem;
    }
  }

  @media(max-width: 767px) {
    .div_top_info_tit {
      font-size: 24px;
    }
  }
}
</style>