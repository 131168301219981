<template>
<!--  v-model="selectSelected"-->
  <multiselect
      class="multiselect_multiple"

      :modelValue="modelValue"
      @update:modelValue="modelValue = $event"

      :class="{'select_active': modelValue.length > 0}"

      :track-by="trackby"
      :label="label"
      :placeholder="placeholder"
      :options="options"
      :searchable="false"
      :multiple="true"
      :close-on-select="false"
      :clear-on-select="false"
      @select="selectChange"
      @remove="selectChange"
      ref="multiselect"

  >
    <template v-slot:selection>
      <div v-if="modelValue.length > 0" class="selected_wrap">
        <div class="multiselect__placeholder">{{ placeholder }}</div>
        <div class="multiselect__length">{{ modelValue.length }}</div>
      </div>
    </template>
    <template v-slot:afterList>
      <div class="multiselect__bottom" v-if="btnShow">
        <cl-button type="light2" class="" @click="modelValue = []; selectChange(); $refs.multiselect.deactivate()">Сбросить</cl-button>
        <cl-button @click="$refs.multiselect.deactivate()">Применить</cl-button>
      </div>
    </template>
    <template v-slot:clear>
          <span v-if="modelValue.length > 0"
                @click="$emit('clearFilter')"
                class="multiselect__clear img_wh"
          ><img src="/img/ico/close.svg"></span>
    </template>
  </multiselect>
</template>

<script>
export default {
  name: "selectAndBtn",
  props: {
    modelValue: [Array, String, Number],
    trackby: [String, Number],
    label: [String, Number],
    options: {
      type: Array,
      required: true
    },
    placeholder: {
      type: String
    },
    btnShow: {
      type: Boolean,
      default: true
    }
  },
  methods: {
    /**
     * $emit выборка элементов
     */
    selectChange(){
      this.$emit('update:modelValue', this.modelValue);
      this.$emit('selectChange', this.modelValue);
    },
  },
  emits: ['clearFilter'],
}
</script>

<style lang="scss" scoped>

</style>