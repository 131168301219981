<template>
  <div class="mainBigBlock" :style="[ dataInfo.img ? { backgroundImage: `url(${getImage(dataInfo.img)})` } : '' ]">
    <video preload="metadata" v-if="dataInfo.video" autoplay muted loop playsinline id="videoMainTop">
      <source :src="dataInfo.video.value.src" type='video/mp4; codecs="avc1.42E01E, mp4a.40.2"'>
    </video>
    <container>
      <h1><span class="sp1" v-if="dataInfo.title1" v-html="dataInfo.title1.value"></span><span class="sp2" v-if="dataInfo.title2" v-html="dataInfo.title2.value"></span><span class="sp3" v-if="dataInfo.title3" v-html="dataInfo.title3.value"></span></h1>
      <filter-tabs
          v-if="dataInfo.tab1"
          :tabActive="filter.typeCategory"
          :tabList="filter.typeCategoryOptions"
          :tabSample="1"
          label="name"
          tabKey="value"
          class="filter-ico"
          @changeTab="$store.commit('updateCategoryPeople', filter.typeCategory[0])"
      ></filter-tabs>

      <div v-if="tagS" class="div_tag_wrap">
        <template v-for="tag of tagS">
          <router-link
              v-if="tag.url || tag.anchor"
              :to="tag.url ? tag.url.value : tag.anchor"
              class="div_tag"
          >{{ tag.title.value }}</router-link>

        </template>
      </div>
    </container>

  </div>
</template>

<script>

import {mapGetters} from "vuex";

// Что бы блок на страницы отображался в зависимости от выбранной категории надо ему добавить id tabCategoryPeople[номер идет от 0,1,2,3][название блока]. Например tabCategoryPeople0News

export default {
  name: "mainBigBlockText",
  props: {
    dataInfo: {
      type: Object,
      require: true
    },
  },
  data() {
    return {
      filter: {
        typeCategory: [],
        typeCategoryOptions: [
          {
            id: 1,
            value: "tabCategoryPeople0",
            name: this.dataInfo?.tab1?.value,
            "icoHtml": `<img src="${this.getImage(this.dataInfo?.tab1Ico?.value)}">`
          },
          {
            id: 2,
            value: "tabCategoryPeople1",
            name: this.dataInfo?.tab2?.value,
            "icoHtml": `<img src="${this.getImage(this.dataInfo?.tab2Ico?.value)}">`
          },
        ],
      }

    }
  },
  methods: {
    toggleCategoryPeopleBlock(){
      document.querySelectorAll('[id^="tabCategoryPeople"]').forEach(element => {
        if (element.id.indexOf(this.getCategoryPeople) != -1) {
          element.classList.remove('d-none');
        } else {
          element.classList.add('d-none');
        }
      });
    }
  },
  computed: {
    ...mapGetters([
      'getCategoryPeople',
      'widthSite'
    ]),
    tagS(){
      return [...this.dataInfo.tag].filter(item => (item.tab1 && this.filter.typeCategory[0] == "tabCategoryPeople0") || (item.tab2 && this.filter.typeCategory[0] == "tabCategoryPeople1"));
    },
  },
  watch: {
    getCategoryPeople(){
      this.filter.typeCategory =  [this.getCategoryPeople];
      this.toggleCategoryPeopleBlock();
    }
  },
  mounted() {
    this.filter.typeCategory =  [this.getCategoryPeople];
    this.$nextTick(() => {
      this.toggleCategoryPeopleBlock();
    });
    const video = document.getElementById('videoMainTop');
    if(video){
      video.muted = true; // Отключение звука для предотвращения блокировки автовоспроизведения
      video.play().catch((error) => {
        //console.error('Автовоспроизведение заблокировано', error);
      });
    }
  }
}
</script>

<style lang="scss" scoped>
.mainBigBlock {
  min-height: 760px;
  overflow: hidden;
  gap: 48px;
  border-radius: 64px;
  background-position: center top;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  margin: 80px auto 100px auto;
  padding: 80px 0;
  max-width: 1440px;
  width: 100%;
  video {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    width: 100%;
  }
  .container {
    position: relative;
    //padding: 8rem 6rem;
  }
  h1 {
    font-family: Playfair Display;
    font-size: 11rem;
    font-weight: 400;
    line-height: 1;
    letter-spacing: 0.01em;
    display: flex;
    flex-direction: column;
    gap: .8rem;
    margin-bottom: 48px;
    .sp2 {
      padding-left: 91px;
    }
  }
  .filter-ico {
    margin-bottom: 48px;
    gap: 0;
    border-radius: 100rem;
    border: 3px solid #F1F2F3;
    display: inline-flex;
    background: #FDFDFDCC;
    //box-shadow: 0px -3px 10px 0px #0000001F inset;
    box-shadow: 0px 3px 10px 0px #0000001F inset;

    :deep(.filter_tabs_el:first-child) .btn.active  {
      box-shadow: 4px 0 8px 0 rgba(0, 0, 0, 0.1), 14px 0 14px 0 rgba(0, 0, 0, 0.09), 32px 0 19px 0 rgba(0, 0, 0, 0.05), 58px 0 23px 0 rgba(0, 0, 0, 0.01), 90px 0 25px 0 rgba(0, 0, 0, 0);

    }
    :deep(.filter_tabs_el) .btn.active {
      box-shadow: -4px 1px 9px 0 rgba(0, 0, 0, 0.1), -16px 3px 17px 0 rgba(0, 0, 0, 0.09), -37px 7px 23px 0 rgba(0, 0, 0, 0.05), -66px 12px 27px 0 rgba(0, 0, 0, 0.01), -103px 19px 29px 0 rgba(0, 0, 0, 0);
    }

    :deep(.btn) {
      padding: 11px 32px;
      border: none;
      height: 70px;
      display: flex;
      align-items: center;
      border-radius: 100px;
      text-transform: uppercase;
      color: #000106;
      transition: all 0.5s ease;
      &:hover {
        background: rgba(0, 0, 0, 0);
        &.active {
          background: #ED850A;
        }
      }
      img {
        width: 48px;
        transition: all 0.5s ease;
      }
      &.active {
        color: #ffffff;
        pointer-events: none;
        //animation: scaleAnimation1 3s  alternate;
        img {
          filter: brightness(0) invert(1);
        }
      }
      //@keyframes scaleAnimation1 {
      //  0% {
      //    color: #000106;
      //  }
      //  100% {
      //    color: #ffffff;
      //  }
      //}
      .text {
        display: flex;
        align-items: center;
        gap: 8px;
        svg {
          display: block;
        }
        &.active {
          svg {
            stroke: #ffffff;
          }
        }
      }
    }
  }
  .div_tag_wrap {
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
    max-width: 996px;
  }
  .div_tag {
    padding: 12px 16px;
    gap: 8px;
    border-radius: 100px;
    border: 1px solid #ED850A;
    background: rgba(0, 0, 0, .8);
    //
    //transition: all .5s ease;
    //&:nth-child(3),
    //&:nth-child(4) {}
  }
  @media (max-width: 1440px) {
    border-radius: 0;
    video {
      height: 100%;
      width: auto;
      left: 50%;
      transform: translateX(-50%);
    }
  }
  @media (max-width: 1200px) {
    min-height: 600px;
  }
  @media (max-width: 991px) {
    margin: 0 0 80px 0;
    min-height: 900px;
    h1 {
      font-size: 80px;
    }
  }
  @media (max-width: 767px) {
    margin: 0 0 60px 0;
    min-height: 721px;
    h1 {
      font-size: 43px;
      .sp1 {
        padding-left: 61px;
      }
      .sp2 {
        padding: 0;
      }
    }
    .filter-ico {
      flex-wrap: nowrap;
      margin-bottom: 24px;
      :deep(div){
        flex: none;
      }
      :deep(.btn) {
        font-size: 14px;
        padding: 1.1rem 1.1rem;
        height: 42px;
        min-height: 10px;
        font-weight: 500;
        .text {
          gap: 0;
          > div:first-child {
            display: none;
          }
        }
        .sp_ico_text {
          text-align: left;
        }
      }
    }
    .div_tag {
      font-size: 12px;
    }
  }
}
</style>