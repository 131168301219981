<template>
  <slot/>
</template>

<script>
export default {
  name: "ContainerWidth1200",
  data() {
    return {

    }
  },
  mounted() {
    document.body.classList.add("body_container");
  },
  beforeUnmount() {
    document.body.classList.remove("body_container");
  },
}
</script>

<style scoped>

</style>