<template>
  <h2>props1 dataInfo</h2>
  <div v-if="dataInfo" style="margin-bottom: 40px;">
    {{ dataInfo.title }}<br>
    {{ dataInfo.text }}
  </div>
  <h2>props2 dataInfo2</h2>
  <div>
    {{ dataInfo2.title }}<br>
    {{ dataInfo2.text }}
  </div>

</template>

<script setup lang="ts">
import {defineEmits, onMounted, PropType, withDefaults} from 'vue';
import {ITestComponentTsProps} from "@/interface/props/ITestComponentTsProps";


/**
 * @typedef {Object} Props
 * @property { title: string, text: string } dataInfo - тайтл
 * @property { title: string, text: string } dataInfo2 -значение счетчика
 */

/** @type {Props} */
const props = defineProps<ITestComponentTsProps>()

// случай когда задаем значения по умолчанию
// const  props = withDefaults(defineProps<ITestComponentTs>(), {
//   dataInfo: { title: 'string', text: 'string' },
//   dataInfo2: { title: 'string', text: 'string' }
// })
//версия 3.5
//const  { dataInfo = { title: 'string', text: 'string' }, dataInfo2 = { title: 'string', text: 'string' }}  = defineProps<ITestComponentTs>()

/**
 * пример пропсов по другому
 */
// interface dataInfo2 {
//   title: string,
//   text: string
// }
// const props = defineProps({
//   dataInfo: {
//     type: Object as PropType<{ title: string, text: string }>,
//     required: true,
//     default: {
//       title: 'title',
//       text: 'text'
//     }
//   },
//   dataInfo2: Object as PropType<dataInfo2>,
// })


/**
 * тестируем emit
 */
const emit = defineEmits<{
  customEvent: (data: string) => void, // Типизируем emit 'customEvent' с данными типа string
}>();
//const emit = defineEmits(['close'])


onMounted(()=>{
  emit('customEvent', 'dataInfo2');
})


</script>
<style lang="scss" scoped>

</style>