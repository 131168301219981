<template>
  <div v-if="dataInfo" class="sub_block_black_wrapper">
    <div class="sub_block_black">
      <container class="banner_container">
        <div class="banner_wrapper" :style="dataInfo.img ? getBkgrImage(dataInfo.img) : ''">
          <div class="banner">
            <div v-if="dataInfo.title || dataInfo.text" class="banner__info">
              <h1 v-if="dataInfo.title" class="banner__title">{{dataInfo.title.value}}</h1>
              <div v-if="dataInfo.text" class="banner__text">{{dataInfo.text.value}}</div>
            </div>
            <div v-if="dataInfo.fioName || dataInfo.fioText || dataInfo.fioImg || dataInfo.fioAudio" class="banner_card">
              <div v-if="dataInfo.fioImg" class="div_img">
                <img :src="getImage(dataInfo.fioImg)" alt="">
              </div>
              <div v-if="dataInfo.fioName" class="div_fio">{{dataInfo.fioName.value}}</div>
              <div v-if="dataInfo.fioText" class="div_text">{{dataInfo.fioText.value}}</div>
              <div v-if="dataInfo.fioAudio" class="audio_people">
                <Vue3WaveAudioPlayer
                    :wave_width="189"
                    :wave_height="24"
                    wave_type="mirror"
                    :src="dataInfo.fioAudio.value.src"
                    :wave_options='{"samples":24,"type":"steps","width":189,"height":24,"disable_seeking":true}'
                />
              </div>
            </div>
          </div>
        </div>
      </container>
    </div>
  </div>
</template>

<script>
import Vue3WaveAudioPlayer from 'vue3-wave-audio-player'
import Container from "@/components/library/Container.vue";
export default {
  name: "bigBlockAndAudio",
  components: {
    Container,
    Vue3WaveAudioPlayer
  },
  props: {
    dataInfo: {
      type: Object,
      require: true,
    }
  },
  mounted() {
    document.body.classList.add("cinemapark-moskino");
  },
  beforeUnmount() {
    document.body.classList.remove('cinemapark-moskino');
  },
}
</script>

<style lang="scss" scoped>
:global(.cinemapark-moskino) {
  background-image: url(../assets/img/moskino_fon.svg);
  background-position: center -500px;
  background-repeat: no-repeat;
}
:global(.cinemapark-moskino .smi_black) {
  background-color: rgba(0, 0, 0, 0) !important;
}

.sub_block_black_wrapper {
  @media (max-width: 767px) {
    padding-bottom: 350px;
  }
}

.sub_block_black {
  padding: 0;
  background: none;
}

.banner_container {
  padding: 0;
  max-width: 1320px;
}

.banner_wrapper {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  border-radius: 6.4rem;
  height: 75.2rem;
}

.banner {
  height: calc(100% + 1px);
  border-radius: 6.4rem;
  width: 100%;
  position: relative;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 61.98%, rgba(0, 0, 0, 0.5) 68.15%, rgba(0, 0, 0, 1) 100%);

  &__info {
    position: absolute;
    left: 12rem;
    bottom: 4.8rem;
    margin-right: 42rem;
  }

  &__title {
    font-family: Playfair Display;
    font-size: 8rem;
    font-weight: 400;
    line-height: 1;
    letter-spacing: 0.01em;
    margin-bottom: 0.8rem;
  }

  &__text {
    font-family: Raleway;
    font-size: 2.4rem;
    font-weight: 400;
    line-height: 1.33;
    letter-spacing: 0.015em;
  }

  @media (max-width: 1440px) {
    &__info {
      margin-right: 30rem;
    }
  }

  @media (max-width: 1024px) {
    &__info {
      left: 4.8rem;
    }
  }

  @media (max-width: 991px) {
    &__info {
      left: 3.2rem;
    }

    &__title {
      font-size: 6.4rem;
      line-height: 1;
      letter-spacing: 0.01em;
    }

    &__text {
      font-size: 1.8rem;
      line-height: 1.33;
      letter-spacing: 0.015em;
    }
  }

  @media (max-width: 767px) {
    &__info {
      margin-right: 1.6rem;
      left: 1.6rem;
    }

    &__title {
      font-size: 5.6rem;
      line-height: 1;
      letter-spacing: 0.01em;
    }
  }
}

.banner_card {
  background: rgba(255, 255, 255, 0.8);
  border-radius: 1.6rem;
  position: absolute;
  bottom: -6.825rem;
  right: 12rem;
  padding: 1.05rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 28.2rem;
  text-align: center;
  gap: 0.6rem;

  .div_img {
    height: 26.1rem;
    width: 26rem;
    overflow: hidden;
    border-radius: 1.6rem;
  }

  .div_fio {
    font-family: Raleway;
    font-size: 2.4rem;
    line-height: 1.25;
    letter-spacing: 0.015em;
    color: rgba(51, 42, 42, 1);
  }

  .div_text {
    font-family: Raleway;
    font-size: 1.35rem;
    line-height: 1.33;
    letter-spacing: 0.015em;
    color: rgba(51, 42, 42, 1);
  }

  @media (max-width: 1440px) {
    right: 0;
  }

  @media (max-width: 767px) {
    left: 50%;
    bottom: 0;
    transform: translate(-50%, 100%);
  }
}

.audio_people {
  width: 100%;
  :deep(#play){
    flex: none;
    width: 30px;
    height: 30px;
    border-radius: 100%;
    background: #ED850A;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    svg {
      fill: rgba(255, 255, 255, 0.8) !important;
      width: 20px !important;
      height: 20px !important;
      margin-left: 2px;
    }
  }
  :deep(#current-time),
  :deep(#duration){
    display: none !important;
  }
  :deep(input) {
    opacity: 0;
  }
  :deep(#path1){
    stroke: #ED850A;
  }
  :deep(#path2){
    stroke: #FDC788;
  }
}
</style>