<template>
  <div class="blog_single">
    <div class="container container_blog_single">
      <div class="blog_single_header" onclick="history.back()">
        <a class="backward_blog">
          <img src="/img/ico/arrow_left_orange.svg" alt="">
          <span> Назад </span>
        </a>
      </div>
      <div class="header_content">
          <h3>
            {{ dataInfo.selectedBlog.name }}
          </h3>
        <div class="block_authr block_single_authr">
          <div class="auth_desc">
            <div class="logo_authr">
              <template v-if="dataInfo.selectedBlog.author.logo !== null">
                <img :src="dataInfo.selectedBlog.author.logo.url" alt="">
              </template>
              <template v-else>
                <div class="mask_logo">
                  {{ dataInfo.selectedBlog.author.name.split(" ").map((word) => word[0]).join('') }}
                </div>
              </template>
            </div>
            <div class="auth_stat">
              <div class="auth_name" v-if="dataInfo.selectedBlog.author.name">
                {{ dataInfo.selectedBlog.author.name }}
              </div>
              <div class="auth_sub">
                <div class="auth_date" v-if="dataInfo.selectedBlog.datePublication">
                  {{ $filters.ruDate4(new Date(dataInfo.selectedBlog.datePublication)) }}
                </div>
                <div class="auth_watch" v-if="dataInfo.selectedBlog.viewsCount">
                  <img src="/img/ico/eye.svg" alt="">
                  {{ dataInfo.selectedBlog.viewsCount }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="text_content" v-html="dataInfo.selectedBlog.content"></div>
        <div class="source_link">
          <a :href="dataInfo.selectedBlog.source" target="_blank">
            <img src="/img/ico/source_link.svg" alt="">
            <span class="desc_source">Ссылка на источник</span>
          </a>
        </div>
        <div class="not_location singleBlog">
          <div class="flex_e flex_e1">
            <img src="/img/blog_banner_illustration.svg" class="d-none d-lg-block" alt="">
            <img src="/img/blog_banner_illustration_mobile.svg" class="d-block d-lg-none" alt="">
          </div>
          <div class="flex_e flex_e2">
            <h4 class="div_tit"> {{ dataInfo.bannerTittle.value }} </h4>
            <div class="div_text"> {{ dataInfo.bannerDescription.value }} </div>
            <div class="div_btn">
              <!--                    <cl-button v-if="getIsAuthorization" @click="formShow = !formShow">Подать заявку</cl-button>-->
              <cl-button  @click="formShow = !formShow" type="grad">Стать автором</cl-button>
            </div>
          </div>
          <cl-modal v-model="formShow" class="modal_form" :closeFonActive="false" v-bind="modalAlertMeasureLeaveTopModalAttr()">
            <h3 class="modal-header">
              Форма для авторов
            </h3>
            <measure-form-component
                :measureGuid="dataInfo.measureGuid.value"
                :showMode="showMode"
                :form-type="1"
                @formTitle="formTitleFun"

                v-bind="modalAlertMeasureLeaveAttr()"
                v-on="modalAlertMeasureLeaveEmits()"
            />
          </cl-modal>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MeasureFormComponent from "@/components/measureForm";
import ClModal from "@/components/library/ClModal.vue";
import mixinModalAlertMeasureLeave from "@/mixin/mixinModalAlertMeasureLeave";
import {updateMetaTags} from "@/assets/js/updateMetaTags";

export default {
  name: "blogList",
  components: {
    ClModal,
    MeasureFormComponent,
  },
  mixins: [mixinModalAlertMeasureLeave],
  props: {
    dataInfo: {
      type: Object,
      require: true
    },
  },
  data() {
    return {
      formShow: false,
      showMode: "inline"
    }
  },
  methods: {
    addBreadAdaptive(){
      // setTimeout(() => {
        let newBread = document.querySelector('.breadcrumbs .breadcrumbs_a').cloneNode(true),
            razdelBread = document.querySelector('.breadcrumbs .breadcrumbs_a .sp_line').cloneNode(true),
            parentBread = document.querySelector('.breadcrumbs .container');
        // console.log(parentBreadLast)
        document.querySelector('.breadcrumbs .container').appendChild(razdelBread);
        newBread.innerHTML = (this.dataInfo.selectedBlog.name);
        parentBread.appendChild(newBread);
      // }, 3000)

    }
  },
  mounted() {
    updateMetaTags(this.$store.dataMeta, this.dataInfo?.selectedBlog?.name)
    this.addBreadAdaptive()
    document.querySelector('.breadcrumbs').classList.add('w800');
  },
}
</script>

<style lang="scss">
  .backward_blog{
    display: flex;
    font-size: 1.8rem;
    gap: 8px;
    align-items: center;
    margin-bottom: 24px;
    color: #ED850A;
    font-weight: 500;
    &:hover{
      color: #ED850A;
      img{
        left: -5px;
      }
    }
    img{
      position: relative;
      left: 0;
      transition: left .4s ease;
    }
  }
  .breadcrumbs.w800 .container{
    max-width: 804px;
    @media (max-width: 1025px) {
      max-width: calc(810px + 64px);
    }
  }
  .blog_single{
    padding-top: 148px;
    padding-bottom: 80px;
    .container{
      max-width: 804px;
    }
    .header_content{
      h3{
        text-transform: uppercase;
      }
      .text_content{
        img{
          height: auto !important;
          object-fit: contain;
        }
      }
    }
    @media(max-width: 1025px){
      .container{
        max-width: calc(810px + 64px);
      }
    }
    @media(max-width: 991px){
      .header_content{
        h3{
          font-size: 3.2rem;
          margin-bottom: 12px;
        }
      }
    }
    @media(max-width: 991px){
      padding-bottom: 64px;
      .header_content{
        h3{
          font-size: 3.2rem;
          margin-bottom: 4px;
        }
      }
    }
  }
  .block_authr.block_single_authr, .source_link{
    margin-bottom: 32px;
    padding-bottom: 32px;
    border-bottom: 1px solid white;
    &.text_content{
      margin-bottom: 24px;
      font-size: 1.8rem;
    }
    @media(max-width: 767px){
      padding-left: 0;
      padding-right: 0;
    }
  }
  .source_link{
    .desc_source{
      color: #ED850A;
      text-decoration-line: underline;
      font-size: 1.8rem;
      line-height: 24px;
      @media(max-width: 767px){
        font-size: 1.6rem;
      }
    }
    img{
      margin-right: 8px;
    }
  }
  .not_location.singleBlog {
    width: 100%;
    border-radius: 12px;
    border: 1px solid #ED850A;
    padding: 0;
    background: unset;
    display: flex;
    gap: 24px;
    background-size: auto 100%;
    flex-direction: row;
    min-height: fit-content;
    justify-content: center;
    height: fit-content;
    align-items: center;
    .flex_e{
      width: 50%;
      &.flex_e1{
        padding-bottom: 2px;
        padding-top: 2px;
      }
      &.flex_e2{
        padding: 16px 0;
      }
    }
    .div_tit {
      margin-bottom: 8px;
      max-width: 75%;
    }
    .div_text {
      margin-bottom: 24px;
    }
    @media (max-width: 1024px) {
      .div_tit {
        font-size: 2.4rem;
        font-weight: 500;
        max-width: unset;
      }
    }
    @media (max-width: 991px) {
      .div_tit {
        font-size: 2.1rem;
      }
      .div_text{
        font-size: 1.6rem;
        margin-bottom: 12px;
      }
      .btn{
        min-height: 46px;
      }
    }
    @media (max-width: 767px) {
      height: fit-content;
      flex-direction: column;
      .div_tit {
        font-size: 1.8rem;
      }
      .div_text {
        font-size: 1.4rem;
      }
      .flex_e{
        width: 100%;
        &.flex_e2{
          padding: 16px;
        }
      }
    }
  }
  .breadcrumbs.breadcrumbs_abs .container{
    margin-top: 10px;
  }
  .logo_authr, .mask_logo{
    border-radius: 50%;
    overflow: hidden;
    width: 36px;
    height: 36px;
    img{
      width: 100%;
      height: 100%;
    }
  }
  .mask_logo{
    background: #ED850A;
    display: flex;
    justify-content: center;
    align-items: center;
  }
</style>