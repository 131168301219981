<template>
  <div v-if="dataInfo && Object.keys(dataInfo).length !== 0" class="sub_block_black_wrapper">
    <div class="sub_block_black">
      <container>
        <div class="footer_quest" :class="{'footer_quest__cont_vert_center': dataInfo.isVerticalCenter}">
          <div class="footer_quest_left">
            <h2 v-if="dataInfo.title" class="fs3">{{ dataInfo.title.value }}</h2>
            <div v-if="dataInfo.text" class="div_text">{{dataInfo.text.value}}</div>
            <div v-if="dataInfo.phone" class="div_flex div_flex1">
              <div class="div_img">
                <img src="/img/ico/phone.svg" alt="" class="img_wh">
              </div>
              <a :href="'tel:' + dataInfo.phone.value" class="div_phone">{{ dataInfo.phone.value }}</a>
            </div>
            <div v-if="dataInfo.email" class="div_flex div_flex2">
              <div class="div_img">
                <img src="/img/ico/mail.svg" alt="" class="img_wh">
              </div>
              <a :href="'mailto:' + dataInfo.email.value" class="div_email">{{ dataInfo.email.value }}</a>
            </div>
            <h4 v-if="dataInfo.subTitle && (dataInfo.vkLink || dataInfo.telegramLink)">{{ dataInfo.subTitle.value }}</h4>
            <div v-if="dataInfo.vkLink || dataInfo.telegramLink" class="div_flex_social">
              <a v-if="dataInfo.telegramLink" :href="dataInfo.telegramLink.value" class="div_link">
                <img src="/img/ico/tg.svg" alt="">
              </a>
              <a v-if="dataInfo.vkLink" :href="dataInfo.vkLink.value" class="div_link">
                <img src="/img/ico/vk.svg" alt="">
              </a>
            </div>
            <div class="div_btn" v-if="dataInfo.btnText && dataInfo.btnLink">
              <cl-button type="light" link :href="dataInfo.btnLink.value">{{dataInfo.btnText.value}}</cl-button>
            </div>
          </div>
          <div class="footer_quest_right">
            <div class="div_img">
              <img v-if="dataInfo.img" :src="getImage(dataInfo.img, 'hd')" alt="">
            </div>
          </div>
        </div>
      </container>
    </div>
  </div>

</template>

<script>
import Container from "@/components/library/Container.vue";
import ClButton from "@/components/library/ClButton.vue";

export default {
  name: "footerQuestWithoutForm",
  components: {ClButton, Container},
  props: {
    dataInfo: {
      type: Object,
      require: true,
    },
  },
}
</script>

<style lang="scss" scoped>
.footer_quest {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 2.4rem;

  h2 {
    margin-bottom: 0.8rem;
  }

  &__cont_vert_center {
    align-items: center;
  }

  @media (max-width: 991px) {
    grid-template-columns: repeat(1, 1fr);
    h2 {
      font-weight: 500;
      font-size: 3.2rem;
    }

  }

  @media (max-width: 767px) {
    h2 {
      font-size: 2.4rem;
      margin-bottom: 1.6rem;
    }
  }
}

.footer_quest_left {
  .div_flex {
    display: flex;
    align-items: center;
    gap: 2.4rem;
    font-size: 2.4rem;
  }

  .div_flex1 {
    margin-bottom: 1.6rem;
  }

  .div_flex2 {
    margin-bottom: 2.4rem;
  }

  .div_img {
    flex: none;
    width: 3.2rem;
    height: 3.2rem;
    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  .div_text {
    margin-bottom: 1.6rem;
  }

  .div_phone, .div_email {
    color: rgba(237, 133, 10, 1);
  }

  h4 {
    margin-bottom: 0.8rem;
  }

  .div_flex_social {
    display: flex;
    gap: 1.6rem;
    margin-bottom: 2.4rem;
  }

  .div_link {
    width: 6.4rem;
    height: 6.4rem;

    img {
      width: 100%;
      height: 100%;
    }
  }

  > :last-child {
    margin-bottom: 0;
  }

  @media (max-width: 991px) {
    order: 2;
    h4 {
      font-size: 2.4rem;
    }
  }

  @media (max-width: 767px) {
    h4 {
      font-size: 1.8rem;
    }
    .div_flex {
      font-size: 1.8rem;
    }
  }
}

.footer_quest_right {
  .div_img {
    border-radius: 1.6rem;
    width: 100%;
    height: 100%;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 1.6rem;
    }
  }

  @media (max-width: 991px) {
    order: 1;
  }
}

.footer_quest_v2 {
  @media (max-width: 991px) {
    h2 {
      font-size: 2.4rem !important;
      line-height: 1.33;
    }

    .footer_quest_left {
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;

      .div_btn {
        width: 100%;
        .btn {
          width: 100%;
        }
      }
    }

    .footer_quest_right {
      .div_img {
        img {
          max-height: 30rem;
        }
      }
    }
  }
  
  @media (max-width: 767px) {
    .footer_quest_left {
      .div_text {
        font-size: 1.4rem;
        line-height: 1.428;
        letter-spacing: 0.015em;
      }

      .div_flex {
        font-size: 1.8rem;
        line-height: 1.33;
        letter-spacing: 0.015em;
      }
    }
  }
}

</style>