<template>
    <teleport to="#block_proj" v-if="this.isMountedComponent">
      <cl-button type="light btn-middle" @click="showSelectedForm()">
        <img src="/img/ico/orange_plus.svg">
        {{ dataInfo.textButton?.value }}
      </cl-button>
    </teleport>
    <cl-modal v-model="showForm" :closeFonActive="false" class=" modal_form modal_projects_add">
      <div class="title" v-if="dataInfo.title?.value">
        <h3 class="mb-24">{{ dataInfo.title.value }}</h3>
      </div>
      <row>
        <column md="12"><h3 class="fw400 mb-0 text-center">Согласование съемок</h3></column>
        <template v-for="item in dataInfo.list">
          <column md="12">
            <card cardType="projects-add">
              <row>
                <column md="3">
                  <div class="ico" v-if="item.image">
                    <img :src="getImage(item.image)" alt="">
                    <div class="card_title" v-if="item.title?.value">{{ item.title.value }}</div>
                  </div>
                </column>
                <column md="7">
                  <div class="card_desc" v-if="item.desc?.value">{{ item.desc.value }}</div>
                </column>
                <column md="2">
                  <cl-button type="light" class="btn-middle" :link="true" :href="item.link?.value" target="_blank">Выбрать</cl-button>
                </column>
              </row>
            </card>
          </column>
        </template>
        <column md="12"><h3 class="fw400 mb-0 text-center">Дополнительно</h3></column>
        <template v-for="item in dataInfo.listSecond">
          <column md="12">
            <card cardType="projects-add">
            <row>
              <column md="3">
                <div class="ico" v-if="item.image">
                  <img :src="getImage(item.image)" alt="">
                  <div class="card_title" v-if="item.title?.value">{{ item.title.value }}</div>
                </div>
              </column>
              <column md="7">
                <div class="card_desc" v-if="item.desc?.value">{{ item.desc.value }}</div>
              </column>
              <column md="2">
                <cl-button type="light" class="btn-middle" :link="true" :href="item.link?.value" target="_blank">Выбрать</cl-button>
              </column>
            </row>
          </card>
          </column>
        </template>
      </row>
    </cl-modal>

    <cl-modal v-if="dataInfo.isWidget" class="modal-iframe-excursions" v-model="isShowModalIframe">
      <loader
          :animation-duration="3000"
          :size="60"
          :color="'#ED850A'"
          v-if="isShowModalIframeLoader"
      ></loader>
      <iframe v-show="!isShowModalIframeLoader" class="iframe-excursions" ref="iframeModalOr" :src="dataInfo.widgetLink.value"/>
    </cl-modal>
</template>

<script>
import MeasureFormComponent from "@/components/measureForm.vue";
import {mapGetters} from "vuex";
import Column from "@/components/library/Column.vue";
import ClButton from "@/components/library/ClButton.vue";
import {list} from "quill/ui/icons";
import ClModal from "@/components/library/ClModal.vue";

export default {
    name: "blockButtonKinokomisProj",
    components: {ClModal, ClButton, Column, MeasureFormComponent},
    props: {
        dataInfo: {
            type: Object,
            require: true
        },
    },
    data(){
        return{
            showForm: false,
            showMode: 'inline',
            formParams: {
                'appendCss': '/override/measure.css',
            },
            isMountedComponent: false,
            isShowModalIframe: false,
            isShowModalIframeLoader: true,
            elementId: 'block_proj'
        }
    },
    computed: {
      list() {
        return list
      },
      ...mapGetters([
          'widthSite',
          'getUser'
      ])
    },
    methods: {
      showModalIframe(){
        this.isShowModalIframe = !this.isShowModalIframe;
        this.$nextTick(()=>{
          if(this.$refs.iframeModalOr){
            this.$refs.iframeModalOr.addEventListener('load', () => {
              this.isShowModalIframeLoader = false;
            });
          } else {
            this.isShowModalIframeLoader = false;
          }
        });
      },

      showSelectedForm() {
        if(this.dataInfo?.isWidget) {
          if(this.dataInfo?.widgetLink) {
            this.showModalIframe();
          }
        } else {
          this.showForm = !this.showForm;
        }
      }
    },
    mounted() {
      document.querySelector('body').classList.add('button_kinomiks');

    },
    watch:{
      elementId: {
        handler: function (val) {
          this.$nextTick(() => {
            let el = document.getElementById(val);
            if (el) {
              this.isMountedComponent = true;
            } else {
              console.log('Элемент не существует');
            }
          });
        },
        immediate: true
      }
    },
    beforeUnmount() {
      document.querySelector('body').classList.remove('button_kinomiks')
    },
}
</script>

<style lang="scss" scoped>

</style>