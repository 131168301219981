<template>
  <container>
    <loader
        v-if="!data"
        :animation-duration="3000"
        :size="60"
    ></loader>
    <div class="film_grants_card" v-else>
      <h1 class="title hasSubtit" v-if="data.name">{{ data.name }}</h1>
      <row class="list_conditions">
        <column class="col-conditions" md="4" v-if="data.amountSupportTo && dataInfo.labelFinac?.value">
          <div class="item_conditions">
            <div class="div_tit">{{ dataInfo.labelFinac.value }}</div>
            <div class="div_val">до {{ data.amountSupportTo }} млн ₽</div>
          </div>
        </column>
        <column class="col-conditions" md="4" v-else-if="data.amountSupportFrom && dataInfo.labelFinac?.value">
          <div class="item_conditions">
            <div class="div_tit">{{ dataInfo.labelFinac.value }}</div>
            <div class="div_val">от {{ data.amountSupportFrom }} млн ₽</div>
          </div>
        </column>
        <column class="col-conditions" md="4" v-if="data.isElectronicSubmission && dataInfo.labelForm?.value && dataInfo.textElcForm?.value">
          <div class="item_conditions">
            <div class="div_tit">{{ dataInfo.labelForm.value }}</div>
            <div class="div_val">{{ dataInfo.textElcForm.value }}</div>
          </div>
        </column>
        <column class="col-conditions" md="4" v-else-if="dataInfo.labelForm?.value && dataInfo.textBumForm?.value">
          <div class="item_conditions">
            <div class="div_tit">{{ dataInfo.labelForm.value }}</div>
            <div class="div_val">{{ dataInfo.textBumForm.value }}</div>
          </div>
        </column>
        <column class="col-conditions" md="4" v-if="data.supportBeginReceiveDate && data.supportEndReceiveDate && dataInfo.labelDate?.value">
          <div class="item_conditions">
            <div class="div_tit">{{ dataInfo.labelDate.value }}</div>
            <div class="div_val">c {{ getDate(data.supportBeginReceiveDate)}} до {{ getDate(data.supportEndReceiveDate)}}</div>
          </div>
        </column>
      </row>
      <span class="op05" v-if="new Date() > new Date(data.supportEndReceiveDate)">Прием заявок закрыт</span>
      <cl-button v-else-if="data.isElectronicService && data.isElectronicSubmission && dataInfo.btnForm && dataInfo.btnText"
                 type="default" class="btn-middle" :link="true" :href="dataInfo.btnForm.value">{{ dataInfo.btnText.value }}</cl-button>
      <cl-button v-else-if="data?.url && dataInfo.btnForm"
                 type="default" class="btn-middle" :link="true" :href="data.url" target="_blank">{{ dataInfo.btnText.value }}</cl-button>
      <cl-button v-else-if="data?.addressFiling && dataInfo.btnForm"
                 type="default" class="btn-middle" @click="showModal = true">{{ dataInfo.btnText.value }}</cl-button>
      <div class="line_grant"></div>
      <div class="tabs_grant_buttons">
        <div class="tab_grant" :class="tabActive == 'description' ? 'active' : ''" @click="tabActive = 'description'" v-if="data.targetSupport && dataInfo.textDesc?.value">{{ dataInfo.textDesc.value }}</div>
        <div class="tab_grant" :class="tabActive == 'conditions' ? 'active' : ''" @click="tabActive = 'conditions'" v-if="data.applicantRequirement && dataInfo.textDistribution?.value">{{ dataInfo.textDistribution.value }}</div>
        <div class="tab_grant" :class="tabActive == 'document' ? 'active' : ''" @click="tabActive = 'document'" v-if="data.measureServiceDocuments && dataInfo.textDoc?.value">{{ dataInfo.textDoc.value }}</div>
        <div class="tab_grant" :class="tabActive == 'base' ? 'active' : ''" @click="tabActive = 'base'" v-if="data.legalAct && dataInfo.textBase?.value">{{ dataInfo.textBase.value }}</div>
      </div>
      <div class="tabs_grant_content">
        <div class="content_tab_grant" v-if="data.targetSupport && tabActive == 'description'">
          <h3 v-if="dataInfo.titleDesc?.value">{{ dataInfo.titleDesc.value }}</h3>
          <div class="content" v-html="data.targetSupport"></div>
        </div>
        <div class="content_tab_grant" v-if="data.applicantRequirement && tabActive == 'conditions'">
          <h3 v-if="dataInfo.titleDistribution?.value">{{ dataInfo.titleDistribution.value }}</h3>
          <div class="content" v-html="data.applicantRequirement"></div>
        </div>
        <div class="content_tab_grant" v-if="data.measureServiceDocuments && tabActive == 'document'">
          <h3 v-if="dataInfo.titleDoc?.value">{{ dataInfo.titleDoc.value }}</h3>
          <div class="content">
            <template v-for="item in this.data.measureServiceDocuments">
              <div class="item_doc" :class="item.url ? 'doc_file' : ''">
                <div class="dot"></div>
                <div class="text">
                  {{ item.name }}
                  <a :href="item.url" v-if="item.url" class="link_grant">Скачать</a>
                </div>
              </div>
            </template>
          </div>
        </div>
        <div class="content_tab_grant" v-if="data.legalAct && tabActive == 'base'">
          <h3 v-if="dataInfo.titleBase?.value">{{ dataInfo.titleBase.value }}</h3>
          <div class="content content_base" v-html="data.legalAct"></div>
        </div>
      </div>
      <div class="line_grant"></div>
      <div class="company_info">
        <div class="dt1">
          <div class="company_name fs3" v-if="data.company?.name">{{ data.company.name }}</div>
          <div class="company_contacts">
            <cl-button v-if="data.company?.phone" type="light" class="btn-ico btn-middle phone" img="/img/ico/phone.svg" @click.prevent.once="showNumber = !showNumber">
              <template v-if="!showNumber">Показать телефон</template>
              <a v-else :href="'tel:' + data.company?.phone">{{ data.company?.phone }}</a>
            </cl-button>
            <cl-button v-if="data.company?.email" type="light" class="btn-ico btn-middle mail" img="/img/ico/mail.svg" @click.prevent.once="showMail = !showMail">
              <template v-if="!showMail">Написать на почту</template>
              <a v-else :href="'mailto:' + data.company?.email">{{ data.company?.email }}</a>
            </cl-button>
          </div>
        </div>
        <div class="dt2" v-if="data.company.logo.imageUrl">
          <img :src="data.company.logo.imageUrl">
        </div>
      </div>
    </div>
    <cl-modal v-model="showModal" modal-type="center" class="modal_grant modal_form" :closeStandart="false" v-if="data?.addressFiling">
      <template v-slot:header>
        <div class="title">Подать заявку</div>
      </template>
      <template v-slot:body>
        <div v-html="data.addressFiling"></div>
      </template>
      <template v-slot:footer>
        <cl-button type="light" class="btn-middle" @click="showModal = false">Закрыть</cl-button>
      </template>
    </cl-modal>
  </container>
</template>

<script>
import ClModal from "@/components/library/ClModal.vue";
import ClButton from "@/components/library/ClButton.vue";
import {updateMetaTags} from "@/assets/js/updateMetaTags";

export default {
  name: "filmGrantsCard",
  components: {ClButton, ClModal},
  props: {
    dataInfo: {
      type: Object,
      require: true,
    }
  },
  data() {
    return{
      data: null,
      tabActive: null,
      showNumber: false,
      showMail: false,
      showModal: false,
    }
  },
  methods: {
    getId(){
      return this.$route.path.split("/").at(-1);
    },
    setData(){
      this.axios.get(`/api/get/grant/${this.getId()}`)
          .then(response => {
            this.data = response.data;

            updateMetaTags(this.$store.dataMeta, this.data.name);

            this.$nextTick(() => {
              helperApp.addBreadcrumbsLink(this.data.name);
            });

            this.data.company.phone = this.getPhone(this.data.contactList);
            this.data.company.email = this.getEmail(this.data.contactList);

            if(this.data.targetSupport){
              this.tabActive = "description";
            } else if(this.data.applicantRequirement) {
              this.tabActive = "conditions";
            } else if(this.data.measureServiceDocuments) {
              this.tabActive = "document";
            } else if(this.data.legalAct) {
              this.tabActive = "base";
            }
          })
          .catch(error => {
            this.showError(error);
          });
    },
    getDate(date){
      return new Date(date).toLocaleString('ru-RU', {year: 'numeric', month: 'numeric', day: 'numeric',});
    },
    getPhone(contactList){
      if(contactList.length && contactList.length > 0){
        for (let item of contactList){
          if(item.type.id == "1006201"){
            return item.value;
          }
        }
        return null;
      }
    },
    getEmail(contactList){
      if(contactList.length && contactList.length > 0){
        for (let item of contactList){
          if(item.type.id == "1006202" && item.value.includes("@")){
            return item.value;
          }
        }
        return null;
      }
    }
  },
  mounted() {
    this.setData();
  }
}
</script>

<style lang="scss" scoped>
.film_grants_card{
  padding: 12.2rem 6.6rem;
  max-width: 108rem;
  margin: 0 auto;
  box-sizing: content-box;

  h3 {
    margin-bottom: 0;
  }
  .col-conditions{
    &:not(:last-child){
      .item_conditions{
        border-right: 1px solid #fff;
      }
    }
    .div_tit{
      font-size: 21px;
      font-style: normal;
      font-weight: 500;
      line-height: 133.333%;
      letter-spacing: 0.315px;
      color: #ED850A;
      margin-bottom: 0.8rem;
    }
  }
  .list_conditions{
    margin-bottom: 3.2rem;
  }
  .line_grant{
    width: 100%;
    height: 1px;
    margin: 3.2rem 0;
    background-color: #9096A2;
  }
  .tabs_grant_buttons{
    display: flex;
    .tab_grant{
      width: 100%;
      padding: 1.2rem 0.8rem;
      border: 1px solid #ED850A;
      display: flex;
      justify-content: center;
      cursor: pointer;
      font-size: 1.6rem;
      line-height: 112.5%;
      letter-spacing: 0.032rem;
      &:first-child{
        border-radius: 0.8rem 0 0 0.8rem;
      }
      &:last-child{
        border-radius: 0 0.8rem 0.8rem 0;
      }
      &.active{
        background-color: #ED850A;
      }
    }
  }
  .tabs_grant_content{
    padding: 1.6rem 0;
    .content{
      ul li:before{
        width: 3px;
        height: 3px;
        background-color: #fff;
      }
    }
  }
  .item_doc{
    padding: 12px 0;
    display: flex;
    gap: 0.8rem;
    .dot{
      width: 2.4rem;
      min-width: 2.4rem;
      height: 2.4rem;
      display: flex;
      align-items: center;
      justify-content: center;
      &:after{
        content: '';
        width: 0.8rem;
        height: 0.8rem;
        background-color: #ED850A;
        border-radius: 50%;
      }
    }
    &.doc_file{
      .text{
        display: flex;
        flex-direction: column;
        .link_grant{
          font-size: 1.4rem;
          font-style: normal;
          font-weight: 400;
          line-height: 114.286%;
          letter-spacing: 0.021rem;
          text-decoration-line: underline;
        }
      }
      .dot{
        background-image: url("@/assets/img/ico/file.svg");
        background-position: center center;
        background-size: contain;
        &:after{
          content: unset;
        }
      }
    }
  }
  .company_info{
    padding: 3.2rem;
    border-radius: 1.2rem;
    border: 2px solid #696C71;
    display: flex;
    gap: 2.4rem;
    justify-content: space-between;
    .company_name{
      margin-bottom: 2.4rem;
    }
    .company_contacts{
      display: flex;
      gap: 1.6rem;
      
      .btn {
        color: white;
      }
    }
    .dt2{
      width: 14.4rem;
      height: 14.4rem;
      img{
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
  }
  .content_base{
    color: #ED850A;
    text-decoration: underline;
    text-underline-offset: 3px;
  }

  @media (max-width: 1440px) {
    max-width: 100%;
    padding: 15.2rem 1.6rem;

    .hasSubtit {
      margin-top: 2rem;
    }

    .title {
      max-width: 70rem;
      margin-bottom: 4px;
    }
  }

  @media (max-width: 1024px) {
    max-width: 100%;
    padding: 16.2rem 1.6rem;

    .title {
      max-width: 118rem;
      margin-bottom: 4px;
      font-size: 48px;
      font-style: normal;
      font-weight: 500;
      line-height: 64px;
      letter-spacing: 0.96px;
    }

    .list_conditions {
      margin-bottom: 32px;

      .col-conditions {

        .item_conditions {

          .div_tit {
            margin-bottom: 8px;
          }

          .div_val {
            font-size: 18px;
          }
        }
      }
    }

    .line_grant {
      margin: 32px 0;
    }

    .tabs_grant_buttons {

      .tab_grant {
        font-size: 16px;
        padding: 12px 8px;
      }
    }

    .tabs_grant_content {
      .content_tab_grant {
        h3 {
          font-size: 32px;
          margin-bottom: 0;
        }

        .content {
          font-size: 18px;
        }
      }
    }

    .company_info {

      .dt1 {

        .company_name {
          font-size: 32px;
          margin-bottom: 24px;
        }

        .company_contacts {
          font-size: 18px;
        }
      }
    }
  }

  @media (max-width: 991px) {
    max-width: 100%;
    padding: 16.2rem 1.6rem;
    overflow: hidden;

    .hasSubtit {
      margin-top: 0;
    }

    .title {
      max-width: 118rem;
      font-size: 32px;
      font-style: normal;
      font-weight: 500;
      line-height: 48px;
      letter-spacing: 0.64px;
    }

    .list_conditions {
      margin-bottom: 32px;

      .col-conditions {

        .item_conditions {

          .div_tit {
            font-size: 18px;
          }

          .div_val {
            font-size: 16px;
          }
        }
      }
    }

    .btn {
      font-size: 18px;
    }

    .line_grant {
      margin: 32px 0;
    }

    .tabs_grant_buttons {
      overflow-x: auto;
      width: 100%;

      .tab_grant {
        font-size: 16px;
        padding: 12px 8px;
        white-space: nowrap;
        min-width: 244px;
      }
    }

    .tabs_grant_content {
      .content_tab_grant {
        h3 {
          font-size: 32px;
          margin-bottom: 0;
        }

        .content {
          font-size: 18px;
        }
      }
    }

    .company_info {
      flex-direction: column-reverse;
      gap: 24px;
      .dt1 {

        .company_name {
          font-size: 32px;
          margin-bottom: 24px;
        }

        .company_contacts {
          font-size: 18px;
          .btn {
            width: 100%;
          }
        }
      }
    }
  }

  @media (max-width: 767px) {
    max-width: 100%;
    padding: 5.2rem 0rem;
    overflow: hidden;

    .title {
      max-width: 118rem;
      font-size: 24px;
      font-weight: 500;
      line-height: 32px;
      letter-spacing: normal;
      margin-bottom: 12px;
    }

    .list_conditions {
      margin-bottom: 32px;

      .col-conditions {

        .item_conditions {
          border-right: none;
          border-bottom: 1px solid #FFF;
          padding-bottom: 8px;

          .div_tit {
            font-size: 16px;
          }

          .div_val {
            font-size: 16px;
          }
        }
      }
    }

    .btn {
      font-size: 18px;
    }

    .line_grant {
      margin: 32px 0;
    }

    .tabs_grant_buttons {
      overflow-x: auto;
      width: 100%;

      .tab_grant {
        font-size: 16px;
        padding: 12px 8px;
        white-space: nowrap;
        min-width: 244px;
      }
    }

    .tabs_grant_content {
      .content_tab_grant {
        h3 {
          font-size: 32px;
          margin-bottom: 0;
        }

        .content {
          font-size: 18px;
        }
      }
    }

    .company_info {
      flex-direction: column-reverse;
      gap: 24px;
      padding: 16px;
      align-items: center;
      .dt1 {
        width: 100%;

        .company_name {
          font-size: 24px;
          margin-bottom: 24px;
          text-align: center;
        }

        .company_contacts {
          flex-direction: column;
          font-size: 18px;

          .btn {
            width: 100%;
          }
        }
      }
    }
  }
}
</style>

<style lang="scss">
.tabs_grant_content{
  .content{
    li{
      padding-left: 2.8rem;
      &:before{
        width: 4px;
        height: 4px;
        left: 1.2rem;
        border-radius: 50%;
        background-color: #fff;
      }
    }
  }
}
.content_base{
  a{
    color: #ED850A;
    &:hover, &:active, &:visited, &:focus{
      color: #ED850A;
    }
  }
}
</style>