<template>
  <how-to-get v-if='blockComponent == "howToGet"' :dataInfo="dataInfo" :id="id"/>
  <project-detail v-if='blockComponent == "projectDetail"' :dataInfo="dataInfo" :id="id"/>
  <main-big-block-text-en v-else-if='blockComponent == "mainBigBlockTextEn"' :dataInfo="dataInfo" :id="id"/>
  <main-big-block-kinopark v-else-if='blockComponent == "mainBigBlockKinopark"' :dataInfo="dataInfo" :id="id"/>
  <video-reviews-mos v-else-if='blockComponent == "videoReviewsMos"' :dataInfo="dataInfo" :id="id"/>
  <restaurant-menu v-else-if='blockComponent == "restaurantMenu"' :dataInfo="dataInfo" :id="id"/>
  <cinema-desc v-else-if='blockComponent == "cinemaDesc"' :dataInfo="dataInfo" :id="id"/>
  <cinema-park-events v-else-if='blockComponent == "cinemaParkEvents"' :dataInfo="dataInfo" :id="id"/>
</template>

<script>
import howToGet from "@/components/howToGet.vue";
import projectDetail from "@/components/ProjectDetail.vue";
import HeaderMenuCategory from "@/components/header/headerMenuCategory.vue";
import mainBigBlockTextEn from "@/components/mainBigBlockTextEn.vue";
import mainBigBlockKinopark from "@/components/mainBigBlockKinopark.vue";
import videoReviewsMos from "@/components/videoReviewsMos.vue";
import restaurantMenu from "@/components/restaurantMenu.vue";
import cinemaDesc from "@/components/cinemaDesc.vue";
import cinemaParkEvents from "@/components/cinemaParkEvents.vue";

export default {
  name: "templateDynamic4",
  components: {
    projectDetail,
    HeaderMenuCategory,
    howToGet,
    mainBigBlockTextEn,
    mainBigBlockKinopark,
    videoReviewsMos,
    restaurantMenu,
    cinemaDesc,
    cinemaParkEvents
  },
  props: {
    dataInfo: {
      type: Object,
      require: true,
    },
    id: {
      type: String,
    },
    blockComponent: {
      type: String,
    }
  },
}
</script>

<style lang="scss" scoped>

</style>