<template>
  <container>
    <h1 style="padding-top: 100px">
      {{testInfo}}
    </h1>
    <test-component-ts @customEvent="customEvent" :dataInfo="dataInfo" :dataInfo2="dataInfo2"/>
    <div ref="prev" class="swiper-button-prev"></div>
    <div ref="next" class="swiper-button-next"></div>
  </container>
</template>

<script setup lang="ts">
import {computed, ComputedRef, defineComponent, ref, Ref} from "vue";
import TestComponentTs from "@/views/testTs/TestComponentTs.vue";
import Container from "@/components/library/Container.vue";
import store from "@/store";
import {SwiperModule} from "swiper/types/shared";
import {Autoplay, Navigation} from "swiper";
import {IDataInfoProps} from "@/interface/props/IDataInfoProps";

/**
 * название компонента
 */
defineComponent({
  name: "testTs"
})


/**
 * данные для компонента
 * @type {Object}
 */
const dataInfo = {
  title: 'title1111',
  text: 'text1111'
};
/**
 * данные для компонента test-component-ts
 * @type {Object}
 */
const dataInfo2 = {
  title: 'title2222',
  text: 'text222222'
};

/**
 * тестовая переменная
 * @type {string | null}
 */
const testInfo: Ref<string | null> = ref(null);
testInfo.value = 'тест';

/**
 * выводим в консоли текст
 * @param data {String} - текст
 */
const customEvent = (data: String) => {
  console.log('Получены данные:', data);
};

/**
 * Ширина экрана. Эквивалентно:
 * computed: {
 *     ...mapGetters([
 *       'widthSite',
 *     ])
 *   },
 */
const widthSite: ComputedRef<any> = computed(() => {return store.getters.widthSite});

/**
 * Реф prev
 */
const prev: Ref<HTMLElement | null> = ref(null);
/**
 * Реф next
 */
const next: Ref<HTMLElement | null> = ref(null);
/**
 * Подключение модулей Navigation и Autoplay для swiper
 */
const modules: Array<SwiperModule> = [Navigation, Autoplay];

/**
 * Подключение пропа из админки.
 */
const props = defineProps<IDataInfoProps>();

/**
 * Пример конкретики интерфейса в компоненте cinemaBannersMos.vue
 */

</script>

<style lang="scss" scoped>

</style>