<template>
  <div class="top_trans_ob">
    <container class="equip-container">
      <h1 class="fs2">{{card.name}}</h1>
      <h4 class="subtitle" v-if="card.company">{{card.company}}</h4>
      <row class="row_gap">
        <column md="7" v-if="card.gallery?.galleryItems?.length">
          <swiper
            :spaceBetween="10"
            :navigation="true"
            :threshold='10'
            :modules="modules"
            :loop="card.gallery?.galleryItems?.length > 1 ? true : false"
            class="align-items-center-wrapper"
          >
            <swiper-slide v-for="photo of card.gallery.galleryItems" :key="photo.url">
              <div class="d-flex justify-content-center">
                <img :src="photo.url" alt=""/>
              </div>
            </swiper-slide>
          </swiper>
        </column>
        <column md="5">
          <div class="content_block">
            <h4>Описание</h4>
            <div class="description" v-html="card.description"></div>
            <div class="description">Стоимость зависит от объема и продолжительности аренды. Для уточнения свяжитесь по телефону или электронной почте указанным ниже.</div>
            <div class="div_btns">
              <cl-button v-if="card.phone" type="light" class="btn-ico phone" img="/img/ico/phone.svg" @click.prevent.once="showNumber = !showNumber">
                <template v-if="!showNumber">Показать телефон</template>
                <template v-else><a :href="'tel:' + card.phone">{{card.phone}}</a></template>
              </cl-button>
              <cl-button type="light" class="btn-ico mail" img="/img/ico/mail.svg" @click.prevent="getMail">
                <template v-if="!showMail">Написать на почту</template>
                <template v-else>{{ card.email }}</template>
              </cl-button>
            </div>
          </div>
        </column>
      </row>
    </container>
  </div>
</template>

<script>
import {Swiper, SwiperSlide} from "swiper/vue";
import {FreeMode, Navigation} from "swiper";
import {updateMetaTags} from "@/assets/js/updateMetaTags";

export default {
  name: "equipmentItem",
  components: {SwiperSlide, Swiper},
  props: {
    dataInfo: {
      type: Object,
      require: true
    },
  },
  data: () => ({
    showNumber: false,
    showMail: false
  }),
  methods:{
      getMail(){
          if(!this.showMail){
              this.showMail = !this.showMail;
          } else {
              window.location.href = `mailto:${this.card.email}`;
          }
      },
  },
  mounted() {
    this.$nextTick(() => {
      helperApp.addBreadcrumbsLink(this.card.name);
    });
  },
  computed: {
    card() {
      updateMetaTags(this.$store.dataMeta, this.dataInfo.card.name);
      return this.dataInfo.card
    }
  },
  setup() {
    return {
      modules: [FreeMode, Navigation]
    }
  }
}
</script>

<style lang="scss" scoped>
.subtitle {
  margin-bottom: 32px;
}
.content_block h4 {
  margin-bottom: 16px;
}
.description {
  margin-bottom: 24px;
}
.div_btns {
  display: flex;
  gap: 16px;
  .btn {
    width: 50%;
  }
  @media (max-width: 767px) {
    flex-direction: column;
    .btn{
      width: 100%;
    }
  }
}
</style>



<style lang="scss">
.equip-container {
  .swiper-slide {
    overflow: hidden;
    img {
      //height: 604px;
      //max-width: inherit;
      width: 100%;
    }
  }
}

.text{
  a{
    color: #ED850A;
    &:hover, &:focus{
      color: #ED850A;
    }
  }
}
</style>