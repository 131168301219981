<template>
  <Header :dataLogo="dataHeaderLogo"/>
  <router-view @updateHeaderLogo="updateHeaderLogo" @addGlobalAlertData="addGlobalAlertData"/>

  <cl-modal :modelValue="true" class="d-none center" :closeStandart="false" :buttonTextClose="true" id="modal-form-success">
    <template v-slot:body> </template>
    <template v-slot:header> </template>
    <template v-slot:footer> </template>
  </cl-modal>

  <!--  компонент модалка при переходе к авторизации старый  -->
<!--  <cl-modal v-model="modalAuthShow" class="d-none" modal-type="center text-center modalInfo" id="modalAuthShow" :closeStandart="'false'" v-if="!getIsAuthorization">-->
<!--    <template v-slot:header>Для подачи заявки на проект вы будете перенаправлены на портал mos.ru для прохождения авторизации.</template>-->
<!--    <template v-slot:footer>-->
<!--      <cl-button id="modalAuthHref">перейти</cl-button>-->
<!--      <cl-button @click="modalAuthClose" type="light2">отмена</cl-button>-->
<!--    </template>-->
<!--  </cl-modal>-->

  <!--  компонент модалка при переходе к авторизации  -->
  <cl-modal v-model="modalAuthShow" class="d-none" modal-type="center modal-ico" id="modalAuthShow" :closeStandart="'false'" v-if="!getIsAuthorization">

    <template v-slot:body>
      <div class="div_img"><img src="/img/ico/user-round-check.svg" alt=""></div>
      <h4 class="div_tit">Для продолжения, пожалуйста авторизуйтесь!</h4>
    </template>
    <template v-slot:footer>
      <cl-button link href="/connect/mosru">Авторизоваться</cl-button>
    </template>
  </cl-modal>

  <modal-cookies :modal-alert-cookies="globalAlertData?.modalAlertCookies"/>
  <notification-extra :globalAlertData="globalAlertData"/>
</template>

<style lang="scss">

</style>

<script>
import Header from "@/components/header.vue";
import {mapActions, mapGetters} from "vuex";
import NotificationExtra from "@/components/notificationExtra.vue";
import ModalCookies from "@/components/modalCookies.vue";
export default {
  components: {ModalCookies, NotificationExtra, Header},
  data(){
    return {
      dataHeaderLogo: false,
      modalAuthShow: true,
      globalAlertData: null,
    }
  },
  methods: {
    ...mapActions([
      'widthSite',
      'fetchPersonData'
    ]),
    updateHeaderLogo(data){
      this.dataHeaderLogo = data;
    },
    addGlobalAlertData(data) {
      this.globalAlertData = data;
    },
    modalAuthClose() {
      document.getElementById('modalAuthShow').classList.add('d-none');
    },
  },
  mounted() {
    window.addEventListener('resize', this.widthSite);
  },
  computed: {
    ...mapGetters([
      'getIsAuthorization',
    ])
  },
  created() {
    if (this.getIsAuthorization) {
      this.fetchPersonData();
    }
  }
}
</script>