<template>
  <div class="reviews-video">
    <container>
      <h2 v-if="dataInfo.title?.value">{{ dataInfo.title.value }}</h2>
      <div class="reviews-video-content">
        <div class="reviews-video-loader" v-if="isLoadingVideo">
          <loader
              v-if="isLoadingVideo"
              :animation-duration="3000"
              :size="60"
          ></loader>
        </div>
        <swiper
            v-else
            :spaceBetween="24"
            :navigation="true"
            :threshold='10'
            :modules="modules"
            :slidesPerView="'auto'"
            :loop="true"
            class="slide100h arrow_top_pag arrow_top_pag_h"
            :lazy="true"
            :pagination="{
              type: 'fraction',
              formatFractionCurrent: num => { return (num > 9) ? num : '0' + num},
              formatFractionTotal: num => { return (num > 9) ? num : '0' + num}
            }"
            :breakpoints="{
                  992: {
                    slidesPerView: 3,
                  },
                  768: {
                    slidesPerView: 2,
                  },
                  300: {
                    slidesPerView: 1,
                  }
                }"
        >
          <swiper-slide v-for="item in dataInfo.list"
                        class="cursor-pointer"
                        @click="infoVideo.name = item.name.value; infoVideo.iframe = item.iframe; showModal = !showModal">
            <div class="card-reviews-video">
              <img v-if="item.preview" :src="item.preview">
              <div class="div_cont">
                <div class="div_name" v-if="item.name?.value">{{ item.name.value }}</div>
                <div class="div_prof" v-if="item.prof?.value">{{ item.prof.value }}</div>
              </div>
            </div>
          </swiper-slide>
        </swiper>
      </div>
    </container>
    <cl-modal modal-type="center" class="modal-rutube" v-model="showModal">
      <!--            <template v-slot:header>-->
      <!--                <div class="div_name" v-if="infoVideo.name">{{ infoVideo.name }}</div>-->
      <!--            </template>-->
      <template v-slot:body>
        <div v-if="infoVideo.iframe" v-html="infoVideo.iframe"></div>
      </template>
    </cl-modal>
  </div>
</template>

<script>
import {Swiper, SwiperSlide} from "swiper/vue";
import {Navigation, Pagination} from "swiper";
import {mapGetters} from "vuex";
import ClModal from "@/components/library/ClModal.vue";

export default {
  name: "videoReviews",
  components: {
    ClModal,
    Swiper,
    SwiperSlide,
  },
  props: {
    dataInfo: {
      type: Object,
      require: true,
    }
  },
  data() {
    return {
      infoVideo: {
        name: null,
        iframe: null,
      },
      showModal: false,
      isLoadingVideo: true,
    }
  },
  setup() {
    return {
      modules: [Navigation, Pagination],
      videos: null,
    }
  },
  computed: {
    ...mapGetters([
      'widthSite'
    ])
  },
  methods: {
    getVideos() {
      this.isLoadingVideo = true;
      if (this.dataInfo.list?.length > 0) {
        let promises = [];
        this.dataInfo.list.forEach(item => {
          if (item.guid?.value) {
            const promis = this.axios.get("/api/data/rutube", {
              params: {
                id: item.guid.value,
              }
            })
                .then(response => {
                  item.preview = response.data.thumbnailUrl;
                  item.iframe = response.data.html;
                  promises.push(promis);
                })
          }
        });
        Promise.all(promises)
            .then(() => {
              this.isLoadingVideo = false;
            });
      }
    },
  },
  mounted() {
    this.getVideos();
  }
};
</script>

<style lang="scss" scoped>
.reviews-video-loader {
  position: absolute;
  inset: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.reviews-video {
  padding: 4.8rem 0;

  h2 {
    font-family: Playfair Display;
    font-size: 4.8rem;
    font-weight: 500;
    line-height: 5.6rem;
    letter-spacing: 0.02em;
    text-transform: uppercase;
    margin-bottom: 2.4rem;
  }

  .reviews-video-content {
    position: relative;
    min-height: 10rem;
  }
  
  .card-reviews-video {
    height: 66.3rem;
    position: relative;
    border-radius: 1.2rem;
    overflow: hidden;
    &:after {
      content: "";
      width: 5.6rem;
      height: 5.6rem;
      background: url(../assets/img/ico/play-circle.svg) center center;
      filter: invert(1);
      background-size: contain;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      display: block;
      opacity: .8;
    }

    img {
      position: absolute;
      inset: 0;
      width: 110%;
      height: 110%;
      object-fit: cover;
    }

    .div_cont {
      position: absolute;
      bottom: .8rem;
      left: .8rem;
      right: .8rem;
    }

    .div_name {
      font-size: 2.4rem;
      font-weight: 500;
      line-height: 3.2rem;
      letter-spacing: 0.015em;
      text-align: left;
    }
    .div_pof {
      letter-spacing: 0.015em;
    }
    @media(max-width: 1050px) {
      img {
        width: 140%;
        height: 140%;
      }
    }
  }

  @media(max-width: 991px){
    h2{
      font-size: 3.2rem;
    }
  }

  @media(max-width: 767px){
    h2{
      font-size: 3.2rem;
      text-align: center;
      margin-right: 0;
    }
  }
}

</style>