<template>
  <div v-if="dataInfo && Object.keys(dataInfo).length !== 0" class="block_wrap pavilony_list">
    <container>
      <div v-if="dataInfo.title || (!dataInfo.isButtonHide && dataInfo.types)" class="div_tit">
        <div class="div_tit_inner">
          <h2 v-if="dataInfo.title">{{ dataInfo.title.value }}</h2>
          <div v-if="dataInfo.subTitle" class="div_sub_title">{{dataInfo.subTitle.value}}</div>
        </div>
        <template v-if="!dataInfo.isButtonHide && dataInfo.types">
          <div class="sub_slider_top">
            <a class="btn btn-light" target="_blank" :href="`/arenda_kinopomecheniy/pavilony?types=${dataInfo.types.value}`">{{dataInfo.buttonText ? dataInfo.buttonText.value : 'Посмотреть все'}}</a>
          </div>
        </template>
      </div>

      <loader
          v-if="isLoading"
          :animation-duration="3000"
          :size="60"
      ></loader>
      <not-info
          v-if="!isLoading && dataList?.length == 0"
          :title="`Здесь пока ничего нет`"
          :text="`Нет результатов`"
      >
      </not-info>
      <template v-if="!isLoading && dataList?.length > 0">
        <swiper
            :spaceBetween="24"
            :navigation="true"
            :threshold='10'
            :modules="modules"
            :loop="widthSite > 992 ? dataList.length > 4 : dataList.length > 2"
            :slidesPerView="'auto'"
            class="slide100h"
            :class="{'arrow_center': !scrollPagination, 'arrow_center_op': !scrollPagination, 'progress-bar-line': scrollPagination, 'progress-bar-line-button': scrollPagination}"
            :pagination="scrollPagination ? {type: 'progressbar'} : false"
            :centered-slides="centeredSlides"
            :breakpoints="{
                  '992': {
                    slidesPerView: slidesPerViewLg,
                  },
                  '768': {
                    slidesPerView: slidesPerViewMd,
                    loop: dataList.length > 2
                  },
                  '300': {
                    slidesPerView: slidesPerView,
                    loop: dataList.length > 1
                  }
                }"
        >
          <swiper-slide
              :key='item.guid'
              v-for='item of dataList'
          >
            <card-rent2-pavilony :item="item" :routeLink="dataInfo.link?.value + item.guid" class="border_img"/>
          </swiper-slide>
          <template v-if="!dataInfo.isButtonHide && dataInfo.types">
            <div class="sub_slider_bottom">
              <a class="btn btn-light" :class="{'btn-middle': scrollPagination}" target="_blank" :href="`/arenda_kinopomecheniy/pavilony?types=${dataInfo.types.value}`">{{dataInfo.buttonText ? dataInfo.buttonText.value : 'Посмотреть все'}}</a>
            </div>
          </template>
        </swiper>
      </template>
    </container>
  </div>
</template>

<script>
/*
* types - тип для фильтра (обязательное поле)
* */
import {Swiper, SwiperSlide} from "swiper/vue";
import {Navigation, Pagination} from "swiper";
import {mapGetters} from "vuex";
import CardRent2Pavilony from "@/components/cardRent2Pavilony.vue";

export default {
  name: "pavilonyListTypes",
  components: {
    CardRent2Pavilony,
    Swiper,
    SwiperSlide,
  },
  props: {
    dataInfo: {
      type: Object,
      require: true
    },
    /**
     * Настройка свайпера slidesPerView. Количество слайдов на странице для экрана больше 991px
     * @default 4
     */
    slidesPerViewLg : {
      type: Number,
      default: 4,
    },
    /**
     * Настройка свайпера slidesPerView. Количество слайдов на странице для экрана больше 767px и меньше 991px
     * @default 2
     */
    slidesPerViewMd : {
      type: Number,
      default: 2,
    },
    /**
     * Настройка свайпера slidesPerView. Количество слайдов на странице для экрана меньше 767px
     * @default 1
     */
    slidesPerView : {
      type: Number,
      default: 1,
    },
    /**
     * Настройка свайпера. Добавление скролла
     * @default false
     */
    scrollPagination: {
      type: Boolean,
      default: false,
    },
    /**
     * Настройка свайпера. Центрирование слайдов
     */
    centeredSlides: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isLoading: true,
      getParams: {
        all: 1
      },
      dataList: false,
      controller: null,
      loopStatus: false,
    }
  },
  methods: {
    getRentItem(isLoadMore) {
      return this.axios
          .get(`/api/data/all-platforms`, {params: this.getParams, signal: this.controller.signal})
          .then(response => {
            this.dataList = response.data.records;
            this.isLoading = false;
          })
          .catch(error => {
            this.showError(error);
          });
    },
  },
  computed: {
    ...mapGetters([
      'widthSite'
    ]),
  },
  setup() {
    return {
      modules: [Navigation, Pagination],
    }
  },
  created() {
    this.controller = new AbortController();
  },
  mounted() {
    this.getParams.types = this.dataInfo.types.value;
    this.getRentItem();
  },
  beforeUnmount() {
    this.controller.abort();
    this.controller = null;
  },
}
</script>

<style lang="scss" scoped>
.pavilony_list {
  padding: 64px 0;

  .div_tit {
    display: flex;
    justify-content: space-between;
    margin-bottom: 64px;

    .btn{
      height: fit-content;
    }
  }

  .div_tit_inner {
    h2{
      margin-bottom: 0.8rem;
    }
    .div_sub_title {
      text-align: center;
      margin-bottom: 0.8rem;
    }
    & > :last-child {
      margin-bottom: 0;
    }
  }

  .sub_slider_top {
    display: block;
  }

  .sub_slider_bottom{
    margin-top: 2.4rem;
    display: none;
    .btn{
      width: 100%;
    }
  }

  @media (max-width: 991px) {
    .sub_slider_top {
      display: none;
    }

    .sub_slider_bottom{
      display: block;
    }
  }
}

.pavilony_list_v2 {
  padding: 6rem 0;

  .div_tit {
    justify-content: center;
    gap: 0.8rem;
    margin-bottom: 2.4rem;
  }

  h2 {
    font-size: 3.2rem;
    font-weight: 500;
    line-height: 1.5;
    letter-spacing: 0.02em;
    text-align: center;
  }

  .div_sub_title {
    font-size: 1.6rem;
    line-height: 1.5;
    letter-spacing: 0.015em;
    color: #6B7280;
  }

  .sub_slider_top {
    margin-left: auto;
    display: none;
  }

  .sub_slider_bottom{
    display: block;
    margin-top: 0;
    grid-area: btn;
    max-width: 30rem;
    .btn {
      width: auto;
    }
  }

  :deep(.progress-bar-line) {
    grid-template-areas:
                          "a a a a"
                          "b c d btn";
  }

  @media(max-width: 991px) {
    h2 {
      font-size: 2.4rem;
      line-height: 1.33;
    }

    .sub_slider_bottom{
      display: flex;
      justify-content: center;
      max-width: none;
      .btn {
        width: auto;
      }
    }

    :deep(.progress-bar-line) {
      grid-template-areas:
                          "a a a"
                          "d d d"
                          "btn btn btn";

      .swiper-button-prev, .swiper-button-next{
        display: none;
      }

      .swiper-slide {
        max-width: 34.3rem;
      }
    }
  }

  @media(max-width: 767px) {
    .div_sub_title {
      font-size: 1.4rem;
      line-height: 1.428;
    }

    .sub_slider_bottom{
      .btn {
        width: 100%;
      }
    }
  }
}
</style>