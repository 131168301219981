<template>
  <partners-images v-if='blockComponent == "partnersImages"' :dataInfo="dataInfo" :id="id"/>
  <partners-images-list v-if='blockComponent == "partnersImagesList"' :dataInfo="dataInfo" :id="id"/>
  <join-concurs-scenarists v-if='blockComponent == "joinConcursScenarists"' :dataInfo="dataInfo" :id="id"></join-concurs-scenarists>
  <rules-and-restr v-if='blockComponent == "RulesAndRestr"' :dataInfo="dataInfo" :id="id"/>
  <start-actors v-if='blockComponent == "startActors"' :dataInfo="dataInfo" :id="id"></start-actors>
  <banner-premiere-cinema v-if='blockComponent == "bannerPremiereCinema"' :dataInfo="dataInfo" :id="id"></banner-premiere-cinema>
  <news-concurs v-if='blockComponent == "newsConcurs"' :dataInfo="dataInfo" :id="id"></news-concurs>
  <cards-list-cinema v-if='blockComponent == "cardsListCinema"' :dataInfo="dataInfo" :id="id"></cards-list-cinema>
  <competition-banner-no-slider v-if='blockComponent == "competitionBannerNoSlider"' :dataInfo="dataInfo" :id="id"></competition-banner-no-slider>
  <selectedBlogList v-if='blockComponent == "selectedBlogList"' :dataInfo="dataInfo" :id="id"></selectedBlogList>
  <afisha-excurse v-if='blockComponent == "afishaExcurse"' :dataInfo="dataInfo" :id="id"></afisha-excurse>
  <moskino-k-inopark v-if='blockComponent == "moskinoKInopark"' :dataInfo="dataInfo" :id="id"></moskino-k-inopark>
  <moskino-carousel v-if='blockComponent == "moskinoCarousel"' :dataInfo="dataInfo" :id="id"></moskino-carousel>
  <how-to-get-new v-if='blockComponent == "howToGetNew"' :dataInfo="dataInfo" :id="id"/>
  <add-projects v-if='blockComponent == "addProjects"' :dataInfo="dataInfo" :id="id"></add-projects>
  <edit-projects v-if='blockComponent == "editProjects"' :dataInfo="dataInfo" :id="id"></edit-projects>
  <project-detail-print v-if='blockComponent == "projectDetailPrint"' :dataInfo="dataInfo" :id="id"/>
  <block-button-kinokomis-proj v-if='blockComponent == "blockButtonKinokomisProj"' :dataInfo="dataInfo" :id="id"/>
</template>

<script>/**
 * dataInfo - данные приходящие в блок
 * id - якорь блока
 * blockComponent - название компонента (которое прописывается в админке)
 */

import partnersImages from "@/components/partnersImages.vue";
import partnersImagesList from "@/components/partnersImagesList.vue";
import joinConcursScenarists from "@/components/joinConcursScenarists";
import RulesAndRestr from "@/components/rulesAndRestr";
import startActors from "@/components/startActors";
import bannerPremiereCinema from "@/components/bannerPremiereCinema";
import newsConcurs from "@/components/newsConcurs";
import cardsListCinema from "@/components/cardsListCinema";
import competitionBannerNoSlider from "@/components/competitionBannerNoSlider";
import selectedBlogList from "@/components/selectedBlogList";
import afishaExcurse from "@/components/afishaExcurse";
import moskinoKInopark from "@/components/moskinoKInopark";
import moskinoCarousel from "@/components/moskinoCarousel";
import howToGetNew from "@/components/howToGetNew";
import addProjects from "@/components/addProjects";
import editProjects from "@/components/editProjects";
import projectDetailPrint from "@/components/ProjectDetailPrint";
import blockButtonKinokomisProj from "@/components/blockButtonKinokomisProj.vue";

export default {
  name: "templateDynamic3",
  components: {
    cardsListCinema, 
    partnersImages,
    partnersImagesList,
    joinConcursScenarists,
    RulesAndRestr,
    startActors,
    bannerPremiereCinema,
    newsConcurs,
    competitionBannerNoSlider,
    selectedBlogList,
    afishaExcurse,
    moskinoKInopark,
    moskinoCarousel,
    howToGetNew,
    addProjects,
    editProjects,
    projectDetailPrint,
    blockButtonKinokomisProj
  },
  props: {
    dataInfo: {
      type: Object,
      require: true,
    },
    id: {
      type: String,
    },
    blockComponent: {
      type: String,
    }
  }
}
</script>

<style lang="scss">

</style>