<template>
  <container>
    <div class="print_block">
      <div class="print_header">
        <div class="logo">
          <svg xmlns="http://www.w3.org/2000/svg" width="110" height="44" viewBox="0 0 110 44" fill="none">
            <g clip-path="url(#clip0_4_20698)">
              <path d="M1.02699 10.0358C1.62883 9.89373 2.24936 10.1841 2.5259 10.7372L6.66667 19.0187L10.8074 10.7372C11.084 10.1841 11.7045 9.89373 12.3063 10.0358C12.9081 10.1779 13.3333 10.7151 13.3333 11.3335V32.6668C13.3333 33.4032 12.7364 34.0001 12 34.0001C11.2636 34.0001 10.6667 33.4032 10.6667 32.6668V16.9815L7.85927 22.5964C7.6334 23.0481 7.17167 23.3335 6.66667 23.3335C6.16164 23.3335 5.69995 23.0481 5.4741 22.5964L2.66667 16.9815V32.6668C2.66667 33.4032 2.06971 34.0001 1.33333 34.0001C0.596953 34.0001 0 33.4032 0 32.6668V11.3335C0 10.7151 0.425168 10.1779 1.02699 10.0358Z" fill="#000106"/>
              <path fill-rule="evenodd" clip-rule="evenodd" d="M17.7029 13.8612C18.7389 11.7892 20.4168 10 22.6667 10C24.9165 10 26.5945 11.7892 27.6305 13.8612C28.7063 16.013 29.3333 18.8924 29.3333 22C29.3333 25.1076 28.7063 27.987 27.6305 30.1387C26.5945 32.2108 24.9165 34 22.6667 34C20.4168 34 18.7389 32.2108 17.7029 30.1387C16.627 27.987 16 25.1076 16 22C16 18.8924 16.627 16.013 17.7029 13.8612ZM20.088 15.0538C19.2336 16.7626 18.6667 19.2166 18.6667 22C18.6667 24.7834 19.2336 27.2374 20.088 28.9462C20.9823 30.7347 21.971 31.3333 22.6667 31.3333C23.3623 31.3333 24.3511 30.7347 25.2453 28.9462C26.0997 27.2374 26.6667 24.7834 26.6667 22C26.6667 19.2166 26.0997 16.7626 25.2453 15.0538C24.3511 13.2653 23.3623 12.6667 22.6667 12.6667C21.971 12.6667 20.9823 13.2653 20.088 15.0538Z" fill="#000106"/>
              <path fill-rule="evenodd" clip-rule="evenodd" d="M102.667 10C100.417 10 98.7387 11.7892 97.7027 13.8612C96.6267 16.013 96 18.8924 96 22C96 25.1076 96.6267 27.987 97.7027 30.1387C98.7387 32.2108 100.417 34 102.667 34C104.917 34 106.595 32.2108 107.631 30.1387C108.707 27.987 109.333 25.1076 109.333 22C109.333 18.8924 108.707 16.013 107.631 13.8612C106.595 11.7892 104.917 10 102.667 10ZM98.6667 22C98.6667 19.2166 99.2333 16.7626 100.088 15.0538C100.982 13.2653 101.971 12.6667 102.667 12.6667C103.362 12.6667 104.351 13.2653 105.245 15.0538C106.1 16.7626 106.667 19.2166 106.667 22C106.667 24.7834 106.1 27.2374 105.245 28.9462C104.351 30.7347 103.362 31.3333 102.667 31.3333C101.971 31.3333 100.982 30.7347 100.088 28.9462C99.2333 27.2374 98.6667 24.7834 98.6667 22Z" fill="#000106"/>
              <path d="M81.3333 10C82.07 10 82.6667 10.597 82.6667 11.3333V20.6667H90.6667V11.3333C90.6667 10.597 91.2633 10 92 10C92.7367 10 93.3333 10.597 93.3333 11.3333V32.6667C93.3333 33.4031 92.7367 34 92 34C91.2633 34 90.6667 33.4031 90.6667 32.6667V23.3333H82.6667V32.6667C82.6667 33.4031 82.07 34 81.3333 34C80.5967 34 80 33.4031 80 32.6667V11.3333C80 10.597 80.5967 10 81.3333 10Z" fill="#000106"/>
              <path d="M76.6668 11.3335C76.6668 10.7151 76.2415 10.1779 75.6402 10.0358C75.0382 9.89373 74.4175 10.1841 74.1408 10.7372L66.0002 27.0187V11.3335C66.0002 10.5971 65.4032 10.0001 64.6668 10.0001C63.9304 10.0001 63.3335 10.5971 63.3335 11.3335V32.6668C63.3335 33.2851 63.7586 33.8224 64.3605 33.9645C64.9623 34.1065 65.5828 33.8162 65.8594 33.2631L74.0002 16.9815V32.6668C74.0002 33.4032 74.5968 34.0001 75.3335 34.0001C76.0702 34.0001 76.6668 33.4032 76.6668 32.6668V11.3335Z" fill="#000106"/>
              <path d="M50.5524 22L60.2763 12.2761C60.797 11.7554 60.797 10.9112 60.2763 10.3905C59.7556 9.86983 58.9114 9.86983 58.3907 10.3905L50.0002 18.7811V11.3333C50.0002 10.597 49.4032 10 48.6668 10C47.9304 10 47.3335 10.597 47.3335 11.3333V32.6667C47.3335 33.4031 47.9304 34 48.6668 34C49.4032 34 50.0002 33.4031 50.0002 32.6667V25.2189L58.3907 33.6095C58.9114 34.1302 59.7556 34.1302 60.2763 33.6095C60.797 33.0888 60.797 32.2445 60.2763 31.7239L50.5524 22Z" fill="#000106"/>
              <path d="M34.0002 22C34.0002 16.8453 38.1788 12.6667 43.3335 12.6667C44.0699 12.6667 44.6668 12.0697 44.6668 11.3333C44.6668 10.597 44.0699 10 43.3335 10C36.7061 10 31.3335 15.3726 31.3335 22C31.3335 28.6274 36.7061 34 43.3335 34C44.0699 34 44.6668 33.4031 44.6668 32.6667C44.6668 31.9303 44.0699 31.3333 43.3335 31.3333C38.1788 31.3333 34.0002 27.1547 34.0002 22Z" fill="#000106"/>
            </g>
            <defs>
              <clipPath id="clip0_4_20698">
                <rect width="109.333" height="24" fill="white" transform="translate(0 10)"/>
              </clipPath>
            </defs>
          </svg>
        </div>
        <div class="text">
          Московская киноплатформа
        </div>
      </div>

      <div class="flex-data" v-if="this.projectInfo">
        <div class="dt dt1">
          <template v-if="this.projectInfo?.logo !== null && this.imageUrl !== ''">
            <div class="div_img">
             <img height="202" width="146" style="object-fit: cover;" :src="this.projectInfo.logo.imageUrl" alt="" @click="openFileInput">
            </div>
          </template>
          <template v-else>
            <img :src="this.imageUrl" :class="{'not_img' : this.projectInfo.logo == null}" alt="" @click="openFileInput">
          </template>
        </div>
        <div class="dt dt2">
          <div class="table_elem">
            <div class="tab1">
              <div class="tit">Номер проекта</div>
              <div class="text">{{ this.projectInfo?.id }}</div>
            </div>
            <div class="tab2">
              <div class="tit">Дата изменения</div>
              <div class="text">
                <template v-if="this.projectInfo?.updated !== null">{{ $filters.ruDate6(new Date(this.projectInfo?.updated)) }}</template>
                <template v-else>{{ $filters.ruDate6(new Date(this.projectInfo?.created)) }}</template>
              </div>
            </div>
            <div class="tab3">
              <div class="tit">
                <template v-if="this.projectInfo !== null && this.projectInfo?.typeAudio?.name || this.projectInfo?.typeAudio?.comment">
                  <template v-if="this.projectInfo?.typeAudio?.comment">
                    {{ this.projectInfo?.typeAudio?.comment }}
                  </template>
                  <template v-else>
                    {{ this.projectInfo?.typeAudio.name }}
                  </template>
                </template>
                <template v-if="this.projectInfo !== null && this.projectInfo?.typeAudio?.name && this.projectInfo?.formatAudio?.name">&nbsp/&nbsp</template>
                <template v-if="this.projectInfo !== null && this.projectInfo?.formatAudio?.name">
                  <template v-if="this.projectInfo?.typeAudio?.comment">
                    {{ this.projectInfo?.formatAudio?.comment }}
                  </template>
                  <template v-else>
                    {{ this.projectInfo?.formatAudio.name }}
                  </template>
                </template>
              </div>
              <div class="text"><h4>
                {{ this.projectInfo?.name }}
              </h4></div>
            </div>
            <div class="tab4">
              <div class="tit">Кол-во заявок</div>
              <div class="text">{{ this.applications.length }}</div>
            </div>
            <div class="tab5">
              <div class="tit">УНФ</div>
              <div class="text">{{ this.projectInfo?.nationalFilm }}</div>
            </div>
            <div class="tab6">
              <div class="tit">ФСО</div>
              <div class="text" v-if="this.projectInfo?.fileFso[0].url !== undefined">
                <a :href="this.projectInfo?.fileFso[0].url">
                  {{ this.projectInfo?.fileFso[0].url.split('/').pop() }}
                </a>
              </div>
            </div>
            <div class="tab7">
              <div class="tit">Гарантийное письмо</div>
              <div class="text">
                <a :href="this.projectInfo?.letters[0].url">
                  {{ this.projectInfo?.letters[0].url.split('/').pop() }}
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="proj_info">
        <h3>Данные проекта</h3>
        <div class="flex-data">
          <div class="dt dt1">
            <div class="tit">Предположительная дата релиза</div>
            <div class="text">
              {{ $filters.ruDate5(new Date(this.projectInfo?.estReleaseDate)) }}
            </div>
          </div>
          <div class="dt dt2">
            <div class="tit">Страна = производитель проекта</div>
              <div class="text">
                {{ this.projectInfo?.country }}
              </div>
          </div>
        </div>
      </div>
      <div class="proj_info">
        <h3>Параметры проекта</h3>
        <div class="el" v-if="this.projectInfo">
          <div class="tit">Вид проката / показа*</div>
          <div class="text">
            <template v-if="this.projectInfo?.typeRental?.comment">
              {{ this.projectInfo?.typeRental.comment }}
            </template>
            <template v-else>
              {{ this.projectInfo?.typeRental.name }}
            </template>
          </div>
        </div>
        <div class="el" v-if="this.projectInfo && this.projectInfo?.fondiSupports !== null ">
          <div class="tit">Федеральная или международная поддержка*</div>
          <div class="text">
            {{ this.projectInfo?.fondiSupports.map(item => {
              if (item.name == "Другое") { return item.comment}
              else {
                return item.name;
              }
            }).join(', ') }}
          </div>
        </div>
        <div class="el" v-if="this.projectInfo && this.projectInfo?.fioDirector !== null ">
          <div class="tit">ФИО режиссера</div>
          <div class="text">{{ this.projectInfo?.fioDirector }}</div>
        </div>
      </div>
<!--      <div class="proj_info" v-if="this.projectInfo && this.projectInfo?.fioDirector !== null ">-->
<!--        <h3>Продюсерский состав проекта</h3>-->
<!--        <div class="text">{{ this.projectInfo?.fioDirector }}</div>-->
<!--      </div>-->
    </div>
    <div style="page-break-before:always;"></div>
    <div class="print_block">
      <div class="print_header">
        <div class="logo">
          <svg xmlns="http://www.w3.org/2000/svg" width="110" height="44" viewBox="0 0 110 44" fill="none">
            <g clip-path="url(#clip0_4_20698)">
              <path d="M1.02699 10.0358C1.62883 9.89373 2.24936 10.1841 2.5259 10.7372L6.66667 19.0187L10.8074 10.7372C11.084 10.1841 11.7045 9.89373 12.3063 10.0358C12.9081 10.1779 13.3333 10.7151 13.3333 11.3335V32.6668C13.3333 33.4032 12.7364 34.0001 12 34.0001C11.2636 34.0001 10.6667 33.4032 10.6667 32.6668V16.9815L7.85927 22.5964C7.6334 23.0481 7.17167 23.3335 6.66667 23.3335C6.16164 23.3335 5.69995 23.0481 5.4741 22.5964L2.66667 16.9815V32.6668C2.66667 33.4032 2.06971 34.0001 1.33333 34.0001C0.596953 34.0001 0 33.4032 0 32.6668V11.3335C0 10.7151 0.425168 10.1779 1.02699 10.0358Z" fill="#000106"/>
              <path fill-rule="evenodd" clip-rule="evenodd" d="M17.7029 13.8612C18.7389 11.7892 20.4168 10 22.6667 10C24.9165 10 26.5945 11.7892 27.6305 13.8612C28.7063 16.013 29.3333 18.8924 29.3333 22C29.3333 25.1076 28.7063 27.987 27.6305 30.1387C26.5945 32.2108 24.9165 34 22.6667 34C20.4168 34 18.7389 32.2108 17.7029 30.1387C16.627 27.987 16 25.1076 16 22C16 18.8924 16.627 16.013 17.7029 13.8612ZM20.088 15.0538C19.2336 16.7626 18.6667 19.2166 18.6667 22C18.6667 24.7834 19.2336 27.2374 20.088 28.9462C20.9823 30.7347 21.971 31.3333 22.6667 31.3333C23.3623 31.3333 24.3511 30.7347 25.2453 28.9462C26.0997 27.2374 26.6667 24.7834 26.6667 22C26.6667 19.2166 26.0997 16.7626 25.2453 15.0538C24.3511 13.2653 23.3623 12.6667 22.6667 12.6667C21.971 12.6667 20.9823 13.2653 20.088 15.0538Z" fill="#000106"/>
              <path fill-rule="evenodd" clip-rule="evenodd" d="M102.667 10C100.417 10 98.7387 11.7892 97.7027 13.8612C96.6267 16.013 96 18.8924 96 22C96 25.1076 96.6267 27.987 97.7027 30.1387C98.7387 32.2108 100.417 34 102.667 34C104.917 34 106.595 32.2108 107.631 30.1387C108.707 27.987 109.333 25.1076 109.333 22C109.333 18.8924 108.707 16.013 107.631 13.8612C106.595 11.7892 104.917 10 102.667 10ZM98.6667 22C98.6667 19.2166 99.2333 16.7626 100.088 15.0538C100.982 13.2653 101.971 12.6667 102.667 12.6667C103.362 12.6667 104.351 13.2653 105.245 15.0538C106.1 16.7626 106.667 19.2166 106.667 22C106.667 24.7834 106.1 27.2374 105.245 28.9462C104.351 30.7347 103.362 31.3333 102.667 31.3333C101.971 31.3333 100.982 30.7347 100.088 28.9462C99.2333 27.2374 98.6667 24.7834 98.6667 22Z" fill="#000106"/>
              <path d="M81.3333 10C82.07 10 82.6667 10.597 82.6667 11.3333V20.6667H90.6667V11.3333C90.6667 10.597 91.2633 10 92 10C92.7367 10 93.3333 10.597 93.3333 11.3333V32.6667C93.3333 33.4031 92.7367 34 92 34C91.2633 34 90.6667 33.4031 90.6667 32.6667V23.3333H82.6667V32.6667C82.6667 33.4031 82.07 34 81.3333 34C80.5967 34 80 33.4031 80 32.6667V11.3333C80 10.597 80.5967 10 81.3333 10Z" fill="#000106"/>
              <path d="M76.6668 11.3335C76.6668 10.7151 76.2415 10.1779 75.6402 10.0358C75.0382 9.89373 74.4175 10.1841 74.1408 10.7372L66.0002 27.0187V11.3335C66.0002 10.5971 65.4032 10.0001 64.6668 10.0001C63.9304 10.0001 63.3335 10.5971 63.3335 11.3335V32.6668C63.3335 33.2851 63.7586 33.8224 64.3605 33.9645C64.9623 34.1065 65.5828 33.8162 65.8594 33.2631L74.0002 16.9815V32.6668C74.0002 33.4032 74.5968 34.0001 75.3335 34.0001C76.0702 34.0001 76.6668 33.4032 76.6668 32.6668V11.3335Z" fill="#000106"/>
              <path d="M50.5524 22L60.2763 12.2761C60.797 11.7554 60.797 10.9112 60.2763 10.3905C59.7556 9.86983 58.9114 9.86983 58.3907 10.3905L50.0002 18.7811V11.3333C50.0002 10.597 49.4032 10 48.6668 10C47.9304 10 47.3335 10.597 47.3335 11.3333V32.6667C47.3335 33.4031 47.9304 34 48.6668 34C49.4032 34 50.0002 33.4031 50.0002 32.6667V25.2189L58.3907 33.6095C58.9114 34.1302 59.7556 34.1302 60.2763 33.6095C60.797 33.0888 60.797 32.2445 60.2763 31.7239L50.5524 22Z" fill="#000106"/>
              <path d="M34.0002 22C34.0002 16.8453 38.1788 12.6667 43.3335 12.6667C44.0699 12.6667 44.6668 12.0697 44.6668 11.3333C44.6668 10.597 44.0699 10 43.3335 10C36.7061 10 31.3335 15.3726 31.3335 22C31.3335 28.6274 36.7061 34 43.3335 34C44.0699 34 44.6668 33.4031 44.6668 32.6667C44.6668 31.9303 44.0699 31.3333 43.3335 31.3333C38.1788 31.3333 34.0002 27.1547 34.0002 22Z" fill="#000106"/>
            </g>
            <defs>
              <clipPath id="clip0_4_20698">
                <rect width="109.333" height="24" fill="white" transform="translate(0 10)"/>
              </clipPath>
            </defs>
          </svg>
        </div>
        <div class="text">
          Московская киноплатформа
        </div>
      </div>
      <div class="proj_info" v-if="this.projectInfo && this.projectInfo?.fioActors !== null ">
        <h3>Параметры проекта</h3>
        <div class="el">
          <div class="tit">ФИО главных актеров</div>
          <div class="text">{{ this.projectInfo?.fioActors.join(",") }}</div>
        </div>
      </div>
      <div class="proj_info" v-if="this.projectInfo && this.projectInfo?.synopsis !== null ">
        <h3>Синопсис</h3>
        <div class="el">
          <div class="text synopsys_text">{{ this.projectInfo?.synopsis }}</div>
        </div>
      </div>
      <div class="proj_info" v-if="this.projectInfo && this.projectInfo?.comment !== null ">
        <h3>Ссылки с информацией на ранее снятые компанией фильма</h3>
        <div class="el">
          <div class="text"> {{ this.projectInfo?.comment }}</div>
        </div>
      </div>
    </div>
  </container>
</template>

<script>
import Container from "@/components/library/Container.vue";

export default {
  name: 'ProjectDetail',
  components: {Container},
  props: {
    dataInfo: {
      type: Object,
      require: true
    },
  },
  data() {
    return {
      projects: null,
      projectInfo: null,
      applications: {},
      isLoadingMore: false,
      id: this.$route.params.post_id3,
      getParams: {
        sortExpression:"created DESC",
        filmProjectGuids: this.$route.params.post_id3,
        pageSize : 3,
      },
    };
  },
  methods: {
    /**
     * Функция для получения данных о проекте
     * @param id - guid проекта
     */
    getProjectInfo(id){
      this.loaderProjects = true;
      this.axios
          .get(`/api/get/one/film/project/${id}`)
          .then(response => {
            this.projectInfo = response.data;
            this.getProjects(this.id);
          })
          .catch(error => {
            this.showError(error);
          })
          .finally(() => {
            this.loaderProjects = false;
          });
    },
    /**
     * Функция для получения данных о заявках проекта
     */
    getProjects(isLoadMore) {
      this.loaderProjects = true;
      if (!isLoadMore) {
        delete this.getParams.page;
        this.isLoading = true;
      }
      this.axios
        .get(`/api/get/measure-services`, {params: this.getParams})
        .then(response => {
          this.applications = response.data.records;
          this.showLoader = false;
          setTimeout(() => {
            window.print();
          }, 1000);
        })
        .catch(error => {
          this.showError(error);
        });
    },
  },
  mounted() {
    document.querySelector('body').classList.add('print_body');
    this.getProjectInfo(this.id);
  },
  unmounted() {
    document.querySelector('body').classList.remove('print_body');
  }
}
</script>

<style lang="scss">
  body.print_body{
    background: white !important;
    color: black !important;
    .site_header{
      display: none;
    }
  }
  .print_block{
    padding-left: 32px;
    padding-right: 32px;
    a{
      color: black;
    }
    .print_header{
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 1.6rem;
      padding-bottom: 1.6rem;
      border-bottom: 2px solid black;
      .text{
        font-size: 1.6rem;
        font-weight: 700;
      }
    }
    .flex-data{
      display: flex;
      gap: 2.4rem;
      .row{
        gap: 4px 0;
      }
      .div_img{
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        width: 146px;
        height: 202px;
        border-radius: 1.2rem;
        overflow: hidden;
        img{
          position: absolute;
          height: 100%;
        }
      }
    }
    .tit{
      font-size: 1.2rem;
      line-height: 1.4rem;
      letter-spacing: .002rem;
      color: rgba(63, 63, 66, 1);
    }
    .text{
      font-size: 1.4rem;
      line-height: 150%;
      color: rgba(0, 1, 6, 1);
    }
    h4{
      margin-bottom: 0;
    }
  }

  .table_elem {
    display: grid;
    grid-template-rows: 1fr 1fr 1fr 1fr 1fr 1fr;
    grid-template-columns: 1fr 1fr;
    gap: 4px 10px;
    height: 100%;
  }
  .tab1 {
    grid-row-start: 1;
    grid-column-start: 1;
    grid-row-end: 2;
    grid-column-end: 2;
  }
  .tab2 {
    grid-row-start: 1;
    grid-column-start: 2;
    grid-row-end: 2;
    grid-column-end: 3;
  }
  .tab3 {
    grid-row-start: 2;
    grid-column-start: 1;
    grid-row-end: 3;
    grid-column-end: 3;
  }
  .tab4 {
    grid-row-start: 3;
    grid-column-start: 1;
    grid-row-end: 4;
    grid-column-end: 3;
  }
  .tab5 {
    grid-row-start: 4;
    grid-column-start: 1;
    grid-row-end: 5;
    grid-column-end: 3;
  }
  .tab6 {
    grid-row-start: 5;
    grid-column-start: 1;
    grid-row-end: 6;
    grid-column-end: 3;
  }
  .tab7 {
    grid-row-start: 6;
    grid-column-start: 1;
    grid-row-end: 7;
    grid-column-end: 3;
  }
  .flex-data{
    margin-bottom: 2rem;
  }
  .proj_info{
    margin-bottom: 2rem;
    h3{
      font-size: 1.6rem;
      margin-bottom: 0.8rem;
    }
    .el{
      margin-bottom: .8rem;
    }
  }

  .synopsys_text{
    word-wrap: break-word;
  }
</style>