<template>
  <div style="padding-top: 150px;">
    <container>
      <div class="container_custom">
        <h1 class="fs2" v-if="data?.name">{{ data.name }}</h1>
        <h4 class="school_category" v-if="data?.direction?.name">{{ data.direction.name }}</h4>
        <div class="block_info">
            <row>
                <column lg="fl" md="6" v-if="data?.level?.name">
                    <div class="dt">
                        <div class="info_tit fs21">Уровень:</div>
                        <div class="info_value">{{ data.level.name }}</div>
                    </div>
                </column>
                <column lg="fl" md="6" v-if="data?.forms?.length > 0">
                    <div class="dt">
                        <div class="info_tit fs21">Форма обучения:</div>
                        <div class="info_value" v-for="item in data.forms">{{ item.name }}</div>
                    </div>
                </column>
                <column lg="fl" md="6" v-if="data?.durationValue">
                    <div class="dt">
                        <div class="info_tit fs21">Длительность:</div>
                        <div class="info_value">{{ data.durationValue }}</div>
                    </div>
                </column>
                <column lg="fl" md="6" v-if="data?.document?.name">
                    <div class="dt">
                        <div class="info_tit fs21">Документ:</div>
                        <div class="info_value">{{ data.document.name }}</div>
                    </div>
                </column>
            </row>
        </div>
        <div class="film_content film_schools_item_block">
          <div class="div_top" v-if="data?.description">
              <h3>Описание</h3>
              <div v-html="data.description"/>
          </div>
          <div class="div_bottom" v-if="data?.school">
              <div class="left">
                  <h3 class="div_tit" v-if="data?.school?.fullName">
                      Данную программу обучения предоставляет {{ data.school.fullName }} <span class="shortName" v-if="data?.school?.shortName">({{ data.school.shortName }})</span>
                  </h3>
                  <div class="div_desc" v-if="data?.school?.description">
                      {{ data.school.description }}
                  </div>
                  <div class="div_btn">
                      <cl-button type="middle" :img="'/img/ico/phone.svg'" class="btn-light btn-ico color_wh" v-if="data?.school?.phone" @click.once="showContacts = !showContacts">
                          {{showContacts ? data.school.phone : "Показать телефон"}}
                      </cl-button>
                      <cl-button type="middle" :img="'/img/ico/languageOrang.svg'" class="btn-light btn-ico color_wh" v-if="data?.link" link :href="data.link" target="_blank">Перейти на сайт</cl-button>
                  </div>
              </div>
              <div class="right" v-if="data?.school?.logo">
                <div class="img">
                    <img :src="data?.school?.logo.imageUrl" alt="">
                </div>
              </div>
          </div>
        </div>
      </div>
    </container>
  </div>
</template>

<script>
import Column from "@/components/library/Column.vue";
import {updateMetaTags} from "@/assets/js/updateMetaTags";

export default {
  name: "filmSchoolsItem",
  components: {Column},
  props: {
    dataInfo: {
      type: Object,
      require: true
    },
  },
  data: () => ({
    showContacts: false,
    id: null,
    data: null,
  }),
  methods:{
    getCard(){
			if(this.id){
        return this.axios
          .get(`/api/data/educational-program?id=${this.id}`)
          .then(response => {
            this.data = response.data;

            updateMetaTags(this.$store.dataMeta, this.data.name);

            this.$nextTick(() => {
              helperApp.addBreadcrumbsLink(this.data.name, 'breadcrumbs5');
            });
          })
          .finally(() => {
            this.isLoading = false;
          })
          .catch(error => {
            this.showError(error);
          });
      }
    }
  },
  computed: {
    card() {
      return this.dataInfo.card
    }
  },
  mounted() {
		const str = window.location.pathname;
		const arrStr = str.split("/");
		if(arrStr.length > 2){
			this.id = arrStr[arrStr.length - 1];
		}
		this.getCard();
	}
}
</script>

<style lang="scss" scoped>

.container_custom{
  max-width: 108rem;
  margin: 0 auto;
}
.school_category{
  color: #ED850A;
  margin-bottom: 3.2rem;
}
.block_info{
  padding-bottom: 3.2rem;
  margin-bottom: 3.2rem;
  border-bottom: 1px solid #9096A2;
  .dt{
    display: flex;
    flex-direction: column;
    gap: 0.8rem;
    padding: 0.8rem 1.6rem 0.8rem 0;
    .info_tit{
      color: #ED850A;
    }
  }
}
.block_info :deep(.col:not(:last-child)){
  .dt{
    border-right: 1px solid #FFF;
  }
}
.film_schools_item_block{
  h3{
    margin-bottom: 1.6rem;
  }
  .div_top{
    margin-bottom: 3.6rem;
  }
  .div_bottom{
    display: flex;
    justify-content: space-between;
    gap: 2.4rem;
    padding: 3.2rem;
    border: 2px solid #696C71;
    border-radius: 1.2rem;
    .right{
      width: 14.4rem;
      min-width: 14.4rem;
      height: 14.4rem;
      img{
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
    .left{
      display: flex;
      flex-direction: column;
      gap: 2.4rem;
      .div_tit{
        margin: 0;
      }
      .div_btn{
        display: flex;
        gap: 1.6rem;
      }
    }
  }
  @media (max-width: 767px) {
    .div_bottom{
      .right{
        display: none;
      }
    }
  }
}
</style>

<style lang="scss">
.film_content.film_schools_item_block {
  ul {
    padding-left: 11px;
    li {
      padding-left: 14px;
      &:before {
        width: 2px;
        height: 2px;
        border-radius: 50%;
        background-color: #fff;
      }
    }
  }
}
</style>