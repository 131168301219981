<template>
  <container>
    <row class="trusted_persone_detail">
      <a class="return-src" href="/lk/filmProjects">
        <img src="/img/ico/move-left-2.svg" height="19" width="19">
        Все проекты
      </a>
      <column md="12">
        <card card-type="trusted2" class="trusted2_sec" v-if="this.projectInfo !== null">
          <div class="td1" v-if="this.projectInfo !== null">
            <template v-if="this.projectInfo?.logo !== null && this.imageUrl == ''">
              <div class="div_img">
                <img :src="this.projectInfo.logo.imageUrl" alt="" @click="openFileInput">
              </div>
            </template>
            <template v-else-if="this.imageUrl !== ''">
              <div class="div_img">
                <img :src="this.imageUrl" alt="" @click="openFileInput">
              </div>
            </template>
            <template v-else>
              <div class="div_img">
                <div class="not_img" alt="Добавить лого" @click="openFileInput"> <div class="text">Загрузить лого</div></div>
              </div>
            </template>
            <input type="file" @change="handleFileUpload" ref="fileInput" style="display: none;">
            <cl-button @click="uploadImage" class="btn-save">Сохранить</cl-button>
            <a class="btn-cancel color-or" @click="this.imageUrl = ''">Отменить</a>
          </div>
          <div class="td2">
            <div class="div_tag">
              <span class="sp_all"><span class="sp1">Номер проекта {{this.projectInfo?.id}}</span></span>
              <span class="sp_all">
                <span class="sp1" v-if="this.projectInfo">Дата изменения
                  <template v-if="this.projectInfo?.updated !== null">{{ $filters.ruDate6(new Date(this.projectInfo?.updated)) }}</template>
                  <template v-else>{{ $filters.ruDate6(new Date(this.projectInfo?.created)) }}</template>
                </span>
              </span>
            </div>
            <h3 v-if="this.projectInfo !== null && this.projectInfo?.name">{{ this.projectInfo?.name }}</h3>
            <div class="div_type">
              <template v-if="this.projectInfo !== null && this.projectInfo?.typeAudio?.name || this.projectInfo?.typeAudio?.comment">
                <template v-if="this.projectInfo?.typeAudio?.comment">
                  {{ this.projectInfo?.typeAudio?.comment }}
                </template>
                <template v-else>
                  {{ this.projectInfo?.typeAudio.name }}
                </template>
              </template>
              <template v-if="this.projectInfo !== null && this.projectInfo?.typeAudio?.name && this.projectInfo?.formatAudio?.name">&nbsp/&nbsp</template>
              <template v-if="this.projectInfo !== null && this.projectInfo?.formatAudio?.name">
                <template v-if="this.projectInfo?.typeAudio?.comment">
                  {{ this.projectInfo?.formatAudio?.comment }}
                </template>
                <template v-else>
                  {{ this.projectInfo?.formatAudio.name }}
                </template>
              </template>
            </div>
            <div class="div_type" v-if="this.application !== undefined && this.applications.length > 0 ">
              <span class="sp_all">
                <span class="sp1">Кол-во заявок:</span>
                <span class="sp_color_or">{{ this.applications.length }}</span>
              </span>
            </div>
            <div class="div_type">
              <div class="sp_all" v-if="this.projectInfo !== null && this.projectInfo?.nationalFilm">
                <span class="sp1">УНФ</span>
                <span>{{ this.projectInfo?.nationalFilm }}</span>
                <svg class="svg-icon" width="24" height="24" stroke="#10B981">
                  <use xlink:href="/svg/icons.svg#check"></use>
                </svg>
              </div>
              <div class="sp_all" v-else>
                <span class="sp1">УНФ</span>
                <svg class="svg-icon" width="24" height="24" stroke="#F43F5E" >
                  <use xlink:href="/svg/icons.svg#close"></use>
                </svg>
              </div>
              <div class="sp_all">
                ФСО
                <svg class="svg-icon" width="24" height="24" stroke="#F43F5E" >
                  <use xlink:href="/svg/icons.svg#close"></use>
                </svg>
              </div>
              <div class="sp_all">
                Гарантийное письмо
              </div>
            </div>
          </div>
        </card>
      </column>
    </row>
    <ms-form class="formEditProjects" :urlData="`/api/update/film/projects/${$route.params.post_id3}`" :submitName="'Сохранить'" :btn-submit-modal="true" :btnCloseModal="false" @closeModal="modalProjectsEditShow = false" @ok="okProjects"/>
    <cl-modal v-model="modalProjectsOk" modal-type="center">
      <template v-slot:header>
        <div class="success_block">
          <img src="/img/ico/check-circle-2.svg" alt="">
          <div>{{ `Проект ${modalProjectsName} успешно сохранен!`}}</div>
        </div>
<!--        <div class="success_block_dop">Теперь он доступен для выбора в новых заявках</div>-->
      </template>
      <template v-slot:footer>
        <a href="/lk/filmProjects" class="btn btn-default m-0-auto">К списку проектов</a>
      </template>
    </cl-modal>
  </container>
</template>

<script>

import Card from "@/components/library/Card.vue";
import MsForm from "@/components/forms/MsForm.vue";
import {mapGetters} from "vuex";
import ClModal from "@/components/library/ClModal.vue";
import ClButton from "@/components/library/ClButton.vue";
import {Base64} from "js-base64";

export default {
  name: "filmProjects",
  components: {ClButton, ClModal, MsForm, Card},
  props: {
    dataInfo: {
      type: Object,
      require: true
    },
  },
  data(){
    return{
      projects: null,
      modalProjectsAddShow: false,
      modalProjectsEditShow: false,
      modalProjectsDeleteShow: false,
      activeProjects: null,
      loaderProjects: true,
      modalProjectsOk: false,
      modalProjectsName: null,
      projectInfo: null,
      id: this.$route.params.post_id3,
      image: null,
      selectedFile: null,
      imageUrl: '',
    }
  },
  computed: {
    ...mapGetters([
      'getUser'
    ]),
  },
  methods: {
    handleFileUpload(event) {
      const file = event.target.files[0];
      const reader = new FileReader();
      reader.onload = (e) => {
        this.image = e.target.result.split(",")[1];
        this.imageUrl = e.target.result;
      };
      reader.readAsDataURL(file);
      this.getProjectInfo(this.id);
    },
    selectImage(event) {
      this.selectedFile = event.target.files[0];
    },
    openFileInput() {
      this.$refs.fileInput.click();
    },
    uploadImage() {
      let data = new FormData();
      data.append("FileName", 'Имя файла');
      data.append("Logo", this.image);
      this.axios
          .post(`/api/add/film-projects/${this.id}/logo`, data)
          .then(response => {
            this.showInfo(false,`Логотип проекта успешно обновлен`, '');
          })
          .catch(error => {
            console.log('error')
          })
          .finally(() => {
            console.log('finally')
          });
    },
    getProjectInfo(id){
      this.loaderProjects = true;
      this.axios
          .get(`/api/get/one/film/project/${id}`)
          .then(response => {
            this.projectInfo = response.data;
          })
          .catch(error => {
            this.showError(error);
          })
          .finally(() => {
            this.loaderProjects = false;
          });
    },
    getProjects(){
      this.loaderProjects = true;
      this.axios
          .get(`/api/get/list/film/projects/`)
          .then(response => {
            this.projects = response.data.records;
          })
          .catch(error => {
            this.showError(error);
          })
          .finally(() => {
            this.loaderProjects = false;
          });
    },
    removeProjects(){
      this.modalProjectsDeleteShow = false;
      this.loaderProjects = true;
      this.axios
          .post(`/api/delete/film/projects/${this.activeProjects.guid}`)
          .then(response => {
            this.getProjects();
          })
          .catch(error => {
            this.showError(error);
          })
          .finally(() => {
            this.loaderProjects = false;
          });
      },
    okProjects(){
      this.modalProjectsName = document.getElementById('film_projects_name').value;
      this.modalProjectsOk = true;
      this.getProjects();
    }
  },
  mounted(){
    this.getProjectInfo(this.id);
  }
}
</script>

<style lang="scss" scoped>
.trusted_persone{
  margin-top: 3.6rem;
  position: relative;
  .modal_bkcg{
    position: absolute;
    inset: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 1, 6, 0.80);
  }
  .modal_info{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 32px 24px;
    display: flex;
    flex-direction: column;
    gap: 12px;
    border-radius: 6px;
    background: #17191C;
    max-width: 800px;
  }
  .mInfo_row2{
    display: flex;
    gap: 12px;
    height: 64px;
    .mInfo_info{
      display: flex;
      gap: 12px;
      align-items: center;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 131.25%;
      letter-spacing: 0.24px;
    }
    img{
      height: 100%;
      width: auto;
      object-fit: contain;
    }
  }
}
.loader-trusted{
  position: absolute;
  width: 100%;
  height: 100%;
  inset: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 10;
}

.card-trusted2 .card-holder .div_tag {
  gap: 1rem;
}
.div_tag{
  font-size: 1.2rem;
}
.trusted_persone_detail{
  margin-bottom: 2.4rem;
}
.card-trusted2{
  cursor: unset;
  padding: 1.6rem;
  margin-bottom: 3.2rem;
  height: 33.6rem;
  .card-holder .td1{
    display: flex;
    flex-direction: column;
    gap: 1.2rem;
    align-items: center;
    height: fit-content;
    .btn.btn-save{
      min-height: 3.6rem;
      padding: .9rem 3rem;
      height: 3.6rem;
      width: 14.6rem;
    }
    .div_img{
      overflow: hidden;
      border-radius: 1.2rem;
      width: 14.6rem;
      height: 20.6rem;
      img{
        width: calc(100% + 1rem);
        height: calc(100% + 1rem);
        object-fit: cover;
        top: -.2rem;
        position: relative;
      }
    }
  }
}
.return-src{
  display: flex;
  align-items: center;
  cursor: pointer;
  gap: .5rem;
}

</style>
<style lang="scss">

//grid form
.formEditProjects .measure {
    display: grid;
    grid-template-rows: fit-content;
    grid-template-columns: calc(20% - 20px) calc(20% - 20px) calc(20% - 20px) calc(20% - 20px) calc(20% - 20px);
    gap: 20px;
    height: 100%;
    margin-bottom: 12rem;

  .form-group:not(.d-none):nth-child(2){
    //background-color: #5978AB;
    grid-row-start: 1;
    grid-column-start: 1;
    grid-row-end: 2;
    grid-column-end: 6;
    font-size: 2.4rem;
  }
  .form-group:not(.d-none):nth-child(3){
    //background-color: #F88AB9;
    grid-row-start: 2;
    grid-column-start: 1;
    grid-row-end: 3;
    grid-column-end: 5;
  }
  .form-group:not(.d-none):nth-child(4){
    //background-color: #59A7B9;
    grid-row-start: 3;
    grid-column-start: 1;
    grid-row-end: 4;
    grid-column-end: 3;
  }
  .form-group:not(.d-none):nth-child(5){
    //background-color: #BAC675;
    grid-row-start: 3;
    grid-column-start: 3;
    grid-row-end: 4;
    grid-column-end: 5;
  }
  .form-group:not(.d-none):nth-child(6){
    //background-color: #9BBDBB;
    grid-row-start: 4;
    grid-column-start: 1;
    grid-row-end: 5;
    grid-column-end: 5;
    display: flex;
    flex-direction: column;
    .form-label{
      font-size: 2rem;
      margin-bottom: .4rem;
    }
    .informer{
      margin-bottom: .4rem;
    }
    .form-file{
      order: 2;
    }
  }
  .form-group:not(.d-none):nth-child(7){
    //background-color: #C96D99;
    grid-row-start: 5;
    grid-column-start: 1;
    grid-row-end: 6;
    grid-column-end: 2;
  }
  .form-group:not(.d-none):nth-child(8){
    //background-color: #FBD877;
    grid-row-start: 5;
    grid-column-start: 2;
    grid-row-end: 6;
    grid-column-end: 5;
  }
  .form-group:not(.d-none):nth-child(9){
    //background-color: #AADF7F;
    grid-row-start: 6;
    grid-column-start: 1;
    grid-row-end: 6;
    grid-column-end: 3;
  }
  .form-group:not(.d-none):nth-child(10){
    //background-color: #ADDD58;
    grid-row-start: 6;
    grid-column-start: 3;
    grid-row-end: 6;
    grid-column-end: 5;
    align-items: baseline;
    .form-label{
      display: none;
    }
  }
  .form-group:not(.d-none):nth-child(11){
    //background-color: #ADDD58;
    grid-row-start: 7;
    grid-column-start: 1;
    grid-row-end: 7;
    grid-column-end: 6;
  }
  .form-group:not(.d-none):nth-child(12){
    //background-color: #ADDD58;
    grid-row-start: 8;
    grid-column-start: 1;
    grid-row-end: 8;
    grid-column-end: 3;
  }
  .form-group:not(.d-none):nth-child(13){
    //background-color: #ADDD58;
    grid-row-start: 8;
    grid-column-start: 3;
    grid-row-end: 8;
    grid-column-end: 5;
  }
  .form-group:not(.d-none):nth-child(14){
    //background-color: #ADDD58;
    grid-row-start: 9;
    grid-column-start: 1;
    grid-row-end: 9;
    grid-column-end: 3;
  }
  .form-group:not(.d-none):nth-child(15){
    //background-color: #ADDD58;
    grid-row-start: 9;
    grid-column-start: 3;
    grid-row-end: 10;
    grid-column-end: 5;
  }
  .form-group:not(.d-none):nth-child(16){
    //background-color: #ADDD58;
    grid-row-start: 11;
    grid-column-start: 1;
    grid-row-end: 11;
    grid-column-end: 3;
  }
  .form-group:not(.d-none):nth-child(17){
    //background-color: #ADDD58;
    grid-row-start: 11;
    grid-column-start: 3;
    grid-row-end: 11;
    grid-column-end: 5;
  }
  .form-group:not(.d-none):nth-child(18){
    //background-color: #ADDD58;
    grid-row-start: 12;
    grid-column-start: 1;
    grid-row-end: 12;
    grid-column-end: 3;
  }
  .form-group:not(.d-none):nth-child(19){
    //background-color: #ADDD58;
    grid-row-start: 12;
    grid-column-start: 3;
    grid-row-end: 12;
    grid-column-end: 5;
  }
  .form-group:not(.d-none):nth-child(20){
    //background-color: #ADDD58;
    grid-row-start: 13;
    grid-column-start: 1;
    grid-row-end: 13;
    grid-column-end: 6;
  }
  .form-group:not(.d-none):nth-child(21){
    //background-color: #ADDD58;
    grid-row-start: 14;
    grid-column-start: 1;
    grid-row-end: 14;
    grid-column-end: 5;
  }
  .form-group:not(.d-none):nth-child(22){
    //background-color: #ADDD58;
    grid-row-start: 15;
    grid-column-start: 1;
    grid-row-end: 15;
    grid-column-end: 6;
  }
  .form-group:not(.d-none):nth-child(23){
    //background-color: #ADDD58;
    grid-row-start: 16;
    grid-column-start: 1;
    grid-row-end: 16;
    grid-column-end: 6;
  }
  .form-group:not(.d-none):nth-child(24){
    //background-color: #ADDD58;
    grid-row-start: 17;
    grid-column-start: 1;
    grid-row-end: 17;
    grid-column-end: 6;
  }
  .form-group:not(.d-none):nth-child(25){
    //background-color: #ADDD58;
    grid-row-start: 18;
    grid-column-start: 1;
    grid-row-end: 18;
    grid-column-end: 6;
  }
  .form-group:not(.d-none):nth-child(26){
    //background-color: #ADDD58;
    grid-row-start: 19;
    grid-column-start: 1;
    grid-row-end: 19;
    grid-column-end: 6;
  }
  .form-group:not(.d-none):nth-child(27){
    //background-color: #ADDD58;
    grid-row-start: 20;
    grid-column-start: 1;
    grid-row-end: 20;
    grid-column-end: 6;
  }
  .buttons_bottom{
    //background-color: #ADDD58;
    grid-row-start: 21;
    grid-column-start: 1;
    grid-row-end: 21;
    grid-column-end: 5;
  }
  .form-group.select{
    .my-select--select{
      height: 5.6rem;
      overflow: hidden;
    }
    .my-select--tags {
      padding: 2px 40px 2px 14px;
      margin: 3px 5px 3px 0;
    }
  }
  .form-group.checkbox{
    display: flex;
    .label_input{
      display: flex;
      align-items: center;
      position: relative;
      &:checked, &[checked=true] {
        &::before {
          content: "" !important;
          height: 24px !important;
          width: 24px !important;
          background: url(@/assets/img/ico/check-white.svg) no-repeat center center orange !important;
          border: 1px solid orange !important;
          position: absolute !important;
          border-radius: 4px !important;
        }
      }
    }
    .form-check-input{
      content: "";
      width: 24px;
      height: 24px;
      border-radius: 4px;
      border: 1px solid #C2C2C2;
      background-color: #ffffff;
      margin-right: 16px;
    }
  }
  .form-group.film_project_file{
    display: flex;
    align-items: center;
    gap: 1.2rem;
  }
}

.form-container .form-group.string input, .form-container .form-group.datetime input {
  min-height: 5.6rem;
}

.form-container .form-group.datetime{
  .dp__input{
    padding: 2.2rem 4.8rem 1rem 5.6rem;
    max-height: 5.6rem;
    &.dp__input_icon_pad{
      padding: 2.2rem 4.8rem 1rem 5.6rem;
    }
  }
  .dp__input_icons{
    padding-top: 20px;
  }
  .form-label2{
    font-size: 1.2rem;
    line-height: 116.667%;
    letter-spacing: 0.018rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    position: absolute;
    z-index: 10;
    left: 2rem;
    top: .4rem;
    color: #6B7280;
  }
}

.not_img{
  border-radius: 1.2rem;
  width: 14.6rem;
  height: 20.2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  .text{
    color: #ED870A;
    font-weight: 500;
    cursor: pointer;
    z-index: 10;
  }
}
</style>