<template>
  <card
      card-type="support-measure"
      :class="{'options_showed': showedOptions}"
  >
    <div class="div_status"  v-if="event.alert || event.status">
      <template v-if="event.status">
        <cl-status type="approved" class="status-ico-wrap" v-if="event.status == 'Одобрена'">
          {{ event.status }}
        </cl-status>
        <cl-status type="rejected" class="status-ico-wrap" v-else-if="event.status == 'Отклонена'">
          {{ event.status }}
        </cl-status>
        <cl-status type="new" class="status-ico-wrap" v-else-if="event.status == 'Новая'">
          {{ event.status }}
        </cl-status>
        <cl-status type="consideration" class="status-ico-wrap" v-else-if="event.status == 'В работе'">
          {{ event.status }}
        </cl-status>
        <cl-status type="draft" class="status-ico-wrap" v-else-if="event.status == 'Черновик'">
          {{ event.status }}
        </cl-status>
        <cl-status type="lock" class="status-ico-wrap" v-else-if="event.status == 'Завершена'">
          {{ event.status }}
        </cl-status>
        <cl-status v-else>{{ event.status }}</cl-status>
      </template>
      <Popper
          v-if="event.alert"
          content="Требуется действия"
          class="light2 popper_info"
          hover="true"
          arrow
      >
        <img src="/img/alert-circle.svg">
      </Popper>
<!--      <span v-if="event.result && event.resultCode" class="color_gray">{{ event.result }}</span>-->
    </div>
    <h4  v-if="event.outerParams?.['frontOutput:name_poject']" class="div_tit_h">{{ event.outerParams['frontOutput:name_poject'].value }}</h4>
    <div class="div_tit_flex">
      <div class="dt1" v-if="event.measureSupport?.image"><img :src="event.measureSupport.image" alt=""></div>
      <div class="dt2" v-if="event.name">
        <div class="div_tit_gray">Тип заявки:</div>
        <div>{{ event.name }}</div>
      </div>
    </div>
    <div class="div_number" v-if="event.code"><span class="sp_num">№{{ event.code }}</span>
      <template v-if="event.fio">
        <span class="color_gray">отправил:</span> {{ event.fio.split(' ')[0] }} {{ event.fio.split(' ')[1] ? event.fio.split(' ')[1].charAt(0) + '.' : '' }} {{ event.fio.split(' ')[2] ? event.fio.split(' ')[2].charAt(0) + '.' : '' }}
      </template>
      <template v-if="event.created">
      <span class="color_gray">Дата подачи:</span> {{ new Date(event.created).getYear() == new Date().getYear() ? $filters.ruDate1(new Date(event.created)) : $filters.ruDate3(new Date(event.created)) }}
      </template>
    </div>
    <div class="div_tit_flex2" v-if="event.outerParams">
      <div class="dt" v-if="event.outerParams['frontOutput:filming_date_from']">
        <div class="div_tit_gray">Дата съемки:</div>
        <div>{{ event.outerParams['frontOutput:filming_date_from'].value }} <template v-if="event.outerParams['frontOutput:filming_date_to'] && event.outerParams['frontOutput:filming_date_from'] != event.outerParams['frontOutput:filming_date_to']">- {{ event.outerParams['frontOutput:filming_date_to'].value }}</template></div>
      </div>
      <div class="dt" v-if="event.outerParams['frontOutput:name_address'] || event.outerParams['frontOutput:name_location']">
        <div class="div_tit_gray">Локация:</div>
        <div>{{ event.outerParams['frontOutput:name_location'].value }} <template v-if="event.outerParams['frontOutput:name_address'] && event.outerParams['frontOutput:name_location']"> / </template> {{ event.outerParams['frontOutput:name_address'].value }}</div>
      </div>

      <div class="dt" v-if="event.outerParams['frontOutput:name_route']">
        <div class="div_tit_gray">Маршут:</div>
        <div>{{ event.outerParams['frontOutput:name_route'].value }}</div>
      </div>
    </div>

<!--    <div class="div_btn"><cl-button type="light" class="btn-middle" @click="$router.push(`/lk/my-applications/item/${event.guid}`)">Подробнее</cl-button></div>-->
    <div class="div_btn_options">
      <cl-button class="btn_options" :img="showedOptions ? '/img/ico/cancel.svg' : ''" @click="showOptions()">
        <template v-if="!showedOptions" v-slot:before>
          <svg class="svg-icon" width="24" height="24" stroke="white" >
            <use xlink:href="/svg/icons.svg#kebab-menu"></use>
          </svg>
        </template>
      </cl-button>
    </div>

    <div v-if="showedOptions" class="div_options_wrapper">
      <loader
          v-if="!optionsLoaded"
          :animation-duration="3000"
          :size="60"
      ></loader>
      <div v-else-if="!optionsLoadedWithError" class="div_options">
<!--        <div class="div_options_btn">-->
<!--          <cl-button class="btn_option">-->
<!--            <template v-slot:before>-->
<!--              <svg class="svg-icon" width="24" height="24" stroke="white" >-->
<!--                <use xlink:href="/svg/icons.svg#file-down"></use>-->
<!--              </svg>-->
<!--            </template>-->
<!--            Скачать PDF-->
<!--          </cl-button>-->
<!--        </div>-->
        <div v-if="event.guid" class="div_options_btn">
          <cl-button link :href="`/lk/my-applications/item/${event.guid}`" class="btn_option img_wh" img="/img/ico/file.svg">Подробнее</cl-button>
        </div>
        <div v-if="optionsDataMeasureRequests?.outCode && Object.keys(optionsDataMeasureRequests.outCode).length !== 0" class="div_options_btn">
          <cl-button class="btn_option img_wh" @click="openModalMeas(optionsDataMeasureRequests.outCode)">
            <template v-slot:before>
              <svg class="svg-icon" width="24" height="24" stroke="white" >
                <use xlink:href="/svg/icons.svg#file-symlink"></use>
              </svg>
            </template>
            Отозвать
          </cl-button>
        </div>
        <div v-if="event.status == 'Черновик' && event.measureSupport?.guid" class="div_options_btn">
          <cl-button link :href="`/form/${event.measureSupport.guid}`" class="btn_option img_wh" img="/img/ico/pencil.svg">Редактировать</cl-button>
        </div>
        <div v-if="event.status == 'Черновик' && event.guid" class="div_options_btn">
          <cl-button class="btn_option" img="/img/ico/trash.svg" @click="openModalAlertDeleteApplication(event.guid)">Удалить</cl-button>
        </div>
      </div>
      <div v-else class="div_options">
        <div class="div_options_err_text">
          Произошла ошибка при загрузке опций, попробуйте загрузить данные снова.
        </div>
        <div class="div_options_btn">
          <cl-button type="light" class="btn-middle w-100" @click="updateRequests()">Загрузить</cl-button>
        </div>
      </div>
    </div>
  </card>
</template>

<script>
import ClButton from "@/components/library/ClButton.vue";

export default {
  name: "CardSupportMeasure",
  components: {ClButton},
  data() {
    return {
      /**
       * Состояние, которое показывает открыто ли окно с опциями
       * @type {boolean}
       * @default false
       */
      showedOptions: false,
      /**
       * Опции (Информация о доп. действиях в заявке на меру поддержки)
       * @type {object | null}
       * @default null
       */
      optionsDataMeasureRequests: null,
      /**
       * Состояние, которое показывает загружены ли опции (Информация о доп. действиях в заявке на меру поддержки)
       * @type {boolean}
       * @default false
       */
      optionsDataMeasureRequestsLoaded: false,
      /**
       * Состояние, которое показывает было ли уже запущено получение всех опций
       * @type {boolean}
       * @default false
       */
      optionsDataTaken: false,
      /**
       * Состояние, которое показывает были ли ошибки при получении всех опций
       * @type {boolean}
       * @default false
       */
      optionsLoadedWithError: false,
      /**
       * Контроллер запросов
       * @type {object | null}
       * @default null
       */
      controller: null,
    }
  },
  props: {
    event: {
      type: Object
    }
  },
  computed: {
    /**
     * Состояние, которое показывает загружены ли все опции
     * @return {boolean}
     */
    optionsLoaded() {
      return this.optionsDataMeasureRequestsLoaded
    }
  },
  methods: {
    /**
     * Функция, которая выводит на экран окно с опциями
     */
    showOptions() {
      this.showedOptions = !this.showedOptions;
      if(!this.optionsDataTaken) {
        this.optionsDataTaken = !this.optionsDataTaken;
        this.getMeasureRequests(this.event.guid);
      }
    },

    /**
     * Функция получения опций (Информация о доп. действиях в заявке на меру поддержки)
     * @param {string} id - id карточки (меры)
     */
    getMeasureRequests(id) {
      this.axios
          .get(`/api/get/measure-services/${id}/measure-service-requests`, {signal: this.controller.signal})
          .then(response => {
            this.optionsDataMeasureRequests = response.data;
            this.optionsDataMeasureRequestsLoaded = true;
          })
          .catch(error => {
            this.optionsLoadedWithError = true;
            this.optionsDataMeasureRequestsLoaded = true;
            this.showError(error);
          });
    },

    /**
     * Функция для повтора запросов, чтобы полученить все опции
     */
    updateRequests() {
      this.optionsDataMeasureRequestsLoaded = false;
      this.optionsLoadedWithError = false;
      this.getMeasureRequests(this.event.guid);
    },

    /**
     * Функция, которая получает параметры меры и передает их в верхний компонент, чтобы открыть модалку с мерами
     * @param {object} outCode - параметры меры
     */
    openModalMeas(outCode) {
      const value = {
        measureServiceGuid: this.event.guid,
        subRequestGuid: outCode.guid,
        formTitle: outCode.name,
      }
      this.$emit("openModalMeas", value)
    },

    /**
     * Функция, которая вызывает emit openModalAlertDeleteApplication, чтобы открыть модалку об удалении заявки
     * @param {string} id - id заявки
     */
    openModalAlertDeleteApplication(id) {
      this.$emit("openModalAlertDeleteApplication", id)
    },
  },
  created() {
    this.controller = new AbortController();
  },
  beforeUnmount() {
    // отмена запросов
    this.controller.abort();
    this.controller = null;
  },
  emits: ['openModalMeas', 'openModalAlertDeleteApplication']
}
</script>

<style lang="scss" scoped>

</style>