<template>
  <div class="block_start_actors">
    <container class="container3">
      <div class="start_actors">
        <div class="dt dt1">
          <template v-if="dataInfo.bannerStartActors.titleBlock">
            <div class="title" v-html="dataInfo.bannerStartActors.titleBlock.value"></div>
          </template>
          <div class="desc" v-if="dataInfo.bannerStartActors.descBlock.value"> {{ dataInfo.bannerStartActors.descBlock.value }}</div>
          <div class="dib_btn" v-if="dataInfo.bannerStartActors.descBtn">
            <cl-button class="btn-light" @click="this.showModal = true;">{{ dataInfo.bannerStartActors.descBtn.value }}</cl-button>
          </div>
        </div>
        <div class="dt dt2">
          <img :src="getImage(dataInfo?.bannerStartActors.imageBlock)" alt="">
        </div>
      </div>
    </container>
  </div>
  <cl-modal modal-type="black" class="modal-actors" v-if="dataInfo.bannerStartActors.measureGuid" v-model="showModal" :closeStandart="true" v-bind="modalAlertMeasureLeaveTopModalAttr(dataInfo?.bannerStartActors?.measureGuid?.value)">
    <template v-if="dataInfo.bannerStartActors.modalTextTitle" v-slot:header>
        <h4>{{ dataInfo.bannerStartActors.modalTextTitle.value }}</h4>
    </template>
    <template v-slot:body>
      <measure-form-component
          v-if="dataInfo.bannerStartActors.measureGuid.value"
          :measureGuid="measureGuid"
          :formParams="measureFormParams"
          :showMode="showMode"
          :successText="successText"
          :successTextImportant="true"

          v-bind="modalAlertMeasureLeaveAttr()"
          v-on="modalAlertMeasureLeaveEmits()"
      />
    </template>
  </cl-modal>
</template>

<script>
import MeasureFormComponent from "@/components/measureForm";
import mixinModalAlertMeasureLeave from "@/mixin/mixinModalAlertMeasureLeave";

export default {
  name: "startActor",
  components: {MeasureFormComponent},
  mixins: [mixinModalAlertMeasureLeave],
  data(){
    return {
      showModal: false,
      showMode: 'inline',
      measureFormParams: {
        'appendCss': '/override/events.css',
        preSetParams: {
          widget: "mcounter",
        }
      },
      measureGuid: this.dataInfo.bannerStartActors.measureGuid.value,
      successText: "Ваше обращение успешно отправлено!",
    }
  },
  props: {
    dataInfo: {
      type: Object,
      require: true
    },
  },
  mounted() {
    if (this.dataInfo.bannerStartActors.modalTextSucceed){
      this.successText = this.dataInfo.bannerStartActors.modalTextSucceed.value
    }
    const targetNode = document.getElementById("modal-form-success");
    const observer = new MutationObserver((mutationsList) => {
      for (let mutation of mutationsList) {
        this.showModal = false;
      }
    });
    const config = { attributes: true };
    observer.observe(targetNode, config);
  }
}
</script>

<style lang="scss">
.modal.modal-actors{
  .modal-dialog{
    height: 100%;
    width: 660px;
    max-width: 100vw;
    padding: 30px 0;
    display: flex;
    align-items: center;
    margin-bottom: 0;
  }
  .modal-close{
    display: block;
    &::before, &::after {
      background: white;
    }
  }
  .modal-header{
    color: #ED850A;
  }
}
</style>

<style lang="scss" scoped>
.block_start_actors{
  margin: 4rem 0;
  .container{
    max-width: 1440px;
  }
  @media(max-width: 1440px){
    .container{
      padding: 0;
    }
  }
  @media(max-width: 991px){
    .container{
      padding: 0 4rem;
    }
    .block_scheme{
      border-radius: 2.4rem;
    }
  }
  @media(max-width: 767px){
    .container{
      padding: 0;
    }
  }
}

.start_actors{
  padding: 64px 120px;
  background: #17191C;
  display: flex;
  justify-content: space-between;
  gap: 24px;
  border-radius: 16px;
  .dt{
    display: flex;
    &.dt1{
      flex-direction: column;
      justify-content: center;
      width: 50%;
      .title{
        font-size: 32px;
        line-height: 150%;
        margin-bottom: 8px;
      }
      .desc{
        font-size: 24px;
        line-height: 150%;
        margin-bottom: 24px;
      }
    }
    &.dt2{
      width: 50%;
      img{
        border-radius: 16px;
      }
    }
  }
  @media(max-width: 1240px){
    padding: 64px 32px;
  }
  @media(max-width: 991px){
    padding: 32px 16px;
    flex-direction: column-reverse;
    .dt{
      &.dt1{
        width: 100%;
        .title{
          font-size: 24px;
        }
        .desc{
          font-size: 18px;
        }
        .btn{
          width: 100%;
          min-height: 48px;
        }
      }
      &.dt2{
        width: 100%;
        max-height: 310px;
        display: flex;
        align-items: center;
        justify-content: center;
        overflow: hidden;
        border-radius: 16px;
        img{
          object-fit: contain;
          width: 100%;
        }
      }
    }
  }
}
</style>