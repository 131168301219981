<template>
  <div  v-if="dataInfo" :class="{'block_video_top': !dataInfo.MosIsVideo, 'block_text_top': dataInfo.MosIsVideo}">
    <div class="div_video">
      <video preload="metadata" v-if="dataInfo.MosVideo && !dataInfo.MosIsVideo" autoplay muted loop playsinline id="videoMainTop">
        <source :src="dataInfo.MosVideo.value.src" type='video/mp4; codecs="avc1.42E01E, mp4a.40.2"'>
      </video>
    </div>
    <container class="banner_container">
      <div class="banner" v-if="dataInfo.MosTitle || dataInfo.MosText">
        <h1 v-if="dataInfo.MosTitle" class="banner__title">{{dataInfo.MosTitle.value}}</h1>
        <div v-if="dataInfo.MosText" class="banner__text">{{dataInfo.MosText.value}}</div>
        <filter-tabs
            v-if="filter.typeCategoryOptions && filter.typeCategoryOptions.length > 0"
            :tabActive="filter.typeCategory"
            :tabList="filter.typeCategoryOptions"
            :tabSample="1"
            tabKey="value"
            label="name"
            class="filter-ico"
            @changeTab="(event) => changeFilter(event.anchorToMove)"
            :data-filter="1"
            id="filterTopCategory"
        ></filter-tabs>
      </div>
    </container>
  </div>
</template>

<script>
// Что бы блок на страницы отображался в зависимости от выбранной категории надо ему добавить id tabCategoryMoskino-[номер идет от 0,1,2,3]

import Container from "@/components/library/Container.vue";
import FilterTabs from "@/components/library/FilterTabs.vue";
import {mapGetters} from "vuex";

export default {
  name: "mainBigBlockKinopark",
  components: {
    FilterTabs,
    Container
  },
  props: {
    dataInfo: {
      type: Object,
      require: true,
    }
  },
  data() {
    return {
      filter: {
        typeCategory: [],
        typeCategoryOptions: [],
      },
      /**
       * Текущий Url
       * @type {URL}
       */
      currentUrl: new URL(window.location.href),
    };
  },
  methods: {
    /**
     * Функция выставления активного таба при монтировании
     */
    initializeAndToggleFilter() {
      if (this.dataInfo && this.dataInfo.MosTabList) {
        this.filter.typeCategoryOptions = [...this.dataInfo.MosTabList].map((tab) => {
          return {value: `tabCategoryMoskino-${tab.indexId.value}`, name: tab.MosTab.value, anchorToMove: tab.anchorToMove?.slice(1)};
        });
        //определение активного таба
        if(this.dataInfo.query && this.currentUrl.searchParams.has(this.dataInfo.query.value)){
          this.filter.typeCategory = [ 'tabCategoryMoskino-' + [...this.dataInfo.MosTabList].filter(item => item.query.value === this.currentUrl.searchParams.get(this.dataInfo.query.value))[0]?.indexId.value];
        } else {
          this.filter.typeCategory = [this.filter.typeCategoryOptions[0].value];
        }
        this.$nextTick(() => {
          this.toggleCategoryMoskinoBlock();
        });
      }
    },

    /**
     * Функция фильтрации блоков на странице
     * @returns {Promise<unknown>}
     */
    toggleCategoryMoskinoBlock() {
      return new Promise((resolve, reject) => {
      //смена url query
        if(this.dataInfo.query){
          if(this.currentUrl.searchParams.has(this.dataInfo.query.value)) {
            this.currentUrl.searchParams.set(this.dataInfo.query.value, [...this.dataInfo.MosTabList].filter(item => item.indexId.value === this.filter.typeCategory[0].replace('tabCategoryMoskino-', ''))[0]?.query.value);
            history.pushState({}, "", this.currentUrl);
          } else {
            history.pushState({}, "", this.currentUrl.origin + this.currentUrl.pathname + "?" + this.dataInfo.query.value + "=" + [...this.dataInfo.MosTabList].filter(item => item.indexId.value === this.filter.typeCategory[0].replace('tabCategoryMoskino-', ''))[0]?.query.value + (this.currentUrl.search ? '&' + this.currentUrl.search.slice(1) : '') + this.currentUrl.hash);
            this.currentUrl = new URL(window.location.href);
          }
        }
        setTimeout(() => {
          document.querySelectorAll('[id^="tabCategoryMoskino"]').forEach((element) => {
            if (element.id.indexOf(this.filter.typeCategory[0]) !== -1) {
              element.classList.remove("d-none");
            } else {
              element.classList.add("d-none");
            }
          });
          resolve();
        }, 0);
      });
    },
    /**
     * Событие нажатия на таб фильтрация блоков и скрол до нужного
     * @param {string | undefined} idScroll - id блока до которого необходимо скролить страницу
     */
    changeFilter(idScroll) {
      this.toggleCategoryMoskinoBlock().then(() => {
        let block = document.querySelector('.block_video_top') || document.querySelector('.block_text_top');
        if(idScroll){
          block = document.getElementById(idScroll);
        }
        if(block){
          let topPos = idScroll ? block.offsetTop : block.offsetTop + block.offsetHeight;

          window.scrollBy({
            top: topPos - window. scrollY,
            behavior: 'smooth'
          });
        }
      });
    },
  },
  computed: {
    ...mapGetters(["widthSite"]),
  },
  mounted() {
    this.initializeAndToggleFilter();

    if(!this.dataInfo.MosIsVideo){
      // обработка видео
      const video = document.getElementById("videoMainTop");
      if (video) {
        video.muted = true; // Отключение звука для предотвращения блокировки автовоспроизведения
        video.play().catch((error) => {
          //console.error('Автовоспроизведение заблокировано', error);
        });
      }

      this.$nextTick(() => {
        if(document.querySelector('.button_kinokomis_container')) {
          document.body.classList.add('body_video_top');
        }
      });

      // добавляем фон
      document.body.classList.add("cinemapark-moskino");
    }

    /**
     * наблюдаем за изменение filterTopCategory и скролим до якоря
     */
    this.$nextTick(() => {
      const targetNode = document.getElementById("filterTopCategory");
      const observer = new MutationObserver((mutationsList) => {
        for (let mutation of mutationsList) {
          const cat = document.querySelector('#filterTopCategory').getAttribute('data-filter').replace('#', ''),
                catSplit = cat.split('-');
          this.filter.typeCategory = [catSplit[0] + '-' + catSplit[1]];
          this.changeFilter(cat);
        }
      });
      const config = {attributes: true};
      observer.observe(targetNode, config);
    });

  },
  beforeUnmount() {
    document.body.classList.remove('body_video_top');
    document.body.classList.remove('cinemapark-moskino');
  },
};
</script>

<style lang="scss">
.body_video_top {
  .block_video_top {
    margin-top: 125px !important;

    @media (max-width: 767px) {
      margin-top: 135px !important;
    }
  }

  .button_kinokomis_container {
    @media (max-width: 1500px) {
      max-width: 1320px;

      .button_kinokomis {
        border-radius: 0.8rem;
      }
    }
    @media (max-width: 1368px) {
      margin-left: 24px;
      margin-right: 24px;
      width: calc(100% - 48px);
    }
    @media (max-width: 767px) {
      width: 100%;
      margin: 0;

      .button_kinokomis {
        border-radius: 0;
      }
    }
  }
}

</style>

<style lang="scss" scoped>

.block_video_top {
  max-width: 1440px;
  min-height: 76rem;
  gap: 4.8rem;
  background-position: center top;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  margin: 8rem auto 4rem auto;
  padding: 4.8rem 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  width: 100%;

  .div_video {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    border-radius: 6.4rem;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;

    video {
      width: 100%;
    }
  }

  .container {
    position: relative;
    //padding: 8rem 6rem;
  }

  @media (max-width: 1500px) {
    max-width: 1320px;
  }
  @media (max-width: 1368px) {
    margin-left: 24px;
    margin-right: 24px;
    width: calc(100% - 48px);
  }
  @media (max-width: 991px) {
    .div_video {
      video {
        height: 100%;
        width: auto;
      }
    }
  }
  @media (max-width: 767px) {
    width: 100%;
    margin: 8rem 0 16px 0;
    .div_video {
      border-radius: 1.6rem;
    }
  }
}

:global(.cinemapark-moskino) {
  background-image: url(../assets/img/moskino_fon.svg);
  background-position: center -50rem;
  background-repeat: no-repeat;
}
:global(.cinemapark-moskino .smi_black) {
  background-color: rgba(0, 0, 0, 0) !important;
}

.banner {
  &__title {
    font-family: Playfair Display;
    font-size: 8rem;
    font-weight: 400;
    line-height: 1;
    letter-spacing: 0.01em;
    margin-bottom: 0.8rem;
    max-width: 69rem;
  }

  &__text {
    font-family: Raleway;
    font-size: 2.4rem;
    font-weight: 400;
    line-height: 1.33;
    letter-spacing: 0.015em;
    margin-bottom: 3.2rem;
    max-width: 69rem;
  }

  @media (max-width: 767px) {

    &__title {
      font-size: 4.8rem;
      line-height: 1;
      letter-spacing: 0.01em;
    }
    &__text {
      font-size: 1.8rem;
      line-height: 1.33;
      letter-spacing: 0.015em;
    }

  }
}

.block_text_top {
  padding-top: 172px;
  margin-bottom: 24px;
  .banner__title {
    max-width: 100%;
    margin-bottom: 8px;
  }
  .banner__text {
    max-width: 100%;
    margin-bottom: 16px;
  }
}

.filter-ico {
  display: flex;
  gap: 1.6rem;

  :deep(.filter_tabs_el:first-child) .btn.active  {
    box-shadow: 0.4rem 0 0.8rem 0 rgba(0, 0, 0, 0.1), 1.4rem 0 1.4rem 0 rgba(0, 0, 0, 0.09), 3.2rem 0 1.9rem 0 rgba(0, 0, 0, 0.05), 5.8rem 0 2.3rem 0 rgba(0, 0, 0, 0.01), 9rem 0 2.5rem 0 rgba(0, 0, 0, 0);

  }
  :deep(.filter_tabs_el) .btn.active {
    box-shadow: -0.4rem 0.1rem 0.9rem 0 rgba(0, 0, 0, 0.1), -1.6rem 0.3rem 1.7rem 0 rgba(0, 0, 0, 0.09), -3.7rem 0.7rem 2.3rem 0 rgba(0, 0, 0, 0.05), -6.6rem 1.2rem 2.7rem 0 rgba(0, 0, 0, 0.01), -10.3rem 1.9rem 2.9rem 0 rgba(0, 0, 0, 0);
  }

  :deep(.btn) {
    padding: 1.2rem 1.6rem;
    gap: 0.8rem;
    border-radius: 10rem;
    border: 0.1rem solid #ED850A;
    background: rgba(0, 0, 0, 0.8);
    font-size: 1.8rem;
    font-style: normal;
    font-weight: 400;
    line-height: 133.333%;
    letter-spacing: 0.036rem;
    color: #FFF;
    &.active {
      background: #ED850A;
      pointer-events: none;
    }
    .text {
      display: flex;
      align-items: center;
      gap: 0.8rem;
      svg {
        display: block;
      }
      &.active {
        svg {
          stroke: #ffffff;
        }
      }
    }
  }
  @media(max-width: 767px){
    :deep(.btn) {
      min-height: 4.6rem;
      line-height: 1;
    }
  }
}

</style>