<template>
  <div class="circle_btn" ref="btnCircle" v-if="dataInfo.imgText && dataInfo.guidForm?.value" @click="formShow = true">
    <div class="img_circle">
      <img :src="getImage(dataInfo.imgText)">
    </div>
    <div class="circle_center">
      <img src="/img/ico/arrow_link_orange.svg" alt="">
    </div>
  </div>
  <container class="container3">
    <div class="step_Competition">
      <div class="div_top">
        <h3 class="div_tit" v-if="dataInfo.title?.value">
          {{ dataInfo.title.value }}
        </h3>
        <h4 class="div_subtit" v-if="dataInfo.subtitle?.value">
          {{ dataInfo.subtitle.value }}
        </h4>
      </div>
      <div class="step_list">
        <template v-for="(step, key) of dataInfo.steps" :key="key">
          <div class="item" :class="{'disabled': !step.link}">
            <div class="div_date fs3" v-if="step.date?.value">
              {{ step.date.value }}
            </div>
            <div class="div_right">
              <div class="div_info">
                <div class="tit fs4" v-if="step.title?.value">
                  {{ step.title.value }}
                </div>
                <div class="subtit" v-if="step.subtitle?.value">
                  {{ step.subtitle.value }}
                </div>
              </div>
              <div class="btn_anchor" v-if="step.btnText?.value" @click="scrollToBlock(step.link)">
                <div class="text">
                  {{ step.btnText.value }}
                </div>
                <div class="link_button">
                  <img class="img1" src="/img/ico/arrow_link.svg" alt="">
                  <img class="img2" src="/img/ico/arrow_link_orange.svg" alt="">
                </div>
              </div>
            </div>
          </div>
        </template>
      </div>
    </div>
  </container>
  <cl-modal v-model="formShow" class="modal_form" v-if="dataInfo.guidForm?.value" :closeFonActive="false" v-bind="modalAlertMeasureLeaveTopModalAttr()">
    <h4 class="modal-header" v-if="formTitle">
      {{ formTitle }}
    </h4>
    <measure-form-component
        :measureGuid="dataInfo.guidForm.value"
        :showMode="showMode"
        :formParams="measureFormParams"
        :form-type="1"
        @formTitle="formTitleFun"

        v-bind="modalAlertMeasureLeaveAttr()"
        v-on="modalAlertMeasureLeaveEmits()"
    />
  </cl-modal>
</template>

<script>
import Container from "@/components/library/Container.vue";
import ClModal from "@/components/library/ClModal.vue";
import MeasureFormComponent from "@/components/measureForm.vue";
import mixinModalAlertMeasureLeave from "@/mixin/mixinModalAlertMeasureLeave";

export default {
  name: "stepsCompetition",
  components: {MeasureFormComponent, ClModal, Container},
  mixins: [mixinModalAlertMeasureLeave],
  props: {
    dataInfo: {
      type: Object,
      require: true
    },
  },
  data() {
    return {
      formTitle: null,
      measureFormParams: {},
      formShow: false,
      showMode: 'inline',
    }
  },
  methods: {
    scrollToBlock(idElement) {
      const block = document.querySelector(idElement);
      if (block) {
        const top = block.getBoundingClientRect().top;
        window.scrollTo({
          top: top + window.scrollY - 30,
          behavior: 'smooth'
        })
      }
    },
    formTitleFun(name) {
      this.formTitle = name;
    },
  },
  mounted() {
    const footer = document.querySelector("footer");
    const btnCircle = this.$refs.btnCircle;
    if (footer && btnCircle) {
      window.addEventListener("scroll", () => {
        const footerBounding = footer.getBoundingClientRect();
        if (footerBounding.top < window.innerHeight) {
          btnCircle.style.top = footerBounding.top - 168 + "px";
        } else {
          btnCircle.style.top = "";
        }
      });
    }
  }
};
</script>

<style lang="scss" scoped>
  .step_Competition {
    margin: 4rem auto;
    padding: 12rem;
    border-radius: 3.2rem;
    background-color: #0C0C0E;
    max-width: 1440px;
    .div_top {
      width: 100%;
      text-align: center;
      margin-bottom: 3.2rem;
      text-transform: uppercase;
    }
    .div_tit {
      width: 100%;
      text-align: center;
      margin-bottom: 1.6rem;
      text-transform: uppercase;
    }
    .step_list {
      display: flex;
      flex-direction: column;
      gap: 2.4rem;
    }
    .item {
      width: 100%;
      padding: 2.4rem;
      border-radius: 1.2rem;
      border: 1px solid #2E3138;
      display: flex;
      &.disabled {
        pointer-events: none;
      }
      &:not(.disabled) {
        border-color: #ED850A;
        .div_date {
          color: #ED850A;
        }
        .link_button {
          border-color: #ED850A;
          background-color: #ED850A;
          cursor: pointer;
          img {
            opacity: 1;
          }
          .img2 {
            display: none;
          }
        }
        .btn_anchor {
          cursor: pointer;
          border-color: #ED850A;
          transition: all .4s ease;
          .link_button{
            transition: all .4s ease;
          }
          &:hover {
            background-color: #ED850A;
            .link_button {
              background-color: #FFF;
              border-color: #FFF;
              .img1 {
                display: none;
              }
              .img2 {
                display: block;
              }
            }
          }
        }
      }
    }
    .div_right {
      display: flex;
      width: 100%;
      justify-content: space-between;
    }
    .div_date {
      min-width: 35.7rem;
      text-transform: uppercase;
      display: flex;
      align-items: center;
    }
    .div_info {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 100%;
    }
    .tit {
      text-transform: uppercase;
    }
    .subtit {
      color: #6B7280;
    }
    .btn_anchor {
      height: 6.4rem;
      width: 24.5rem;
      border-radius: 3.2rem;
      border: 1px solid #2E3138;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-left: 2.4rem;
      .text {
        font-size: 1.6rem;
        line-height: 150%;
      }
    }
    .disabled {
      .text {
        font-size: 1.4rem;
        line-height: 140%;
        color: #6B7280;
      }
    }
    .link_button {
      width: 6.3rem;
      min-width: 6.3rem;
      height: 6.3rem;
      border-radius: 50%;
      border: 1px solid #2E3138;
      display: flex;
      align-items: center;
      justify-content: center;
      translate: 1px -0.5px;
      img {
        width: 16px;
        height: 16px;
        object-fit: contain;
        opacity: 0.5;
      }
      .img2 {
        display: none;
      }
    }
    @media (max-width: 991px) {
      padding: 60px 16px;
      .div_tit {
        font-size: 3.2rem;
        font-weight: 500;
        line-height: 133.33%;
        text-transform: none;
        margin-bottom: 1.6rem;
      }
      .div_subtit {
        font-size: 2.4rem;
        font-weight: 500;
        line-height: 133.33%;
        text-transform: none;
        margin-bottom: 3.2rem;
      }
      .item {
        flex-direction: column;
        .div_date {
          font-size: 3.2rem;//32px
          font-weight: 500;
          line-height: 150%;
          margin-bottom: 1.6rem;
          min-width: unset;
        }
        .tit {
          font-size: 2.4rem;
          font-weight: 500;
          line-height: 133.333%;
          margin-bottom: 8px;
        }
        .div_info {
          margin-bottom: 1.6rem;
        }
      }
      .div_right {
        flex-direction: column;
      }
      .btn_anchor {
        width: 100%;
      }
    }
  }
  .circle_btn {
    width: 12rem;
    height: 12rem;
    background-color: #ED850A;
    border-radius: 50%;
    position: sticky;
    z-index: 10;
    top: calc(100vh - 12rem  - 19.8rem);
    left: calc(50% + 660px);
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    margin-bottom: -12rem;
    translate: 0 15rem;
    @media (max-width: 1750px) {
      left: calc(100vw - 12rem - 2.4rem);
    }
    @media (max-width: 991px) {
      display: none;
    }
    .img_circle{
      animation: rotate 10s linear infinite;
      img {
        width: 12rem;
        object-fit: contain;
        filter: grayscale(1) brightness(1000%);
      }
    }
    .circle_center {
      width: 8rem;
      height: 8rem;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      top: 50%;
      left: 50%;
      translate: -50% -50%;
      background-color: #FFF;
      img {
        width: 2.4rem;
        height: 2.4rem;
        object-fit: contain;
      }
    }
    &:hover {
      background-color: #FFF;
      .img_circle{
        img {
          filter: unset;
        }
      }
      .circle_center {
        background-color: #ED850A;
        img {
          filter: grayscale(1) brightness(1000%);
        }
      }
    }
  }
  @keyframes rotate {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
</style>