<template>
  <container>
    <row class="trusted_persone_detail">
      <column md="12">
        <div class="return-src" @click="$router.push(`/lk/filmProjects`)">
          <img src="/img/ico/move-left-2.svg" height="19" width="19">
          Все проекты
        </div>
      </column>
      <template v-if="this.projectInfo">
        <column md="12">
          <card card-type="trusted2" class="trusted2_sec">
            <div class="td1 not_img">
              <template v-if="this.projectInfo?.logo !== null && this.imageUrl !== ''">
                <img :src="this.projectInfo.logo.imageUrl" alt="">
              </template>
              <template v-else>
                <div class="not_img"></div>
              </template>
            </div>
            <div class="td2">
              <div class="div_tag">
                <span class="sp_all"><span class="sp1">Номер проекта {{this.projectInfo?.id}}</span></span>
                <span class="sp_all">
                  <span class="sp1">Дата изменения
                    <template v-if="this.projectInfo?.updated !== null">{{ $filters.ruDate6(new Date(this.projectInfo?.updated)) }}</template>
                    <template v-else>{{ $filters.ruDate6(new Date(this.projectInfo?.created)) }}</template>
                  </span>
                </span>
              </div>
              <h3 v-if="this.projectInfo?.name">{{ this.projectInfo?.name }}</h3>
              <div class="div_type">
                <template v-if="this.projectInfo?.typeAudio?.name || this.projectInfo?.typeAudio?.comment">
                  <template v-if="this.projectInfo?.typeAudio?.comment">
                    {{ this.projectInfo?.typeAudio?.comment }}
                  </template>
                  <template v-else>
                    {{ this.projectInfo?.typeAudio.name }}
                  </template>
                </template>
                <template v-if="this.projectInfo?.typeAudio?.name && this.projectInfo?.formatAudio?.name">&nbsp/&nbsp</template>
                <template v-if="this.projectInfo?.formatAudio?.name">
                  <template v-if="this.projectInfo?.formatAudio?.comment !== null">
                    {{ this.projectInfo?.formatAudio?.comment }}
                  </template>
                  <template v-else>
                    {{ this.projectInfo?.formatAudio?.name }}
                  </template>
                </template>
              </div>
              <div class="div_type" v-if="this.applications.length > 0 ">
                <span class="sp_all">
                  <span class="sp1">Кол-во заявок:</span>
                  <span class="sp_color_or">{{ this.applications.length }}</span>
                </span>
              </div>
              <div class="div_type">
                <div class="sp_all" v-if="this.projectInfo?.nationalFilm">
                  <span class="sp1">УНФ</span>
                  <span>{{ this.projectInfo?.nationalFilm }}</span>
                  <svg class="svg-icon" width="24" height="24" stroke="#10B981">
                    <use xlink:href="/svg/icons.svg#check"></use>
                  </svg>
                  <template v-if="this.projectInfo.natFilmCertificate !== null">
                    <a :href="this.projectInfo.natFilmCertificate[0].url">
                      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                        <path d="M21 15V19C21 19.5304 20.7893 20.0391 20.4142 20.4142C20.0391 20.7893 19.5304 21 19 21H5C4.46957 21 3.96086 20.7893 3.58579 20.4142C3.21071 20.0391 3 19.5304 3 19V15" stroke="#ED850A" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M7 10L12 15L17 10" stroke="#ED850A" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M12 15V3" stroke="#ED850A" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                      </svg>
                    </a>
                  </template>
                </div>
                <div class="sp_all" v-else>
                  <span class="sp1">УНФ</span>
                  <svg class="svg-icon" width="24" height="24" stroke="#F43F5E" >
                    <use xlink:href="/svg/icons.svg#close"></use>
                  </svg>
                </div>
                <div class="sp_all" v-if="this.projectInfo?.fileFso !== null">
                  ФСО
                  <svg class="svg-icon" width="24" height="24" stroke="#10B981">
                    <use xlink:href="/svg/icons.svg#check"></use>
                  </svg>
                  <a :href="this.projectInfo.fileFso[0].url">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                      <path d="M21 15V19C21 19.5304 20.7893 20.0391 20.4142 20.4142C20.0391 20.7893 19.5304 21 19 21H5C4.46957 21 3.96086 20.7893 3.58579 20.4142C3.21071 20.0391 3 19.5304 3 19V15" stroke="#ED850A" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                      <path d="M7 10L12 15L17 10" stroke="#ED850A" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                      <path d="M12 15V3" stroke="#ED850A" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                  </a>
                </div>
                <div class="sp_all" v-else>
                  ФСО
                  <svg class="svg-icon" width="24" height="24" stroke="#F43F5E" >
                    <use xlink:href="/svg/icons.svg#close"></use>
                  </svg>
                </div>
                <div class="sp_all">
                  Гарантийное письмо
                  <template v-if="this.projectInfo.letters.length > 0">
                    <a :href="this.projectInfo.letters[0].url">
                      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                        <path d="M21 15V19C21 19.5304 20.7893 20.0391 20.4142 20.4142C20.0391 20.7893 19.5304 21 19 21H5C4.46957 21 3.96086 20.7893 3.58579 20.4142C3.21071 20.0391 3 19.5304 3 19V15" stroke="#ED850A" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M7 10L12 15L17 10" stroke="#ED850A" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M12 15V3" stroke="#ED850A" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                      </svg>
                    </a>
                  </template>
                </div>
              </div>
              <Popper
                  class="div_buttons"
              >
                <div class="div_buttons_ev">
                  <svg class="svg-icon" width="24" height="24" stroke="#ffffff">
                    <use xlink:href="/svg/icons.svg#button"></use>
                  </svg>
                </div>
                <template #content>
                  <div class="div_buttons_mod">
                    <div>
                      <cl-button class="btn-op"  @click="$router.push(`/lk/filmProjects/project_print/${this.$route.params.post_id3}`)">
                        <template v-slot:before>
                          <svg class="svg-icon" width="24" height="24" stroke="#ffffff">
                            <use xlink:href="/svg/icons.svg#file-text"></use>
                          </svg>
                        </template>
                        Скачать PDF
                      </cl-button>
                    </div>
                    <div>
                      <cl-button class="btn-op" @click="$router.push(`/lk/filmProjects/editProject/${this.$route.params.post_id3}`)">
                        <template v-slot:before>
                          <svg class="svg-icon" width="24" height="24" stroke="#ffffff">
                            <use xlink:href="/svg/icons.svg#pensil"></use>
                          </svg>
                        </template>
                        Редактировать</cl-button>
                    </div>
                    <div>
                      <cl-button class="btn-op" @click="modalProjectsDeleteShow = !modalProjectsDeleteShow; activeProjects = {'guid': this.projectInfo.guid, 'name': this.projectInfo.name}">
                        <template v-slot:before>
                          <svg class="svg-icon" width="24" height="24" stroke="#ffffff" >
                            <use xlink:href="/svg/icons.svg#trash"></use>
                          </svg>
                        </template>
                        Удалить</cl-button>
                    </div>
                  </div>
                </template>
              </Popper>
            </div>
          </card>
        </column>
        <column md="12">
          <card card-type="trusted2" class="trusted2_sec">
            <div class="td2">
              <div class="div_tit">Данные проекта</div>
              <div class="div_data">
                <div class="div_item" v-if="this.projectInfo !== null && this.projectInfo?.estReleaseDate">
                  <div class="div_title">
                    Предположительная дата релиза
                  </div>
                  <div class="div_content">
                    {{ $filters.ruDate5(new Date(this.projectInfo?.estReleaseDate)) }}
                  </div>
                </div>
                <div class="div_item" v-if="this.projectInfo !== null && this.projectInfo?.country">
                  <div class="div_title">
                    Страна - производитель проекта
                  </div>
                  <div class="div_content">
                    {{ this.projectInfo?.country }}
                  </div>
                </div>
              </div>
            </div>
          </card>
        </column>
        <column md="12">
          <card card-type="trusted2" class="trusted2_sec">
            <div class="td2">
              <div class="div_tit">Параметры проекта</div>
              <div class="div_data">
                <div class="div_item" v-if="this.projectInfo !== null && this.projectInfo?.typeRental">
                  <div class="div_title">
                    Вид проката / показа*
                  </div>
                  <div class="div_content">
                    <template v-if="this.projectInfo?.typeRental?.comment">
                      {{ this.projectInfo?.typeRental.comment }}
                    </template>
                    <template v-else>
                      {{ this.projectInfo?.typeRental.name }}
                    </template>
                  </div>
                </div>
                <div class="div_item" v-if="this.projectInfo !== null && this.projectInfo?.fondiSupports">
                  <div class="div_title">
                    Федеральная или международная поддержка*
                  </div>
                  <div class="div_content">
                      {{ this.projectInfo?.fondiSupports.map(item => {
                        if (item.name == "Другое") { return item.comment}
                        else {
                          return item.name;
                        }
                      }).join(', ') }}
                  </div>
                </div>
              </div>
            </div>
          </card>
        </column>
        <column md="12" v-if="this.projectInfo?.fioDirector">
          <card card-type="trusted2" class="trusted2_sec">
            <div class="td2">
              <div class="div_tit">ФИО режиссера</div>
              <div class="div_struct">
                {{ this.projectInfo.fioDirector }}
              </div>
            </div>
          </card>
        </column>
        <column md="12" v-if="this.projectInfo?.fioActors">
          <card card-type="trusted2" class="trusted2_sec">
            <div class="td2">
              <div class="div_tit">ФИО главных актеров</div>
              <div class="div_struct">
                {{ this.projectInfo?.fioActors.join(",") }}
              </div>
            </div>
          </card>
        </column>
        <column md="12" v-if="this.projectInfo?.synopsis">
          <card card-type="trusted2" class="trusted2_sec">
            <div class="td2">
              <div class="div_tit">Синопсис</div>
              <div class="div_txt">
                {{ this.projectInfo.synopsis }}
              </div>
            </div>
          </card>
        </column>
        <column md="12" v-if="this.projectInfo?.comment">
        <card card-type="trusted2" class="trusted2_sec">
          <div class="td2">
            <div class="div_tit">Ссылки с информацией на ранее снятые компанией фильма</div>
            <div class="div_links">
              {{ this.projectInfo.comment }}
            </div>
          </div>
        </card>
      </column>
      </template>
      <div class="h1_lk">
        <h1 class="hasSubtit hasSubtitProf" v-if="this.projectInfo">Заявки по проекту: <span>{{ this.totalApplications }}</span></h1>
        <div class="btn-holder" id="block_proj" :class="{ 'visible-none': !this.projectInfo}">
<!--          Сюда подцепляется кнопка из блока добавления новых заявок к проекту-->
        </div>
      </div>
      <template v-if="!isLoading && this.applications && this.applications.length > 0">
        <row class="row_mb row_list">
          <transition-group name="fade-base2">
            <column
                v-for="(event, index) in applications"
                :key="event.id"
            >
              <card-support-measure :event="event"/>
            </column>
          </transition-group>
        </row>
        <div v-if="this.data?.page < this.data?.pages" class="show_more">
          <loader
              v-if="isLoadingMore"
              :animation-duration="1500"
              :size="55"
          ></loader>
          <cl-button v-else @click="this.loadMore" type="light sm-w-100">показать еще</cl-button>
        </div>
      </template>
    </row>
    <loader
        v-if="!this.projectInfo"
        :animation-duration="3000"
        :size="60"
        :color="'#ED850A'"
    ></loader>
    <cl-modal v-model="modalProjectsDeleteShow" modal-type="center" class="modal-trusted" :closeFonActive="false" :closeNone="true">
      <div class="delete_person">
        <div class="delete_person_img">
          <img src="/img/ico/trashOrg.svg" alt="">
        </div>
        <div class="delete_person_tit">Вы точно хотите удалить проект "{{ activeProjects.name }}"?</div>
        <div class="delete_person_buttons">
          <cl-button class="btn-light btn-middle sp_img btn-ico" img="/img/ico/cancel.svg" @click="modalProjectsDeleteShow = false">Отменить</cl-button>
          <cl-button class="btn-middle  sp_img btn-ico" img="/img/ico/checkWhite.svg" @click="removeProjects">Подтвердить</cl-button>
        </div>
      </div>
    </cl-modal>

  </container>
</template>

<script>
import Container from "@/components/library/Container.vue";
import Card from "@/components/library/Card.vue";
import ClButton from "@/components/library/ClButton.vue";
import cardSupportMeasure from "@/components/CardSupportMeasure.vue";
import MyApplications from "@/views/personal/MyApplications.vue";

export default {
  name: 'ProjectDetail',
  components: {MyApplications, cardSupportMeasure, ClButton, Card, Container},
  props: {
    dataInfo: {
      type: Object,
      require: true
    },
  },
  data() {
    return {
      projects: null,
      projectInfo: null,
      applications: {},
      isLoadingMore: false,
      id: this.$route.params.post_id3,
      loaderProjects: true,
      showLoader: true,
      data: false,
      getParams: {
        sortExpression:"created DESC",
        filmProjectGuids: this.$route.params.post_id3,
        pageSize : 3,
        page: 1
      },
      activeProjects: null,
      modalProjectsDeleteShow: false,
      totalApplications: 0,
    };
  },
  methods: {
    /**
     * Функция для получения данных о проекте
     * @param id - guid проекта
     */
    getProjectInfo(id){
      this.loaderProjects = true;
      this.axios
          .get(`/api/get/one/film/project/${id}`)
          .then(response => {
            this.projectInfo = response.data;
          })
          .catch(error => {
            this.showError(error);
          })
          .finally(() => {

          });
    },
    /**
     * Функция для получения данных о заявках проекта
     */
    getProjects(isLoadingMore) {
      this.showLoader = true;
      this.axios
          .get(`/api/get/measure-services`, {params: this.getParams})
          .then(response => {
            if (isLoadingMore) {
              this.applications = [...this.applications, ...response.data.records];
              this.totalApplications = response.data.total;
            } else {
              this.data = response.data;
              this.applications = response.data.records;
              this.totalApplications = response.data.total;
              this.showLoader = false;

            }
            this.isLoadingMore = false;
          })
          .catch(error => {
            this.showError(error);
          });
    },
    removeProjects(){
      this.modalProjectsDeleteShow = false;
      this.loaderProjects = true;
      this.axios
          .post(`/api/delete/film/projects/${this.activeProjects.guid}`)
          .then(response => {
            this.getProjects();
          })
          .catch(error => {
            this.showError(error);
          })
          .finally(() => {
            this.loaderProjects = false;
          });
    },
    loadMore() {
      this.isLoadingMore = true;
      this.getParams.page = ++this.data.page;
      // this.$nextTick(() => {
        this.getProjects(true);
      // });
    },
  },
  mounted() {
    this.getProjectInfo(this.id);
    this.getProjects(this.isLoadingMore);
  }
}
</script>

<style lang="scss" scoped>

.trusted2_sec {
  cursor: auto;
  .td2 {

    .div_tit {
      font-size: 1.8rem;
      text-transform: uppercase;
      font-weight: 500;
      line-height: 2.4rem;
      letter-spacing: 0.02em;
      margin-bottom: 1.6rem;
    }

    .div_data {
      display: flex;
      gap: 1.6rem;

      .div_item {
        min-width: 378px;

        .div_title {
          font-size: 1.2rem;
          line-height: 1.4rem;
          letter-spacing: 0.015em;
          color: #9096A2;
        }

        .div_content {
          font-size: 1.8rem;
          line-height: 2.4rem;
          letter-spacing: 0.015em;
        }
      }
    }

    .div_struct {
      font-size: 1.6rem;
      line-height: 2.4rem;
      letter-spacing: 0.015em;
      color: #ED850A;

      span {
        text-decoration: underline;
      }
    }

    .div_links {
      display: flex;
      flex-direction: column;
      gap: 1.6rem;

      a {
        font-size: 1.8rem;
        line-height: 2.4rem;
        letter-spacing: 0.015em;
        color: #ED850A;
        text-decoration: underline;
        width: fit-content;
      }
    }
  }
}

.trusted2_req_sec {
  display: flex;
  justify-content: space-between;

  .div_header {
    font-size: 2.4rem;
    font-weight: 500;
    line-height: 3.2rem;
    letter-spacing: 0.02em;
    text-transform: uppercase;
  }

  a {

    img {
      filter: none;
      margin-right: .8rem;
    }
  }
}

.h1_lk{
  display: flex;
  justify-content: space-between;
  gap: 2.4rem;
  align-items: center;
  margin-bottom: 2rem;
  h1{
    margin-bottom: 0;
  }
  .btn .text{
    display: flex;
    align-items: center;
    gap: 1.2rem;
  }
}

.row.row_list{
  width: calc(100% + 2.4rem);
}

.trusted_persone_detail{
  padding-top: 6rem;
  margin-bottom: 12rem;
}

.return-src{
  display: flex;
  align-items: center;
  cursor: pointer;
  gap: .5rem;
}

.card-trusted2 .card-holder .div_tag {
  gap: 1rem;
}
.div_tag{
  font-size: 1.2rem;
}

.card-trusted2 .card-holder .td1 {
  overflow: hidden;
  border-radius: 1.2rem;
  img {
    width: calc(100% + 1rem);
    height: calc(100% + 1rem);
    object-fit: cover;
    top: -.2rem;
    position: relative;
  }
}

.delete_person{
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2.4rem;

  .delete_person_tit{
    text-align: center;
    font-size: 2.4rem;
    font-style: normal;
    font-weight: 500;
    line-height: 133.333%;
    letter-spacing: 0.048rem;
    color: #fff;
  }

  .delete_person_img{
    width: 64px;
    height: 64px;
    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
}
.delete_person_buttons{
  display: flex;
  gap: 1.6rem;
  align-items: center;
}

.not_img{
  width: 100%;
  height: 100%;
  border-radius: 1.2rem;
}

.visible-none{
  visibility: hidden;
}
</style>