<template>
  <container class="block_wrap_eng">
  <div class="top_nav">
    <ul class="navigation">
     <li class="nav_link" v-for="tab in dataInfo.list" :class="tabActive == tab.name ? 'active' : ''" @click="tabActive = tab.name">{{tab.name.value}}<span class="slehNav">/</span></li>
    </ul>
  </div>
  <div class="tabs-details">
    <div class="tabs_content" v-for="tab in dataInfo.list" :class="tabActive == tab.name ? 'active' : ''">
      <div class="div_topCards">
        <div class="card" v-for="card in tab.lsitCard1">
          <div class="div_img" v-if="card.icon">
            <img :src="getImage(card.icon, 'middle')" alt="">
          </div>
          <div v-if="card.text?.value" class="div_text">{{card.text.value}}</div>
        </div>
      </div>
      <div class="div_bottomCards">
          <swiper
                  :spaceBetween="24"
                  :navigation="false"
                  :threshold='10'
                  :modules="modules"
                  :loop="false"
                  class="slide100h"
                  :slidesPerView="3.5"
                  :mousewheel="{
                    invert: false,
                  }"
                  :breakpoints="{
                  '992': {
                    slidesPerView: 3.5
                  },
                  '768': {
                    slidesPerView: 1.77
                  },
                  '300':{
                    slidesPerView: 1.32
                  }
                }"
          >
            <swiper-slide v-for="(card, index) in tab.lsitCard2">
              <div class="card">
                <div class="card_num">{{ index + 1 }}</div>
                <h4 class="card_tit" v-if="card.title?.value">{{ card.title.value }}</h4>
                <div class="card_text" v-if="card.text?.value" v-html="card.text.value"></div>
              </div>
            </swiper-slide>
          </swiper>
      </div>
    </div>
  </div>
  </container>
</template>
<script>
import {Swiper, SwiperSlide} from "swiper/vue";
import {Mousewheel, Navigation, Pagination} from "swiper";
import {mapGetters} from "vuex";

export default {
  name: "tabsBlockEngland",
  components: {
    Swiper,
    SwiperSlide,
  },
  props: {
    dataInfo: {
      type: Object,
      require: true
    },
  },
  setup() {
    return {
      modules: [Navigation, Pagination, Mousewheel],
    }
  },
  data() {
    return{
      tabActive: this.dataInfo.list[0]?.name,
    }
  },
  computed:{
    ...mapGetters([
      'widthSite'
    ])
  },
}
</script>

<style lang="scss" scoped>
.top_nav {
  margin-bottom: 2.4rem;
  .navigation {
    display: flex;

    .nav_link {
      cursor: pointer;
      font-size: 2.4rem;
      text-transform: uppercase;
      &.active{
          color: #ED850A;
      }
      &:last-child {
        .slehNav {
          display: none;
        }
      }
    }

    .slehNav {
      color: #ED850A;
      margin: 0 2.4rem;
    }
  }
}
.tabs_content{
  display: none;
  &.active{
    display: block;
  }
  .div_topCards{
    display: flex;
    gap: 2.4rem;
    margin-bottom: 3.2rem;
    .card{
      background: #000106;
      border: 1px solid #ED850A;
      width: 100%;
      .div_img{
        margin-bottom: 2.4rem;
      }
    }
  }
}

.div_bottomCards{
  .card{
    border-radius: 1.2rem;
    border: 1px solid #2E3138;
    background-color: #000106;
    display: flex;
    flex-direction: column;
    height: 100%;
  }
  .card_num{
    font-size: 4.8rem;
    font-style: normal;
    font-weight: 500;
    line-height: 133.333%;
    letter-spacing: 0.096rem;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    border: 1px solid #17191C;
    width: 6.4rem;
    height: 6.4rem;
    margin-bottom: 4.8rem;
  }
  .card_tit{
    margin-bottom: 2rem;
  }
  .card_text{
    flex-grow: 1;
    display: flex;
    align-items: flex-end;
    height: 100%;
  }
}
@media (max-width: 991px) {
  .block_wrap_eng{
    padding-bottom: 8.4rem;
  }
.tabs_content{
  .div_topCards{
    gap: 1.6rem;
    overflow-x: scroll;
    scrollbar-width: none;
    &::-webkit-scrollbar{
      -webkit-appearance: none;
      width: 0px;
      height: 0px;
    }
    &::-webkit-scrollbar-button {
      width: 0px;
      height: 0px;
    }
    &::-moz-scrollbar-button {
      width: 0px;
      height: 0px;
    }
    .card{
      padding: 1.6rem .8rem;
      min-width: 15.5rem;
      width: 100%;
      height: auto;
      .div_img{
        margin-bottom: 1.2rem;
      }
      .div_text{
        font-size: 1.6rem;
      }
    }
  }
}
  .div_bottomCards {
    .card {
      padding: 1.2rem;

      .card_num {
        margin-bottom: 1.2rem;
      }

      .card_tit {
        font-size: 2.4rem;
      }

      .card_text {
        font-size: 1.4rem;
        letter-spacing: 0.021rem;
      }
    }
  }
}
@media (max-width: 767px) {
  .block_wrap_eng{
    padding-left: 1.6rem;
    padding-right: 1.6rem ;
    padding-bottom: 8.4rem;
  }
  .top_nav{
    margin-bottom: 1.6rem;
    .navigation{
      .nav_link{
        font-size: 1.8rem;
      }
    }
  }
  .div_bottomCards {
    .card {
      padding: 1.6rem;
    }
  }
}
</style>