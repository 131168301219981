<template>
  <container>
    <div v-if="dataInfo" class="dop_info_img" :class="{'dop_info_img_right': dataInfo.place}">
      <div class="dt1">
        <div ref="perfectScrollbar" class="dop_info_img_card" :class="{'dop_info_img_card_backRotated': dataInfo.place}" :style="dataInfo.backgroundImg ? getBkgrImage(dataInfo.backgroundImg) : ''">
          <div ref="perfectScrollbarContent" class="div_cont">
            <div class="div_info">
              <h2 v-if="dataInfo.title" class="div_title">{{dataInfo.title.value}}</h2>
              <div v-if="dataInfo.text" :class="{'div_text_vert_center': !(dataInfo.textList && dataInfo.textList.length > 0)}">{{dataInfo.text.value}}</div>
              <ul v-if="dataInfo.textList && dataInfo.textList.length > 0" class="div_ul">
                <li v-for="item of dataInfo.textList">{{item.text ? item.text.value : null}}</li>
              </ul>
            </div>
            <div class="div_btns">
              <template v-if="dataInfo.buttonTextOr && dataInfo.buttonLinkOr">
                <cl-button v-if="!dataInfo.buttonModalOr"
                           link @click="v_ym(dataInfo.yandexMetrikaTargetBtnOr?.value)" :href="dataInfo.buttonLinkOr.value"  class="btn-ico-right img_wh div_custom_btn_or" img="/img/ico/move-up-right.svg">
                  {{dataInfo.buttonTextOr.value}}
                </cl-button>
                <cl-button v-else
                           @click="v_ym(dataInfo.yandexMetrikaTargetBtnOr?.value); showModalIframe();"  class="btn-ico-right img_wh div_custom_btn_or" img="/img/ico/move-up-right.svg">
                  {{dataInfo.buttonTextOr.value}}
                </cl-button>
              </template>

              <cl-button link :href="dataInfo.buttonLinkWh.value" v-if="dataInfo.buttonTextWh && dataInfo.buttonLinkWh" class="btn-ico-right img_or div_custom_btn_wh" img="/img/ico/move-up-right.svg">
                {{dataInfo.buttonTextWh.value}}
              </cl-button>
              <cl-button link :href="dataInfo.buttonLinkGr.value" v-if="dataInfo.buttonTextGr && dataInfo.buttonLinkGr" class="btn-ico-right img_wh div_custom_btn_gr" img="/img/ico/move-up-right.svg">
                {{dataInfo.buttonTextGr.value}}
              </cl-button>
            </div>
          </div>
        </div>
      </div>
      <div class="dt2">
        <div class="div_img">
          <img v-if="dataInfo.image" :src="getImage(dataInfo.image, 'hd')" alt="">
        </div>
      </div>
      <cl-modal v-if="dataInfo.buttonModalOr" class="modal-iframe-excursions" v-model="isShowModalIframe">
        <loader
            :animation-duration="3000"
            :size="60"
            :color="'#ED850A'"
            v-if="isShowModalIframeLoader"
        ></loader>
        <iframe v-show="!isShowModalIframeLoader" class="iframe-excursions" ref="iframeModalOr" :src="dataInfo.buttonLinkOr.value"/>
      </cl-modal>
    </div>
  </container>
</template>

<script>

import Column from "@/components/library/Column.vue";
import ClButton from "@/components/library/ClButton.vue";
import ClModal from "@/components/library/ClModal.vue";
import PerfectScrollbar from "perfect-scrollbar";

export default {
  name: "sideInfoWithImg",
  components: {ClModal, ClButton, Column},
  props: {
    dataInfo: {
      type: Object,
      require: true
    },
  },
  data(){
    return {
      isShowModalIframe: false,
      isShowModalIframeLoader: true,
      /**
       * Perfect Scrollbar
       * @type {PerfectScrollbar | null}
       * @default null
       */
      ps: null,
      /**
       * Resize Observer
       * @type {ResizeObserver | null}
       * @default null
       */
      resizeObserver: null,
    }
  },
  methods: {
    showModalIframe(){
      this.isShowModalIframe = !this.isShowModalIframe;
      this.$nextTick(()=>{
        if(this.$refs.iframeModalOr){
          this.$refs.iframeModalOr.addEventListener('load', () => {
            this.isShowModalIframeLoader = false;
          });
        } else {
          this.isShowModalIframeLoader = false;
        }
      });
    },
    /**
     * Функция добавления perfect scrollbar
     */
    addPerfectScrollbar() {
      if(this.$refs.perfectScrollbar && this.$refs.perfectScrollbarContent) {
        this.ps = new PerfectScrollbar(this.$refs.perfectScrollbar);
        this.resizeObserver = new ResizeObserver(() => setTimeout(() => {
          this.ps.update();
        }, 15));
        this.resizeObserver.observe(this.$refs.perfectScrollbarContent);
      }
    }
  },
  mounted() {
    this.addPerfectScrollbar();
  },
  beforeUnmount() {
    if(this.resizeObserver) this.resizeObserver.disconnect();
  }
}
</script>

<style lang="scss" scoped>

.dop_info_img {
  padding-top: 6rem;
  padding-bottom: 6rem;
  display: flex;
  gap: 2.4rem;

  .btn{
    border-radius: 16px;
  }

  .dt1, .dt2 {
    min-height: 36rem;
    max-height: 50rem;
  }

  .dt1 {
    flex: 1;
  }

  .dt2 {
    width: 57.5%;
  }

  .div_img {
    width: 100%;
    height: 100%;
    overflow: hidden;
    border-radius: 2.4rem;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  @media (max-width: 1439px){
    .dt2 {
      width: 50%;
    }
  }
  @media (max-width: 991px) {
    flex-direction: column;

    .dt1{
      min-height: unset;
      max-height: none;
    }
    .dt2 {
      width: auto;
      max-height: 400px;
      height: 400px;
    }
  }
}

.dop_info_img_right {
  flex-direction: row-reverse;

  @media (max-width: 991px) {
    flex-direction: column-reverse;
  }
}

.dop_info_img_card {
  height: 100%;
  border: 0.1rem solid rgba(63, 63, 66, 1);
  border-radius: 2.4rem;
  padding: 3.2rem;
  background: url(@/assets/img/dopInfoImgCardBackgroud.jpg) no-repeat center center;
  background-size: cover;

  :deep(.ps__rail-y) {
    right: 0 !important;
  }

  .div_cont {
    display: flex;
    flex-direction: column;
    gap: 2.4rem;
  }

  .div_info{
    flex: 1;
    display: flex;
    flex-direction: column;
  }

  .div_btns {
    display: flex;
    flex-direction: column;
    gap: 0.8rem;

    :deep(.btn) {
      .sp_img {
        max-width: 2.4rem;
        max-height: 2.4rem;
      }
    }
  }

  .div_title {
    font-family: Playfair Display;
    font-weight: 500;
    letter-spacing: 0.02em;
    margin-bottom: 0.8rem;
    font-size: 4rem;
    line-height: 120%;
    text-transform: unset;
  }

  .div_text_vert_center {
    margin: auto 0;
  }

  .div_ul {
    li {
      padding-left: 2.7rem;
      position: relative;
    }

    li:before {
      display: inline-block;
      content: "";
      position: absolute;
      top: 0.55em;
      left: 1.1rem;
      width: 0.4rem;
      height: 0.4rem;
      background-color: white;
      border-radius: 50%;
    }
  }

  .div_custom_btn_or, .div_custom_btn_wh, .div_custom_btn_gr {
    justify-content: space-between;
  }

  .div_custom_btn_wh {
    color: black;
    background: white;
    border: none;
  }

  .div_custom_btn_gr {
    color: white;
    background: rgba(63, 63, 66, 1);
    border: none;
  }

  @media (max-width: 991px) {
    .div_title {
      font-size: 2.4rem;
      margin-bottom: 1.6rem;
      font-weight: 500;
    }
  }
}

.dop_info_img_card_backRotated {
  background: url(@/assets/img/dopInfoImgCardBackgroudRotated.jpg) no-repeat center center;
  background-size: cover;
}

</style>