<template>
  <footer class="site_footer">
    <container>
      <div class="content">
        <div class="row1">
            <div class="info">
                <div class="dt_info">
                    <div v-if="dataInfo.email" class="r_info">
                        <div class="image">
                            <img src="/img/ico/mail.svg" alt="" class="img_wh">
                        </div>
                        <div class="text">{{ dataInfo.email?.value }}</div>
                    </div>
                </div>
            </div>
            <div id="toButtonFooter" class="toButtonFooter"></div>
        </div>
        <div class="row2">
          <router-link v-if="dataInfo.linkNamePol && dataInfo.file"
                       :to="dataInfo.file?.value?.src"
                       class="link clOr">{{ dataInfo.linkNamePol?.value }}</router-link>
          <router-link v-else-if="dataInfo.linkNamePol && dataInfo.linkPol"
                       :to="dataInfo.linkPol?.value"
                       class="link clOr">{{ dataInfo.linkNamePol?.value }}</router-link>
            <div v-if="dataInfo.textRight" class="text">{{ dataInfo.textRight?.value }}</div>
        </div>
      </div>
    </container>
  </footer>
</template>

<script>
export default {
  name: "Footer",
  props: {
    dataInfo: {
        type: Object,
        require: true
    }
  },
  methods: {
    hrefUrl(url){
      if(url.includes("https")) {
        return url;
      } else if(url.includes("/") && !url.includes("http")){
        this.$router.push({path: url});
      }
    }
  },
}
</script>

<style lang="scss" scoped>
.site_footer {
  position: relative;
  padding: 120px 0 48px 0;
  margin-top: auto;
  &:before {
    content: "";
    position: absolute;
    top: 60px;
    left: 0;
    right: 0;
    height: 1px;
    display: block;
    background: #2E3138;
  }
  .content{
    display: flex;
    flex-direction: column;
    gap: 82px;
    @media (max-width: 767px) {
      gap: 40px;
    }
  }
  .row1{
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
    flex-wrap: wrap;
    .info{
      display: flex;
      flex-direction: column;
      gap: 48px;
      .logo{
        height: 48px;
        img{
          height: 100%;
          object-fit: contain;
        }
      }
      .dt_info{
        display: flex;
        flex-direction: column;
        gap: 8px;
        .r_info{
          display: flex;
          gap: 24px;
          .image{
            width: 32px;
            height: 32px;
            img{
              width: 100%;
              height: 100%;
              object-fit: contain;
            }
          }
          .text{
            font: {
              size: 24px;
              weight: 500;
            }
            line-height: 133.33%;
            color: #ED850A;
          }
        }
      }
    }
    @media (max-width: 767px) {
      justify-content: center;
      flex-direction: column;
      gap: 0px;
      :deep(.btn:last-child) {
        margin-bottom: 48px;
      }
    }
  }
  .row2{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    //padding-top: 32px;
    .link, .text{
      color: #B2B5BB;
      font-size: 16px;
      font-weight: 400;
      line-height: 125%;
      &.clOr{
        color: #ED850A;
        line-height: 125%;
        &:after{
          content: '';
          display: block;
          width: 100%;
          height: 1px;
          background-color: #ED850A;
        }
      }
    }
    @media (max-width: 767px) {
      flex-direction: column;
      gap: 16px;
      .link {
        font-size: 14px;
      }
      .text {
        font-size: 16px;
      }
    }
  }
  @media (max-width: 767px) {
    padding-bottom: 24px;
  }
}
.toButtonFooter{
  display: flex;
  gap: 2.4rem;
  flex-wrap: wrap;
  @media (max-width: 767px) {
    order: -1;
  }
}
</style>