<template>
  <container>
    <nav v-if="dataInfo?.listSection && dataInfo.listSection.length > 0" class="menu_lk">
      <div v-for="item in dataInfo.listSection">
          <template v-if="!item.isCompany?.value || getUser.currentCompany">
            <router-link
                v-if="item.link && item.name"
                :to="item.link?.value"
                :class="{'router-link-exact-active': $route.path == item.link?.value || ($route.path.indexOf(item.link?.value) != -1 && item.link?.value != '/lk')}"
            >{{ item.name?.value }}</router-link>
            <span
                v-else-if="item.name"
                class="no-active-link"
            >{{ item.name?.value }}</span>
          </template>
      </div>
    </nav>
  </container>
</template>

<script>

import {mapGetters} from "vuex";

export default {
  name: "lkMenu",
  props: {
    dataInfo: {
      type: Object,
      required: true
    }
  },
  computed: {
    ...mapGetters([
      'getUser'
    ]),
  },
}
</script>

<style lang="scss" scoped>
.menu_lk {
  margin-top: 60px;
  display: flex;
  gap: 8px;
  margin-bottom: 16px;
  flex-wrap: wrap;
  span, a {
    display: inline-block;
    padding: 12px 16px;
    position: relative;
    &:before {
      content: "";
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      height: 2px;
      background: #ED850A;
      display: none;
    }
  }
  a:hover {
    &:before {
      display: block;
    }
  }
  .router-link-exact-active {
    cursor: default;
    &:before {
      display: block;
    }
  }
}
</style>