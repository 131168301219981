<template>
  <div class="sub_block_black_wrapper">
    <div class="sub_block_black">
      <container>
        <div class="guides">
          <h2 class="fs3 div_top_tit" v-if="dataInfo.title?.value">
            {{ dataInfo.title.value }}
          </h2>
          <swiper class="progress-bar-line"
              :modules="modules"
              :slidesPerView="'auto'"
              :spaceBetween="24"
              :pagination="{
                type: 'progressbar',
              }"
              :navigation="true"
          >
            <swiper-slide v-for="(item, key) of dataInfo.cardsList" :key="key">
              <div class="guides__card">
                <div class="guides__card__img" :style="item.cardImage ? getBkgrImage(item.cardImage) : ''">
                  <div class="div_categories" v-if="item.categoriesList">
                    <div class="div_category" v-for="categoryEl of item.categoriesList">{{categoryEl.category.value}}</div>
                  </div>
                </div>
                <div class="guides__card__info">
                  <h4 class="div_tit" v-if="item.cardName">
                    {{ item.cardName.value }}
                  </h4>
                  <div class="div_desc" v-if="item.cardAbout" v-html="item.cardAbout.value"></div>
                  <div class="div_quote" v-if="item.cardQuote" v-html="item.cardQuote.value"></div>
                </div>
              </div>
            </swiper-slide>
          </swiper>
        </div>
      </container>
    </div>
  </div>
</template>

<script>
import Container from "@/components/library/Container.vue";
import {Swiper, SwiperSlide} from "swiper/vue";
import {Navigation, Pagination} from "swiper";

export default {
  components: {Container, Swiper, SwiperSlide},
  props: {
    dataInfo: {
      type: Object,
      require: true
    },
  },
  data(){
    return{
      slidesPerView: 4,
    }
  },
  setup() {
    return {
      modules: [Navigation, Pagination],
    }
  },
};
</script>

<style lang="scss" scoped>

.sub_block_black{
  overflow: hidden;
}

.guides {
  .div_top_tit {
    width: 100%;
    text-align: center;
    text-transform: uppercase;
    margin-bottom: 3.2rem;
  }

  .swiper-slide {
    width: 34.3rem;
  }

  &__card {
    background: black;
    border-radius: 3.2rem;

    &__img {
      position: relative;
      height: 36.6rem;
      border-radius: 3.2rem;
      overflow: hidden;
      background-color: #c3c1c6;
      background-repeat: no-repeat;
      background-position: center center;
      background-size: cover;
      display: flex;
      flex-direction: column;
      justify-content: end;

      &::before, .div_categories {
        opacity: 0;
        transition: opacity 0.5s;
      }

      &::before {
        content: '';
        position: absolute;
        top: -10px;
        left: -10px;
        width: calc(100% + 20px);
        height: calc(100% + 20px);
        padding: 10px;
        background: linear-gradient(180deg, rgba(0, 1, 6, 0) 50%, rgba(0, 1, 6, 1) 85%);
        user-select: none;
        pointer-events: none;
      }

      .div_categories{
        padding: 1.6rem;
      }

      .div_category{
        margin-bottom: 0.4rem;
        padding-left: 1.6rem;
        position: relative;
        font-size: 1.6rem;
        font-weight: 400;
        line-height: 1.5;
        letter-spacing: 0.015em;

        &::before{
          content: '';
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          left: 0;
          height: 0.8rem;
          width: 0.8rem;
          background-color: #ED850A;
          border-radius: 50%;
        }

        &::after{
          content: '';
          position: absolute;
          top: 50%;
          transform: translateY(-50%) translateX(-50%);
          left: 0.4rem;
          height: 0.4rem;
          width: 0.4rem;
          background-color: #0C0C0E;
          border-radius: 50%;
        }

        &:last-child {
          margin-bottom: 0;
        }
      }
    }

    &__info {
      padding: 0.8rem 1.6rem 2.4rem;

      .div_tit {
        margin-bottom: 0.4rem;
        font-size: 2.4rem;
        font-weight: 500;
        line-height: 1.33;
        letter-spacing: 0.02em;
      }

      .div_desc {
        font-size: 1.4rem;
        line-height: 1.428;
        margin-bottom: 0.4rem;
        letter-spacing: 0.015em;
        max-height: 20rem;
        display: -webkit-box;
        -webkit-line-clamp: 10;
        -webkit-box-orient: vertical;
        overflow: hidden;

        :deep(p) {
          margin-bottom: 0.4rem;

          &:last-child {
            margin-bottom: 0;
          }
        }
      }

      .div_quote {
        font-size: 1.6rem;
        font-style: italic;
        line-height: 1.5;
        letter-spacing: 0.015em;
        color: #6B7280;
        margin-bottom: 0.4rem;
      }

      > :last-child {
        margin-bottom: 0;
      }
    }

    &:hover {
      .guides__card__img {
        &::before, .div_categories {
          opacity: 1;
        }
      }
    }
  }

  @media (max-width: 991px) {
    .div_top_tit {
      font-size: 3.2rem;
      font-weight: 500;
      line-height: 150%;
    }
  }
  @media (max-width: 767px) {
    .swiper-slide {
      width: 31.8rem;
    }

    .div_top_tit{
      font-size: 2.4rem;
      margin-bottom: 1.2rem;
    }
  }
}
</style>