<template>
  <Teleport to="#app">
    <transition>
      <div class="modal" :class="[type]" v-if="modelValue === true" v-bind="$attrs"
           ref="modalEl">
        <div @click="closeFonActive ? closeModal() : ''" class="modal_close"></div>
        <div class="modal-dialog">
          <div class="modal-content" :style="backgroundCont ? { backgroundImage: `url(${backgroundCont})` } : ''">
            <slot name="close" v-if="!buttonTextClose && !closeNone">
              <span class="modal-close" @click="closeModal()"></span>
            </slot>
            <slot>
              <h3 class="modal-header" v-if="$slots.header">
                <slot name="header"></slot>
              </h3>
              <div class="modal-body" v-if="$slots.body">
                <slot name="body"></slot>
              </div>
              <div class="modal-footer" v-if="$slots.footer">
                <slot name="footer"></slot>
              </div>
            </slot>
            <slot name="closeButton" v-if="buttonTextClose && !closeNone">
              <div class="wrapp-button">
                <cl-button @click="closeModal()" id="button-close">Закрыть</cl-button>
              </div>
            </slot>
          </div>
        </div>
      </div>
    </transition>
  </Teleport>
</template>

<script>
/*
* modelValue открытие/закрытие
* :close функция при закрытии
* closeStandart - закрываем окно через класс или переменную
* closeFonActive - можно ли закрыть молаку по подложке
*/

export default {
  name: 'ClModal',
  props: {
    modelValue: {
      type: Boolean,
      default: false
    },
    modalType: {
      type: String,
      required: true
    },
    close: {
      type: Function,
      default: () => true
    },
    closeStandart: {
      type: Boolean,
      default: true
    },
    closeWithAlert: {
      type: Boolean,
      default: false,
    },
    closeWithAlertFunc: {
      type: Function,
      default: () => true,
    },
    closeFonActive: {
      type: Boolean,
      default: true
    },
    backgroundCont: {
      type: String
    },
    buttonTextClose:{
      type: Boolean,
      default: false,
    },
    closeNone: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      type: `modal-${this.modalType}`
    }
  },
  watch: {
    modelValue() {
      let bodyEl = document.getElementsByClassName('site_body')[0];
      this.modelValue ? bodyEl.classList.add("modal_open") : bodyEl.classList.remove("modal_open");
    }
  },
  methods: {
    closeModal(closeWithAlert = Boolean(this.closeWithAlert)) {
      if(!closeWithAlert) {
        this.close();
        if (this.closeStandart === true) {
          this.$emit('update:modelValue', false);
        } else {
          this.$refs.modalEl.classList.add('d-none');
          document.getElementsByClassName('site_body')[0].classList.remove("modal_open");
        }
      } else {
        this.closeWithAlertFunc(this);
      }
    }
  },
}
</script>

<style lang="scss">
.modal_open {
  overflow: hidden;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99999;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  outline: 0;
  background: rgba(0, 0, 0, 0.5);

  .modal_close {
    position: fixed;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
  }

  @media (min-width: 992px) and (max-width: 1310px) {
    padding-left: 35px;
    padding-right: 35px;
  }

  &.modal746 {
    .modal-dialog {
      max-width: 746px;
    }
  }
  .modal-dialog {
    max-width: 1152px;
    margin-top: 30px;
    margin-bottom: 30px;
    margin-right: auto;
    margin-left: auto;
    position: relative;
    width: auto;
    //pointer-events: none;
    @media (max-width: 991px) {
      margin-top: 0;
      padding: 40px 60px;
    }
    @media (max-width: 767px) {
      padding: 0;
      height: 100%;
    }
  }

  .modal-content {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 32px 48px;
    color: #000106;
    background: #FFFFFF;
    border-radius: 12px;
    //@media (max-width: 991px) {
    //  padding: 40px;
    //}
    @media (max-width: 767px) {
      padding: 20px;
      min-height: 100vh;
    }
  }

  .modal-header {
    margin-bottom: 28px;
  }

  .modal-body {
    //display: flex;
    margin-bottom: 36px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  .modal-footer {
    display: flex;
    align-items: center;
    justify-content: right;
    gap: 24px;
  }

  .modal-close {
    position: absolute;
    right: 48px;
    top: 44px;
    width: 20px;
    height: 20px;
    transition: .2s linear;
    cursor: pointer;
    z-index: 10;
    @media (max-width: 1310px) {
      right: -27px;
    }
    @media (max-width: 767px) {
      right: 10px;
      top: 10px;
    }

    &:hover {
      opacity: .5;
    }

    &::before {
      transform: rotate(45deg);
    }

    &::after {
      transform: rotate(-45deg);
    }

    &::before, &::after {
      position: absolute;
      left: 8px;
      content: '';
      height: 20px;
      width: 2px;
      background-color: #000000;
    }
  }

  &.modal-center {
    display: flex;
    justify-content: center;
    align-items: center;

    .modal-dialog {
      margin: 0;
    }
  }

  &.modal-scroll {
    .modal-close {
      right: 58px;
      @media (max-width: 767px) {
        right: 10px;
      }
    }
  }

  &.modal-video {
    display: flex;
    justify-content: center;
    align-items: center;

    .modal-dialog {
      margin: 0;
      max-width: 90vw;
    }
    .modal-content {
      padding: 0;
      display: block;
    }
    iframe {
      width: 80vw !important;
      height: 80vh !important;
      display: block;
    }
    @media (max-width: 767px) {
      .modal-dialog {
        max-width: 100vw;
      }
      iframe {
        width: 100vw !important;
        height: 100vh !important;
      }
      .modal-close {
        z-index: 10;
        &:before,
        &:after {
          background-color: #fff;
        }
      }
    }

  }

  @media (max-width: 767px) {
    .modal-footer {
      gap: 12px;
    }
  }
}
.modalTour{
  .modal-close{
    top: -81px;
    right: 0;
    width: 48px;
    height: 48px;
    transition: none;
    &::before, &::after{
      background-color: white;
      height: 4px;
      width: 38px;
      left: 7px;
      top: 22px;
      border-radius: 2px;
    }
  }
  .modal-dialog{
    width: calc(100% - 98px);
    max-width: none;
    height: calc(100% - 228px);
    margin: 114px 49px;
  }
  .modal-content{
    height: 100%;
    padding: 0;
  }
  .modal-header{
    margin: 0;
  }
  .modal-body{
    height: 100%;
    margin: 0;
    overflow: hidden;
    border-radius: 12px;
    iframe{
      width: 100%;
      height: 100%;
    }
  }
  .modal-footer{
    position: absolute;
    right: 0;
    bottom: -57px;
  }
  .button_botton{
    position: absolute;
    display: flex;
    gap: 24px;
    .button_ico{
      width: 48px;
      height: 48px;
      cursor: pointer;
      img{
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
  }
  @media (max-width: 991px) {
    .modal-close{
      top: 8px;
      right: 8px;
      width: 30px;
      height: 30px;
      background-color: white;
      border-radius: 4px;
      border: 1px solid #ececec;
      z-index: 10001;
      &::before, &::after{
        background-color: white;
        height: 2px;
        width: 20px;
        left: 5px;
        top: 13px;
        border-radius: 0;
        background-color: black;
      }
    }
    .modal-dialog{
      width: 100vw;
      height: 100vh;
      margin: 0;
    }
    .modal-body{
      border-radius: 0;
    }
    .modal-footer{
      right: 32px;
      bottom: 57px;
    }
    .button_botton{
      .fullScreen{
        display: none;
      }
      .exitfullScreen{
        display: none;
      }
    }
  }
}
.fullscreen{
  //.modal-close{
  //  top: 32px;
  //  right: 32px;
  //  width: 48px;
  //  height: 48px;
  //}
  .modal-dialog{
    width: 100vw;
    height: 100vh;
    margin: 0;
  }
  .modal-footer{
    right: 32px;
    bottom: 57px;
  }
}
.center{
  .modal-dialog{
    height: 100%;
    width: 660px;
    max-width: 100vw;
    padding: 30px 0;
    display: flex;
    align-items: center;
    margin-bottom: 0;
  }
  .modal-content{
    height: fit-content;
    padding: 32px 64px;
    .success_block{
      text-align: center;
      gap: 24px;
    }
    .modal-body{
      margin-bottom: 24px;
    }
  }
  .wrapp-button{
    width: 100%;
    display: flex;
    justify-content: center;
    .btn.btn-default{
      width: fit-content;
    }
  }
  @media (max-width: 991px) {
    .modal-dialog{
      padding: 16px;
    }
    .modal-content{
      padding: 16px 32px;
    }
  }
}

.modal_form {
  .modal-close {
    &:before,
    &:after {
      background-color: #ffffff;
    }
  }
  .modal-header {
    color: #ED850A;
    text-align: center;
  }
  .modal-content {
    background: #0F1015;
    color: #ffffff;
  }
  .modal-dialog {
    max-width: 863px;
  }
}

.modalInfo {
  .modal-dialog {
    max-width: 746px;
  }
  .modal-close {
    right: 19px;
    top: 19px;
  }
}

.modal-gal {
  .modal-dialog {
    margin: 4vh auto;
    max-width: 90vw;
    width: 90vw;
    height: 90vh;
  }
  .modal-close {
    &:before,
    &:after {
      background-color: #fff;
    }
  }
  .modal-content {
    padding: 0;
    background: none;
    border-radius: 0;
    display: block;
  }
  @media (max-width: 767px) {
    .modal-close {
      top: 5px;
      right: 0;
    }
  }
  &.modal-gal-thumbs {
    .modal-content {
      display: flex;
      flex-direction: column;
      gap: 12px;
      height: 100%;
    }
  }
  &.modal-gal-map{
    .modal-content{
      height: 100%;
    }
    .modal-body{
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .modal-close{
      top: 0;
      right: 0;
    }
    .modal-body{
      img{
        max-width: 135.6rem;
        max-height: 76.4rem;
        border-radius: 2.4rem;
        width: 100%;
      }
    }
  }
}

.modalCinemaLink {
  .modal-content {
    padding: 3.2rem;
    border-radius: .6rem;
    background: #0F1015;
    .modal-close{
      width: 3rem;
      height: 3rem;
      right: 3.8rem;
      top: 3.9rem;
      &:before{
        background: #FFFFFF;
        height: 3rem;
        width: .3rem;
      }
      &:after{
        background: #FFFFFF;
        width: .3rem;
        height: 3rem;
      }
    }
  }
  @media(max-width: 991px){
    .modal-content .modal-close{
      top: 3.4rem;
    }
  }
}

.modal-trusted{
  h2{
    font-size: 3.2rem;//32px
    font-weight: 500;
    line-height: 150%;
    letter-spacing: .064rem;//0.64px
    margin-bottom: 2.4rem;
    color: #fff;
    text-transform: none;
    @media (max-width: 991px) {
      font-size: 1.6rem;
      font-weight: 400 !important;
      line-height: 150% !important;
      letter-spacing: 0.24px;
    }
  }
  .modal-dialog{
    max-width: 740px;
    width: 100%;
    @media (max-width: 767px) {
      height: auto;
      padding: 12px;
      width: 100%;
    }
  }
  .modal-content{
    width: 100%;
    border-radius: 6px;
    border: 2px solid rgba(255, 255, 255, 0.02);
    background: #0F1015;
    padding: 3.2rem;
    @media (max-width: 991px){
      min-height: unset;
      padding: 2.4rem;
    }
    @media (max-width: 767px) {
      min-width: unset;
      width: 100%;
    }
  }
  .modal-close{
    top: 0;
    right: 0;
    width: 2.4rem;
    height: 4.8rem;
    display: flex;
    align-items: center;
    justify-content: center;
    &:after, &:before{
      background-color: white;
    }
  }
}
.modal-rutube {
  .modal-dialog{
    width: 140rem;
  }
  .div_name{
    text-align: center;
    color: white;
    text-transform: uppercase;
  }
  .modal-content{
    padding: 0;
    background-color: #000106;
  }
  .modal-body, .modal-header{
    margin: 0;
  }
  iframe{
    max-height: 100vh;
    max-width: 100vh;
    height: 90vh;
    width: 100%;
    min-width: 100%;
    display: block;
  }
  .modal-close{
    top: 0;
    right: -3rem;
    height: 2rem;
    width: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    &:after, &:before{
      background-color: white;
    }
  }
  @media (max-width: 767px) {
    .modal-close {
      right: 0;
    }
  }
}
.modal-helper{
  .modal-dialog{
    width: 100%;
    max-width: 97.4rem;
  }
  .modal-content{
    padding: 3.2rem;
  }
  .title{
    width: 100%;
    text-align: center;
    font-size: 2.4rem;
    font-style: normal;
    font-weight: 500;
    line-height: 133.333%;
    letter-spacing: 0.048rem;
    margin-bottom: 2.4rem;
  }
  .desc{
    font-size: 1.8rem;
    font-style: normal;
    font-weight: 400;
    line-height: 133.333%;
    letter-spacing: 0.027rem;
    text-align: center;
    margin-bottom: 2.4rem;
    a{
      color: #ED850A;
      text-decoration: underline;
    }
  }
}
.modal_grant{
  .modal-content{
    width: 810px;
    background-color: #0C0C0E;
    border: 2px solid rgba(255, 255, 255, 0.02);
    border-radius: 0.6rem;
    padding: 3.2rem;
    display: flex;
    flex-direction: column;
    gap: 1.6rem;
  }
  .modal-header{
    text-align: center;
    font-size: 3.2rem;
    font-style: normal;
    font-weight: 500;
    line-height: 125%;
    letter-spacing: 0.064rem;
    text-transform: uppercase;
    color: #ED850A;
    margin: 0;
  }
  .modal-body{
    text-align: center;
    margin: 0;
  }
  .modal-footer{
    display: flex;
    justify-content: center;
  }
  .modal-close{
    display: none;
  }
}

.modal-black {
  text-align: center;
  .modal-dialog {
    max-width: 804px;
  }
  .modal-close {
    display: none;
  }
  .modal-content{
    background-color: #0C0C0E;
    border-radius: 0.6rem;
    padding: 3.2rem;
    display: flex;
    flex-direction: column;
    gap: 1.6rem;
    color: #fff;
  }
  .modal-header{
    text-align: center;
    font-size: 2.4rem;
    line-height: 125%;
    letter-spacing: 0.02em;
    margin: 0;
  }
  .modal-body{
    margin: 0;
  }
  .modal-footer{
    display: flex;
    justify-content: center;
  }
  @media (max-width: 767px) {
    .modal-close {
      display: flex;
      border: 2px solid #FFFFFF;
      border-radius: 100%;
      right: 25px;
      &:before,
      &:after {
        left: 7px;
        height: 11px;
        background-color: #fff;
        top: 2px;
      }
    }
    .modal-dialog,
    .modal-content{
      height: auto;
      min-height: 1px
    }
  }
}

.modal-afisha {
  .modal-dialog {
    max-width: 503px;
  }
  .modal-content {
    padding: 24px;
  }
  .modal-close {
    display: block;
    top: 30px;
    right: 24px;
    &:after, &:before {
      background-color: #FFFFFF;
    }
  }
  .modal-header {
    text-align: left;
    font-weight: 400;
    @media (min-width: 992px) {
      font-size: 3.2rem;
    }
  }
}

.modal-ico {
  text-align: center;
  .modal-close {
    width: 32px;
    height: 32px;
    right: 32px;
    top: 32px;
    //background: url(@/assets/img/ico/closeCircle.svg) no-repeat center center;
    //background-size: contain;
    border: 1px solid #ED850A;
    border-radius: 100%;
    &:before,
    &:after {
      left: 8px;
      height: 18px;
      background-color: #ED850A;
      top: 44%;
    }
    &:before {
      transform: rotate(45deg) translateY(-50%);
    }
    &:after {
      transform: rotate(-45deg) translateY(-50%);
      left: 20px;
    }
  }
  .modal-content {
    padding: 32px;
    border-radius: 6px;
  }
  .modal-body {
    margin-bottom: 16px;
  }
  .modal-footer {
    justify-content: center;
  }
  .div_img{
    text-align: center;
    margin-bottom: 16px;
  }
  .div_tit {
    margin-bottom: 8px;
    &:last-child {
      margin-bottom: 0;
    }
  }
}

.modal_competition {
  .modal-dialog {
    max-width: 1000px;
    border-radius: 8px;
    overflow: hidden;
    margin: 0 auto;
    top: 50%;
    translate: 0 -50%;
  }
  .modal-content {
    background-color: #000106;
    padding: 0;
  }
  .contet_modal_comp {
    display: flex;
    padding-left: 6.4rem;
    align-items: center;
    justify-content: space-between;
    color: #FFF;
    .div_content {
      width: 39.4rem;
    }
    .div_tit {
      font-size: 3.2rem;
      font-weight: 500;
      line-height: 120%;
      margin-bottom: 0.8rem;
    }
    .div_desc {
      font-size: 2.4rem;
      line-height: 133.33%;
      margin-bottom: 1.6rem;
    }
  }
  .modal-close {
    top: 0;
    right: 0;
    width: 4.8rem;
    height: 4.8rem;
    background-color: #FFF;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 0 8px 0 0;
    &:after, &:before {
      left: unset;
      background-color: #ED850A;
    }
    transition: unset;
    &:hover {
      opacity: 1;
      background-color: #ED850A;
      &:after, &:before {
        left: unset;
        background-color: #FFF;
      }
    }
  }
  .div_img {
    height: 48rem;
    img {
      object-fit: cover;
      object-position: center;
      height: 100%;
      border-radius: 0 8px 8px 0;
    }
  }
  @media (max-width: 767px) {
    .div_img {
      img {
        border-radius: 0;
      }
    }
    .modal-close, .modal-dialog {
      border-radius: 0;
    }
    .contet_modal_comp {
      flex-direction: column;
      padding: 0;
      height: 100vh;
      align-items: unset;
      justify-content: unset;
      .div_content {
        padding: 16px;
        width: 100%;
        max-width: unset;
        order: 2;
        padding-bottom: 80px;
        position: absolute;
        bottom: 80px;
        left: 0;
      }
      .div_img {
        width: 100%;
        flex-grow: 1;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
    }
  }
}

.modal-iframe-excursions {
  .modal-dialog {
    max-width: 1040px;
  }
  .modal-content {
    background: #ffffff;
  }
  .modal-close {
    right: 9px;
    top: 12px;
  }
  .iframe-excursions {
    height: 90vh;
    scrollbar-width: thin;
  }
}

.modal_alert_measure_leave{
  .modal-close {
    display: none;
  }

  .modal-dialog {
    width: auto;
  }

  .modal-body{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1.6rem;
    margin: 0 !important;

    .div_img {
      img {
        height: 6.4rem;
        width: 6.4rem;
      }
    }

    .div_info {
      display: flex;
      flex-direction: column;
      gap: 0.8rem
    }

    .div_title {
      margin: 0;
      font-size: 2.4rem;
      font-weight: 500;
      line-height: 1.33;
      letter-spacing: 0.02em;
      text-align: center;
    }

    .div_text {
      line-height: 1.5;
      letter-spacing: 0.015em;
      text-align: center;

      a {
        color: #ED850A;
        text-decoration: underline;
      }
    }

    .div_btns {
      display: flex;
      gap: 1.6rem;

      .btn {
        min-width: 25rem;
      }
    }
  }

  &__black {
    .modal-content {
      background: black;
      color: white;
    }
  }

  &__btn_reverse {
    .modal-body {
      .div_btns {
        flex-direction: row-reverse;
      }
    }
  }

  @media (max-width: 767px) {
    .modal-body {
      .div_btns {
        flex-direction: column-reverse;
        width: 100%;

        .btn {
          min-width: auto;
        }
      }
    }

    &__btn_reverse {
      .modal-body {
        .div_btns {
          flex-direction: column;
        }
      }
    }
  }
}

.modal_with_scrollbar {
  .modal-dialog {
    width: auto;
  }

  .modal-content {
    padding: 3.2rem;
  }

  &__custom_close {
    width: 3.2rem !important;
    height: 3.2rem !important;
    top: 3.2rem !important;
    right: 3.2rem !important;
  }

  .modal-body {
    display: flex;
    flex-direction: column;
    gap: 1.6rem;
    margin: 0 !important;
    max-height: calc(100vh - 60px - 6.4rem);
  }

  .div_info {
    display: flex;
    flex-direction: column;
    gap: 0.8rem;
  }

  .div_title {
    padding: 0 4.8rem;
    margin: 0;
    text-align: center;
  }

  .div_subtitle {
    display: flex;
    flex-direction: column;
    gap: 0.8rem;
    text-align: center;

    p {
      margin-bottom: 0;
    }
  }

  .div_content {
    &.ps--active-y {
      padding: 0 34px 0 0;
    }

    p, ul, li {
      margin-bottom: 0 !important;
      font-size: 1.8rem !important;
      font-weight: 400 !important;
      line-height: 1.33 !important;
      letter-spacing: 0.015em !important;
    }

    li {
      padding-left: 27px;
      &:before {
        left: 12px;
        width: 3px;
        height: 3px;
        border-radius: 50%;
      }
    }

    .ps__rail-y {
      right: 5px;
    }
  }

  .div_btns {
    display: flex;
    gap: 2.4rem;
  }

  &__black {
    .modal-content {
      background: black;
      color: white;
    }

    .div_content {
      li {
        &:before {
          background: white;
        }
      }
    }
  }
}

.modal_projects_add.modal_form {
  .modal-dialog {
    max-width: 1000px;
  }
}

</style>