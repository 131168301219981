// alertMeasureLeave для вывода предупреждения перед закрытием модалки.
// Добавляется на компонент measure-form-component и cl-modal.
// alertMeasureLeave при открытой форме выполняет действие при закрытии модалки.
// Форма в модалке. Чтобы добавить alertMeasureLeave на measure-form-component, который находится в модалке нужно добавить:
//     import:
//         import mixinModalAlertMeasureLeave from "@/mixin/mixinModalAlertMeasureLeave";
//     mixin:
//         mixins: [mixinModalAlertMeasureLeave],
//     cl-modal следующие атрибуты:
//         v-bind="modalAlertMeasureLeaveTopModalAttr(УСЛОВИЕ ОТКРЫТОЙ МЕРЫ)"
//     measure-form-component следующие атрибуты:
//         v-bind="modalAlertMeasureLeaveAttr()"
//         v-on="modalAlertMeasureLeaveEmits()"
//
// Где УСЛОВИЕ ОТКРЫТОЙ МЕРЫ - условие при котором открыта мера (Важно!!! Работает только при открытой мере поддержки, иначе может сломать логику компонента);

// Пример в компоненте rentItem.vue

export default {
    data() {
        return {
            modalAlertMeasureLeaveShow: false, // Показывать/скрывать модалку modalAlertMeasureLeave
            modalAlertMeasureLeaveClModalThis: null, // This открытой верхней модалки
            modalAlertMeasureLeaveIsSuccessPostSend: false, // Состояние успешной отправки заявки
        }
    },
    methods: {
        modalAlertMeasureLeaveTopModalAttr(condition = true) {
            return {
                'closeWithAlert': condition && this.$root.globalAlertData?.modalAlertMeasureLeave?.active, // Атрибут запрещающей верхней модалке закрываться
                'closeWithAlertFunc': condition && this.$root.globalAlertData?.modalAlertMeasureLeave?.active ? this.modalAlertMeasureLeaveTopModalBeforeClose : () => true, // Атрибут с функцией, которая вызывается вместо закрытия верхней модалки
            }
        },
        modalAlertMeasureLeaveAttr() {
            return {
                'modal-alert-measure-leave-location-type': 'onModal', // Тип меры (на странице или в модалке)
                'modal-alert-measure-leave-show': this.modalAlertMeasureLeaveShow, // Атрибут, показать/скрыть модалку
            }
        },
        modalAlertMeasureLeaveEmits() {
            return {
                'modalAlertMeasureLeaveCloseApply': this.modalAlertMeasureLeaveCloseApply, // Emit на закрытие модалки при подтвержднии
                'modalAlertMeasureLeaveCloseCancel': this.modalAlertMeasureLeaveCloseCancel, // Emit на закрытие модалки при отмене
                'modalAlertMeasureLeaveUpdateSuccessSend': this.modalAlertMeasureLeaveUpdateSuccessSend, // Emit, который позволяет закрыть модалку без предупреждения (после успешной отправки заявки)
            }
        },
        modalAlertMeasureLeaveTopModalBeforeClose(modalAlertMeasureLeaveClModalThis) {
            if(!this.modalAlertMeasureLeaveIsSuccessPostSend) {
                this.modalAlertMeasureLeaveClModalThis = modalAlertMeasureLeaveClModalThis; // Получить this верхней модалки
                this.modalAlertMeasureLeaveShow = true; // Открыть modalAlertMeasureLeave перед закрытием верхней модалки
            } else {
                this.modalAlertMeasureLeaveIsSuccessPostSend = false; // Отключить свободное закрытие модалки (после успешной отправки заявки)
                this.modalAlertMeasureLeaveClModalThis.closeModal(false); // Зарыть верхнюю модалку
            }
        },
        modalAlertMeasureLeaveCloseCancel() {
            this.modalAlertMeasureLeaveShow = false; // Закрыть modalAlertMeasureLeave, не закрывая верхнюю модалку
        },
        modalAlertMeasureLeaveCloseApply() {
            this.modalAlertMeasureLeaveShow = false; // Закрыть modalAlertMeasureLeave
            this.modalAlertMeasureLeaveClModalThis.closeModal(false); // Зарыть верхнюю модалку
        },
        modalAlertMeasureLeaveUpdateSuccessSend() {
            this.modalAlertMeasureLeaveIsSuccessPostSend = true; // Включить свободное закрытие модалки (после успешной отправки заявки)
        },
    },
}