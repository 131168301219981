/**
 * подключаем плагин на страницу, через npm и yarn их плагин не находит (https://dev.afisha.ru/afisha-widget-manager/session.html здесь подробно описано как встраивать виджет по сеансу)
 * функция открытия записи
 * ticketManager.session('fcc1d4dd-23b0-42b5-a428-dcd09b0037ea', `${seanceInfo.externalCode}`)
 */
export function ticketmanagerAdd() {
    if(!document.querySelector('script[src="https://afisha.ru/w/ticketmanager.js"]')){
        const script = document.createElement('script');
        script.src = 'https://afisha.ru/w/ticketmanager.js';
        script.async = true;
        document.body.appendChild(script);
    }
}