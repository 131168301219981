<template>
  <teleport to="#site_header_td2">
    <nav v-if="dataInfo && dataInfo.list && dataInfo.list.length > 0" class="category_people">
      <template v-for="(item, index) in dataInfo.list">
        <span
            class="sp_el"
            :class="{'active': getCategoryPeople == 'tabCategoryPeople' + index}"
            @click="$store.commit('updateCategoryPeople', 'tabCategoryPeople' + index)">
        {{ item.title?.value }}
      </span>
      </template>

    </nav>
  </teleport>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "headerCategoryPeople",
  props: {
    dataInfo: {
      type: Object,
      require: true
    }
  },
  computed: {
    ...mapGetters([
      'getCategoryPeople'
    ])
  }
}
</script>

<style lang="scss" scoped>
.category_people {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 2.4rem;
  font-weight: 500;
  .sp_el {
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: .8rem;
  }
  .active {
    color: #F43F5E;
    cursor: default;
    &:before {
      content: "";
      width: .8rem;
      height: .8rem;
      border-radius: 100%;
      display: block;
      background: #F43F5E;
    }
  }
}
</style>