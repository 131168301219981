<template>
  <documents-and-agreements v-if='blockComponent == "documentsAndAgreements"' :dataInfo="dataInfo" :id="id"/>
  <banner-form v-else-if='blockComponent == "bannerForm"' :dataInfo="dataInfo" :id="id"/>
  <events-list v-else-if='blockComponent == "eventsList"' :dataInfo="dataInfo" :id="id"/>
  <events-list v-else-if='blockComponent == "eventsListOne"' class="events-top-one" :dataInfo="dataInfo" :id="id"/>
  <excursions-cinema v-else-if='blockComponent == "excursionsCinema"' :dataInfo="dataInfo" :id="id"/>
  <main-big-block-text v-else-if='blockComponent == "mainBigBlockText"' :dataInfo="dataInfo" :id="id"/>
  <video-reviews v-else-if='blockComponent == "videoReviews"' :dataInfo="dataInfo" :id="id"/>
  <header-category-people v-else-if='blockComponent == "headerCategoryPeople"' :dataInfo="dataInfo" :id="id"/>
  <big-block-and-audio v-else-if='blockComponent == "bigBlockAndAudio"' :dataInfo="dataInfo" :id="id"/>
  <header-menu-category v-else-if='blockComponent == "headerMenuCategory"' :dataInfo="dataInfo" :id="id"/>
  <movie-show-schedule v-else-if='blockComponent == "movieShowSchedule"' :dataInfo="dataInfo" :id="id"/>
</template>

<script>/**
 * dataInfo - данные приходящие в блок
 * id - якорь блока
 * blockComponent - название компонента (которое прописывается в админке)
 */

import DocumentsAndAgreements from "@/components/documentsAndAgreements.vue";
import BannerForm from "@/components/library/bannerForm.vue";
import EventsList from "@/components/eventsList.vue";
import ExcursionsCinema from "@/components/excursionsCinema.vue";
import MainBigBlockText from "@/components/mainBigBlockText.vue";
import VideoReviews from "@/components/videoReviews.vue";
import HeaderCategoryPeople from "@/components/header/headerCategoryPeople.vue";
import BigBlockAndAudio from "@/components/bigBlockAndAudio.vue";
import HeaderMenuCategory from "@/components/header/headerMenuCategory.vue";
import MovieShowSchedule from "@/components/movieShowSchedule.vue";

export default {
  name: "templateDynamic2",
  components: {
    MovieShowSchedule,
    HeaderMenuCategory,
    BigBlockAndAudio,
    HeaderCategoryPeople,
    VideoReviews, MainBigBlockText, ExcursionsCinema, EventsList, BannerForm, DocumentsAndAgreements},
  props: {
    dataInfo: {
      type: Object,
      require: true,
    },
    id: {
      type: String,
    },
    blockComponent: {
      type: String,
    }
  }
}
</script>