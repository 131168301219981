<template>
  <div class="content_form_area" v-if="showForm">
    <div>
      <div v-html="btest" v-if="btest"></div>
      <div v-html="data.content" v-if="data" :class="{'op_form': opacityShow}"></div>
      <!--    <div class="loader_wrap"-->
      <!--         v-if="!data && opacityShow"-->
      <!--    >-->
      <!--      <loader-->
      <!--          :animation-duration="3000"-->
      <!--          :size="60"-->
      <!--          :color="'#ED850A'"-->
      <!--      ></loader>-->
      <!--    </div>-->

    <div class="loader-measure loader-measure-js" :ref="'form_loader_id' + measureGuid" :id="'form_loader_id' + measureGuid">
      <loader
          :animation-duration="3000"
          :size="60"
          :color="'#ED850A'"
      ></loader>
    </div>

      <cl-modal v-model="modalview" class="d-none" :id="modal_id">
        <div :id="modal_content_id"></div>
      </cl-modal>
      <div :id="'form_type' + measureGuid" :data-type="formType" :data-text="successText" :data-text2="successText2" :data-button="buttonText" :data-link="buttonLink" :data-reload="reload"></div>
      <div class="d-none" id="measureNumber">
        <router-link :to="`/lk/my-applications/item/${measureServiceGuid}`" class="a-or">{{ measureServicecode }}</router-link>
      </div>
    </div>
    <div class="fs3n" v-if="data?.isAuthorizationRequired && !getUser">Требуется авторизация</div>
    <modal-alert-measure-leave
        v-if="$root.globalAlertData?.modalAlertMeasureLeave?.active"
        :modelValue="modalAlertMeasureLeaveShow"
        @update:modelValue="modalAlertMeasureLeaveShow = $event"
        @modalAlertMeasureLeaveCloseCancel="modalAlertMeasureLeaveCloseCancel"
        @modalAlertMeasureLeaveCloseApply="modalAlertMeasureLeaveCloseApply"/>
  </div>
</template>


<script>
/*
* formType - вид вывода сообщения о успешной отправке
* successText - текст сообшения о успешной отправке
* successText2 - текст под первым successText
* successTextImportant - текст будет полностью обязательным
* buttonText - текст кнопки о успешной отправке
* buttonLink - ссылка кнопки о успешной отправке
* reload - запрет на перезагрузку формы, сама форма удаляется со страницы
*
* Важно!!! Если на странице инициализированно несколько мер с одинаковым measureGuid,
* то функция measureBundleSuccessPostSendEvent выполниться столько раз, сколько данных мер на странице.
* Это может привести к таким ошибкам как:
* 1) Повторное открывание модалки об успешной отправке;
* 2) Повторная отправка метрик на сервер.
* */
//import {nextTick} from "vue";
import {mapGetters} from "vuex";

let current_measure_guid = null;
let iframe_id = null;


import {Base64} from 'js-base64';
import ModalAlertMeasureLeave from "@/components/modalAlertMeasureLeave.vue";

export default {
  name: "MeasureFormComponent",
  components: {ModalAlertMeasureLeave},
  props: {
    measureGuid: {
      type: String,
      required: true
    },
    showMode: {
      type: String,
      required: true
    },
    formParams: {
      type: Array,
    },
    formType:{
        type: String,
        default: "2",
    },
    successText:{
        type: String,
        default: "Ваше обращение успешно отправлено!"
    },
    successText2:{
      type: String,
      default: ""
    },
    successTextImportant: {
      type: Boolean,
      default: false
    },
    buttonText:{
        type: String,
        default: "Закрыть"
    },
    buttonLink:{
        type: String,
        default: ""
    },
    reload: {
      type: Boolean,
      default: true
    },
    modalAlertMeasureLeaveShow: {
      type: Boolean,
      default: false,
    },
    modalAlertMeasureLeaveLocationType: {
      type: String
    },
    /**
     * Объект содержащий идентификатор цели яндекс метрики при успешной отправки формы.
     * @type {object}
     * @property {string} value - Идентификатор цели яндекс метрики при успешной отправки формы.
     */
    yandexMetrikaTargetFormSubmit: {
      type: Object,
    }
  },
  data() {
    return {
      data: null,
      btest: null,
      opacityShow: true,
      modalview: true,
      modal_id: null,
      modal_content_id: null,
      measureServicecode: null,
      measureServiceGuid: null,
      showForm: true,
    }
  },
  methods: {
    getFormItem(id) {
      this.axios
          .post(
              `/api/get/form/measure-support/${id}`,
              null,
              {
                params: {
                  'formparams': Base64.encode(JSON.stringify(this.formParams)),
                  'viewmode': this.showMode,
                  'hideFormLabel': true,
                }

              }
          )
          .then(response => {
            /*console.log('response')
            console.log(response)*/
            this.data = response.data;
            this.$emit('formTitle', response.data.measureSupportName);
            this.$emit('isAuthorizationRequired', response.data.isAuthorizationRequired);
          })
          .catch(error => {
            this.showError(error, 'При загрузке формы произошла ошибка');
            if(this.$refs['form_loader_id' + this.measureGuid]){
              this.$refs['form_loader_id' + this.measureGuid].classList.add('d-none');
            }
          })
          .finally(() => {
            setTimeout(() => {
              this.opacityShow = false;
              this.$emit('loaderHide', "");
            }, 500)
            setTimeout(() => {
              if(this.$refs['form_loader_id' + this.measureGuid] && !this.$refs['form_loader_id' + this.measureGuid].classList.contains('loader-window-global')) {
                this.$refs['form_loader_id' + this.measureGuid].classList.add('d-none');
              }
            }, 2000)
          });
    },

    modalAlertMeasureLeaveCloseCancel(value) {
      if(this.modalAlertMeasureLeaveLocationType === 'onModal') this.$emit('modalAlertMeasureLeaveCloseCancel', value);
      if(this.modalAlertMeasureLeaveLocationType === 'onPage') this.$emit('modalAlertMeasureLeaveRouteCloseCancel', value);
    },

    modalAlertMeasureLeaveCloseApply(value) {
      if(this.modalAlertMeasureLeaveLocationType === 'onModal') this.$emit('modalAlertMeasureLeaveCloseApply', value);
      if(this.modalAlertMeasureLeaveLocationType === 'onPage') this.$emit('modalAlertMeasureLeaveRouteCloseApply', value);
    },

    modalAlertMeasureLeaveAvailableOff(value) {
      if(this.modalAlertMeasureLeaveLocationType === 'onPage') this.$emit('modalAlertMeasureLeaveRouteAvailableOff', value);
    },

    modalAlertMeasureLeaveAvailableOn(value) {
      if(this.modalAlertMeasureLeaveLocationType === 'onPage') this.$emit('modalAlertMeasureLeaveRouteAvailableOn', value);
    },

    modalAlertMeasureLeaveSuccessPostSendEvent() {
      if(this.modalAlertMeasureLeaveLocationType === 'onModal') this.$emit('modalAlertMeasureLeaveUpdateSuccessSend', true);
      if(this.modalAlertMeasureLeaveLocationType === 'onPage') this.$emit('modalAlertMeasureLeaveRouteAvailableOn', true);
    },

    /**
     * Функция-событие, которое вызывается после удачно отправленной формы.
     *
     * Важно!!! Если на странице инициализированно несколько мер с одинаковым measureGuid,
     * то функция measureBundleSuccessPostSendEvent выполниться столько раз, сколько данных мер на странице.
     * Это может привести к таким ошибкам как:
     * 1) Повторное открывание модалки об успешной отправке;
     * 2) Повторная отправка метрик на сервер.
     * @param {event} event - событие
     */
    measureBundleSuccessPostSendEvent(event) {
      if(event?.detail?.resp?.measureSupportGuid === this.measureGuid) {
        this.v_ym(this.yandexMetrikaTargetFormSubmit?.value);
        this.modalAlertMeasureLeaveSuccessPostSendEvent();
        if(event.detail.resp.measureServicecode && !this.successTextImportant){
          this.measureServicecode =  event.detail.resp.measureServicecode;
          this.measureServiceGuid = event.detail.resp.measureServiceGuid;
          //document.getElementById('measureNumber').querySelector('a').innerHTML = event.detail.resp.measureServicecode;
          document.getElementById('form_type' + this.measureGuid).setAttribute('data-text', `Ваша заявка <span id="measureNumberSp"></span> успешно отправлена!`);
          this.$nextTick(() => {
            if (document.getElementById('measureNumberSp') && document.getElementById('measureNumber')) {
              document.getElementById('measureNumberSp').innerHTML = document.getElementById('measureNumber').innerHTML;
            }
          });
        }
      }
    },
  },
  watch: {
    data() {
      this.$nextTick(() => {
        let element = document.getElementById(this.data.iframe_id);

        current_measure_guid = this.data.measure_id;
        iframe_id = this.data.iframe_id;

        var timerId = setInterval(function () {
          if (!element || !element.contentWindow || !element.contentWindow.document || !element.contentWindow.document.body) {
            return;
          }
          element.height = element.contentWindow.document.body.scrollHeight + 30;
        }, 200);

        window.removeEventListener('measureBundle.SuccessPostSendEvent', this.measureBundleSuccessPostSendEvent);
        window.addEventListener('measureBundle.SuccessPostSendEvent', this.measureBundleSuccessPostSendEvent);

        if(this.data.isAuthorizationRequired && !this.getUser){
          this.showForm = false;
          document.getElementById('modalAuthShow').classList.remove('d-none');
        }


      });
    },
    getUser() {
      if(!this.getUser){
        this.showForm = false;
      }
    }
  },
  computed: {
    ...mapGetters([
      'getUser'
    ]),
  },
  mounted() {
    // if(this.$refs['form_loader_id' + this.measureGuid]){
    //   this.$refs['form_loader_id' + this.measureGuid].classList.remove('d-none');
    // }
    this.getFormItem(this.measureGuid);
    this.modal_id = 'measure_ctx_modal_' + this.measureGuid;
    this.modal_content_id = 'measure_ctx_modal_content_' + this.measureGuid;
    this.modalAlertMeasureLeaveAvailableOff(true);
  },
  beforeUnmount() {
    this.modalAlertMeasureLeaveAvailableOn(true);
    window.removeEventListener('measureBundle.SuccessPostSendEvent', this.measureBundleSuccessPostSendEvent);
  }
}
</script>

<style scoped lang="scss">
.content_form_area {
  min-height: 100px;
}
.loader-measure {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.4);
  display: flex;
  align-items: center;
  justify-content: center;
}
.modal_open .loader-measure {
 z-index: 99999999;
}

.support_measure_form_tit0 {
  margin-bottom: 40px;
}

.support_measure_form_tit1 {
  max-width: 872px;
  margin-bottom: 50px;
}

.loader_ab {
  position: relative;
  .loader-measure {
    position: absolute;
    top: -88px;
    right: -35px;
    bottom: -25px;
    left: -35px ;
  }
}
</style>
