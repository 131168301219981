<template>
  <cl-modal
      v-if="dataInfo && Object.keys(dataInfo).length !== 0"
      v-model="isShowModalSurvey"
      class="modalInfo modal_with_scrollbar modal_with_scrollbar__black"
      :close-fon-active="false"
      :close="closeModalInside"
  >
    <template v-slot:close>
      <svg class="svg-icon modal-close modal_with_scrollbar__custom_close" width="32" height="32" stroke="#ED850A" @click="closeModal">
        <use xlink:href="/svg/icons.svg#closeCircle"></use>
      </svg>
    </template>
    <template v-slot:body>
      <div v-if="dataInfo.title || dataInfo.subTitle" class="div_info">
        <h3 v-if="dataInfo.title" class="div_title">{{dataInfo.title.value}}</h3>
        <div v-if="dataInfo.subTitle" class="div_subtitle" v-html="dataInfo.subTitle.value"></div>
      </div>
      <div v-if="dataInfo.urlData" ref="perfectScrollbar" class="div_content">
        <div ref="msFormWrapRef">
          <ms-form ref="msFormRef" :urlData="dataInfo.urlData.value" :submitName="'Отправить'" @closeModal="closeModal"/>
        </div>
      </div>
      <div v-if="dataInfo.urlData" class="div_btns">
        <cl-button type="light" @click="closeModal">
          {{ dataInfo.cancelBtnText ? dataInfo.cancelBtnText.value : '' }}
        </cl-button>
        <cl-button @click="sendData">
          {{ dataInfo.applyBtnText ? dataInfo.applyBtnText.value : '' }}
        </cl-button>
      </div>
    </template>
  </cl-modal>
</template>

<script>
import ClModal from "@/components/library/ClModal.vue";
import ClButton from "@/components/library/ClButton.vue";
import MsForm from "@/components/forms/MsForm.vue";
import {ref} from "vue";
import PerfectScrollbar from "perfect-scrollbar";

export default {
  name: "modalSurvey",
  components: {MsForm, ClButton, ClModal},
  data() {
    return {
      /**
       * Состояние, с помощью которого можно открывать/закрывать модалку
       * @type {boolean}
       * @default false
       */
      isShowModalSurvey: false,
      /**
       * Perfect Scrollbar
       * @type {PerfectScrollbar}
       * @default null
       */
      ps: null,
      /**
       * ResizeObserver контента внутри PerfectScrollbar
       * @type {ResizeObserver}
       * @default null
       */
      resizeObserver: null,
    }
  },
  props: {
    /**
     * Данные из админки
     * @type Object
     */
    dataInfo: {
      type: Object,
      require: true,
    },
  },
  methods: {
    /**
     * Функция для открытия модалки.
     */
    openModal() {
      this.isShowModalSurvey = true;
      this.addResizeObserverScrollbar();
    },
    /**
     * Функция для закрытия модалки.
     */
    closeModal() {
      this.removeResizeObserverScrollbar();
      this.isShowModalSurvey = false;
    },
    /**
     * Функция для закрытия модалки. Вызывается при закрытии модалки кликом сбоку.
     */
    closeModalInside() {
      this.removeResizeObserverScrollbar();
    },
    /**
     * Функция-событие, которая вызывает функцию sendData из дочернего компонента ms-form
     * @param {event} event - событие
     */
    sendData(event) {
      this.$refs.msFormRef.sendData(event);
    },
    /**
     * Функция, которая добавляет ResizeObserver контенту внутри PerfectScrollbar.
     *
     * ResizeObserver обновляет скроллбар при изменеии размеров контента.
     */
    addResizeObserverScrollbar() {
      if(this.dataInfo?.urlData) {
        setTimeout(() => {
          this.ps = new PerfectScrollbar(this.$refs.perfectScrollbar);
          this.resizeObserver = new ResizeObserver(() => {
            setTimeout(() => {
              this.ps.update();
            }, 15);
          });
          this.resizeObserver.observe(this.$refs.msFormWrapRef);
        }, 15);
      }
    },
    /**
     * Функция, которая убирает ResizeObserver контенту внутри PerfectScrollbar.
     */
    removeResizeObserverScrollbar() {
      if(this.dataInfo?.urlData) {
        this.resizeObserver.disconnect()
      }
    }
  },
}
</script>

<style lang="scss" scoped>

</style>