<template>
  <div>
    <container>
      <div class="block-list-main">
        <div class="block-header">
          <div class="title">
            <h2 v-if="dataInfo.title">{{dataInfo.title.value}}</h2>
            <h4 v-if="dataInfo.subtitle">{{dataInfo.subtitle.value}}</h4>
          </div>
          <a :href="dataInfo.buttonLink.value" class="title-button" v-if="dataInfo.buttonLink && dataInfo.buttonText">{{dataInfo.buttonText.value}}</a>
        </div>
        <row class="cards-wrapper" v-if="dataInfo.listCard && dataInfo.listCard.length">
          <column :lg="4" :md="6" :sm="12" v-for="card of dataInfo.listCard">
            <a :href="card.cardLink ? card.cardLink.value : null" class="card" :class="{'cursor-default': !card.cardLink}" :style="[card.cardBackground?.value?.src? { backgroundImage: `url(${card.cardBackground?.value?.src})` } : '' ]">
              <div class="card-image" v-if="card.cardIcon">
                <img v-bind:src="card.cardIcon.value.src" alt="">
              </div>
              <h4 class="card-title" v-if="card.cardTitle">{{card.cardTitle.value}}</h4>
              <div class="hover-block" v-if="card.cardTitle || card.cardText || card.cardButtonText">
                <h4 class="card-title" v-if="card.cardTitle">{{card.cardTitle.value}}</h4>
                <p class="fs14" v-if="card.cardText">{{card.cardText.value}}</p>
                <a class="card-button" :href="card.cardLink.value" v-if="card.cardLink && card.cardButtonText">{{card.cardButtonText.value}}</a>
              </div>
            </a>
          </column>
        </row>
      </div>
    </container>
  </div>
</template>

<script>

import Column from "@/components/library/Column.vue";

export default {
  name: "blockListMain",
  components: {Column},
  props: {
    dataInfo: {
      type: Object,
      require: true
    },
  },
}
</script>

<style lang="scss" scoped>

  .block-list-main{
    margin-block: 12rem;

    .block-header{
      margin-bottom: 2.4rem;
      display: flex;
      justify-content: space-between;

      .title{
        h2{
          margin-bottom: 0.8rem;
          font-family: 'Playfair Display', sans-serif;
        }
      }

      .title-button{
        display: block;
        padding: 1.2rem 5.6rem 1.2rem 2.4rem;
        position: relative;
        background-color: #ED850A;
        border-radius: 1.6rem;
        height: 4.8rem;
        max-height: 4.8rem;

        &::after{
          content: url("../assets/img/ico/move-up-right.svg");
          filter: brightness(0) invert(1);
          position: absolute;
          top: 50%;
          transform: translateY(-50%) !important;
          right: 2.4rem;
          left: auto;
          height: 2.4rem;
          width: 2.4rem;
          opacity: 1;
          background: rgba(0, 0, 0, 0);
        }
      }

    }


      .card{
        border: 1px solid rgba(63, 63, 66, 1);
        width: 100%;
        background-color:#0C0C0E;
        height: 20rem;
        display: flex;
        gap: 0.4rem;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        position: relative;
        border-radius: 1.6rem;
        cursor: pointer;

        p, .card-title{
          margin-bottom: 0;
          text-align: center;
        }

        .hover-block{
          display: flex;
          opacity: 0;
          position: absolute;
          background-color: #0C0C0E;
          flex-direction: column;
          justify-content: space-between;
          align-items: center;
          padding: 2.8rem 2.4rem;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          border: 1px solid rgba(237, 133, 10, 1) ;
          border-radius: 1.6rem;
          text-align: center;
          transition: 0.1s;
          cursor: default;

          &:hover{
            opacity: 1;
          }

          .card-button{
            display: block;
            background-color: #0C0C0E;
            width: 100%;
            padding: 12px 24px;
            border: 1px solid rgba(237, 133, 10, 1);
            text-align: center;
            color: rgba(237, 133, 10, 1);
            font-weight: 500;
            border-radius: 0.6rem;
            transition: 0.3s;

            &:hover{
              color: #0C0C0E;
              background-color: rgba(237, 133, 10, 1);
            }
          }
        }
      }

    @media(max-width: 991px){

      .block-header{
        flex-direction: column;

        .title-button{
          text-align: center;
        }
      }

      h2{
        font-size: 3.2rem;
      }
    }

    @media(max-width: 767px){
      h2{
        font-size: 3.2rem;
      }
    }
  }

  .block-list-main-v2 {
    padding: 6rem 0;
    .block-list-main {
      margin: 0;
    }

    .block-header {
      flex-direction: column;
      margin-bottom: 0;

      .title {
        margin-bottom: 1.6rem;
        h2 {
          font-family: Raleway;
          font-size: 3.2rem;
          font-weight: 500;
          line-height: 1.5;
          letter-spacing: 0.02em;
          text-align: center;
          margin-bottom: 0.8rem;
        }

        h4 {
          text-align: center;
          font-size: 1.6rem;
          line-height: 1.5;
          letter-spacing: 0.015em;
          color: #6B7280;
          margin-bottom: 0.8rem;
        }

        & > :last-child {
          margin-bottom: 0;
        }
      }

      .title-button {
        margin-bottom: 1.6rem;
      }
    }

    @media (max-width: 991px) {
      .block-header {
        .title {
          h2 {
            font-size: 2.4rem !important;
            line-height: 1.33;
          }
        }
      }
    }
  }

</style>