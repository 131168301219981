/**
 * Функция для создания или обновления элементов meta, если metaList - false, то можно заменить тока title
 * @param {Object | null} - metaList список мета
 * @param {String} - title список мета
 * @param {String} - description список мета
 */
export function updateMetaTags(metaList, title, description) {
    if(metaList && Object.keys(metaList).length > 0) {
        //те мета что не удаляем
        const allowedKeys = ["og:title", "og:description"];

        const existingMetaTags = document.querySelectorAll('meta');

        existingMetaTags.forEach((metaTag) => {
            if (!allowedKeys.includes(metaTag.getAttribute('property'))) {
                metaTag.remove();
            }
        });
    }


    if(title && metaList && Object.keys(metaList).length > 0) {
        // Вызываем функцию для замены значения
        replacePlaceholder(metaList, '{h1}', title);
        document.title = metaList.title || title;
    } else if(title) {
        metaList = {
            "og:title": title,
            "og:description": description || title
        }
        document.title = title;
    }

    for (const key of Object.keys(metaList)) {
        const existingMeta = document.querySelector(`meta[property="${key}"]`);
        if (existingMeta) {
            existingMeta.setAttribute("content", metaList[key]);
        } else {
            const metaTag = document.createElement("meta");
            metaTag.setAttribute("property", key);
            metaTag.setAttribute("content", metaList[key]);
            document.head.appendChild(metaTag);
        }
    }
}

/**
 * Функция для замены подстроки в значениях объекта по умолчанию {h1}
 * @param Object - обьект meta
 * @param placeholder - что меняем
 * @param replacement - на что
 */
function replacePlaceholder(obj, placeholder, replacement) {
    for (let key in obj) {
        if (obj.hasOwnProperty(key)) {
            obj[key] = obj[key].replace(new RegExp(placeholder, 'g'), replacement);
        }
    }
}
