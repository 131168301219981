<template>
  <container>

    <loader
        v-if="!serviceStages || !data"
        :animation-duration="3000"
        :size="60"
    ></loader>

    <template v-else>

      <template v-if="data">
        <div class="form_status" v-if="data.status">
          <cl-status type="approved" class="status-ico-wrap status-ico-wrap2" v-if="data.status == 'Одобрена'">
            {{ data.status }}
          </cl-status>
          <cl-status type="rejected" class="status-ico-wrap status-ico-wrap2" v-else-if="data.status == 'Отклонена'">
            {{ data.status }}
          </cl-status>
          <cl-status type="new" class="status-ico-wrap status-ico-wrap2" v-else-if="data.status == 'Новая'">
            {{ data.status }}
          </cl-status>
          <cl-status type="consideration" class="status-ico-wrap status-ico-wrap2" v-else-if="data.status == 'В работе'">
            {{ data.status }}
          </cl-status>
          <cl-status type="draft" class="status-ico-wrap status-ico-wrap2" v-else-if="data.status == 'Черновик'">
            {{ data.status }}
          </cl-status>
          <cl-status type="lock" class="status-ico-wrap status-ico-wrap2" v-else-if="data.status == 'Завершена'">
            {{ data.status }}
          </cl-status>
          <cl-status v-else class="status-text">{{ data.status }}</cl-status>
          <span v-if="data.result && data.resultCode" class="result_op">{{data.result}}</span>
        </div>

        <h1 class="form_h1 hasSubtit">{{ data.measureSupport.name }}</h1>
        <div class="form_text fs21">
          <div class="dt1">
            <div><span class="div_tit_grey">№ Заявки:</span> {{ data.code }}</div>
            <div><span class="div_tit_grey">Дата:</span> {{ $filters.ruDate3(new Date(data.created)) }}</div>
          </div>
          <div class="dt2" v-if="data.outerParams && data.outerParams['frontOutput:printed_application_form']?.files[0]?.url">
            <cl-button link :href="data.outerParams['frontOutput:printed_application_form'].files[0].url" target="_blank">Посмотреть заявку</cl-button>
          </div>
        </div>
      </template>

      <row class="column1 column1_pl">
        <column class="dt1" lg="8">
          <div class="requests_container mb-24">
            <row class="row_mb">
              <column v-for="item in requestsData.records" v-if="requestsData">
                <card class="request-action" card-type="transparent">
                  <h4>{{ item.name }}</h4>
                  <div>
                    <MeasureSubRequestFormComponent
                        :measureServiceGuid="data.guid"
                        :subRequestGuid="item.guid"
                        :formParams="formParams"
                    />
                  </div>
                </card>
              </column>
            </row>
          </div>
          <row class="row_mb mb-24">
            <transition-group name="fade-base2">
              <column
                  v-for="serviceStage in serviceStages.fullStages"
                  :key="serviceStage.guid"
              >
                <card card-type="transparent">
                  <h4>{{ serviceStage.name }}</h4>
                  <div class="mb-20">Задачи этапа</div>
                  <template v-for="stage in serviceStage.stages"
                            :key="stage.guid"
                  >
                    <cl-collapse
                        v-if="stage.isActive || stage.isCompeleted"
                        @click="getStageParameters(stage, stage.guid)"
                        class="collapse_point"
                        :ref="`collapse_${stage.guid}`"
                    >
                      <template v-slot:name>
                                            <span
                                                class="div_point"
                                                :class="{'is-active': stage.isActive, 'is-completed': stage.isCompeleted}"
                                            >
                                            </span>
                        <span class="div_tit fs5">{{ stage.name }}</span>
                      </template>
                      <template v-slot:card>
                        <loader
                            v-if="!stage.params"
                            :animation-duration="3000"
                            :size="60"
                        ></loader>
                        <template v-else>
                          <!--                                                <div class="edprog_text">Параметры оценки</div>-->
                          <div class="edprog_info">
                            <template
                                v-if="stage.params.records.length"
                                v-for="param in stage.params.records"
                            >
                              <div v-if="param.file && param.fieldType?.id && param.fieldType.id == '2416'" class="stage-document stage-param">
                                <div class="stage-document_content">
                                  <div class="item p2">
                                    {{ param.name }}
                                  </div>
                                  <div class="signature" v-if="param.signature">
                                    <cl-status type="approved" class="status-ico-wrap">Документ подписан
                                    </cl-status>
                                    <a is-external-resource :href="param.signature.url">Скачать
                                      подпись</a>
                                  </div>
                                </div>
                                <cl-button is-external-resource :href="param.file.url"
                                           :link="true" type="light">
                                  Скачать
                                </cl-button>
                              </div>
                              <div class="stage-param stage-info" v-else-if="param.fieldType?.id && (param.fieldType.id == '2418' || param.fieldType.id == '2403' || param.fieldType.id == '2411' || param.fieldType.id == '2414')">
                                <span
                                    v-if="param.fieldType.id == '2418'"
                                    class="div_point"
                                    :class="{'is-completed': param.value === 'true'}"
                                >
                                </span>
                                {{ param.name }}: {{ getParamValueRu(param.value) }}
                              </div>
                            </template>
                            <template v-else>
                              Параметры отсутствуют
                            </template>
                          </div>
                        </template>
                      </template>
                    </cl-collapse>
                  </template>

                </card>
              </column>
            </transition-group>

            <cl-modal v-model="showReviewModal" :closeFonActive="false">
              <measure-form-component
                  :measureGuid="reviewMeasureId"
                  :showMode="showMode"
                  :formParams="measureFormParams"
              />
            </cl-modal>
          </row>

          <div class="requests_container">
            <row class="row_mb">
              <column v-if="requestsData?.outCode">
                <card card-type="transparent">
                  <h4>{{ requestsData.outCode.name }}</h4>
                  <div>
                    <MeasureSubRequestFormComponent
                        :measureServiceGuid="data.guid"
                        :subRequestGuid="requestsData.outCode.guid"
                        :formParams="formParamsRecall"
                    />
                  </div>
                </card>
              </column>
            </row>
          </div>
        </column>
        <column class="dt2" lg="4">
          <div class="form-sidebar" v-if="serviceStages.shortStages.length">
            <ul class="form-nav-tabs form-nav-tabs-top" role="tablist"
                v-if="widthSite <= 767"
                @click="mobStageShow = !mobStageShow"
                :class="{'active': mobStageShow}"
            >
              <template
                  v-for="(stage, index) in serviceStages.shortStages"
                  :key="stage.guid"
              >
                <li class="nav-item"
                    v-if="stage.isActive || stage.isCompeleted && ((index + 1) == serviceStages.shortStages.length) || (index == 0 && !stage.isActive && !stage.isCompeleted)"
                >
                  <div
                      class="nav-link"
                      :class="{'passed': stage.isCompeleted, 'active': stage.isActive }"
                  >
                                        <span class="radio_input">
                                          {{ stage.index > 10 ? stage.index : stage.index }}
                                        </span>
                    <span>
                                          {{ stage.name }}
                                    <span class="d-block d-md-none op05 sp_num">({{
                                        index + 1
                                      }}/{{ serviceStages.shortStages.length }})</span>
                                        </span>
                  </div>
                </li>
              </template>
            </ul>

            <div class="form-nav-tabs-cont">
              <div class="progress-cont"
                   :class="{'mb0md':  serviceStages.shortStages[serviceStages.shortStages.length - 1].isCompeleted || serviceStages.shortStages[serviceStages.shortStages.length - 1].isActive}">
                <div class="progress-bar">
                  <progress-bar :strokeWidth="12" :progress="progress"/>
                </div>
                <div class="progress-text">
                  <h3 class="progress-textStat">{{ progress }}%</h3>
                  <div class="progress-textLabel">Прогресс рассмотрения заявки</div>
                </div>
              </div>

              <div class="form-nav">
                <ul class="form-nav-tabs" role="tablist">
                  <!--             active класс для выбраного шага
                                   passed класс для заполненого шага
                  -->
                  <li class="nav-item"
                      v-for="(stage, index) in serviceStages.shortStages"
                      :key="stage.guid"
                      :class="{'passed': stage.isCompeleted, 'active': stage.isActive }"
                  >
                    <div
                        class="nav-link cursor-default"
                        :class="{'passed': stage.isCompeleted, 'active': stage.isActive }"
                    >
                                        <span class="radio_input">
                                          {{ stage.index > 10 ? stage.index : stage.index }}
                                        </span>
                      <span>
                                          {{ stage.name }}
                                    <span class="d-block d-md-none op05 sp_num">({{
                                        index + 1
                                      }}/{{ serviceStages.shortStages.length }})</span>
                                        </span>
                    </div>
                  </li>
                </ul>
                <!--                            <div class="step-buttons">-->
                <!--                                <cl-button type="light">Назад</cl-button>-->
                <!--                                <cl-button>Далее</cl-button>-->
                <!--                            </div>-->
              </div>

            </div>

          </div>
        </column>
      </row>
    </template>
  </container>
</template>

<script>
import MeasureFormComponent from "@/components/measureForm";
import MeasureSubRequestFormComponent from "@/components/subrequestForm.vue"
import {mapGetters} from "vuex";
import Column from "@/components/library/Column.vue";

export default {
  name: "MyApplicationsAction",
  components: {Column, MeasureSubRequestFormComponent, MeasureFormComponent},
  data() {
    return {
      data: null,
      requestsData: null,
      serviceStages: null,
      showReviewModal: false,
      reviewMeasureId: null,
      showMode: 'inline',
      formParams: {
        'appendCss': '/override/application.css',
      },
      formParamsRecall: {
        'appendCss': '/override/application_recall.css'
      },
      measureFormParams: {
        'appendCss': '/override/idea_measure.css',
        preSetParams: {
          measureId: this.$route.params.id,
        }
      },
      mobStageShow: false,
      controller: null
    }
  },
  computed: {
    completedStagesCount: function () {
      if (this.serviceStages && this.serviceStages.shortStages.length) {
        return this.serviceStages.shortStages.filter((item) => {
          return item.isCompeleted
        }).length
      }
    },
    progress: function () {
      if (this.serviceStages && this.serviceStages.shortStages) {
        return Math.ceil((this.completedStagesCount / this.serviceStages.shortStages.length) * 100)
      }
    },
    ...mapGetters([
      'widthSite'
    ]),
  },
  methods: {
    getItem(id) {
      this.axios
          .get(`/api/get/measure-services/${id}`, {signal: this.controller.signal})
          .then(response => {
            this.data = response.data;
            this.reviewMeasureId = response.data?.MeasureIdRequestReview;
            this.showReviewModal = response.data.showReview;
          })
          .catch(error => {
            this.showError(error);
          });
    },
    getMeasureRequests(id) {
      this.axios
          .get(`/api/get/measure-services/${id}/measure-service-requests`, {signal: this.controller.signal})
          .then(response => {
            this.requestsData = response.data;
          })
          .catch(error => {
            this.showError(error);
          });
    },
    getServiceStages(id) {
      this.axios
          .get(`/api/get/measure-services/${id}/measure-service-stages`, {signal: this.controller.signal})
          .then(response => {
            this.serviceStages = response.data;
          })
          .catch(error => {
            this.showError(error);
          });
    },
    getStageParameters(item, id) {
      if (!item.hasParameters) {
        this.axios
            .get(`/api/get/measure-service-stages/${id}/measure-service-parameters`, {signal: this.controller.signal})
            .then(response => {
              item.hasParameters = true;
              item.params = response.data;
              // событие для ререндеринга высоты коллпаса
              this.$emit('collapse-content-update');
              this.$nextTick(() => {
                this.$refs[`collapse_${item.guid}`][0].renderHeight();
              })
            })
            .catch(error => {
              this.showError(error);
            });
      }
    },
    getParamValueRu(value) {
      switch (value) {
        case 'false': return "Нет";
        case 'true': return "Да";
        default: return value;
      }
    }
  },
  created() {
    this.controller = new AbortController();
  },
  mounted() {
    const id = this.$route.fullPath.split("/").slice(-1)[0];
    this.getItem(id);
    this.getMeasureRequests(id);
    this.getServiceStages(id);
  },
  beforeUnmount() {
    // отмена запросов
    this.controller.abort();
    this.controller = null;
  }
}
</script>

<style scoped lang="scss">

.form_status {
  margin-bottom: 8px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 1rem;
  .result_op {
    opacity: .5;
  }
}
.form_h1 {
  margin-bottom: 16px;
  text-transform: none;
  line-height: 133.333%;
}
.form_text {
  margin-bottom: 24px;
  display: flex;
  gap: 2rem;
  flex-wrap: wrap;
  .div_tit_grey {
    color: #9096A2;
  }
}


.form-nav-tabs {
  list-style: none;
  margin-top: 0;
  padding-left: 0;
  display: block;
  border-bottom: none;
  position: relative;
  margin-bottom: 30px;
  overflow: hidden;
  line-height: 1.1;

  .nav-item {
    padding-bottom: 30px;
    overflow: hidden;
    margin-bottom: -1px;

    &:last-child {
      padding-bottom: 2px;

      .nav-link .radio_input:after {
        display: none;
      }
    }
  }

  .radio_input {
    background: #fff;
    border: 1px solid rgba(51, 51, 51, .5);
    width: 29px;
    height: 29px;
    display: inline-block;
    border-radius: 50%;
    position: relative;
    margin-right: 14px;
    flex: none;
    color: transparent
  }

  .nav-link {
    padding: 0;
    display: flex;
    opacity: 1;
    color: #000;
    cursor: pointer;
    color: rgba(255, 255, 255, 0.7);
    margin-bottom: 0;
    align-items: flex-start;

    .radio_input {
      border-color: rgba(255, 255, 255, .2);
      border-width: 1px;
      width: 20px;
      height: 20px;
      background: transparent;
      margin-right: 20px;
    }

    .radio_input:after {
      content: "";
      position: absolute;
      top: 100%;
      left: calc(50% - 1px);
      height: 200px;
      width: 1px;
      background: #ffffff;
      opacity: 0.2;
    }

    &.passed,
    {
      .radio_input:after {
        background: #ED850A;
        opacity: 1;
      }

      .radio_input {
        background-color: #ffffff;
        border-width: 5px;
        border-color: #ED850A;
      }

      .radio_input:before {
        display: none;
      }
    }

    &.active {
      color: #ffffff;

      .radio_input {
        border-color: #ED850A;
        background: #ED850A;
      }
    }

  }

  @media (min-width: 768px) and (max-width: 991px) {
    display: flex;
    flex-wrap: wrap;
    .nav-item {
      padding-bottom: 20px;
      width: 20%;
      font-size: 12px;
      line-height: 18px;
      padding-right: 10px;
    }
    .nav-link {
      flex-direction: column;

      .radio_input {
        margin-bottom: 10px;

        &:after {
          top: 50%;
          left: 20px;
          height: 1px;
          width: 200px;
        }
      }
    }

  }
  @media (max-width: 767px) {
    margin-bottom: 20px;
    .nav-item {
      padding-bottom: 20px;

      &:last-child {
        padding-bottom: 2px;
      }

      .sp_num {
        font-size: 14px;
        margin-top: 2px;
      }
    }
  }

}

@media (max-width: 991px) {
  .form-sidebar {
    background: rgba(87, 136, 200, 0.15);
    padding: 30px 20px 10px 20px;
    margin-bottom: 20px;
    border-radius: 7px;
  }
}

.form-nav-tabs-top {
  display: none;
}

@media (max-width: 767px) {
  .form-nav-tabs-top {
    margin-bottom: 15px;
    align-items: center;
    display: flex;

    .nav-link {
      align-items: center;
    }

    &:after {
      content: "";
      flex: none;
      margin: 0 5px 0 20px;
      width: 8px;
      height: 8px;
      border-top: 2px solid #ED850A;
      border-right: 2px solid #ED850A;
      transform: rotate(130deg);
      transition: transform .3s ease;
    }

    &.active {
      &:after {
        transform: rotate(-45deg);
      }

      + .form-nav-tabs-cont {
        display: block;
      }
    }
  }
  .form-nav-tabs-cont {
    border-top: 1px solid rgba(255, 255, 255, .2);
    padding-top: 20px;
    display: none;

    .nav-item {
      display: none;

      &:not(.passed):not(.active),
      &.passed:last-child,
      &.active {
        ~ .nav-item {
          display: block;
        }
      }
    }
  }
}

.step-buttons {
  border-top: 1px solid #ced4da;
  display: flex;
  flex-wrap: wrap;
  border-top: none;
  padding-top: 10px;
  justify-content: flex-start;

  .btn {
    margin-right: 20px;
    width: 100%;
    max-width: 280px;
    margin-bottom: 10px;
  }
}

.request-action {
  border: 3px solid #ED850A;
}

.progress {
  &-cont {
    display: flex;
    margin-bottom: 50px;
    align-items: center;
  }

  &-bar {
    width: 68px;
    margin-right: 20px;
  }

  &-text {
    &Stat {
      margin-bottom: 0;
    }
  }
}

@media (max-width: 991px) {
  .progress-cont {
    margin-bottom: 40px;
  }
}

@media (max-width: 767px) {
  .progress-cont {
    margin-bottom: 30px;
  }
  .progress-bar {
    width: 41px;
    margin-right: 16px;
  }
  .progress-text {
    margin-top: -7px;
  }
}

.stage-param {
  &:not(:last-child) {
    margin-bottom: 20px;
  }
}

.stage-document {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;

  .item {
    display: inline-flex;
    align-items: center;
    margin-bottom: 25px;

    &::before {
      //content: url(../../assets/img/document.svg);
      display: inline-block;
      margin-right: 20px;
      height: 42px;
      filter: invert(1);
    }
  }

  .signature {
    display: flex;
    align-items: center;

    .status {
      margin-right: 20px;
    }

    a {
      color: rgba(255, 255, 255, 0.5);
      transition: .2s linear;

      &:hover {
        color: rgba(255, 255, 255, 1);
      }
    }
  }
}

.stage-info {
  display: flex;
  align-items: center;
}

</style>