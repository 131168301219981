<template>
  <cl-modal v-if="modalAlertCookies?.active" v-model="isShowModalCookies" class="modalInfo modal_with_scrollbar" :class="{'modal_with_scrollbar__black': modalAlertCookies.isBlack}" :close-fon-active="false" :close-none="true">
    <template v-slot:close>
      <svg class="svg-icon modal-close modal_with_scrollbar__custom_close" width="32" height="32" stroke="#ED850A" @click="closeModal">
        <use xlink:href="/svg/icons.svg#closeCircle"></use>
      </svg>
    </template>
    <template v-slot:body>
      <div v-if="modalAlertCookies.title || modalAlertCookies.subTitle" class="div_info">
        <h3 v-if="modalAlertCookies.title" class="div_title">{{modalAlertCookies.title.value}}</h3>
        <div v-if="modalAlertCookies.subTitle" class="div_subtitle" v-html="modalAlertCookies.subTitle.value"></div>
      </div>
      <perfect-scrollbar v-if="modalAlertCookies.text" class="div_content" v-html="modalAlertCookies.text.value" :options="{wheelPropagation: false}"/>
      <div v-if="modalAlertCookies.applyBtnText" class="div_btns">
        <cl-button @click="createActivatedCookies(modalAlertCookies.cookiesTime?.value); closeModal();">
          {{ modalAlertCookies.applyBtnText.value }}
        </cl-button>
      </div>
    </template>
  </cl-modal>
</template>

<script>
import ClModal from "@/components/library/ClModal.vue";
import ClButton from "@/components/library/ClButton.vue";

export default {
  name: "modalCookies",
  components: {ClButton, ClModal},
  data() {
    return {
      /**
       * Состояние, с помощью которого можно открывать/закрывать модалку
       * @type {boolean}
       * @default false
       */
      isShowModalCookies: false,
    }
  },
  props: {
    /**
     * Данные из админки
     * @type Object
     * @default null
     */
    modalAlertCookies: {
      type: Object,
      default: null,
    },
  },
  methods: {
    /**
     * Функция для закрытия модалки.
     */
    closeModal() {
      this.isShowModalCookies = false;
    },
    /**
     * Функция получения всех куки в виде объекта
     * @return {{}}
     */
    getCookie() {
      return document.cookie.split('; ').reduce((acc, item) => {
        const [name, value] = item.split('=')
        acc[name] = value
        return acc
      }, {})
    },
    /**
     * Функция, которая созадет куки, которые подтверждают использование куки
     */
    createActivatedCookies(cookiesTime) {
      const cookiesTimeNum = Number(cookiesTime);
      if(Number.isFinite(cookiesTimeNum) && !Number.isNaN(cookiesTimeNum)) {
        const expires = (new Date(Date.now() + cookiesTimeNum * 1000)).toUTCString();
        document.cookie = `activatedCookies=${true}; expires=${expires}; path=/;`;
      } else {
        const expires = (new Date(Date.now() + 30 * 24 * 60 * 60 * 1000)).toUTCString();
        document.cookie = `activatedCookies=${true}; expires=${expires}; path=/;`;
      }
    },
    /**
     * Функция, которая открывает модалку при инициализации
     */
    openModalInitialize() {
      if(this.modalAlertCookies?.active && !this.getCookie().activatedCookies) {
        this.isShowModalCookies = true;
      }
    }
  },
  watch: {
    /**
     * Отслеживание появления данных для куки из админки
     */
    modalAlertCookies() {
      this.openModalInitialize();
    },
  },
  mounted() {
    this.openModalInitialize();
  }
}
</script>

<style lang="scss" scoped>

</style>