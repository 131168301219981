<template>
  <cl-modal class="modalInfo modal_alert_measure_leave"
            :class="{'modal_alert_measure_leave__black': $root.globalAlertData?.modalAlertMeasureLeave?.isBlackColor, 'modal_alert_measure_leave__btn_reverse': $root.globalAlertData.modalAlertMeasureLeave.buttonsReverse}"
            :modelValue="modelValue"
            @update:modelValue="modelValue = $event"
            :close="modalAlertMeasureLeaveCloseCancel"
  >
    <template v-slot:body>
      <div v-if="$root.globalAlertData.modalAlertMeasureLeave.icon" class="div_img">
        <img :src="getImage($root.globalAlertData.modalAlertMeasureLeave.icon)" alt="">
      </div>
      <div v-if="$root.globalAlertData.modalAlertMeasureLeave.title || $root.globalAlertData.modalAlertMeasureLeave.text" class="div_info">
        <h4 v-if="$root.globalAlertData.modalAlertMeasureLeave.title" class="div_title">
          {{ $root.globalAlertData.modalAlertMeasureLeave.title.value }}
        </h4>
        <div v-if="$root.globalAlertData.modalAlertMeasureLeave.text" class="div_text fs16" v-html="$root.globalAlertData.modalAlertMeasureLeave.text.value"></div>
      </div>
      <div class="div_btns">
        <cl-button v-if="$root.globalAlertData.modalAlertMeasureLeave.buttonStayText" :type="$root.globalAlertData.modalAlertMeasureLeave.buttonStayLightType ? 'light' : null" :img="$root.globalAlertData.modalAlertMeasureLeave.buttonStayIcon ? $root.globalAlertData.modalAlertMeasureLeave.buttonStayIcon.value.src : null" @click="modalAlertMeasureLeaveCloseCancel">
          {{ $root.globalAlertData.modalAlertMeasureLeave.buttonStayText.value }}
        </cl-button>
        <cl-button v-if="$root.globalAlertData.modalAlertMeasureLeave.buttonLeaveText" :type="$root.globalAlertData.modalAlertMeasureLeave.buttonLeaveLightType ? 'light' : null" :img="$root.globalAlertData.modalAlertMeasureLeave.buttonLeaveIcon ? $root.globalAlertData.modalAlertMeasureLeave.buttonLeaveIcon.value.src : null" @click="modalAlertMeasureLeaveCloseApply">
          {{ $root.globalAlertData.modalAlertMeasureLeave.buttonLeaveText.value }}
        </cl-button>
      </div>
    </template>
  </cl-modal>
</template>

<script>
export default {
  name: 'modalAlertMeasureLeave',
  props: {
    modelValue: {
      type: Boolean,
      default: false,
    }
  },
  methods: {
    modalAlertMeasureLeaveCloseCancel() {
      this.$emit('modalAlertMeasureLeaveCloseCancel', true);
    },
    modalAlertMeasureLeaveCloseApply() {
      this.$emit('modalAlertMeasureLeaveCloseApply', true);
    }
  }
};
</script>

<style lang="scss" scoped>
</style>