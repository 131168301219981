<template>
  <div class="sub_block_black_wrapper wrapper-mtb-40">
    <div class="sub_block_black">
      <container>
        <div class="gray_fon_block">
          <div class="block_cont">
            <h2 v-if="dataInfo.dataBlock.titleBlock">{{ dataInfo.dataBlock.titleBlock.value }}</h2>
            <div class="desc" v-if="dataInfo.dataBlock.descBlock">
              {{ dataInfo.dataBlock.descBlock.value }}
            </div>
          </div>
          <swiper
            class="slide100h giga-main-slider progress-bar-line"
            :modules="modules"
            :spaceBetween="24"
            :slides-per-view="1"
            :loop="true"
            :lazy="true"
            :pagination="{ el: '.swiper-pagination', type: 'progressbar' }"
            :navigation="{ prevEl: '.swiper-button-prev1', nextEl: '.swiper-button-next1' }"
          >
          <swiper-slide v-for="(galItem, index) in dataInfo.dataSlider" :key="index">
            <div class="content-cinema">
              <div class="dt dt1">
                  <div class="content_text">
                    <div class="fs3 color-or"> {{ galItem.sliderTitle.value }}</div>
                    <div class="text" v-html="galItem.sliderDesc.value"></div>
                  </div>
                  <div class="div-price-hold" v-if="galItem.priceList">
                    <template v-for="elem in galItem.priceList">
                      <div class="div_card_price">
                        <div class="title ">{{ elem.cardPrice.value }}</div>
                        <div class="desc">{{ elem.cardDesc.value }}</div>
                      </div>
                    </template>
                  </div>
                  <div class="link_btn" v-if="galItem.sliderBtnSrc">
                    <a class="btn btn-light" :href="galItem.sliderBtnSrc.value" target="_blank">
                      {{ galItem.sliderBtn.value }}
                    </a>
                  </div>
              </div>
              <div class="dt dt2">
                <swiper
                    class="slide100h main-slider arrow_center"
                    :modules="modules"
                    :spaceBetween="24"
                    :slides-per-view="1"
                    :initial-slide="initialSlide"
                    :loop="true"
                    :lazy="true"
                    :thumbs="{ swiper: thumbsSwiper }"
                    :navigation="{ prevEl: '.swiper-button-prev2', nextEl: '.swiper-button-next2' }"
                >
                  <swiper-slide v-for="galItem2 in galItem.dataImages"
                                :key="galItem2">
                    <img :src="typeof galItem2.imagesList == 'string' ? galItem2.imagesList : getImage(galItem2.imagesList, 'reference')" loading="lazy" alt="">
                  </swiper-slide>
                  <div class="swiper-button-prev swiper-button-prev2"></div>
                  <div class="swiper-button-next swiper-button-next2"></div>
                </swiper>

              </div>
            </div>
          </swiper-slide>
          <div class="swiper-button-prev swiper-button-prev1"></div>
          <div class="swiper-button-next swiper-button-next1"></div>
          <div class="swiper-pagination">
            <span class="swiper-pagination-progressbar-fill" style="transform: translate3d(0px, 0px, 0px) scaleX(0.2) scaleY(1); transition-duration: 300ms;"></span>
          </div>
        </swiper>
        </div>
      </container>
    </div>
  </div>
</template>

<script>
import Container from "@/components/library/Container";
import {Swiper, SwiperSlide} from "swiper/vue";
import {Navigation, Thumbs, Pagination} from "swiper";

export default {
  name: "afishaMoskinoZal",
  components: {
    Container,
    Swiper,
    SwiperSlide,
  },
  data() {
    return {
      thumbsSwiper: null,
    }
  },
  props: {
    dataInfo: {
      type: Object,
      require: true
    },
    modelValue: {
      type: Boolean,
      default: false
    },
    dataGallery: {
      type: Array,
      require: true
    },
    initialSlide: {
      type: Number,
      default: 0,
    },
    thumbsGal: {
      type: Boolean,
      default: true
    },
  },
  methods: {
    setThumbsSwiper(swiper) {
      this.thumbsSwiper = swiper;
    },
  },
  setup() {
    return {
      modules: [Navigation, Thumbs, Pagination],
    }
  },
}
</script>

<style lang="scss" scoped>
.main-slider{
  height: 33.8rem;
  margin-bottom: 1.2rem;
}
.arrow_center .swiper-button-prev{
  top: 0;
  position: absolute;
  transform: unset !important;
}
.arrow_center .swiper-button-next {
  top: 0;
  position: absolute;
  transform: unset !important;
}
.arrow_center .swiper-button-prev2 {
  &:before {
    content:"";
    height: 1.6rem;
    width: 1.6rem;
    background: url(@/assets/img/ico/chevron-left-white.svg) no-repeat center center;
    background-size: contain;
  }
}

.arrow_center .swiper-button-next2 {
  &:before {
    content:"";
    height: 1.6rem;
    width: 1.6rem;
    background: url(@/assets/img/ico/chevron-right-white.svg) no-repeat center center;
    background-size: contain;
  }
}
.swiper-slide {
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}
.sliderThumbsThumbs {
  margin-top: auto;
}
.div_thumbs {
  height: 11.8rem;
  width: 15rem;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
@media (max-width: 767px) {
  .sliderThumbsThumbs {
    width: 100vw;
    margin-left: -4vw;
  }
  .div_thumbs {
    height: 9.4rem;
    width: 12rem;
  }
}
.arrow_center {
  padding: 0;
  margin: 0;
  :deep(.swiper-button-next) {
      &::before{
        filter: unset;
      }
    margin-right: 0;
    transform: translate(0, -50%);
    border-radius: 0 1.6rem 1.6rem 0;
      background: rgba(255, 255, 255, 0.1);
      border: rgba(255, 255, 255, 0.1);
    height: 100%;
  }
  :deep(.swiper-button-prev) {
      &::before{
        filter: unset;
      }
    margin-left: 0;
    transform: translate(0, -50%);
    border-radius: 1.6rem 0 0 1.6rem;
      background: rgba(255, 255, 255, 0.1);
      border: rgba(255, 255, 255, 0.1);
    height: 100%;
  }
}
.content-cinema{
  display: flex;
  gap: 4rem;
  width: 100%;
  .dt1{
    max-width: 48rem;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 2.4rem;
    .btn{
      width: 100%;
    }
    .content_text{
      display: flex;
      flex-direction: column;
        .tit{
          font-size: 3.2rem;
          line-height: 150%;
          margin-bottom: 2.4rem;
          @media(max-width: 991px){
            font-size: 2.4rem;
          }
        }

        .text :deep(ul) li {
          padding-left: 3.2rem;

          &:before {
            width: 1.5rem;
            height: 0.2rem;
          }
        }
    }
  }
  .dt2{
    width: calc(100% - 48rem - 4rem);
    .swiper {
      height: 100%;
      margin-bottom: 0;
      .swiper-slide {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        img {
          object-fit: cover;
          width: 100%;
          height: 100%;
        }
      }
    }
  }
}

.block_cont{
  margin-bottom: 3.2rem;
  h2{
    margin-bottom: .8rem;
  }
  .desc{
    font-size: 1.8rem;
  }
}

.div-price-hold{
  display: flex;
  gap: 1.2rem;
  margin-top: auto;
  .div_card_price{
    border: 0.1rem solid rgba(46, 49, 56, 1);
    background: #17191C;
    padding: 1.8rem 1.6rem;
    border-radius: 1.6rem;
    width: 50%;
    .title{
      color: #ED850A;
      font-weight: 600;
      font-size: 2.4rem;
    }
    .title,.desc{
      line-height: 150%;
    }
  }
}


@media(max-width: 991px){
  .container.gray_fon_block {
    padding: 6rem 1.6rem;
  }
  .content-cinema{
    flex-direction: column-reverse;
    gap: 2.4rem;

    .dt1{
      max-width: unset;
      width: 100%;
    }

    .dt2 {
      width: auto;
    }
  }
}

@media(max-width: 767px){
  .main-slider{
    height: 23rem;
  }
  .arrow_center .swiper-button-next, .arrow_center .swiper-button-prev {
    display: none !important;
  }
  .swiper-thumbs{
    display: none;
  }
}

.sliderThumbsThumbs .swiper-slide {
  height: 11.8rem;
}
.main-slider.arrow_center{
  margin-bottom: 2.4rem;
}
.main-slider .swiper-slide {
  border-radius: 1.8rem;
  img{
    height: 100%;
    width: 100%;
  }
}
</style>

<style lang="scss">
.content_text .text > p {
  margin-bottom: 0rem !important;
  line-height: 150%;
  font-size: 1.6rem !important;
}
ul:not([class]) li:not(:last-child) {
  margin-bottom: 0 !important;
  font-size: 1.6rem;
}
</style>