<template>
  <subscribe-main-page v-if='blockComponent == "subscribeMainPage"' :dataInfo="dataInfo" :id="id"/>
  <block-list-main v-if='blockComponent == "blockListMain"' :dataInfo="dataInfo" :id="id"/>
  <block-cards-people v-if='blockComponent == "blockCardsPeople"' :dataInfo="dataInfo" :id="id"/>
  <block-info-icons-list v-if='blockComponent == "blockInfoIconsList"' :dataInfo="dataInfo" :id="id"/>
  <movie-galleries-progress v-if='blockComponent == "movieGalleriesProgress"' :dataInfo="dataInfo" :id="id"/>

</template>

<script>

import SubscribeMainPage from "@/components/subscribeMainPage.vue";
import BlockListMain from "@/components/blockListMain.vue";
import BlockCardsPeople from "@/components/blockCardsPeople.vue";
import BlockInfoIconsList from "@/components/blockInfoIconsList.vue";
import MovieGalleriesProgress from "@/components/movieGalleriesProgress.vue";

export default {
  name: "templateDynamic6",
  components: {MovieGalleriesProgress, BlockInfoIconsList, BlockCardsPeople, BlockListMain, SubscribeMainPage},
  props: {
    dataInfo: {
      type: Object,
      require: true,
    },
    id: {
      type: String,
    },
    blockComponent: {
      type: String,
    }
  },
}
</script>

<style lang="scss" scoped>

</style>