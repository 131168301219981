<template>
  <container>
    <div v-if="dataInfo && selectedSideInfo" class="dop_info_img" :class="{'dop_info_img_right': selectedSideInfo.place}">
      <div class="dt1">
        <div ref="perfectScrollbar" class="dop_info_img_card" :class="{'dop_info_img_card_backRotated': selectedSideInfo.place}" :style="selectedSideInfo.backgroundImg ? getBkgrImage(selectedSideInfo.backgroundImg) : ''">
          <div ref="perfectScrollbarContent" class="div_cont">
            <div class="div_info">
              <h2 v-if="selectedSideInfo.title" class="div_title">{{selectedSideInfo.title.value}}</h2>
              <div v-if="selectedSideInfo.text" :class="{'div_text_vert_center': !(selectedSideInfo.textList && selectedSideInfo.textList.length > 0)}">{{selectedSideInfo.text.value}}</div>
              <ul v-if="selectedSideInfo.textList && selectedSideInfo.textList.length > 0" class="div_ul">
                <li v-for="item of selectedSideInfo.textList">{{item.text ? item.text.value : null}}</li>
              </ul>
            </div>
            <div v-if="dataInfo.cards && dataInfo.cards.length > 0" class="div_btns">
              <div v-for="(dataCard, index) of dataInfo.cards" class="div_btn">
                <cl-button @click="changeSelectedCard(index)" v-if="dataCard.buttonText" class="btn-ico-right" :class="{'img_wh div_custom_btn_or': selectedSideInfoNum === index, 'img_or div_custom_btn_wh': selectedSideInfoNum !== index}" img="/img/ico/move-up-right.svg">
                  {{dataCard.buttonText.value}}
                </cl-button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="dt2">
        <a :href="selectedSideInfo.link ? selectedSideInfo.link.value : null" class="div_img"
           @mouseover="isHovering = true"
           @mouseout="isHovering = false"
        >
          <div class="div_img__background" :style="selectedSideInfo.image ? getBkgrImage(selectedSideInfo.image, 'hd') : ''">
            <div class="div_img_font">
              <div v-if="selectedSideInfo.link" class="dop_info_img_link">
                <div class="div_text">
                  Подробнее
                </div>
                <img :class="{'img_or': !isHovering, 'img_wh': isHovering}" src="/img/ico/move-up-right.svg" alt="">
              </div>
            </div>
          </div>
        </a>
      </div>
    </div>
  </container>
</template>
<script>

import Column from "@/components/library/Column.vue";
import ClButton from "@/components/library/ClButton.vue";
import PerfectScrollbar from "perfect-scrollbar";

export default {
  name: "sideInfoWithImgToggle",
  components: {ClButton, Column},
  props: {
    dataInfo: {
      type: Object,
      require: true
    },
  },
  data() {
    return {
      selectedSideInfoNum: 0,
      isHovering: false,
      /**
       * Perfect Scrollbar
       * @type {PerfectScrollbar | null}
       * @default null
       */
      ps: null,
      /**
       * Resize Observer
       * @type {ResizeObserver | null}
       * @default null
       */
      resizeObserver: null,
    }
  },
  methods: {
    changeSelectedCard(index) {
      this.selectedSideInfoNum = index;
    },
    /**
     * Функция добавления perfect scrollbar
     */
    addPerfectScrollbar() {
      if(this.$refs.perfectScrollbar && this.$refs.perfectScrollbarContent) {
        this.ps = new PerfectScrollbar(this.$refs.perfectScrollbar);
        this.resizeObserver = new ResizeObserver(() => setTimeout(() => {
          this.ps.update();
        }, 15));
        this.resizeObserver.observe(this.$refs.perfectScrollbarContent);
      }
    }
  },
  computed: {
    selectedSideInfo(){
      return (this.dataInfo.cards && this.dataInfo.cards.length > 0) ? this.dataInfo.cards[this.selectedSideInfoNum] : null;
    }
  },
  mounted() {
    this.addPerfectScrollbar();
  },
  beforeUnmount() {
    if(this.resizeObserver) this.resizeObserver.disconnect();
  }
}
</script>

<style lang="scss" scoped>

.dop_info_img {
  padding-top: 6rem;
  padding-bottom: 6rem;
  display: flex;
  gap: 2.4rem;

  .btn{
    border-radius: 16px;
  }

  .dt1, .dt2 {
    min-height: 36rem;
    max-height: 50rem;
  }

  .dt1 {
    flex: 1;
  }

  .dt2 {
    width: 57.5%;
  }

  .div_img {
    display: block;
    width: 100%;
    height: 100%;
    overflow: hidden;
    position: relative;
    border: 0.1rem solid rgba(237, 133, 10, 1);
    border-radius: 2.4rem;

    &__background {
      width: 100%;
      height: 100%;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center center;
    }

    :hover {
      .div_img_font {
        background: linear-gradient(117.36deg, rgba(0, 0, 0, 0) 0%, rgba(237, 133, 10, 0.4) 75.81%);
      }

      .div_text {
        color: white;
      }
    }
  }

  .div_img_font {
    width: 100%;
    height: 100%;
  }

  @media (max-width: 991px) {
    flex-direction: column;

    .dt1, .dt2 {
      min-height: auto;
      max-height: none;
    }

    .dt2 {
      width: auto;
    }
  }

  @media (max-width: 1439px){
    .dt2 {
      width: 50%;
    }
  }
  @media (max-width: 991px) {
    flex-direction: column;

    .dt1{
      min-height: unset;
    }
    .dt2 {
      width: auto;
      max-height: 400px;
      height: 400px;
    }
  }
}

.dop_info_img_right {
  flex-direction: row-reverse;

  @media (max-width: 991px) {
    flex-direction: column-reverse;
  }
}

.dop_info_img_card {
  height: 100%;
  border: 0.1rem solid rgba(63, 63, 66, 1);
  border-radius: 2.4rem;
  padding: 3.2rem;
  background: url(@/assets/img/dopInfoImgCardBackgroud.jpg) no-repeat center center;
  background-size: cover;

  :deep(.ps__rail-y) {
    right: 0 !important;
  }

  .div_cont {
    display: flex;
    flex-direction: column;
    gap: 2.4rem;
  }

  .div_info{
    flex: 1;
    display: flex;
    flex-direction: column;
  }

  .div_btns {
    display: flex;
    flex-direction: column;
    gap: 0.8rem;

    :deep(.btn) {
      .sp_img {
        max-width: 2.4rem;
        max-height: 2.4rem;
      }
    }
  }

  .div_title {
    font-family: Playfair Display;
    font-weight: 500;
    letter-spacing: 0.02em;
    margin-bottom: 0.8rem;
    font-size: 4rem;
    line-height: 120%;
    text-transform: unset;
  }

  .div_text_vert_center {
    margin: auto 0;
  }

  .div_ul {
    li {
      padding-left: 2.7rem;
      position: relative;
    }

    li:before {
      display: inline-block;
      content: "";
      position: absolute;
      top: 0.55em;
      left: 1.1rem;
      width: 0.4rem;
      height: 0.4rem;
      background-color: white;
      border-radius: 50%;
    }
  }

  .div_custom_btn_or, .div_custom_btn_wh, .div_custom_btn_gr {
    justify-content: space-between;
    width: 100%;
  }

  .div_custom_btn_wh {
    color: black;
    background: white;
    border: none;
  }

  .div_custom_btn_gr {
    color: white;
    background: rgba(63, 63, 66, 1);
    border: none;
  }

  @media (max-width: 991px) {
    .div_title {
      font-size: 2.4rem;
      margin-bottom: 1.6rem;
      font-weight: 500;
    }
  }
}

.dop_info_img_card_backRotated {
  background: url(@/assets/img/dopInfoImgCardBackgroudRotated.jpg) no-repeat center center;
  background-size: cover;
}

.dop_info_img_link {
  display: flex;
  gap: 1.6rem;
  align-items: center;
  position: absolute;
  bottom: 3.2rem;
  right: 3.2rem;

  .div_text {
    color: rgba(237, 133, 10, 1)
  }
}

</style>