<template>
    <div class="testForm">
        <container class="container4">
            <h1 class="fs3 measure_h1" v-if="formTitle">
              <img src="../assets/img/ico/application_measure_ico.svg" alt="">
              <span>{{formTitle}}</span>
            </h1>
            <measure-form-component
                v-if="guid && formShow"
                :measureGuid="guid"
                :showMode="showMode"
                :formParams="formParams"
                @formTitle="formTitleFun"
                buttonText="Перейти на главную"
                buttonLink="/"
                :reload="false"

                v-bind="modalAlertMeasureLeaveRouteAttr()"
                v-on="modalAlertMeasureLeaveRouteEmits()"
                />
        </container>
    </div>
</template>

<script>
import MeasureFormComponent from "@/components/measureForm.vue";
import {mapGetters} from "vuex";
import mixinModalAlertMeasureLeaveRoute from "@/mixin/mixinModalAlertMeasureLeaveRoute";

export default {
	  components: {MeasureFormComponent},
    mixins: [mixinModalAlertMeasureLeaveRoute],
    data(){
        return{
            showMode: "inline",
            formParams: {
              'appendCss': '/override/location_not_platform.css',
              preSetParams: {},
            },
            guid: null,
            formTitle: null,
            formShow: false,
            controller: null,
        }
    },
    mounted() {
        if(this.getIsAuthorization){
          this.showMode = 'full';
        }
        this.getUserPosition();
        this.guid = this.$route.fullPath.split("/").at(-1);
        this.formShow = true;

        this.$nextTick(() => {
          helperApp.addBreadcrumbsLink('Форма заявки');
        });
    },
    computed: {
      ...mapGetters([
        'getIsAuthorization',
        'getUser',
      ])
    },
    methods: {
        formTitleFun(name) {
            this.formTitle = name;
            document.title = name + " - Московская КиноПлатформа"
        },
        // получение должности и передача в форму
        getUserPosition(){
          if(this.getUser){
            return this.axios
                .get(`/api/get/trustee/persons/${this.getUser.iasGuid}`, {signal: this.controller.signal})
                .then(response => {
                  if(response.data.position){
                    this.formShow = false;
                    this.formParams.preSetParams.position = response.data.position;
                    this.$nextTick(() => {
                      this.formShow = true;
                    })

                  }

                })
                .catch(error => {
                  this.showError(error);
                })
                .finally(() => {
                });
          }
        }
    },
    watch: {
      getUser(){
        this.getUserPosition();
      }
    },
    created() {
      this.controller = new AbortController();
    },
    beforeUnmount() {
      this.controller.abort();
      this.controller = null;
    },
};
</script>

<style lang="scss" scoped>

.testForm{
    padding: 150px 0;
    h1 {
       margin-bottom: 2.4rem;
    }
    .bottom-buttons{
      display: flex;
      gap: 1.2rem;
      margin-bottom: 1.2rem;
    }
}

.measure_h1{
  padding: 1.6rem;
  background: #0F1015;
  display: flex;
  align-items: center;
  gap: .8rem;
}

</style>