<template>
  <div v-if="dataInfo && Object.keys(dataInfo).length !== 0" class="work_structure">
    <container>
      <h2 class="div_title" v-if="dataInfo.title" >{{dataInfo.title.value}}</h2>
      <div class="div_subTitle" v-if="dataInfo.subTitle" v-html="dataInfo.subTitle.value"></div>
      <row v-if="dataInfo.cards && dataInfo.cards.length !== 0">
        <column md="3"
                v-for="(item, index) in dataInfo.cards"
                :key="item.text?.value"
        >
          <div class="block_number_text">
            <div class="dt dt1 div_num">{{ index + 1 }}</div>
            <div class="dt dt2 div_text" v-if="item.text" v-html="item.text.value"></div>
          </div>
        </column>
      </row>
    </container>
  </div>
</template>

<script>
import Container from "@/components/library/Container.vue";

export default {
  name: "workStructureList",
  components: {Container},
  /**
   * Данные из админки
   */
  props: {
    dataInfo: {
      type: Object,
      require: true
    },
  },
}
</script>

<style lang="scss" scoped>
.work_structure {
  padding: 6rem 0;
  .div_title {
    font-size: 3.2rem;
    font-weight: 500;
    line-height: 1.5;
    letter-spacing: 0.02em;
    text-align: center;
    margin-bottom: 0.8rem;
  }

  .div_subTitle {
    text-align: center;
    margin-bottom: 1.6rem;
    font-size: 1.6rem;
    line-height: 1.5;
    letter-spacing: 0.015em;
    color: #6B7280;
  }

  @media(max-width: 991px) {
    .div_title {
      font-size: 2.4rem !important;
      line-height: 1.33;
    }

    .row {
      margin-left: -0.8rem;
      margin-right: -0.8rem;
      gap: 1.6rem 0;
    }

    .row > div {
      padding: 0 0.8rem;
    }
  }
}

.block_number_text{
  background: transparent;
  border: 1px solid #ED850A;
  border-radius: 1.2rem;
  flex-direction: column;
  align-items: start;
  gap: 2.4rem;
  padding: 2.4rem;

  .dt1{
    width: 6.4rem;
    height: 6.4rem;
    border-radius: 50%;
    border: 2px solid #17191C;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .div_num {
    font-size: 4.8rem;
    font-weight: 500;
    line-height: 1.33;
    letter-spacing: 0.02em;
    text-align: center;
  }

  .div_text {
    font-weight: 500;
    letter-spacing: 0.02em;
  }

  @media (max-width: 1439px) {
    padding: 1.6rem;
  }

  @media (max-width: 991px) {
    padding: 1.2rem;

    .dt1 {
      width: 4.8rem;
      height: 4.8rem;
    }

    .div_num {
      font-size: 3.2rem;
      line-height: 1.5;
    }

    .div_text {
      font-size: 1.4rem;
      line-height: 1.28;
    }
  }

  @media (max-width: 767px) {
    padding: 1.2rem 1.2rem 3rem;
    .div_text {
      font-size: 1.6rem;
      line-height: 1.125;
    }
  }
}
</style>