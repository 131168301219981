<template>
  <div class="sub_block_black_wrapper wrapper-mtb-40">
    <div class="sub_block_black">
      <container>
        <div class="content-cinema" v-if="dataInfo">
            <div class="dt dt1">
              <template v-if=" dataInfo.dataBlock.btnText && dataInfo.dataBlock.btnLink">
                <div class="content_text">
                  <div class="div_tit">{{dataInfo.dataBlock.titleBlock.value }}</div>
                  <div class="div_text" v-html="dataInfo.dataBlock.descBlock.value"></div>
                </div>
                <div class="link_btn">
                  <a class="btn btn-light" :href="dataInfo.dataBlock.btnLink.value" target="_blank">
                    {{ dataInfo.dataBlock.btnText.value }}
                  </a>
                </div>
              </template>
            </div>
            <div class="dt dt2">
              <swiper
                  ref="mySwiperRent"
                  class="slide100h main-slider arrow_center"
                  :modules="modules"
                  :spaceBetween="24"
                  :initial-slide="initialSlide"
                  :navigation=" dataInfo.imagesCard.length > 1 ? true : false"
                  :loop="false"
                  :lazy="true"
                  :thumbs="{ swiper: thumbsSwiper }"
                  @activeIndexChange="updateActiveIndex"
              >
                <swiper-slide v-for="galItem in dataInfo.imagesCard" :key="galItem">
                  <img :src="typeof galItem.image == 'string' ? galItem.image : getImage(galItem.image, 'reference')" loading="lazy" alt="">
                </swiper-slide>
              </swiper>
              <swiper
                  ref="thumbsSwiper"
                  @swiper="setThumbsSwiper"
                  v-if="dataInfo.imagesCard.length > 1"
                  :slidesPerView="'4'"
                  :watchSlidesProgress="true"
                  :threshold='10'
                  :modules="modules"
                  class="sliderThumbsThumbs sub_swiber"
                  :class="{'swiper_left': dataInfo.imagesCard.length > 4}"
                  :initialSlide="activeIndex"
                  @slideChange="updateMainSwiper"
              >
                <swiper-slide v-for="(galItem, index) in dataInfo.imagesCard" :key="galItem" :class="{ slide_new: index === realIndex }">
                  <div class="div_thumbs">
                    <img :src="typeof galItem.image == 'string' ? galItem.image : getImage(galItem.image, 'reference')" loading="lazy" alt="">
                  </div>
                </swiper-slide>
              </swiper>
            </div>
          </div>
      </container>
    </div>
  </div>
</template>

<script>
import Container from "@/components/library/Container";
import {Swiper, SwiperSlide} from "swiper/vue";
import {FreeMode, Navigation, Thumbs} from "swiper";

export default {
  name: "afishaMoskinoZal",
  components: {
    Container,
    Swiper,
    SwiperSlide,
  },
  data() {
    return {
      thumbsSwiper: null,
      activeIndex: 0,
      realIndex: 0,
    }
  },
  props: {
    dataInfo: {
      type: Object,
      require: true
    },
    modelValue: {
      type: Boolean,
      default: false
    },
    dataGallery: {
      type: Array,
      require: true
    },
    initialSlide: {
      type: Number,
      default: 0,
    },
    thumbsGal: {
      type: Boolean,
      default: true
    },
  },
  methods: {
    updateActiveIndex(swiperInstance) {
      this.realIndex = swiperInstance.realIndex;
      this.activeIndex = swiperInstance.activeIndex;
    },
    updateMainSwiper(swiperInstance) {
      if (this.$refs.swiper1 && this.$refs.swiper1.swiper) {
        this.$refs.swiper1.swiper.slideTo(swiperInstance.realIndex);
      }
    },
    setThumbsSwiper(swiperInstance) {
      this.thumbsSwiper = swiperInstance;
    },
  },
  setup() {
    return {
      modules: [FreeMode, Navigation, Thumbs],
    }
  },
  mounted() {
    // if (this.$refs.thumbsSwiper) {
    //   this.thumbsSwiper = this.$refs.thumbsSwiper.swiper;
    // }
  },
}
</script>

<style lang="scss" scoped>

.content-cinema {
  display: flex;
  gap: 4rem;

  .main-slider{
    height: 38.6rem;
    margin-bottom: 1.2rem;

    :deep(.swiper-button-next), :deep(.swiper-button-prev) {
      transition: all .4s ease;

      &:hover {
        background: rgba(0, 1, 6, 0.1);
        border: rgba(0, 1, 6, 0.1);
        backdrop-filter: blur(2px);
      }
    }
  }

  .swiper-slide {
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
  }

  .sliderThumbsThumbs {
    margin-top: auto;
  }

  .div_thumbs {
    height: 11.8rem;
    width: 15rem;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .arrow_center {
    padding: 0;
    margin: 0;

    :deep(.swiper-button-next) {
      &::before{
        filter: unset;
      }

      margin-right: 0;
      transform: translate(0, -50%);
      border-radius: 0 1.6rem 1.6rem 0;
      background: none;
      border: none;
      backdrop-filter: none;
      height: 100%;
    }
    :deep(.swiper-button-prev) {
      &::before{
        filter: unset;
      }

      margin-left: 0;
      transform: translate(0, -50%);
      border-radius: 1.6rem 0 0 1.6rem;
      background: none;
      border: none;
      backdrop-filter: none;
      height: 100%;
    }
  }

  .dt1{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 2.4rem;

    .btn{
      width: 100%;
    }
  }

  .dt2{
    max-width: 68.5rem;
    width: 100%;
    padding-top: 2.4rem;
  }

  .swiper{
    .swiper-slide{
      display: flex;
      align-items: center;
      justify-content: center;

      img{
        object-fit: cover;
        width: 100%;
        aspect-ratio: 16 / 9;
      }
    }
  }

  .content_text{
    display: flex;
    flex-direction: column;

    .div_tit{
      font-size: 3.2rem;
      line-height: 150%;
      margin-bottom: 2.4rem;
    }

    .div_text {
      font-size: 1.6rem;
      :deep(p) {
        margin-bottom: 1.6rem;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }

  .sliderThumbsThumbs .swiper-slide {
    height: 11.8rem;
  }

  .sliderThumbsThumbs{
    margin-top: 2.4rem;
  }

  .main-slider .swiper-slide {
    border-radius: 1.8rem;

    img{
      width: 100%;
    }
  }

  .sliderThumbs {
    &Thumbs {

      .swiper-slide {

        &.swiper-slide-thumb-active img {
          border: none;
        }

        &.slide_new img {
          border: 0.2rem solid #ED850A;
        }
      }
    }
  }

  @media (max-width: 991px) {
    flex-direction: column-reverse;
    gap: 2.4rem;

    .dt1, .dt2 {
      width: 100%;
      max-width: unset;
    }

    .content_text {
      .div_tit {
        font-size: 2.4rem;
        margin-bottom: 1.6rem;
      }
    }

  }

  @media (max-width: 767px) {
    .sliderThumbsThumbs {
      width: 100vw;
      margin-left: -4vw;
    }

    .div_thumbs {
      height: 9.4rem;
      width: 12rem;
    }

    .main-slider{
      height: 23rem;
    }

    .arrow_center .swiper-button-next, .arrow_center .swiper-button-prev {
      display: none !important;
    }

    .swiper-thumbs{
      display: none;
    }

    .dt2 .swiper .swiper-slide img{
      height: 100%;
    }
  }
}
</style>

<style lang="scss">
  .swiper.sub_swiber{
    .swiper-wrapper{
      justify-content: center;
      gap: 1.2rem;
    }
    &.swiper_left{
      .swiper-wrapper {
        justify-content: unset;
      }
    }
  }
</style>