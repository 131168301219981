<template>
  <container>

    <div class="sub-card">
      <div class="text">
          <h3>
            {{dataInfo.title.value}}
          </h3>
        <h4>
          {{dataInfo.subtitle.value}}
        </h4>
      </div>
      <form action="" class="subscribe_form">
        <div class="dt dt2">
          <measure-form-component
              :measureGuid="dataGuid ? dataGuid : dataInfo?.form.value"
              :showMode="showMode"
              :formParams="formParams"
              :formType="formType"
              :successText="successText"
              class="loader_ab emailForm"
          />

          <!--          <base-input-->
          <!--              class="input_line"-->
          <!--              :placeholder="'Ваш e-mail'"-->
          <!--          />-->
        </div>
        <!--        <div class="dt dt3">-->
        <!--          <cl-button>Отправить</cl-button>-->
        <!--        </div>-->
      </form>
    </div>
  </container>
</template>

<script>

import MeasureFormComponent from "@/components/measureForm.vue";

export default {
  name: "subscribeMainPage",
  components: {MeasureFormComponent},
  props: {
    dataInfo: {
      type: Object,
      require: true
    },
  },
  data() {
    return {
      showMode: 'inline',
      formParams: {
        'appendCss': '/override/flex_input_btn2.css',
      },
      formType: "1",
      successText: "Спасибо! Будем держать вас в курсе событий!"
    }
  },
}
</script>

<style lang="scss" scoped>
.sub-card{
  position: relative;
  padding: 3.2rem;
  border-radius: 2.4rem;
  background-image: url("../assets/img/main-sub-back.png");
  background-repeat: no-repeat;
  background-size: cover;

  .text{
    margin-bottom: 1.6rem;

    h3{
      margin-bottom: 0;
    }

    .subtitle{
      font-size: 24px;
    }
  }

  @media (max-width: 767px) {
    &:before{
      background-position-y: center;

    }
  }
}


</style>