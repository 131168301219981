import { notify } from "@kyvg/vue3-notification";

export default {
    methods: {
        showWarning(event, text = 'Извините, что-то пошло не так', title = 'Предупреждение') {
            notify({
                type: 'warn',
                title: title,
                text: text,
                speed: 500,
                duration: 5000,
            });
        },
        showError(event, text = 'Извините, что-то пошло не так', title = 'Ошибка', classBlock = "default") {
            if (event?.code !== 'ERR_CANCELED') {
                notify({
                    type: 'error ' + classBlock,
                    title: `${title} ${event?.response?.status ?? ''}`,
                    // при налчии errorMessage в ответе с ошибкой выводится переданный с бэкенда текст
                    text: `${event?.response?.data.errorMessage ? event.response.data.errorMessage : text}`,
                    speed: 500,
                    duration: 5000,
                });
            } else {
                console.log('Запрос был отменен')
            }
        },
        showInfo(event, text = 'Спасибо за внимание', title = 'Внимание', classBlock = "default", speed= 500, duration = 5000, data = {}, id = null) {
            notify({
                id: id,
                type: 'info ' + classBlock,
                title: title,
                text: text,
                speed: speed,
                duration: duration,
                data: data,
            });
        },
        showSuccess(text = 'Все изменения внесены', title = 'Успешно', event) {
            notify({
                type: 'success',
                title: title,
                text: text,
                speed: 500,
                duration: 5000,
            });
        },
    }
}