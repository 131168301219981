<template>
  <div class="block_wrap big_first_block no_main_block" :style="[ getBkgrImage([dataInfo.image, dataInfo.image768, dataInfo.image375], 'fullHd') ]">

    <container>
      <h1 class="h1_big h1_banner" v-if="dataInfo.title" v-html="dataInfo.title.value"></h1>
      <div class="div_sub fs4n fs40" v-if="dataInfo.text" v-html="dataInfo.text.value"></div>
      <div class="div_button" v-if="dataInfo.buttonText && dataInfo.buttonLink">
        <cl-button link :href="dataInfo.buttonLink.value">{{dataInfo.buttonText.value}}</cl-button>
      </div>
      <row v-if="dataInfo.listCard && dataInfo.listCard.length > 0" class="nowrap_col_lg">
        <column v-for="(item, index) in dataInfo.listCard"
                :key="item.title?.value"
                :md="columnMd"
                class="col-lg-auto"
                :class="{'col-end-full': colEndFull}"
        >
          <div class="div_num fs2">{{ item.title?.value }}</div>
          <div class="div_text fs4n" v-if="item.text" v-html="item.text?.value"></div>
        </column>
      </row>
    </container>
  </div>
</template>

<script>


export default {
  name: "bannerTitleFirst",
  props: {
    dataInfo: {
      type: Object,
      require: true
    },
    /**
     * Сетка бутстрап md
     * @default '6'
     */
    columnMd : {
      type: String,
      default: '6'
    },
    /**
     * Переключение класса "col-end-full"
     * @default true
     */
    colEndFull: {
      type: Boolean,
      default: true,
    }
  }
}
</script>

<style lang="scss" scoped>
.big_first_block {
  padding: 150px 0 50px 0;
  .container {
    max-height: none !important;
  }

  h1 {
    margin-bottom: 48px;
  }
  .h1_banner{
    text-transform: none;
    @media (max-width: 991px){
      margin-top: 0;
    }
  }
  .div_button {
    margin-bottom: 32px;
  }
  @media (max-width: 991px) {
    background-size: 100% auto;
    background-position: top center;
  }
  @media (max-width: 991px) and (min-width: 768px) {
    .col-end-full:last-child:nth-child(2n+1){
      flex: 0 0 100%;
      max-width: 100%;
      border-top: none;
    }
  }
}

.big_first_block_v2 {
  padding: 150px 0 60px 0;
  width: 1440px;
  margin: 0 auto;
  border-bottom-left-radius: 1.6rem;
  border-bottom-right-radius: 1.6rem;

  .container {
    padding-bottom: 0;
    min-height: auto !important;
  }

  h1 {
    font-family: Playfair Display;
    font-size: 6.4rem;
    font-weight: 400;
    line-height: 1;
    letter-spacing: 0.01em;
    text-transform: uppercase !important;
    max-width: 60rem;
    margin-bottom: 1.6rem;
  }

  .div_sub {
    font-size: 1.8rem;
    line-height: 1.33;
    letter-spacing: 0.015em;
    margin-bottom: 4rem;
    max-width: 48.6rem;

    :deep(p) {
      margin-bottom: 0.8rem;
    }

    :deep(p:last-child) {
      margin-bottom: 0;
    }
  }

  .div_button {
    margin-bottom: 4rem;
  }

  .row {
    margin: 0 -32px 0 -25px;
    flex-wrap: nowrap;
  }

  .row > .col {
    padding: 0 32px 16px 25px;
    border-right: 1px solid #5D636F !important;
    border-top: none;
    align-items: normal;
    text-align: start;
  }

  .row > .col:last-child {
    border-right: 0 !important;
  }

  .div_num {
    margin-bottom: 1.2rem;
    letter-spacing: 0.02em;
  }

  .div_text {
    font-size: 1.8rem;
    font-weight: 400;
    letter-spacing: 0.015em;
    color: #6B7280;
  }

  @media (max-width: 1439px) {
    width: auto;
    margin: auto;

    .row {
      margin: 0 -32px 0 -24px;
    }

    .row > .col {
      padding: 0 32px 16px 24px;
    }
  }
  
  @media (max-width: 991px) {
    .row {
      margin: 0 -16px 0 -24px;
    }

    .row > .col {
      padding: 0 16px 8px 24px;
    }

    .div_num {
      font-size: 3.2rem;
      line-height: 1.5;
      margin-bottom: 0.8rem;
    }

    .div_text {
      font-size: 1.4rem;
      line-height: 1.428;
      letter-spacing: 0.015em;
    }
  }

  @media (max-width: 767px) {
    h1 {
      max-width: none;
      font-size: 3.6rem;
      letter-spacing: 0.01em;
      text-align: center;
    }

    .div_sub {
      max-width: none;
      font-size: 1.6rem;
      line-height: 1.5;
      letter-spacing: 0.015em;
      text-align: center;
      margin-bottom: 1.6rem;
    }

    .div_button {
      .btn {
        width: 100%;
      }
    }

    .div_num {
      text-align: center;
    }

    .row {
      margin: 0 -16px;
      flex-wrap: wrap;
    }

    .row > .col {
      border-right: 0 !important;
      border-bottom: 1px solid #5D636F !important;
      padding: 2.4rem 0;
    }

    .row > .col:last-child {
      border-bottom: none !important;
    }
  }
}
</style>
