<template>
  <div class="competition">
    <swiper
      class="competition_swiper"
      autoplay=""
      :loop="true"
      :modules="modules"
      :speed="800"
      :autoplay="true"
      @slideChange="onSlideChange"
      :allow-touch-move="false"
    >
      <template v-for="(slide, key) of this.dataInfo.slides" :key="key">
        <swiper-slide v-if="slide.image">
          <img :src="getImage(slide.image)" v-if="widthSite > 767 || !slide.imageMob">
          <img :src="getImage(slide.imageMob)" v-else>
        </swiper-slide>
      </template>
    </swiper>
    <div class="abs_content">
      <div class="div_logo" v-if="dataInfo.logo">
        <img :src="getImage(dataInfo.logo)" alt="">
      </div>
      <div class="div_tit" v-if="dataInfo.title?.value">
        {{ dataInfo.title.value }}
      </div>
      <div class="div_subtit">
        <div class="div_text" v-if="dataInfo.subtitle?.value" v-html="dataInfo.subtitle.value"></div>
        <swiper
            ref="swiperText"
            class="competition_swiper_content"
            :loop="true"
            :modules="modules"
            effect="fade"
            :onSwiper="onTextSwiper"
            :allow-touch-move="false"
        >
          <template v-for="(slide, key) of this.dataInfo.slides" :key="key">
            <swiper-slide v-if="slide.image">
              <div class="div_text_or" v-if="slide.textOrange?.value">
                {{ slide.textOrange.value }}
              </div>
            </swiper-slide>
          </template>
        </swiper>
      </div>
      <cl-button v-if="dataInfo.textButton?.value" @click="formShow = true" class="btn_mob_fixed">
        {{ dataInfo.textButton.value }}
      </cl-button>
      <cl-modal v-model="formShow" class="modal_form" v-if="dataInfo.bannerBtnGuid?.value" :closeFonActive="false" v-bind="modalAlertMeasureLeaveTopModalAttr()">
        <h4 class="modal-header" v-if="formTitle">
          {{ formTitle }}
        </h4>
        <measure-form-component
            :measureGuid="dataInfo.bannerBtnGuid.value"
            :showMode="showMode"
            :formParams="measureFormParams"
            :form-type="1"
            @formTitle="formTitleFun"
            @isAuthorizationRequired="isAuthorizationRequiredForm"

            v-bind="modalAlertMeasureLeaveAttr()"
            v-on="modalAlertMeasureLeaveEmits()"
        />
      </cl-modal>
    </div>
  </div>
</template>

<script>
import {Swiper, SwiperSlide} from "swiper/vue";
import {Autoplay, EffectFade} from "swiper";
import ClButton from "@/components/library/ClButton.vue";
import 'swiper/css/effect-fade';
import {mapGetters} from "vuex";
import ClModal from "@/components/library/ClModal.vue";
import MeasureFormComponent from "@/components/measureForm.vue";
import mixinModalAlertMeasureLeave from "@/mixin/mixinModalAlertMeasureLeave";

export default {
  props: {
    dataInfo: {
      type: Object,
      require: true
    },
  },
  components: {
    MeasureFormComponent,
    ClModal,
    ClButton,
    Swiper,
    SwiperSlide,
  },
  mixins: [mixinModalAlertMeasureLeave],
  data() {
    return {
      textSwiper: null,
      formTitle: null,
      measureFormParams: {},
      formShow: false,
      showMode: 'inline',
    }
  },
  setup() {
    return {
      modules: [Autoplay, EffectFade],
    };
  },
  methods: {
    isAuthorizationRequiredForm(){
      if(!this.getIsAuthorization){
        this.formShow = false;
      }
    },
    onTextSwiper(swiper) {
      this.textSwiper = swiper;
    },
    onSlideChange(swiper) {
      if (this.textSwiper) {
        this.textSwiper.slideTo(swiper.realIndex + 1);
      }
    },
    formTitleFun(name) {
      this.formTitle = name;
    },
  },
  computed: {
    ...mapGetters([
      'widthSite',
      'getIsAuthorization',
    ])
  },
};
</script>

<style lang="scss" scoped>
.competition {
  height: 100vh;
  max-height: 1080px;
  position: relative;
  z-index: 11;
}
.competition_swiper {
  height: 100%;
  width: 44%;
  margin-right: 0;
  user-select: none;
  &:after {
    content: '';
    position: absolute;
    inset: 0;
    width: 100%;
    height: 100%;
    background-image: linear-gradient(90deg, #000 10%, rgba(0, 0, 0, 0.6) 25%,  rgba(0, 0, 0, 0.3) 40%,  rgba(0, 0, 0, 0.0) 100%);
    z-index: 2;
  }
  .swiper-slide {
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: top;
    }
  }
}
.abs_content {
  position: absolute;
  width: 100%;
  max-width: 109.8rem;
  top: 50%;
  left: 50%;
  translate: -50% -50%;
  z-index: 3;
  .div_logo {
    margin-bottom: 2.4rem;
    height: 24rem;
    user-select: none;
    img {
      height: 100%;
      object-fit: contain;
    }
  }
  .div_tit {
    font-size: 8rem;
    line-height: 120%;
    font-weight: 500;
    text-transform: uppercase;
    margin-bottom: 1.2rem;
  }
  .div_subtit {
    display: flex;
    font-size: 4.8rem;
    line-height: 133.33%;
    font-weight: 500;
    text-transform: uppercase;
    margin-bottom: 2.4rem;
    gap: 1.4rem;
    .div_text {
      min-width: fit-content;
    }
    .div_text_or {
      color: #ED850A;
    }
  }
}

@media (max-width: 991px) {
  .competition_swiper{
    width: 100%;
    &:after {
      background-image: linear-gradient(360deg, #000 20%, rgba(0, 0, 0, 0.6) 40%,  rgba(0, 0, 0, 0.3) 60%,  rgba(0, 0, 0, 0.0) 100%);
    }
  }
  .abs_content {
    padding: 0 2.4rem;
    top: unset;
    left: unset;
    translate: unset;
    bottom: 7rem;
    .div_logo {
      width: 15rem;
      height: unset;
      img {
        height: 100%;
        object-fit: contain;
      }
    }
    .div_tit {
      font-size: 4.8rem;
      line-height: 133.33%;
      margin-bottom: 2.4rem;
    }
    .div_subtit {
      font-size: 2.4rem;
      line-height: 133.33%;
    }
    .btn {
      width: 100%;
    }
    .btn_mob_fixed {
      position: fixed;
      width: calc(100% - 4.8rem);
      bottom: 2.4rem;
    }
  }
}
@media (max-width: 767px) {
  .competition_swiper{
    width: 100%;
  }
  .abs_content {
    padding: 0 16px 6.4rem;
    bottom: 1.6rem;
    .div_tit {
      font-size: 3.2rem;
      font-weight: 500;
    }
    .d-unset{
      display: unset !important;
    }
    .div_subtit {
      position: relative;
      .competition_swiper_content{
        top: 3.2rem;
        left: 0;
        right: unset;
        width: 100%;
        margin-right: auto;
        position: absolute;
      }
    }
    .btn_mob_fixed {
      position: fixed;
      width: calc(100% - 3.2rem);
      bottom: 1.6rem;
    }
  }
}
</style>

<style>
.abs_content {
  .swiper-fade .swiper-slide {
    opacity: 0 !important;
  }
  .swiper-fade .swiper-slide-active {
    opacity: 1 !important;
  }
}
</style>