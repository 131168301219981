<template>
  <notifications position="bottom right" width="400">
    <template #body="props">
      <div class="notification-wrapper" :class="props.item.data.notificationWrapperClass">
        <div class="notification" :class="props.item.type" v-click-away="event => closeClickAwayNotification(props)">
          <div class="notification-title">
            {{ props.item.title }}
          </div>
          <div class="notification-content" v-html="props.item.text"/>
          <div class="notification-close" @click="event => closeNotification(props)" >
            <div class="close_el"></div>
          </div>
        </div>
      </div>
    </template>
  </notifications>
  <template>
    <div v-if="globalAlertData?.notifyAlertAfkData?.active" ref="notifyAlertAfk">
      <div v-if="globalAlertData?.notifyAlertAfkData.icon" class="div_img">
        <img :src="getImage(globalAlertData.notifyAlertAfkData.icon)" alt="">
      </div>
      <h5 v-if="globalAlertData?.notifyAlertAfkData.title">{{ globalAlertData.notifyAlertAfkData.title.value }}</h5>
      <div class='div_text'>
        <span v-if="globalAlertData?.notifyAlertAfkData.textBeforeTime">{{globalAlertData.notifyAlertAfkData.textBeforeTime.value}}</span>
        <span v-if="globalAlertData?.notifyAlertAfkData.textSpaceBeforeTime">&nbsp;</span>
        <span v-if="globalAlertData?.notifyAlertAfkData.textTime">{{ endAuthTime.toLocaleString('ru-RU', {hour: 'numeric', minute: 'numeric'}) }}</span>
        <span v-if="globalAlertData?.notifyAlertAfkData.textSpaceAfterTime">&nbsp;</span>
        <span v-if="globalAlertData?.notifyAlertAfkData.textAfterTime">{{globalAlertData.notifyAlertAfkData.textAfterTime.value}}</span>
      </div>
    </div>
    <div v-if="globalAlertData?.notifyAlertAuthData.active" ref="notifyAlertAuth">
      <div v-if="globalAlertData?.notifyAlertAuthData.icon" class="div_img">
        <img :src="getImage(globalAlertData.notifyAlertAuthData.icon)" alt="">
      </div>
      <div v-if="globalAlertData?.notifyAlertAuthData.text" class='div_text' v-html="globalAlertData.notifyAlertAuthData.text.value"></div>
      <div v-if="globalAlertData?.notifyAlertAuthData.buttonText && globalAlertData?.notifyAlertAuthData.buttonLink" class="div_btn">
        <a class="btn btn-light" :href="globalAlertData.notifyAlertAuthData.buttonLink.value">{{globalAlertData.notifyAlertAuthData.buttonText.value}}</a>
      </div>
    </div>
  </template>
</template>

<script>
import {notify} from "@kyvg/vue3-notification";
import {mapGetters} from "vuex";

export default {
  name: 'notificationExtra',
  data() {
    return {
      alertAfkTimer: null,
      alertAuthTimer: null,
      endAuthTime: new Date(),
      alertAfkTime: 1800 * 1000,
      alertAfkShowed: false,
      alertAuthShowed: false,
      notifyAlertAfkIds: [],
      notifyAlertAuthId: -1,
      userDetectAlreadyStarted: false,
      userDetectModalAlreadyStarted: false,
      userDetectModalObserver: null,
    }
  },
  props: {
    globalAlertData: {
      type: Object,
      default: null,
    },
  },
  methods: {
    closeClickAwayNotification(notifyProps) {
      if(notifyProps?.item?.data?.notificationCloseClickAway) {
        this.closeNotification(notifyProps);
      }
    },

    closeNotification(notifyProps) {
      if(notifyProps?.item) {
        if(notifyProps.item.data?.notificationCloseClickAway && this.notifyAlertAfkIds.includes(notifyProps.item.id)) {
          this.notifyAlertAfkIds = this.notifyAlertAfkIds.filter(notifyAlertAfkId => notifyAlertAfkId !== notifyProps.item.id);
          this.alertAfkShowed = false;
          this.resetAlertAfkTimer();
        }
        if(notifyProps.item.data?.notificationCloseClickAway && this.notifyAlertAuthId === notifyProps.item.id) {
          this.notifyAlertAuthId = -1;
          this.alertAuthShowed = false;
          this.resetAlertAfkTimer();
        }
        this.closeNotificationById(notifyProps.item.id);
      }
    },

    closeNotificationById(id) {
      notify.close(id);
    },

    closeAllNotificationAlertAfk() {
      this.resetAlertAfkTimer();
      this.alertAfkShowed = false;
      this.notifyAlertAfkIds.forEach(notifyAlertAfkId => this.closeNotificationById(notifyAlertAfkId));
      this.notifyAlertAfkIds = [];
    },

    showAlertAfk() {
      this.alertAfkShowed = true;
      const notifyAlertAfkId = Date.now();
      this.notifyAlertAfkIds.push(notifyAlertAfkId);
      this.showInfo(false, this.$refs.notifyAlertAfk.innerHTML, '', 'notification-alert-afk center_mode', 500, -1, {notificationCloseClickAway: true, notificationWrapperClass: {'notification-wrapper-substrate': true}}, notifyAlertAfkId);
    },

    resetAlertAfkTimer() {
      clearTimeout(this.alertAfkTimer);
      if(!(this.alertAfkShowed || this.alertAuthShowed) && this.endAuthTime > Date.now()) {
        this.alertAfkTimer = setTimeout(this.showAlertAfk, this.alertAfkTime); // запускаем отсчёт
      }
    },

    userDetect() {
      if(!this.userDetectAlreadyStarted) {
        this.userDetectAlreadyStarted = true;
        ['mousemove', 'keypress', 'scroll', 'click'].forEach(event => document.addEventListener(event, this.resetAlertAfkTimer)); // регистрируем действия пользователя
      }
    },

    userDetectModal() {
      if(!this.userDetectModalAlreadyStarted) {
        this.userDetectModalAlreadyStarted = true;

        const bodyElem = document.querySelector('body');

        this.userDetectModalObserver = new MutationObserver((mutations) => {
          mutations.forEach((mutation) => {
            if(mutation.attributeName === 'class' && bodyElem.classList.contains('modal_open')) {
              document.querySelectorAll(".modal:not(.d-none)").forEach((openedModal) => {
                openedModal.removeEventListener('scroll', this.resetAlertAfkTimer); // убираем существующий скролл
                openedModal.addEventListener('scroll', this.resetAlertAfkTimer); // регистрируем скролл пользователя в модалке
              })
            }
          });
        });

        this.userDetectModalObserver.observe(bodyElem, {attributes: true});
      }
    },

    showAlertAuth() {
      this.alertAuthShowed = true;
      this.notifyAlertAuthId = Date.now();
      this.closeAllNotificationAlertAfk();
      this.showInfo(false, this.$refs.notifyAlertAuth.innerHTML, '', 'notification-alert-auth center_mode', 500, -1, {notificationCloseClickAway: true, notificationWrapperClass: {'notification-wrapper-substrate': true}}, this.notifyAlertAuthId);
    },

    resetAlertAuthTimer() {
      clearTimeout(this.alertAuthTimer);
      if(!this.alertAuthShowed && this.endAuthTime > Date.now()) this.alertAuthTimer = setTimeout(this.showAlertAuth, this.endAuthTime - Date.now());
    },

    updateTimers() {
      if(this.getIsAuthorization) {
        if(this.globalAlertData?.notifyAlertAfkData?.active && this.globalAlertData.notifyAlertAfkData.alertAfkTime) {
          this.alertAfkTime = Number(this.globalAlertData.notifyAlertAfkData.alertAfkTime.value) * 1000;
        }

        if(this.globalAlertData?.notifyAlertAfkData?.active && this.endAuthTime > Date.now()) {
          this.userDetect();
          this.userDetectModal();
          this.resetAlertAfkTimer();
        }

        if(this.globalAlertData?.notifyAlertAuthData?.active){
          if(this.endAuthTime > Date.now()) {
            this.resetAlertAuthTimer();
          } else {
            this.alertAuthTimer = setTimeout(this.showAlertAuth, 15);
          }
        }
      }
    }
  },
  watch: {
    globalAlertData: function() {
      this.updateTimers();
    },
    getUser() {
      this.endAuthTime = new Date(Number(this.getUser.sessionEnd) * 1000);
      this.updateTimers();
    }
  },
  computed: {
    ...mapGetters([
      'getIsAuthorization',
      'getUser',
    ])
  },
  mounted() {
    if(this.getUser) {
      this.endAuthTime = new Date(Number(this.getUser.sessionEnd) * 1000);
      this.updateTimers();
    }
  },
  beforeUnmount() {
    if(this.userDetectModalObserver) this.userDetectModalObserver.disconnect();
  },
};
</script>

<style lang="scss" scoped>
</style>