<template>
  <cl-modal v-if="dataInfo?.active" v-model="isShowModalPayment" class="modalInfo center modal_alert_measure_leave" :class="{'modal_alert_measure_leave__black': dataInfo.isBlack}" :close-fon-active="false">
    <template v-slot:body>
      <div v-if="dataInfo.icon" class="div_img">
        <img :src="getImage(dataInfo.icon)" alt="">
      </div>
      <div v-if="dataInfo.title || dataInfo.text" class="div_info">
        <h4 v-if="dataInfo.title" class="div_title">{{ dataInfo.title.value }}</h4>
        <div v-if="dataInfo.text" class="div_text fs16" v-html="dataInfo.text.value"></div>
      </div>
      <div class="div_btns">
        <cl-button @click="closeModal">
          {{ dataInfo.closeBtnText ? dataInfo.closeBtnText.value : '' }}
        </cl-button>
      </div>
    </template>
  </cl-modal>
</template>

<script>
import ClModal from "@/components/library/ClModal.vue";
import ClButton from "@/components/library/ClButton.vue";

export default {
  name: "modalPayment",
  components: {ClButton, ClModal},
  data() {
    return {
      /**
       * Состояние, с помощью которого можно открывать/закрывать модалку
       * @type {boolean}
       * @default false
       */
      isShowModalPayment: false,
    }
  },
  props: {
    /**
     * Данные из админки
     * @type Object
     */
    dataInfo: {
      type: Object,
      require: true,
    },
    /**
     * Тип модалки (результат оплаты)
     */
    modalType: {
      type: String,
    }
  },
  methods: {
    /**
     * Функция для открытия модалки.
     */
    openModal() {
      this.isShowModalPayment = true;
    },
    /**
     * Функция для закрытия модалки.
     */
    closeModal() {
      this.isShowModalPayment = false;
    },
    /**
     * Функция для проверки типа сообщения об оплате
     */
    checkPayment() {
      if(this.$route.query.payment === this.modalType) {
        this.openModal();
      }
    }
  },
  mounted() {
    this.checkPayment();
  }
}
</script>

<style lang="scss" scoped>

</style>