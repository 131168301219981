<template>
  <div v-if="dataInfo && dataInfo.quoteList && dataInfo.quoteList.length > 0">
    <div class="sub_block_black_wrapper">
      <div class="sub_block_black">
        <container>
          <div class="block_wrap block_wh famous_people">
            <div class="swiper_wrapper">
              <h2 class="fs3 famous_people__title div_static_title" v-if="dataInfo.title">{{dataInfo.title.value}}</h2>
              <swiper
                  :navigation="true"
                  :modules="modules"
                  class="str_vertical"
                  :direction="'horizontal'"
                  :breakpoints="{800: {
                direction: 'vertical'
              }}"
                  :loop="true"
              >
                <swiper-slide
                    v-for="item in dataInfo.quoteList"
                    :key="item"
                >
                  <div class="famous_people_el  block_min">
                    <div class="dt1" v-if="item.img"><img :src="getImage(item.img, 'hd')" alt=""></div>
                    <div class="dt2">
                      <h2 class="fs3 famous_people__title invisible" v-if="dataInfo.title">{{dataInfo.title.value}}</h2>
                      <div class="div_text" v-if="item.text" v-html="item.text.value"></div>
                      <div class="div_flex">
                        <div class="div_name fs4">
                          <template v-if="item.name">{{ item.name.value }}{{item.professions ? ',' : ''}}</template>
                          <br>
                          <template v-if="item.professions">{{ item.professions.value }}</template>
                        </div>
                      </div>
                    </div>
                  </div>
                </swiper-slide>
              </swiper>
            </div>
          </div>
        </container>
      </div>
    </div>
  </div>
</template>

<script>

import {Swiper, SwiperSlide} from "swiper/vue";
import {Navigation} from "swiper";
import Container from "@/components/library/Container.vue";

export default {
  name: "famousPeopleV3",
  components: {Container, Swiper, SwiperSlide},
  props: {
    dataInfo: {
      type: Object,
      require: true
    },
  },
  setup() {
    return {
      modules: [Navigation]
    }
  },
}
</script>

<style lang="scss" scoped>

.sub_block_black{
  .block_wrap, .block_wh{
    padding: 0;
  }
}

.famous_people {
  background: black;
  color: white;
  padding: 6rem 0;

  &__title {
    text-transform: unset;
    letter-spacing: 0.02em;
    margin-bottom: 0;
    font-size: 3.2rem;
    font-weight: 500;

    &.invisible {
      opacity: 0;
    }
  }

  @media (max-width: 800px) {
    &__title {
      &.invisible {
        display: none;
      }
    }
  }
}


.swiper_wrapper {
  position: relative;
  background: rgba(12, 12, 14, 1);

  .div_static_title {
    position: absolute;
    top: 0;
    left: 39.2rem;
    z-index: 2;
    background: rgba(12, 12, 14, 1);
    width: calc(100% - 39.2rem);
    padding-top: 3.2rem;
    padding-left: 2.4rem;
    padding-right: 2.4rem;
  }

  @media (max-width: 991px) {
    .div_static_title{
      font-size: 3.2rem;
    }

    .div_static_title{
      left: 27rem;
      width: calc(100% - 27rem);
    }
  }

  @media (max-width: 800px) {
    .div_static_title {
      text-align: center;
      padding: 1.6rem;
      position: static;
      width: auto;
      font-size: 2.4rem;
      background: transparent;
    }
  }
}

.str_vertical {
  height: 52rem;

  :deep(.swiper-button-prev), :deep(.swiper-button-next) {
    margin: 0;
    right: 3.2rem;
    height: 6rem;

    &:before {
      height: 6rem;
      background-size: contain !important;
    }
  }

  :deep(.swiper-button-prev) {
    transform: translateY(-175%);
    &:before {
      background: url(@/assets/img/ico/str_vertical_top_white.svg) no-repeat top center;
    }
  }

  :deep(.swiper-button-next) {
    transform: translateY(75%);
    &:before {
      background: url(@/assets/img/ico/str_vertical_bottom_white.svg) no-repeat top center;
    }
  }

  @media (max-width: 991px) {
    height: 45rem;
  }

  @media (max-width: 800px) {
    height: auto;

    :deep(.swiper-button-prev), :deep(.swiper-button-next) {
      top: auto;
      bottom: -1rem;
    }

    :deep(.swiper-button-prev) {
      transform: translateX(-300%) rotate(-90deg);
    }

    :deep(.swiper-button-next) {
      transform: translateX(200%) rotate(-90deg);
    }
  }
}

.famous_people_el {
  display: flex;
  overflow: hidden;
  height: 100%;
  gap: 2.4rem;
  padding: 3.2rem 7.4rem 3.2rem 3.2rem;

  .dt1 {
    flex: none;
    width: 36rem;
    overflow: hidden;
    border-radius: 16px;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: top;
    }
  }

  .dt2 {
    display: flex;
    flex-direction: column;
    flex: 1;
  }

  .div_text {
    margin: auto 0;
    max-width: 46.8rem;
    max-height: 33rem;
    overflow: auto;
  }

  @media (max-width: 991px) {
    padding: 2.4rem 7.4rem 2.4rem 2.4rem;

    .div_text {
      font-size: 1.6rem;
    }

    .div_name {
      font-weight: 700;
    }

    .dt1{
      width: 24.6rem;
      aspect-ratio: 0.7884615384615385;
    }
  }

  @media (max-width: 800px) {
    padding: 0 1.6rem 7.4rem;
    flex-direction: column;
    height: 100%;

    .dt1 {
      width: 100%;
      max-height: 50rem;
      overflow: hidden;
    }

    .div_text {
      max-width: none;
      max-height: none;
      margin: 0 0 1.2rem;
    }

    .div_flex {
      margin-top: auto;
    }
  }
}
</style>