<template>
  <div class="card_list_block">
    <div class="sub_block_black">
      <container>
        <div>
          <h2 v-if="dataInfo?.dataBlock?.titleBlock">
            {{ dataInfo.dataBlock.titleBlock.value }}
          </h2>
          <row>
            <template v-if="dataList">
              <column class="card_col card_backgr_gray" lg="4" md="12" sm="12" v-for="item in dataList">
                <card card-type="event" :route-link="'events/item/' + item.guid">
                  <div class="div_img" :class="{'not_img': !item.logo?.imageUrl}">
                    <div class="div_tag">
                      <div class="div_tag_el" v-if="item.isRegistrationInRpp">Необходима регистрация</div>
                      <div class="div_tag_el div_tag_el_min" v-if="item.ageLimit || item.ageLimit == 0">{{
                          item.ageLimit
                        }}+
                      </div>
                    </div>
                    <img v-if="item.logo?.imageUrl" :src="item.logo.imageUrl" alt="">
                  </div>
                  <div class="div_info">
                    <div class="div_tag2" v-if="item.types && item.types.length > 0"><span
                        v-for="tag in item.types">{{ tag.name }}</span></div>
                    <div class="div_tit fs5" v-if="item.title">{{ item.title }}</div>
                    <div class="div_dat">
                      <div class="dt1">{{ $filters.ruDate2(new Date(item.eventStartDate)) }}
                        <template v-if="item.startTime">
                          {{ item.startTime.split(':')[0] + ':' + item.startTime.split(':')[1] }}
                        </template>
                      </div>
                      <div class="dt2">
                        <template v-if="item.regEventVenues !== undefined && item.regEventVenues.length == 1">
                          {{ item.regEventVenues[0].name }}
                        </template>
                        <template v-else>
                          <template v-if="item.platform?.name">
                            {{ item.platform.name }}
                          </template>
                        </template>
                      </div>
                    </div>
                  </div>
                </card>
              </column>
            </template>
            <column ref="cardhold" class="cardholder_telep card_col card_col_sub"
                    :class="{'blockElem1': dataInfo.dataBlock?.numberElemCard.value == 1, 'blockElem2': dataInfo.dataBlock?.numberElemCard.value == 2}"
                    v-if="dataInfo.dataBlock.numberElemCard && dataInfo.settings"
                    :id="dataInfo.settings.anchor"></column>
          </row>
        </div>
      </container>
    </div>
  </div>
</template>

<script>

export default {
  name: "cinemaParkEvents",
  data() {
    return {
      getParams: {
        ids: "",
      },
      dataList: false,
      idBlockAnchor: "",
    }
  },
  props: {
    dataInfo: {
      type: Object,
      require: true
    },
  },
  methods: {
    /**
     * Метод получает события (запрос)
     *
     * @param {boolean} isLoadMore - Загружать ещё события (флаг)
     * @returns {Promise<void>} - Промис, который выполняется после завершения загрузки и обработки данных.
     */
    getEvents(isLoadMore) {
      return this.axios
          .get(`/api/data/cinema-events`, {params: this.getParams})
          .then(response => {
            // dateFrom
            if (isLoadMore) {
              this.dataList = [...this.dataList, ...response.data.records];
            } else {
              this.dataList = response.data.records;
            }
            this.data = response.data;

            this.dataList.forEach((item, index) => {
              if (this.dataInfo.dataCard[index].titleEvent !== undefined && this.dataInfo.dataCard[index].titleEvent) {
                this.dataList[index].Name = this.dataInfo.dataCard[index].titleEvent.value;
              }
              if (this.dataInfo.dataCard[index].descEvent !== undefined && this.dataInfo.dataCard[index].descEvent) {
                this.dataList[index].Description = this.dataInfo.dataCard[index].descEvent.value;
              }
            })

            if (this.dataInfo.dataBlock.GrayFonCard && this.dataInfo.dataBlock.GrayFonCard.value !== undefined) {
              this.GrayFonCard = this.dataInfo.dataBlock.GrayFonCard.value;
            }
            if (this.dataInfo.dataBlock.GrayFonBlock && this.dataInfo.dataBlock.GrayFonBlock.value !== undefined) {
              this.GrayFonBlock = this.dataInfo.dataBlock.GrayFonBlock.value;
            }
            if (this.dataInfo.dataBlock.Width50Pers && this.dataInfo.dataBlock.Width50Pers.value !== undefined) {
              this.Width50Pers = this.dataInfo.dataBlock.Width50Pers.value;
            }
            if (this.dataInfo.dataBlock.idAnchor && this.dataInfo.dataBlock.idAnchor.value !== undefined) {
              this.idBlockAnchor = this.dataInfo.dataBlock.idAnchor.value;
            }


            this.isLoading = false;
          })
          .catch(error => {
            this.showError(error);
          });
    },
    /**
     * Собирает GUID из переданного массива элементов и обновляет параметры запроса.
     *
     * @param {Array<Object>} elem - Массив объектов
     * @returns {void} - Этот метод не возвращает значение.
     */
    collectedGuids(elem) {
      let k = []
      elem.forEach(el => {
        k.push(el.guidEvent.value);
      })
      this.getParams.ids = k.join(',');
      this.getEvents();
    }
  },
  mounted() {
    this.collectedGuids(this.dataInfo.dataCard);
  },
}
</script>

<style lang="scss" scoped>
.row {
  justify-content: flex-start;

  > div {
    height: 100%;
  }
}

.card_list_block {
  margin: 3rem 0;
  border-radius: 3.2rem;

  .container {
    max-width: 152rem;
  }

  h2 {
    text-align: center;
  }

  h2 {
    font-size: 3.2rem;
    margin-bottom: 3.2rem;
  }

  .card {
    background: #000106;
    height: 100%;
  }
}

.card-event .div_img {
  border-radius: 0;
}

.cardholder_telep {
  display: flex;
  gap: 12px;

  &.blockElem1 {
    width: 33%;
  }

  &.blockElem2 {
    width: 66%;
  }

  .card_list_block {
    margin: 0;
    width: calc(100% - 12px);
  }

  .block_fon {
    padding: 0;
  }

  .card {
    width: 100%;
  }
}

.gray_fon_card {
  &.card_list_block {
    .card_backgr_gray {
      padding: 2.4rem;
      background: #0C0C0E;
      border-radius: 1.2rem;
      justify-content: center;
    }
  }

  .card_list_block {
    .card_backgr_gray {
      padding: 2.4rem;
      background: #0C0C0E;
      border-radius: 1.2rem;
      justify-content: center;
    }
  }
}

@media(max-width: 991px) {
  .card_list_block {
    margin: 3rem 4rem;

    .container.gray_fon_block {
      padding: 6rem 1.6rem;
    }

    .row {
      gap: 16px 0;
      flex-direction: column;
    }
  }
  .card-event .div_img {
    height: 334px;
  }
  .cardholder_telep {
    display: flex;
    padding: 0;

    &.blockElem1 {
      width: 100%;
      padding: 0 1.2rem;
    }

    &.blockElem2 {
      width: 100%;
      flex-direction: column;
    }
  }

  .cardholder_telep {
    .card_list_block {
      width: 100%;
    }
  }
}

@media(max-width: 767px) {
  .card_list_block {
    margin: 3rem 0;

    .div_img {
      height: 26rem;
    }
  }
  .gray_fon_card.card_list_block .card_backgr_gray {
    padding: 6rem 1.6rem;
  }
}

</style>