<template>
  <div class="cinema_swiper_card_mos" :class="{'cinema_swiper_card_mos__content_right': card.isContentRight}" :style="getCardStyle()">
    <cl-button type="light" class="div_collapse_btn" @click="collapseText()">{{collapseShow ? (card.btnCollapseTextClose ? card.btnCollapseTextClose.value : 'Скрыть') : (card.btnCollapseTextOpen ? card.btnCollapseTextOpen.value : 'Подробнее')}}</cl-button>
    <div class="div_content">
      <h3 v-if="card.title" class="div_title">{{card.title.value}}</h3>
      <div ref="perfectScrollbarRef" v-if="card.text" class="div_scrollbar">
        <div ref="perfectScrollbarContRef" class="div_text" :class="{'short_text': !collapseShow}" v-html="card.text.value"></div>
      </div>
      <div v-if="card.btnText && (widthSite > 767 || collapseShow)" class="div_btn">
        <cl-button
            link
            :href="card.btnLink?.value"
            class="btn-ico-right img_wh div_custom_btn_or"
            img="/img/ico/move-up-right.svg"
            :disabled="!(card.btnLink || card.btnAnchor)"
            @click="customRedirect(card.btnAnchor)">
          {{card.btnText.value}}
        </cl-button>
      </div>
    </div>
    <div ref="swiperImgRef" class="div_img">
      <img v-if="card.img" :src="getImage(card.img, 'hd')" alt="">
    </div>
  </div>
</template>

<script setup lang="ts">
import ClButton from "@/components/library/ClButton.vue";
import {IDataInfoPropsValues} from "@/interface/props/IDataInfoProps";
import PerfectScrollbar from "perfect-scrollbar";
import {computed, ComputedRef, onBeforeUnmount, onMounted, ref, Ref} from "vue";
import router from "@/router";
import store from "@/store";

/**
 * Пропы
 */
const props = defineProps<{
  card: IDataInfoPropsValues,
}>();

/**
 * Perfect Scrollbar
 */
let perfectScrollbar: PerfectScrollbar | null = null;

/**
 * Resize Observer - который отслеживает изменение контента внутри скроллбара и обновляет скроллбар
 */
let resizeObserver: ResizeObserver | null = null;

/**
 * Высота блока с картинкой (в зависимости от ширины)
 */
let imgHeight: Ref<string> = ref('');

/**
 * Состояние отображение полного текста в мобильной версии
 */
let collapseShow: Ref<boolean> = ref(false);

/**
 * Ref на perfect scrollbar
 */
const perfectScrollbarRef: Ref<HTMLElement | null> = ref(null);
/**
 * Ref на контент внутри perfect scrollbar
 */
const perfectScrollbarContRef: Ref<HTMLElement | null> = ref(null);

/**
 * Ref на картинку
 */
const swiperImgRef: Ref<HTMLElement | null> = ref(null);

/**
 * Ширина экрана
 */
const widthSite: ComputedRef<any> = computed(() => {return store.getters.widthSite});

/**
 * Функция перехода по якорю
 */
function customRedirect(btnAnchor: string | undefined): void {
  if (btnAnchor) {
    if(btnAnchor[0] === '#' && btnAnchor.indexOf('tabCategoryMoskino') !== -1) {
      const filter = document.querySelector('#filterTopCategory');
      if (filter) {
        filter.setAttribute('data-filter', btnAnchor);
      }
    } else {
      router.push(btnAnchor.replace('/api/page', ''));
    }
  }
}

/**
 * Добавить листенер на изменение размера экрана
 */
function addResizeListener(): void {
  removeEventListener("resize", windowResized);
  addEventListener("resize", windowResized);
}

/**
 * Событие, которое вызывается при изменение размеров экрана
 */
function windowResized(): void {
  setTimeout(() => {
    if(swiperImgRef.value?.offsetWidth) imgHeight.value = swiperImgRef.value.offsetWidth + 'px';
  }, 15);
}

/**
 * Получение высоты для карточки
 */
function getCardStyle(): {height: string} {
  return widthSite.value > 767 ? {height: imgHeight.value} : {height: 'auto'};
}

/**
 * Скрывать/Показывать текст
 */
function collapseText(): void {
  collapseShow.value = !collapseShow.value;
}

onMounted(() => {
  if (perfectScrollbarRef?.value && perfectScrollbarContRef?.value) {
    perfectScrollbar = new PerfectScrollbar(perfectScrollbarRef.value);
    resizeObserver = new ResizeObserver(() => {
      setTimeout(() => {
        if(perfectScrollbar) perfectScrollbar.update();
      }, 15);
    });
    resizeObserver.observe(perfectScrollbarContRef.value);
  }

  if(swiperImgRef.value?.offsetWidth) imgHeight.value = swiperImgRef.value.offsetWidth + 'px';
  addResizeListener();
});

onBeforeUnmount(() => {
  if(resizeObserver) resizeObserver.disconnect();
  removeEventListener("resize", windowResized);
});

</script>

<style lang="scss" scoped>

.cinema_swiper_card_mos {
  display: flex;
  align-items: center;
  gap: 2.4rem;

  &__content_right {
    flex-direction: row-reverse;
  }

  .div_collapse_btn {
    display: none;
  }

  .div_content {
    flex: 1;
    max-height: 100%;
    display: flex;
    flex-direction: column;

    & > :last-child {
      margin-bottom: 0;
    }
  }

  .div_title {
    font-size: 2.4rem;
    font-weight: 500;
    line-height: 1.33;
    letter-spacing: 0.02em;
    margin-bottom: 1.6rem;
  }

  .div_scrollbar {
    margin-bottom: 2.4rem;
  }

  :deep(.ps) {
    padding-right: 16px;

    .ps__rail-y {
      right: 4px;
      background: rgba(255, 255, 255, 0.05);
    }
  }

  .div_text {
    :deep(p) {
      margin-bottom: 0.8rem;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .div_btn {
    .btn {
      width: 100%;
      min-height: 4.8rem;
      padding: 0 2.4rem;

      :deep(img) {
        width: 2.4rem;
        height: 2.4rem;
      }
    }
  }

  .div_custom_btn_or {
    justify-content: space-between;
  }

  .div_img {
    flex: 1;
    border-radius: 0.8rem;
    overflow: hidden;
    background: black;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  @media (max-width: 991px) {
    .div_title {
      font-size: 1.8rem;
      line-height: 1.33;
      letter-spacing: 0.02em;
    }

    .div_text {
      font-size: 1.4rem;
      line-height: 1.428;
      letter-spacing: 0.015em;

      :deep(p) {
        margin-bottom: 0.4rem;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }

  @media (max-width: 767px) {
    flex-direction: column-reverse;
    gap: 0.8rem;

    .div_collapse_btn {
      display: block;
      align-self: start;
      border: none;
      padding: 0.4rem;
      min-height: 2.4rem;

      font-size: 1.8rem;
      font-weight: 500;
      line-height: 1.33;
      letter-spacing: 0.02em;
      text-align: start;

      width: 100%;
    }

    .div_content {
      width: 100%;
      max-height: none;

      & > :last-child {
        margin-bottom: 0;
      }
    }

    .div_title {
      font-size: 1.6rem;
      line-height: 1.125;
      margin-bottom: 0.4rem;
    }

    .div_scrollbar {
      margin-bottom: 0.8rem;
    }

    :deep(.ps) {
      padding-right: 0;

      .ps__rail-y {
        right: 0;
      }
    }

    .div_text {
      &.short_text {
        display: -webkit-box;
        -webkit-line-clamp: 5; // количество строк
        -webkit-box-orient: vertical;
        overflow: hidden;
        max-height: 10rem;
      }
    }

    .div_img {
      width: 100%;
      max-height: 35rem;
    }
  }
}

</style>