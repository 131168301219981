<template>
    <div class="film_school_adventages">
      <container>
        <div class="film_school_adventages_cont">
          <div v-if="dataInfo.title || dataInfo.desc || dataInfo.nameBtn" class="filmschool_advantages_top">
            <h2 v-if="dataInfo.title">{{dataInfo.title.value}}</h2>
            <div class="film_schoolAdv_desc" v-if="dataInfo.desc" v-html="dataInfo.desc.value"></div>
            <cl-button type="light" v-if="dataInfo.nameBtn" class="btn-middle btn-width-mob-full" @click="showForm = !showForm">{{ dataInfo.nameBtn.value }}</cl-button>
          </div>
          <div v-if="dataInfo.list?.length > 0" class="filmschool_advantages_list">
            <row v-if="widthSite > 991">
              <column md="3" v-if="dataInfo.imageMain" class="card_main">
                <img :src="getImage(dataInfo.imageMain)" alt="">
              </column>
              <column v-for="item in dataInfo.list" class="flex-1">
                <div class="card_filmAdv">
                  <div class="card_ico" v-if="item.icon">
                    <img :src="getImage(item.icon)" alt="">
                  </div>
                  <div class="card_tit" v-if="item.title" v-html="item.title.value"></div>
                  <div class="card_desc" v-if="item.desc" v-html="item.desc.value"></div>
                </div>
              </column>
            </row>
            <swiper
                v-else
                :space-between="16"
                :slides-per-view="dataInfo.list.length > 1 ? 1.5 : 1"
                :breakpoints="{
                      '992': {
                        slidesPerView: dataInfo.list.length > 1 ? 4 : 1
                      },
                      '768': {
                        slidesPerView: dataInfo.list.length > 1 ? 2.7 : 1,
                        spaceBetween: 24
                      }
                    }"
            >
              <swiper-slide v-for="item in dataInfo.list" :key="item" class="swiper-slide-adv">
                <div class="card_filmAdv">
                  <div class="card_ico" v-if="item.icon">
                    <img :src="getImage(item.icon)" alt="">
                  </div>
                  <div class="card_tit" v-if="item.title" v-html="item.title.value"></div>
                  <div class="card_desc" v-if="item.desc" v-html="item.desc.value"></div>
                </div>
              </swiper-slide>
            </swiper>
          </div>
        </div>
        <cl-modal v-model="showForm" :closeFonActive="false" class="modal_form" v-bind="modalAlertMeasureLeaveTopModalAttr()">
          <h4 class="modal-header" v-if="formTitle">
            {{ formTitle }}
          </h4>
          <measure-form-component
              :form-type="1"
              @formTitle="formTitleFun"
              :measureGuid="dataInfo.guid?.value"
              :showMode="showMode"
              :formParams="formParams"

              v-bind="modalAlertMeasureLeaveAttr()"
              v-on="modalAlertMeasureLeaveEmits()"
          />
        </cl-modal>
      </container>
    </div>
</template>

<script>
import ClButton from "@/components/library/ClButton.vue";
import Column from "@/components/library/Column.vue";
import Container from "@/components/library/Container.vue";
import ClModal from "@/components/library/ClModal.vue";
import MeasureFormComponent from "@/components/measureForm.vue";
import {mapGetters} from "vuex";
import {Swiper, SwiperSlide} from "swiper/vue";
import mixinModalAlertMeasureLeave from "@/mixin/mixinModalAlertMeasureLeave";

export default {
    name: "filmSchoolAdvantages",
    components: {MeasureFormComponent, ClModal, Container, Column, ClButton, SwiperSlide, Swiper},
    mixins: [mixinModalAlertMeasureLeave],
    props: {
        dataInfo: {
            type: Object,
            required: true,
        }
    },
    data(){
        return{
            showForm: false,
            formTitle: null,
            showMode: 'inline',
            formParams: {
                'appendCss': '/override/measure.css',
            },
        }
    },
    methods: {
        formTitleFun(name) {
            this.formTitle = name;
        },
    },
    computed: {
      ...mapGetters([
        'widthSite'
      ])
    },

};
</script>

<style lang="scss" scoped>
.film_school_adventages{
    padding: 5.8rem 0;
    h2{
        margin-bottom: 1.2rem;
    }
    .film_schoolAdv_desc{
        margin-bottom: 2.4rem;
        @media (max-width: 767px) {
          font-size: 16px;
        }
    }
    .filmschool_advantages_top{
        margin-bottom: 6.4rem;
    }
    .card_main{
        img{
            width: 100%;
            height: 100%;
            object-fit: contain;
            border-radius: 1.9rem;
        }
    }
    .card_filmAdv{
        height: 100%;
        padding: 3.2rem 1.6rem;
        border-radius: 1.2rem;
        border: 1px solid #ED850A;
        background-color: #000106;
        display: flex;
        flex-direction: column;
        .card_ico{
            display: flex;
            align-items: center;
            justify-content: center;
            width: 4.8rem;
            height: 4.8rem;
            border-radius: 50%;
            border: 1px solid #17191C;
            margin-bottom: 0.8rem;
            img{
                width: 3.2rem;
                height: 3.2rem;
                object-fit: contain;
            }
        }
        .card_tit{
            font-size: 2.4rem;
            font-style: normal;
            font-weight: 500;
            line-height: 133.333%;
            letter-spacing: 0.048rem;
            margin-bottom: 2.4rem;
        }
        .card_desc{
            flex-grow: 1;
            display: flex;
            align-items: end;
        }
        @media (max-width: 767px) {
          .card_tit {
            font-size: 21px;
          }
          .card_desc{
            font-size: 16px;
          }
        }
    }

    & > :last-child {
      margin-bottom: 0;
    }
}
.swiper-slide-adv{
  height: unset;
}
</style>