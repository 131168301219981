<template>
  <container>
    <div class="mainBigBlockEn" :style="[ dataInfo.img ? { backgroundImage: `url(${getImage(dataInfo.img)})` } : '' ]">
      <video preload="metadata" v-if="dataInfo.video" autoplay muted loop playsinline id="videoMainTop">
        <source :src="dataInfo.video.value.src" type='video/mp4; codecs="avc1.42E01E, mp4a.40.2"'>
      </video>
      <h1><span class="sp1" v-if="dataInfo.title1" v-html="dataInfo.title1.value"></span><span class="sp2"
                                                                                               v-if="dataInfo.title2"
                                                                                               v-html="dataInfo.title2.value"></span><span
          class="sp3" v-if="dataInfo.title3" v-html="dataInfo.title3.value"></span><span class="sp4"
                                                                                         v-if="dataInfo.title4"
                                                                                         v-html="dataInfo.title4.value"></span><span
          class="sp5" v-if="dataInfo.title5" v-html="dataInfo.title5.value"></span></h1>
    </div>
  </container>
</template>

<script>

export default {
  name: "mainBigBlockTextEn",
  props: {
    dataInfo: {
      type: Object,
      require: true
    },
  },
  data() {
    return {}
  },
  methods: {},
  mounted() {
    const video = document.getElementById('videoMainTop');
    if (video) {
      video.muted = true; // Отключение звука для предотвращения блокировки автовоспроизведения
      video.play().catch((error) => {
        //console.error('Автовоспроизведение заблокировано', error);
      });
    }
  }
}
</script>

<style lang="scss" scoped>
.container {
  position: relative;

  .mainBigBlockEn {
    min-height: 760px;
    overflow: hidden;
    gap: 48px;
    border-radius: 64px;
    background-position: center top;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    margin: 80px auto 100px auto;
    padding: 80px 85px 80px 60px;
    max-width: 1440px;
    width: 100%;

    video {
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      width: 100%;
    }

    h1 {
      font-family: Playfair Display;
      font-size: 11rem;
      font-weight: 400;
      line-height: 11rem;
      letter-spacing: 0.01em;
      display: flex;
      flex-direction: column;
      gap: .8rem;
      margin-bottom: 48px;
      position: relative;

      .sp2 {
        margin-left: auto;
      }

      .sp5 {
        margin-left: auto;
      }
    }

    @media (max-width: 1440px) {
      h1 {
        font-family: Playfair Display;
        font-size: 11rem;
        font-weight: 400;
        line-height: 11rem;
        letter-spacing: 0.01em;
        display: flex;
        flex-direction: column;
        gap: .8rem;
        margin-bottom: 48px;
        position: relative;

        .sp2 {
          margin-left: 68px;
        }

        .sp4 {
          margin-left: 100px;
        }

        .sp5 {
          margin-left: 0px;
        }
      }
    }
    @media (max-width: 1024px) {
        min-height: auto;
        padding: 80px 85px 40px 60px;
    }
    @media (max-width: 991px) {
      padding: 24px;
      padding-top: 137px;
      video {
        height: 645px;
        width: auto;
      }
      h1 {
        font-size: 8rem;
        font-weight: 400;
        line-height: 8rem;
        letter-spacing: 0.01em;
      }
    }
    @media (max-width: 767px) {
      border-radius: 32px;
      padding: 0;
      padding-top: 85px;
      min-height: 468px;
      margin-bottom: 0;
      video {
        height: 470px;
        width: auto;
      }
      h1 {
        font-size: 4rem;
        font-weight: 400;
        line-height: 4rem;
        letter-spacing: 0.01em;
        text-align: center;
        .sp1 {
          margin: 0 auto;
        }
        .sp2 {
          margin: 0 auto;
        }
        .sp3 {
          margin: 0 auto;
        }
        .sp4 {
          margin: 0 auto;
        }
        .sp5 {
          margin: 0 auto;
        }
      }
    }
  }
}
</style>