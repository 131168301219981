<template>
  <div class="filter_tabs">
    <slot name="before"/>
    <div
        v-if="allBtn"
        class="filter_tabs_el"
        :class="{'active': tabActive.length == 0}"
    >
      <cl-button
          type="light btn-tag"
          :class="{'active': tabActive.length == 0}"
          @click="tabActive.splice(0, tabActive.length); $emit('changeTab')"
      >{{ allBtn }}</cl-button>
    </div>
    <filter-tabs-el
        v-for="tabEl in tabList"
        :key="tabEl"
        :tabActive="tabActive"
        :tabEl="tabEl"
        :tabSample="tabSample"
        :label="label"
        :tabKey="tabKey"
        :allBtn="allBtn"
        :maxAmount="tabList.length"
        @changeTab="$emit('changeTab', tabEl)"
    ></filter-tabs-el>
  </div>
</template>


<script>
/*
* tabSample - если проставить 1 будет выбиратся тока 1 элемент
* label - откуда берем текст у элемента масива
* allBtn - текст кнопки все, так же проверяет наличие
*/
import filterTabsEl from "@/components/library/FilterTabsEl";

export default {
  name: "filterTabs",
  components: {filterTabsEl},
  props: {
    tabActive: {
      type: Object,
      default: () => []
    },
    tabList: {
      type: Object,
      default: () => []
    },
    label: {
      type: String
    },
    // атрибут, отвечающий за то, какое свойство объекта будет возвращаться в массиве активных табов
    tabKey: {
      type: String,
    },
    tabSample: {
      type: Boolean,
      default: false
    },
    allBtn: {
      type: String
    }
  },
  methods: {

  }
}
</script>

<style lang="scss">
.filter_tabs {
  display: flex;
  flex-wrap: wrap;
  gap: 24px;
  &.filter_tabs_48 {
    &.filter_tabs_48p {
      .btn {
        padding-left: 21px;
        padding-right: 21px;
      }
    }
    .btn {
      min-height: 48px;
    }
  }
  &.filter_tabs_bor {
    white-space: nowrap;
    overflow: auto;
    flex-wrap: wrap;
    @media (max-width: 991px) {
      flex-wrap: nowrap;
      scrollbar-width: none;
      &::-webkit-scrollbar {
        -webkit-appearance: none;
        width: 0px;
        height: 0px;
      }
      &::-webkit-scrollbar-button {
        width: 0px;
        height: 0px;
      }
      &::-moz-scrollbar-button {
        width: 0px;
        height: 0px;
      }
    }

    .filter_tabs_el {
      &.active {
        //pointer-events: none;
        .btn {
          border-color: #ED850A;
        }
      }
    }
    .btn {
      border-radius: 30px;
      border-color: rgba(255, 255, 255, 0.3);
      color: #ffffff;
      padding-left: 18px;
      padding-right: 18px;
    }
    .btn:not(.active):hover {
      background: transparent;
      border-color: #ED850A;
    }
    @media (max-width: 767px) {
      margin: 0 -16px;
      padding: 0 16px;
    }
  }
  .btn {
    border-radius: 12px;
  }
  &.filter_tabs100 {
    flex-wrap: nowrap;
    .filter_tabs_el {
      width: 100%;
    }
    .btn {
      white-space: nowrap;
      width: 100%;
    }
  }
  &.filter_tabs4 {
    flex-wrap: nowrap;
    .filter_tabs_el {
      width: calc(25% - 18px);
    }
    .btn {
      white-space: nowrap;
      width: 100%;
    }
    @media (max-width: 767px) {
      flex-wrap: wrap;
      .filter_tabs_el {
        width: auto;
      }
    }
  }
  @media (max-width: 767px) {
    gap: 12px;
  }
}
.filter_tabs_wh {
  .btn-light:not(.active) {
    border-color: #000106;
    color: #000106;
  }
}
.filter_tabs2 {
  gap: 0;
  .filter_tabs_el {
    flex: 1;
    &:first-child {
      .btn {
        border-radius: .8rem 0 0 .8rem;
      }
    }
    &:last-child {
      .btn {
        border-radius: 0 .8rem .8rem 0;
      }
    }
    &.active {
      pointer-events: none;
    }
  }
  .btn {
    font-size: 1.6rem;
    width: 100%;
    border-radius: 0;
    min-height: 42px;
    padding-top: 5px;
    padding-bottom: 5px;
    color: #FFFFFF;
  }
}
</style>