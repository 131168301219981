<template>
  <div v-if="dataInfo && Object.keys(dataInfo).length !== 0" class="sub_block_black_wrapper">
    <div class="sub_block_black">
      <container>
        <div class="doc_and_agreem">
          <div class="div_top_info">
            <h2 v-if="dataInfo.title" class="div_top_info_tit fs3">{{dataInfo.title.value}}</h2>
            <div v-if="dataInfo.text" class="div_top_info_text" v-html="dataInfo.text.value"></div>
          </div>
          <div class="div_groups" v-if="dataInfo.list?.subGroup">
            <div v-for="docGroup in dataInfo.list.subGroup">
              <h4 class="div_group_title" v-if="docGroup.subGroupName">{{ docGroup.subGroupName }}</h4>
              <row class="div_group_row">
                <column lg="6"
                        :key="file"
                        v-for="(file, index) in docGroup.file">
                  <card-document :dataDoc="file" />
                </column>
              </row>
            </div>
          </div>
        </div>
      </container>
    </div>
  </div>
</template>

<script>

import CardDocument from "@/components/cardDocument.vue";

export default {
  name: "documentsAndAgreementsWithUnder",
  components: {CardDocument},
  props: {
    /**
     * Данные из админки
     */
    dataInfo: {
      type: Object,
      require: true
    },
  },
}
</script>

<style lang="scss" scoped>
.doc_and_agreem {
  .div_top_info {
    display: flex;
    flex-direction: column;
    gap: 0.8rem;
    margin-bottom: 3.2rem;
    align-items: center;
  }

  .div_top_info_tit {
    text-align: center;
    letter-spacing: 0.02em;
    font-weight: 500;
    font-size: 3.2rem;
    line-height: 1.5;
    margin-bottom: 0;
  }

  .div_top_info_text {
    font-size: 1.6rem;
    line-height: 1.5;
    letter-spacing: 0.015em;
    color: #6B7280;
    text-align: center;
  }

  .div_groups {
    display: flex;
    flex-direction: column;
    gap: 1.6rem;
  }

  .div_group_title {
    text-transform: uppercase;
    margin-bottom: 1.6rem;
    letter-spacing: 0.02em;
    font-size: 2.4rem;
    font-weight: 500;
    line-height: 133.333%;
  }

  @media(max-width: 991px) {
    .div_top_info_tit {
      font-size: 2.4rem !important;
      line-height: 1.33;
    }
  }

  @media(max-width: 767px) {
    .div_top_info_text {
      font-size: 1.4rem;
      line-height: 1.428;
    }

    .div_group_title {
      font-size: 1.8rem;
    }
  }
}
</style>