<template>
	<container>
<!--		<h1 class="hasSubtit hasSubtitProf">Проекты</h1>-->

<!--		<row class="trusted_persone">-->
<!--			<div id="loader-measure-ms-form" class="loader-trusted" v-if="loaderProjects">-->
<!--				<loader-->
<!--					:animation-duration="3000"-->
<!--					:size="60"-->
<!--					:color="'#ED850A'"-->
<!--				></loader>-->
<!--			</div>-->

    <div class="h1_lk">
      <h1 class="hasSubtit hasSubtitProf">Проекты</h1>
      <div class="btn-holder">
        <cl-button type="light btn-middle" @click="this.$router.push({path: '/lk/filmProjects/addProject'})">
          <img src="/img/ico/orange_plus.svg" >
          Добавить проект
        </cl-button>
      </div>
    </div>

<!--		<cl-modal v-model="modalProjectsAddShow" class="modal-trusted" :closeFonActive="false" :closeNone="true">-->
<!--			<ms-form :urlData="'/api/add/film/projects/'" :submitName="'Добавить'" :btnCloseModal="true" :btnSubmitModal="true" @closeModal="modalProjectsAddShow = false" @ok="okProjects" :title="'Создание нового проекта'"/>-->
<!--		</cl-modal>-->
<!--		<cl-modal v-model="modalProjectsEditShow" class="modal-trusted" :closeFonActive="false" :closeNone="true">-->
<!--			<ms-form :urlData="`/api/update/film/projects/${activeProjects.guid}`" :submitName="'Сохранить'" :btnCloseModal="true" :btnSubmitModal="true" @closeModal="modalProjectsEditShow = false" @ok="okProjects" :title="'Редактирование проекта'"/>-->
<!--		</cl-modal>-->
		<cl-modal v-model="modalProjectsDeleteShow" modal-type="center" class="modal-trusted" :closeFonActive="false" :closeNone="true">
			<div class="delete_person">
				<div class="delete_person_img">
					<img src="/img/ico/trashOrg.svg" alt="">
				</div>
				<div class="delete_person_tit">Вы точно хотите удалить проект "{{ activeProjects.name }}"?</div>
				<div class="delete_person_buttons">
					<cl-button class="btn-light btn-middle sp_img btn-ico" img="/img/ico/cancel.svg" @click="modalProjectsDeleteShow = false">Отменить</cl-button>
					<cl-button class="btn-middle  sp_img btn-ico" img="/img/ico/checkWhite.svg" @click="removeProjects">Подтвердить</cl-button>
				</div>
			</div>
		</cl-modal>

    <cl-modal v-model="modalProjectsOk" modal-type="center">
      <template v-slot:header>
        <div class="success_block">
          <img src="/img/ico/check-circle-2.svg" alt="">
          <div>{{ `Проект ${modalProjectsName} успешно сохранен!`}}</div>
        </div>
        <div class="success_block_dop">Теперь он доступен для выбора в новых заявках</div>
      </template>
      <template v-slot:footer>
       <router-link to="/" class="btn btn-default m-0-auto">На главную</router-link>
      </template>
    </cl-modal>




    <!------------ новый вид ------------------->
    <row class="trusted_persone">
      <div id="loader-measure-ms-form" class="loader-trusted" v-if="loaderProjects">
        <loader
            :animation-duration="3000"
            :size="60"
            :color="'#ED850A'"
        ></loader>
      </div>
      <column md="12" v-for="item in projects">
        <card card-type="trusted2"
              @click="$router.push(`/lk/filmProjects/project/${item.guid}`)">
          <div class="td1">
            <template v-if="item?.logo?.imageUrl">
              <img :src="item?.logo.imageUrl" alt="">
            </template>
            <template v-else>
              <div class="not_img"></div>
            </template>
          </div>
          <div class="td2">
            <div class="div_tag">
              <span class="sp_all"><span class="sp1">Номер проекта {{ item.id }}</span></span>
              <span class="sp_all"><span class="sp1">Дата изменения <template v-if="item?.updated !== null">{{ $filters.ruDate6(new Date(item.updated)) }}</template><template v-else>{{ $filters.ruDate6(new Date(item.created)) }}</template></span></span>
            </div>
            <h3 v-if="item?.name">{{ item.name }}</h3>
            <div class="div_type">
              <template v-if="item !== null && item?.typeAudio?.name || item?.typeAudio?.comment">
                <template v-if="item?.typeAudio?.comment">
                  {{ item?.typeAudio?.comment }}
                </template>
                <template v-else>
                  {{ item?.typeAudio.name }}
                </template>
              </template>
              <template v-if="item !== null && item?.typeAudio?.name && item?.formatAudio?.name">&nbsp/&nbsp</template>
              <template v-if="item !== null && item?.formatAudio?.name">
                <template v-if="item?.typeAudio?.comment">
                  {{ item?.formatAudio?.comment }}
                </template>
                <template v-else>
                  {{ item?.formatAudio.name }}
                </template>
              </template>
            </div>
            <div class="div_type" v-if="item?.fioDirector !== null && item.fioDirector"><span class="sp_all"><span class="sp1">Режиссер:</span> {{ item.fioDirector }}</span></div>
            <div class="div_type">
<!--              <span class="sp_all"><span class="sp1">Кол-во заявок:</span> <span class="sp_color_or">12</span></span> :TODO Вернуться к выводу количества заявок позже, после доработки рпп, задача 469124-->
              <span class="sp_all" v-if="item?.company?.name"><span class="sp1">Локейшн-менеджер</span> {{ item.company.name }}</span></div>
            <div class="div_type">
              <div class="sp_all" v-if="item?.nationalFilm !== null && item?.nationalFilm">
                <span class="sp1">УНФ</span><span>{{ item.nationalFilm  }}</span>
                <svg class="svg-icon" width="24" height="24" stroke="#10B981" >
                  <use xlink:href="/svg/icons.svg#check"></use>
                </svg>
              </div>
              <div class="sp_all" v-else>
                <span class="sp1">УНФ</span>
                <svg class="svg-icon" width="24" height="24" stroke="#F43F5E" >
                  <use xlink:href="/svg/icons.svg#close"></use>
                </svg>
              </div>
              <div class="sp_all">
                ФСО
                <template v-if="item.fso">
                  <svg class="svg-icon" width="24" height="24" stroke="#10B981" >
                    <use xlink:href="/svg/icons.svg#check"></use>
                  </svg>
                </template>
                <template v-else>
                  <svg class="svg-icon" width="24" height="24" stroke="#F43F5E" >
                    <use xlink:href="/svg/icons.svg#close"></use>
                  </svg>

                </template>
              </div>
            </div>
            <Popper
                class="div_buttons"
                @click.stop
            >
              <div class="div_buttons_ev">
                <svg class="svg-icon" width="24" height="24" stroke="#ffffff">
                  <use xlink:href="/svg/icons.svg#button"></use>
                </svg>
              </div>
              <template #content>
                <div class="div_buttons_mod">
                  <div>
                    <cl-button class="btn-op"  @click="$router.push(`/lk/filmProjects/project_print/${item.guid}`)">
                      <template v-slot:before>
                        <svg class="svg-icon" width="24" height="24" stroke="#ffffff">
                          <use xlink:href="/svg/icons.svg#file-text"></use>
                        </svg>
                      </template>
                      Скачать PDF
                    </cl-button>
                  </div>
                  <div>
                    <cl-button class="btn-op" @click="$router.push(`/lk/filmProjects/editProject/${item.guid}`)">
                      <template v-slot:before>
                        <svg class="svg-icon" width="24" height="24" stroke="#ffffff">
                          <use xlink:href="/svg/icons.svg#pensil"></use>
                        </svg>
                      </template>
                      Редактировать</cl-button>
                  </div>
                  <div>
                    <cl-button class="btn-op" @click="modalProjectsDeleteShow = !modalProjectsDeleteShow; activeProjects = {'guid': item.guid, 'name': item.name}">
                      <template v-slot:before>
                        <svg class="svg-icon" width="24" height="24" stroke="#ffffff">
                          <use xlink:href="/svg/icons.svg#trash"></use>
                        </svg>
                      </template>
                      Удалить</cl-button>
                  </div>
                </div>

              </template>
            </Popper>

          </div>
        </card>
      </column>
    </row>
    <!------------- /новый вид ------------------>


	</container>
</template>

<script>

import Card from "@/components/library/Card.vue";
import MsForm from "@/components/forms/MsForm.vue";
import {mapGetters} from "vuex";
import ClModal from "@/components/library/ClModal.vue";
import ClButton from "@/components/library/ClButton.vue";

export default {
	name: "filmProjects",
	components: {ClButton, ClModal, MsForm, Card},
	props: {
		dataInfo: {
			type: Object,
			require: true
		},
	},
	data(){
		return{
			projects: null,
			modalProjectsAddShow: false,
			modalProjectsEditShow: false,
			modalProjectsDeleteShow: false,
			activeProjects: null,
			loaderProjects: true,
      modalProjectsOk: false,
      modalProjectsName: null
		}
	},
	computed: {
		...mapGetters([
			'getUser'
		]),
	},
	methods: {
		getProjects(){
			this.loaderProjects = true;
			this.axios
				.get(`/api/get/list/film/projects/`)
				.then(response => {
					this.projects = response.data.records;
				})
				.catch(error => {
					this.showError(error);
				})
				.finally(() => {
					this.loaderProjects = false;
				});
		},
		removeProjects(){
			this.modalProjectsDeleteShow = false;
			this.loaderProjects = true;
			this.axios
				.post(`/api/delete/film/projects/${this.activeProjects.guid}`)
				.then(response => {
					this.getProjects();
				})
				.catch(error => {
					this.showError(error);
				})
				.finally(() => {
					this.loaderProjects = false;
				});
		},
    okProjects(){
      this.modalProjectsName = document.getElementById('film_projects_name').value;
      this.modalProjectsOk = true;
      this.getProjects();
    },
	},
	mounted(){
		this.getProjects();
	}
}
</script>

<style lang="scss" scoped>
.trusted_advantages{
	margin-top: 2.4rem;
	img{
		width: 64px;
		height: 64px;
		object-fit: contain;
	}
}
.trusted_persone{
	margin-top: 3.6rem;
	position: relative;
	.modal_bkcg{
		position: absolute;
		inset: 0;
		width: 100%;
		height: 100%;
		background-color: rgba(0, 1, 6, 0.80);
	}
	.modal_info{
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		padding: 32px 24px;
		display: flex;
		flex-direction: column;
		gap: 12px;
		border-radius: 6px;
		background: #17191C;
		max-width: 800px;
	}
	.mInfo_row2{
		display: flex;
		gap: 12px;
		height: 64px;
		.mInfo_info{
			display: flex;
			gap: 12px;
			align-items: center;
			font-size: 16px;
			font-style: normal;
			font-weight: 400;
			line-height: 131.25%;
			letter-spacing: 0.24px;
		}
		img{
			height: 100%;
			width: auto;
			object-fit: contain;
		}
	}
}
.delete_person{
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 2.4rem;

	.delete_person_tit{
		text-align: center;
		font-size: 2.4rem;
		font-style: normal;
		font-weight: 500;
		line-height: 133.333%;
		letter-spacing: 0.048rem;
		color: #fff;
	}

	.delete_person_img{
		width: 64px;
		height: 64px;
		img {
			width: 100%;
			height: 100%;
			object-fit: contain;
		}
	}
}
.delete_person_buttons{
	display: flex;
	gap: 1.6rem;
	align-items: center;
}
.loader-trusted{
	position: absolute;
	width: 100%;
	height: 100%;
	inset: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: rgba(0, 0, 0, 0.3);
	z-index: 10;
}
.h1_lk{
  display: flex;
  justify-content: space-between;
  gap: 2.4rem;
  align-items: center;
  margin-bottom: 2rem;
  h1{
    margin-bottom: 0;
  }
}
.card-trusted2 .card-holder .div_tag {
  gap: 1rem;
}
.div_tag{
  font-size: 1.2rem;
}
.card-trusted2 .card-holder .td1 {
  overflow: hidden;
  border-radius: 1.2rem;
  img{
    width: calc(100% + 1rem);
    height: calc(100% + 1rem);
    object-fit: cover;
    top: -.2rem;
    position: relative;
  }
}

.not_img{
  width: 100%;
  height: 100%;
  border-radius: 1.2rem;
}
</style>