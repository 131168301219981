<template>
  <div class="container">
    <slot />
  </div>
</template>

<script>
/**
 * Компонент контейнера
 */
export default {
  name: "container"
}
</script>

<style lang="scss">
  .container {
    padding: 0 48px;
    width: 100%;
    margin: 0 auto;
    max-width: 1728px;
    @media (max-width: 1440px) {
      padding: 0 32px;
    }
    @media (max-width: 991px) {
      padding: 0 24px;
    }
    @media (max-width: 767px){
      padding: 0 16px;
    }
    > .container {
      padding: 0;
    }
  }
  .container2 {
    max-width: 1176px;
  }
  .container4{
    max-width: 1296px;

  }


  .body_container {
    .container {
      max-width: 1296px;
      @media (max-width: 1440px) {
        .container {
          max-width: 1264px;
        }
      }
    }
  }

</style>