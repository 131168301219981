<template>
  <div class="my-select"
       tabindex="0"
       :data-value="value[valueName]"
       :class="{'my-select--above': isAbove}"
       v-click-away="deactivate"
  >
    <select v-show="false"
            class="form-control"
            :id="id"
            :name="multiple ? `${name}[]` : name"
            :data-name="name"
            :multiple="multiple"
            :aria-readonly="readonly"
            :required="required"
            :data-srcmap="dataSrcmap"
            :data-outername="dataOutername"
            :data-qa-column="dataQaColumn"
            @select="selectToView"
    >
      <option value=""></option>
      <template v-for="(item, index) of items.sort((a, b) => a[trackBy].toLowerCase() > b[trackBy].toLowerCase())" :key="index">
        <option :value="item[valueName]"
                :selected="initValue === item[valueName] || (Array.isArray(initValue) && initValue.includes(item[valueName]))"
                :disabled="value.length === choiceCount && multiple && choiceCount > 0">
          {{item[trackBy]}}
        </option>
        <template v-if="item.type === groupType">
          <template v-for="(subItem, subIndex) of item[groupLabel]" :key="subItem">
            <option :value="subItem[valueName]"
                    :selected="initValue === subItem[valueName] || (Array.isArray(initValue) && initValue.includes(subItem[valueName]))"
                    :disabled="value.length === choiceCount && multiple && choiceCount > 0"
            >{{subItem[trackBy]}}</option>
          </template>
        </template>
      </template>
    </select>
    <div class="my-select--select" @click="activate" :class="{'open': isOpen, 'disabled': disabled}">
      <div class="my-select--label">
        <div class="my-select--placeholder" v-if="!(value.length !== 0 || searchField.length !== 0)">{{ placeholder || "" }}</div>
        <template v-else>
          <template v-if="!multiple">
            <span class="my-select--single">{{ value[trackBy] }}</span>
          </template>
          <template v-else>
            <span class="my-select--tags-holder">
              <span class="my-select--tags" v-for="item of value">{{item[trackBy]}} <span @click.stop="selectItemMultiple(item)">&#10006;</span></span>
            </span>
          </template>
          <div class="my-select--clear" @click.stop="clearValue">&#10006;</div>
        </template>
        <input type="text" v-if="value.length === 0 || multiple" class="my-select--search" @focus="$emit('focus', $event)" @blur="$emit('blur', $event)" @input="searchHandler" v-model="searchField" ref="search">
      </div>
      <div class="my-select--toggle" @click="toggle">
      </div>
    </div>
    <ul class="my-select--list" v-if="isOpen" ref="list">
      <perfect-scrollbar @ps-scroll-y="onScroll" ref="scrollbar" :options="{wheelPropagation: false}">
        <template v-for="list of formattedList" v-if="searchField.length === 0">
          <template v-for="item of list.sort((a, b) => a[trackBy].toLowerCase() > b[trackBy].toLowerCase())">
            <list-item @select-item="selectItem" :item="item" :track-by="trackBy" :disabled="value.length === choiceCount && multiple && choiceCount > 0">
              <template #group v-if="item.type === groupType">
                <ul class="my-select--group-list">
                  <template v-for="subItem of item[groupLabel].sort((a, b) => a[trackBy].toLowerCase() > b[trackBy].toLowerCase())">
                    <list-item @select-item="selectItem($event, item)" :item="subItem" :track-by="trackBy" :disabled="value.length === choiceCount && multiple && choiceCount > 0" />
                  </template>
                </ul>
              </template>
            </list-item>
          </template>
        </template>
        <template v-else>
          <list-item @select-item="selectItem" v-for="item of filteredList" :item="item" :track-by="trackBy" />
          <span class="my-select--no-result" v-if="filteredList.length === 0">Нет результатов</span>
        </template>
      </perfect-scrollbar>
    </ul>
    <template v-if="informer?.length">
      <div class="my-select--informer" ref="selectInformer" :class="{'is-above': !isAbove}" :style="`height: ${informerHeight}`" v-if="showInformer">
        <template v-if="informerHeight !== 'auto'">
          <perfect-scrollbar>
            {{informer}}
          </perfect-scrollbar>
        </template>
        <template v-else>
          {{informer}}
        </template>
      </div>
    </template>
  </div>
</template>

<script>
import listItem from "@/components/forms/my-select/ListItem";
import "vue3-perfect-scrollbar/dist/vue3-perfect-scrollbar.css"
import { directive } from 'vue3-click-away'

/**
 * Компонент поля селекта
 */
export default {
  name: "MySelect",
  components: {listItem},
  props: {
    items: {
      type: Array,
      required: true,
      default: []
    },
    placeholder: {
      type: String
    },
    disabled: {
      type: Boolean
    },
    groupLabel: {
      type: String
    },
    groupType: {
      type: String
    },
    valueName: {
      type: String,
      required: true,
      default: "data"
    },
    multiple: {
      type: Boolean
    },
    trackBy: {
      type: String,
      required: false,
      default: "label"
    },
    id: {
      type: String
    },
    name: {
      type: String,
      required: true
    },
    initValue: [String, Object, Array],
    readonly: Boolean,
    required: Boolean,
    dataSrcmap: String,
    dataOutername: String,
    dataQaColumn: String,
    informer: String,
    choiceCount: Number
  },
  directives: {
    clickAway: directive
  },
  data() {
    return {
      isOpen: false,
      isAbove: false,
      searchField: "",
      list: this.items.map(el => {
        const addSelectMark = item => {
          item.isSelected = false
          return item
        }
        let formattedItem = addSelectMark(el)
        if (formattedItem.type === this.groupType) {
          formattedItem[this.groupLabel] = formattedItem[this.groupLabel].map(subEl => addSelectMark(subEl))
        }
        return formattedItem
      }),
      formattedList: [],
      filteredList: [],
      listDivider: 40,
      listCounter: 1,
      value: [],
      initialValue: this.initValue,
      showInformer: false,
      informerHeight: "auto"
    }
  },
  mounted() {
    this.init()
  },
  methods: {
    init() {
      this.items.forEach(item => {
        if (this.initialValue === item.data) {
          this.selectItemSolo(item)
        } else if (this.initialValue.includes(item.data)) {
          this.selectItemMultiple(item)
        }
        if (item.type === this.groupType) {
          item[this.groupLabel].forEach(subItem => {
            if (this.initialValue === subItem.data) {
              this.selectItemSolo(subItem, item)
            } else if (this.initialValue.includes(subItem.data)) {
              this.selectItemMultiple(subItem, item)
            }
          })
        }
      })
    },
    /**
     * Функция активации селекта
     */
    activate (event) {
      if (this.isOpen || this.disabled || this.readonly) return
      this.formattedList = []
      this.listOptimizeHandler()
      this.isOpen = true
      this.showInformer = true
      setTimeout(() => {
        const targetPosition = this.$refs.list.getBoundingClientRect().bottom
        this.isAbove = targetPosition > document.documentElement.clientHeight
        if (this.$refs.selectInformer) {
          const informerRect = this.$refs.selectInformer.getBoundingClientRect()
          if (informerRect.top < 0) {
            this.informerHeight = `${informerRect.top + informerRect.height}px`
          } else {
            this.informerHeight = "auto"
          }
        }
      })
      this.$emit("focus", event)
    },
    /**
     * Функция деактивации селекта
     */
    deactivate (event) {
      if (!this.isOpen) return
      this.listDivider = 40
      this.formattedList = []
      this.isOpen = false
      this.isAbove = false
      this.showInformer = false
      this.$emit("blur", event)
    },
    /**
     * Функция активации\деактивации селекта (нужна для переключателя)
     * @param e - событие
     */
    toggle(e) {
      if (this.isOpen) { // если селект активирова
        e.stopPropagation() // отключаем всплытие
        this.deactivate() // закрываем
      } else { // если закрыт
        this.activate() // открываем без запрета на всплытие
      }
    },
    /**
     * Функция для поля поиска в селекте
     */
    searchHandler() {
      if (!this.readonly) {
        // переменная для хранения отфильтрованных элементов
        const filteredItems = this.list.filter(item =>
          item.type !== this.groupType && item[this.trackBy] // если тип элемента не группа, то элемент с нужным именем
            .toLowerCase().includes(this.searchField.toLowerCase()) // приводится к нижнему регистру и проверяется на наличие подстроки
          && this.searchField.length > 0) // поле поиска при этом не должно быть пустым
        // то же самое проводим для групп элементов
        this.list.forEach(item => {
          // если элемент является группой элементов
          if (item.type === this.groupType) {
            const filteredGroupItems = item[this.groupLabel].filter(subItem =>
              subItem[this.trackBy].toLowerCase().includes(this.searchField.toLowerCase())
              && this.searchField.length > 0)
            filteredItems.push(...filteredGroupItems) // добавляем полученный массив элементов в общий
          }
        })
        this.$emit("search-filled", this.searchField.length > 0)
        this.searchField.length === 0 && this.deactivate()
        this.filteredList = filteredItems // заполняем свойство в data для последствующего вывода
      }
    },
    /**
     * Снятие выбора с элемента
     * @param list - список данных
     * @param groupLabel - лейбл группы
     * @param groupType - тип группы (если есть)
     */
    clearSelected(list, groupLabel, groupType) {
      if (!this.readonly) {
        this.showInformer = false
        list.forEach(item => {
          item.isSelected = false // "выключаем" элемент
          if (item.type === groupType) { // если элемент - группа
            item[groupLabel].forEach(subItem => {
              subItem.isSelected = false // отключаем элементы группы
            })
          }
        });
        [...document.getElementById(this.id).options].forEach(option => option.selected = false)
      }
    },
    /**
     * Очистка значения селекта
     */
    clearValue() {
      if (!this.readonly) {
        this.showInformer = false
        this.clearSelected(this.list, this.groupLabel, this.groupType)
        this.value = []
        this.searchField = ""
        this.$emit("search-filled", false)
        this.$emit("select", this.value)
        document.getElementById(this.id).dispatchEvent(new Event("select"))
        this.deactivate()
        this.$emit("blur")
      }
    },
    /**
     * Назначение выбора элемента
     * @param item - элемент
     * @param parentItem - родительский элемент (для групп)
     */
    selectItem(item, parentItem = null) {
      this.showInformer = false
      if (this.multiple === true) { // распределение функций для определенных случаев
        this.selectItemMultiple(item, parentItem)
      } else {
        this.selectItemSolo(item, parentItem)
      }
    },
    /**
     * Функция для обновления поля селекта под форму
     * @param values - данные
     */
    addToSelect(values) {
      const select = new Event("select")
      if (!this.multiple) {
        document.getElementById(this.id).querySelector(`[value="${values}"]`).selected = true
      } else {
        values.forEach(value => {
          document.getElementById(this.id).querySelector(`[value="${value}"]`).selected = true
        })
      }
      document.getElementById(this.id).dispatchEvent(select)
    },
    /**
     * Функция для одиночного выбора элемента
     * @param item - элемент
     * @param parentItem - родительский элемент (для групп)
     */
    selectItemSolo(item, parentItem) {
      this.value = item
      this.clearSelected(this.list, this.groupLabel, this.groupType)
      if (!parentItem) {
        this.list[this.list.indexOf(item)].isSelected = true
      } else {
        this.list[this.list.indexOf(parentItem)][this.groupLabel][parentItem[this.groupLabel].indexOf(item)].isSelected = true
      }
      this.addToSelect(item[this.valueName])
      // передача события в родительский компонент
      this.$emit('select', item[this.valueName])
      this.searchField = ""
      this.isOpen = false
      this.$emit("blur", item)
    },
    /**
     * Функция для множественного выбора элементов
     * @param item - элемент
     * @param parentItem - родительский элемент (для групп)
     */
    selectItemMultiple(item, parentItem) {
      if ((this.choiceCount !== 0 && this.value.length < this.choiceCount || item.isSelected) || this.choiceCount === 0) {
        if (!parentItem) {
          this.list[this.list.indexOf(item)].isSelected = !item.isSelected
        } else {
          this.list[this.list.indexOf(parentItem)][this.groupLabel][parentItem[this.groupLabel].indexOf(item)].isSelected = !item.isSelected
        }
        if (item.isSelected) {
          this.value.push(item)
        } else {
          this.value.splice(this.value.indexOf(item), 1)
        }
        let valueArr = this.value.map(el => el[this.valueName]);
        [...document.getElementById(this.id).options].forEach(option => option.selected = false);
        //console.log([...document.getElementById(this.id).options])
        this.addToSelect(valueArr)
        this.$emit('select', valueArr)
      }
    },
    /**
     * Функция для постепенного заполнения списка элементов
     * @param divider - делитель, по которому будет заполняться список
     */
    listOptimizeHandler(divider = 0) {
      let segmentedList = this.list.slice((divider * 35), (divider * 35) + 35)
      this.formattedList.push(segmentedList)
    },
    /**
     * Функция для ленивой отрисовки списка
     * @param event
     */
    onScroll(event) {
      let result = event.target.scrollTop / this.listDivider
      if (result > 30) {
        this.listOptimizeHandler(this.listCounter)
        this.listDivider += 50
        this.listCounter++
      }
    },
    selectToView(event) {
      if (event.detail?.custom) {
        let select = event.target
        this.initialValue = select.value
        this.init()
      }
    }
  }
}
</script>

<style lang="scss">
  .my-select {
    width: 100%;
    position: relative;
    &--select {
      width: 100%;
      display: flex;
      min-height: 40px;
      border-radius: 3px;
      font-size: 16px;
      line-height: 1.4;
      padding: 0 15px 5px;
      background: #ffffff;
      border: 1px solid #cdd1e0;
      box-sizing: border-box;
      font-weight: 400;
      color: #495057;
      position: relative;
      transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
      cursor: text;
      &:focus {
        color: #495057;
        box-shadow: 0 0 0 0.2rem rgba(36, 86, 130, 0.25);
      }
    }
    &--placeholder {
      color: #adadad;
      padding-top: 5px;
      position: absolute;
      top: 4px;
      left: 15px;
      pointer-events: none;
      text-overflow: ellipsis;
      overflow: hidden;
      max-width: calc(100% - 48px);
      white-space: nowrap;
    }
    &--no-result {
      padding: 5px 15px;
      min-height: 40px;
      display: flex;
      width: 100%;
      align-items: center;
    }
    &--search {
      border: none;
      height: calc(100% - 5px);
      flex: 1;
      min-width: 100px;
      margin-top: 5px;
      padding: 0;
    }
    &--label {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      padding-right: 45px;
      width: 100%;
    }
    &--single {
      padding-top: 5px;
    }
    &--toggle {
      width: 40px;
      height: 100%;
      position: absolute;
      top: 0;
      right: 0;
      transition: transform .2s ease;
      cursor: pointer;
      .open & {
        transform: rotate(180deg);
      }
      &:before {
        position: absolute;
        right: 15px;
        top: 50%;
        transform: translateY(-50%);
        color: #999;
        border-style: solid;
        border-width: 5px 5px 0;
        border-color: #999 transparent transparent;
        content: "";
      }
    }
    &--clear {
      position: absolute;
      top: 50%;
      right: 35px;
      transform: translateY(-50%);
      display: flex;
      height: 15px;
      width: 15px;
      line-height: 1;
      cursor: pointer;
    }
    &--list {
      display: block;
      padding: 0;
      list-style: none;
      position: absolute;
      top: 100%;
      margin: 2px 0 0;
      border: 1px solid #cdd1e0;
      width: 100%;
      border-radius: 3px;
      background-color: #fff;
      z-index: 10;
      .ps {
        max-height: 350px;
      }
      .my-select--above & {
        top: auto;
        margin: 0 0 2px;
        bottom: 100%;
      }
    }
    &--group-list {
      list-style: none;
      padding: 0;
      > li {
        padding-left: 10px;
      }
    }
    &--tags {
      background: rgba(0, 0, 0, 0.04);
      color: #000;
      padding: 7px 40px 6px 14px;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      max-width: 240px;
      border-radius: 14px;
      margin: 5px 5px 0 0;
      font-size: 14px;
      position: relative;
      cursor: default;
      > span {
        position: absolute;
        right: 10px;
        height: 100%;
        top: 0;
        display: flex;
        align-items: center;
        cursor: pointer;
      }
    }
  }
</style>