<template>
  <div class="restaurant_menu">
    <container>
      <div class="menu_wrapper">
        <swiper
            slides-per-view="auto"
            :slides-per-group-auto="true"
            :space-between="16"
            :breakpoints="{
                  991: {
                    spaceBetween: 27,
                    slidesPerView: 4
                  },
            }"

        >
          <swiper-slide v-for="(item, index) in dataInfo.menuItems" :key="index">
            <a target="_blank" :href="item.pdf.value.src" class="menu_item">
              <img class="menu_img" v-if="item.img" :src="item.img.value.src.big.url" alt="">
              <div class="menu_txt" v-if="item.text">{{ item.text.value }}</div>
            </a>
          </swiper-slide>
        </swiper>
      </div>
    </container>
  </div>
</template>

<script>

import {Swiper, SwiperSlide} from "swiper/vue";
export default {
  name: "restaurantMenu",
  components: {SwiperSlide, Swiper},
  props: {
    dataInfo: {
      type: Object,
      require: true
    },
  },
  data() {
    return {
      menuItems: [],
    }
  },
}

</script>

<style lang="scss" scoped>

.restaurant_menu {
  padding: 4rem 0;

  .swiper-slide {
    width: auto;
    flex: none;
  }

  .menu_item {
    flex: none;
    display: flex;
    gap: 1.6rem;
    height: 12.8rem;
    border: 0.1rem solid #6B7280;
    padding: 2.4rem 1.6rem;
    border-radius: 1.2rem;
    align-items: center;
  }

  .menu_img {
    width: 8rem;
    height: 8rem;
    object-position: center;
    object-fit: contain;
    border-radius: 1.2rem;
  }

  .menu_txt {
    font-family: Raleway;
    font-size: 1.8rem;
    font-weight: 500;
    line-height: 1.33;
    letter-spacing: 0.02em;
    text-decoration: underline;
    text-transform: uppercase;
  }

  @media (max-width: 991px) {
    .menu_item {
      max-width: 28.6rem;
    }
  }

  @media (max-width: 767px) {
    :deep(.swiper) {
      margin: 0 -16px;
      padding: 0 16px;
    }
  }
}

</style>