<template>
  <div v-if="dataInfo && Object.keys(dataInfo).length > 0" class="cinema_banners_mos">
    <container>
      <swiper
          v-if="dataInfo.banners?.length > 0"
          :modules="modules"
          :slides-per-view="'auto'"
          :spaceBetween="8"
          :navigation="{
                    prevEl: prev,
                    nextEl: next,
                  }"
          :autoplay="{
            delay: 5000,
            disableOnInteraction: false,
            pauseOnMouseEnter: true,
          }"
          :centered-slides="true"
          :loop="true"
          :breakpoints="{
            768: {
              slidesPerView: 2.4,
              centeredSlides: false,
              centeredSlidesBounds: false,
              spaceBetween: 24,
            }
          }"
          :mousewheel="{
            invert: true,
            forceToAxis: true,
          }"
      >
        <swiper-slide
            v-for="banner in dataInfo.banners"
            :key="banner">
          <a :href="banner.btnLink?.value" class="cinema_banners_mos__card_wrapper" :class="{'cursor-default': !banner.btnAnchor && !banner.btnLink}" @click="customRedirect(banner.btnAnchor)">
            <div class="cinema_banners_mos__card_background">
              <img v-if="banner.img" :src="getImage(banner.img, 'hd')" alt="">
            </div>
            <div class="cinema_banners_mos__card">
              <div class="div_content">
                <div v-if="banner.categories" class="div_categories">{{banner.categories.value}}</div>
                <div class="div_title">{{banner.title ? banner.title.value : ''}}</div>
                <div v-if="banner.text" class="div_text" v-html="banner.text.value"></div>
                <div v-if="banner.btnText && (banner.btnLink || banner.btnAnchor)" class="div_btn">
                  <cl-button>{{banner.btnText.value}}</cl-button>
                </div>
              </div>
            </div>
          </a>
        </swiper-slide>
        <div ref="prev" class="swiper-button-prev"></div>
        <div ref="next" class="swiper-button-next"></div>
      </swiper>
    </container>
  </div>
</template>

<script setup lang="ts">
import Container from "@/components/library/Container.vue";
import {Swiper, SwiperSlide} from "swiper/vue";
import ClButton from "@/components/library/ClButton.vue";
import {Autoplay, Mousewheel, Navigation} from "swiper";
import {ref, Ref} from "vue";
import {SwiperModule} from "swiper/types/shared";
import {ICinemaBannersMos} from "@/interface/ICinemaBannersMos";
import {Router, useRouter} from 'vue-router'

/**
 * Пропы
 */
const props = defineProps<{
  /**
   * Данные из админки
   * @type ICinemaBannersMos
   */
  dataInfo: ICinemaBannersMos,
}>();

/**
 * useRouter
 */
const router: Router = useRouter();
/**
 * Переход на другую страницу или по якорю
 * @param {string} btnAnchor - Якорь
 */
function customRedirect(btnAnchor: string | undefined): void {
  if (btnAnchor) {
    if(btnAnchor[0] === '#' && btnAnchor.indexOf('tabCategoryMoskino') !== -1) {
      const filter = document.querySelector('#filterTopCategory');
      if (filter) {
        filter.setAttribute('data-filter', btnAnchor);
      }
    } else {
      router.push(btnAnchor.replace('/api/page', ''));
    }
  }
}

/**
 * Реф на стрелочку назад
 */
const prev: Ref<object | null> = ref(null);
/**
 * Реф на стрелочку вперед
 */
const next: Ref<object | null> = ref(null);
/**
 * Модули для свайпера
 * @default [Navigation, Autoplay]
 */
const modules: Array<SwiperModule> = [Navigation, Autoplay, Mousewheel];

</script>

<style lang="scss" scoped>

.cinema_banners_mos {
  padding: 1.2rem 0 4rem;
  overflow: hidden;

  :deep(.swiper) {
    border-radius: 1.2rem;
    margin-left: -120px;
    margin-right: -120px;
    padding-left:  120px;
    padding-right: 120px;

    &::before, &::after {
      content: "";
      position: absolute;
      top: -0.5rem;
      width: 120px;
      height: calc(100% + 1rem);
      z-index: 2;
      pointer-events: none;
      -webkit-user-select: none;
      -moz-user-select: none;
      user-select: none;
    }

    &::before {
      left: 0;
      background: linear-gradient(90deg, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0) 100%);
    }

    &::after {
      right: 0;
      background: linear-gradient(-90deg, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0) 100%);
    }

    .swiper-slide {
      width: 48.6rem;
    }

    .swiper-button-prev,
    .swiper-button-next {
      width: 4.8rem;
      opacity: 0;
      background: rgba(255, 255, 255, 0.1);
      backdrop-filter: blur(2px);
      border-radius: 0;

      &::before {
        width: 1.2rem;
        height: 2.4rem;
        background-size: cover;
      }

      &:hover {
        opacity: 1;
      }
    }
  }

  &__card_wrapper {
    overflow: hidden;
    isolation: isolate; // Для safari, чтобы углы при transition не пропадали
    height: 48.6rem;
    border-radius: 2.4rem;
    position: relative;
    display: block;

    &:hover {
      .cinema_banners_mos__card_background {
        transform: scale(1.08);
      }

      .div_btn {
        .btn.btn-default {
          border: 1px solid #F9BA6C;
          background: #ED870A;
          box-shadow: 0px 0px 0px 0px rgba(237, 135, 10, 0.05), 0px 1px 2px 0px rgba(237, 135, 10, 0.05), 0px 3px 3px 0px rgba(237, 135, 10, 0.04), 0px 8px 5px 0px rgba(237, 135, 10, 0.03), 0px 14px 6px 0px rgba(237, 135, 10, 0.01), 0px 22px 6px 0px rgba(237, 135, 10, 0), inset 0 0 0 1px #F9BA6C;
        }
      }
    }

    &:focus {
      .div_btn {
        .btn.btn-default {
          border: 1px solid #FFF;
          background: #ED850A;
        }
      }
    }

    &:active {
      .div_btn {
        .btn.btn-default {
          background: none;
          border: 1px solid #ED850A;
          color: #ED850A;
        }
      }
    }
  }

  &__card_background {
    height: 100%;
    background: rgba(12, 12, 14, 1);
    transition: transform .5s ease-in, -webkit-transform .5s ease-in;
    position: relative;

    &::before {
      content: '';
      position: absolute;
      top: -10px;
      left: -10px;
      width: calc(100% + 20px);
      height: calc(100% + 20px);
      background: linear-gradient(180deg, rgba(0, 1, 6, 0.29) 49%, rgba(0, 1, 6, 0.8) 69%), rgba(12, 12, 14, 0.8);
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &__card {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: end;

    .div_content {
      padding: 2.4rem;

      > :last-child {
        margin-bottom: 0;
      }
    }

    .div_categories {
      font-size: 1.4rem;
      font-weight: 400;
      line-height: 1.428;
      letter-spacing: 0.015em;

      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      overflow: hidden;
      max-height: 2rem;
    }

    .div_title {
      font-size: 2.4rem;
      font-weight: 600;
      line-height: 1.33;
      letter-spacing: 0.02em;
      margin-bottom: 0.8rem;
      min-height: 3.2rem;

      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      max-height: 6.4rem;
    }

    .div_text {
      font-size: 1.4rem;
      font-weight: 400;
      line-height: 1.428;
      letter-spacing: 0.015em;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      max-height: 6rem;
      overflow: hidden;
      margin-bottom: 1.6rem;

      > :deep(p) {
        margin-bottom: 0.8rem;
      }

      > :deep(p:last-child) {
        margin-bottom: 0;
      }
    }

    .div_btn {
      .btn {
        min-height: 4.8rem;
        padding: 0 2.4rem;
      }
    }
  }

  .swiper-slide-active, .swiper-slide-next {
    .cinema_banners_mos__card_background {
      &::before {
        background: linear-gradient(180deg, rgba(0, 1, 6, 0.29) 49%, rgba(0, 1, 6, 0.8) 69%);
      }
    }
  }

  @media (max-width: 1439px) {
    :deep(.swiper) {
      margin-left: calc((-100vw + 1296px)/2 - 32px);
      margin-right: calc((-100vw + 1296px)/2 - 32px);
      padding-left:  calc((100vw - 1296px)/2 + 32px);
      padding-right: calc((100vw - 1296px)/2 + 32px);

      &::before, &::after {
        width: calc((100vw - 1296px)/2 + 32px);
      }
    }
  }

  @media (max-width: 1295px) {
    :deep(.swiper) {
      margin-left: -32px;
      margin-right: -32px;
      padding-left: 32px;
      padding-right: 32px;

      &::before, &::after {
        width: 32px;
      }
    }
  }

  @media (max-width: 991px) {
    :deep(.swiper) {
      margin-left: -24px;
      margin-right: -24px;
      padding-left: 24px;
      padding-right: 24px;

      &::before, &::after {
        width: 24px;
        background: none;
      }
    }

    &__card {
      .div_title {
        -webkit-line-clamp: 4;
        max-height: 12.8rem;
      }
    }
  }

  @media (max-width: 767px) {
    :deep(.swiper) {
      margin-left: -16px;
      margin-right: -16px;
      padding-left: 16px;
      padding-right: 16px;

      &::before, &::after {
        width: 16px;
      }

      .swiper-slide {
        width: 38.35rem;
      }
    }

    &__card_wrapper {
      height: 38.35rem;
      border-radius: 0.6rem;
    }

    &__card {
      .div_content {
        padding: 1.6rem;
      }

      .div_title {
        font-size: 1.8rem;
      }
    }

    .swiper-slide-next {
      .cinema_banners_mos__card_background {
        &::before {
          background: linear-gradient(180deg, rgba(0, 1, 6, 0.29) 49%, rgba(0, 1, 6, 0.8) 69%), rgba(12, 12, 14, 0.8);
        }
      }
    }
  }

  // centeredSlidesBounds=true - работает нормально только, когда слайд (swiper-slide) больше половины. Иначе свайп в право не будет активировать крайний левый слайд
  @media (max-width: 600px) {
    :deep(.swiper) {
      .swiper-slide {
        width: 30rem;
      }
    }

    &__card_wrapper {
      height: 30rem;
    }
  }
}

</style>