<template>
  <teleport to="#site_header_td3">
    <div class="header_login"  :class="{'active': isOpen}">
      <div
          v-if="getIsAuthorization"
          class="header_loginUser"
          @click="isOpen = !isOpen"
          v-click-away="hideOpen"
      >
        <!--      <notifications-headers/>-->
        <div class="header_loginUserPeople">
          <div class="header_loginUserPeopleTd1 header_logo" :class="{'active': isOpen}">
            <img :src="userAvatar" v-if="userAvatar">
          </div>
          <div
              class="header_loginUserPeopleTd2"
          >
            <div v-if="getIsCompany" class="div_tit fs14">{{ getCompany.name }}</div>
            <div v-else-if="getUser.lastName" class="div_tit fs14">{{ getUser.lastName }}
              {{ getUser.firstName ? getUser.firstName[0] + '.' : '' }}
              {{ getUser.middleName ? getUser.middleName[0] + '.' : '' }}
            </div>
          </div>
          <div class="header_loginUserPeopleTd3"><img
              src="/img/ico/chevron-down.svg" class="img_wh" alt=""></div>
        </div>

        <transition name="pop">
          <div class="header_loginUserPopup fs16"
               v-show="isOpen"
          >
            <div class="header_loginUserPopupTop">
              <template v-if="getIsCompany">{{ getCompany.name }}</template>
              <template v-else="getUser.lastName">{{ getUser.lastName }}
                {{ getUser.firstName ? getUser.firstName[0] + '.' : '' }}
                {{ getUser.middleName ? getUser.middleName[0] + '.' : '' }}
              </template>
            </div>
            <perfect-scrollbar class="div_scroll" @ps-scroll-y="onScrollEvent" v-if="dataInfo?.menu && dataInfo?.menu.length > 0">
              <div v-if="dataInfo?.menu"
                   class="header_loginUserPopupMenu"
              >
                <router-link class="div_a"
                             v-for="link in dataInfo.menu"
                             :to="link.url"
                >
                  <span class="dt1"><img :src="link.icon.url" alt=""></span>
                  <span class="dt2">{{ link.title }}</span>
                </router-link>
              </div>
              <my-company-selected type="headerPopUp" :userAvatar="getPersonData?.logo?.imageUrl ?? false"/>
            </perfect-scrollbar>
            <a href="/auth/sudir/out" class="div_a">
              <div class="dt1">
                <svg class="svg-icon" width="24" height="24">
                  <use xlink:href="/svg/icons.svg#exit"></use>
                </svg>
              </div>
              <div class="dt2">Выйти</div>
            </a>
          </div>
        </transition>
      </div>
      <div class="header_loginUser"
          @click="isOpen = !isOpen"
          v-click-away="hideOpen"
          v-else>
        <div class="btn_cover">
          <div class="header_loginUserPeople">
            <div class="header_loginUserPeopleTd1 header_logo unlogin" :class="{'active': isOpen}">
  <!--            <div class="btn_cover">-->
                <img src="../assets/img/white_avatar.svg">
  <!--            </div>-->
            </div>
            <div
                class="header_loginUserPeopleTd2"
            >
            </div>
            <div class="header_loginUserPeopleTd3">
              <img src="/img/ico/chevron-down.svg" class="img_wh" alt=""></div>
          </div>
        </div>
        <transition name="pop">
          <div class="header_loginUserPopup fs16"
               v-show="isOpen"
          >
            <div class="header_loginUserPopupTop header_login">
              Авторизация
            </div>
            <a href="/connect/mosru" class="div_a login">
              <div class="dt1">
                <svg class="svg-icon" width="24" height="24">
                  <use xlink:href="/svg/icons.svg#login"></use>
                </svg>
              </div>
              <div class="dt2">Войти</div>
            </a>
            <div class="div_top_border"></div>
            <a href="/documents" class="div_a login">
              <div class="dt1">
                <svg class="svg-icon" width="24" height="24">
                  <use xlink:href="/svg/icons.svg#document"></use>
                </svg>
              </div>
              <div class="dt2">Документы и соглашения</div>
            </a>
          </div>
        </transition>
<!--        <cl-button type="light3" class="btn-lite btn_connect"  :link="true" href="/connect/mosru">Войти</cl-button>-->
      </div>

    </div>
  </teleport>
</template>

<script>
import {mapGetters} from "vuex";
import notificationsHeaders from "@/components/notificationsHeaders.vue";
import MyCompanySelected from "@/components/myCompanySelected.vue";

export default {
  name: "headerLogin",
  components: {MyCompanySelected, notificationsHeaders},
  props: {
    dataInfo: {
      type: Object
    },
  },
  data() {
    return {
      isOpen: false,
      userAvatar: false,

    }
  },
  computed: {
    ...mapGetters([
      'getIsAuthorization',
      'getIsCompany',
      'getCompany',
      'getUser',
      'widthSite',
      'getPersonData'
    ]),
  },
  watch: {
    getPersonData(val) {
      if (val.logo && !this.getIsCompany) {
        this.userAvatar = val.logo.imageUrl;
      }
    },
    getUser() {
      this.setAvatar();
    }
  },
  methods: {
    setAvatar() {
      if (this.getIsCompany) {
        this.userAvatar = this.getCompany?.logo?.imageUrl ?? '';
      } else {
        this.userAvatar = this.getPersonData?.logo?.imageUrl ?? '';
      }
    },
    hideOpen(){
      this.isOpen = false;
    },
    onScrollEvent(event){
      const elTop = document.querySelector('.header_loginUserPopupTop');
      if(elTop){
        if(event.srcElement.querySelector('.ps__rail-y').style.top != '0px'){
          elTop.classList.add('scroll_active');
        } else {
          elTop.classList.remove('scroll_active');
        }
      }
    }
  },
  mounted() {
    if (this.getIsAuthorization) {
      this.setAvatar();
    }
  },
}
</script>

<style lang="scss" scoped>
.header {

  &_login.active {
    .header_loginUserPeopleTd3 img {
      rotate: 180deg;
    }
  }

  &_loginUser {
    position: relative;
    padding: 10px 0;

    &People {
      display: flex;
      align-items: center;
      cursor: pointer;

      &Td1 {

      }

      &Td2 {
        margin-right: 4px;
        white-space: nowrap;

        .div_tit,
        .div_text {
          overflow: hidden;
          text-overflow: ellipsis;
          max-width: 200px;
          @media (max-width: 1140px) {
            max-width: 150px;
          }
          @media (max-width: 991px) {
            max-width: 90px;
          }
        }

        .div_tit {
          margin-bottom: 2px;

          &:last-child {
            margin-bottom: 0;
          }
        }

        .div_text {
          color: #9096A2;
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: 116.667%;
          letter-spacing: 0.18px;
        }
      }

      &Td3 {
        flex: none;
        height: 14px;
        width: 14px;

        &.not_center {
          align-self: flex-start;
          padding-top: 1px;
        }

        img {
          width: 100%;
          transition: all .3s ease;
        }
      }
    }

    &Popup {
      position: absolute;
      background: #FFFFFF;
      color: #000000;
      border-radius: 6px;
      box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.20);
      top: 100%;
      right: 0;
      display: flex;
      flex-direction: column;
      width: 290px;
      z-index: 3;
      font-size: 16px;
      overflow: hidden;
      &Top {
        padding: 12px;
        font-size: 18px;
        font-weight: bold;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        position: relative;
        &.scroll_active {
          box-shadow: 0px 0px 6px 0px #00010633;
        }
      }
    }
    .div_scroll {
      max-height: 566px;
      padding: 0;
      &.ps--active-y {
        padding-right: 18px;
      }
      :deep(.ps__rail-y) {
        margin: 3px 0;
        right: 5px;
      }
    }
    :deep(.div_tit_dop) {
      color: #6B7280;
      font-size: 12px;
      padding: 12px 12px 6px 12px;
      border-top: 1px solid rgba(0, 0, 0, 0.18);
    }
    :deep(.div_top_border){
      border-top: 1px solid rgba(0, 0, 0, 0.18);
    }
    :deep(.header_login){
      padding: 12px;
      font-weight: 500;
      line-height: 24px;
    }
    :deep(.div_a) {
      cursor: pointer;
      padding: 12px;
      display: flex;
      gap: 8px;
      &:hover {
        background: linear-gradient(0deg, rgba(237, 133, 10, 0.25), rgba(237, 133, 10, 0.25));
      }
      &:active {
        background: #ED850A;
        color: #ffffff;
        .dt1:not(.dt_circle) {
          filter: brightness(0) invert(1);
        }
        .dt2 {
          color: #ffffff;
        }
      }
      &.exit {
        background: #F1F2F3;
        .dt2 {
          color: #6B7280;
        }
        svg {
          stroke: #5D636F;
        }
        &:hover {
          background: #C7CAD1;
          svg {
            stroke: #ED850A;
          }
          .dt2 {
            color: #000000;
          }
        }
        &:active {
          background: #C7CAD1;
          .dt1 {
            filter: none;
          }
          svg {
            stroke: #ED850A;
          }
          .dt2 {
            color: #ED850A;
          }
        }
      }
      &.login{
        svg{
          stroke: #ED850A;
        }
      }
      .dt1 {
        width: 24px;
        height: 24px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex: none;
        &.dt_circle {
          overflow: hidden;
          border-radius: 100%;
          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }
      }
      .dt2 {
        align-self: center;
        color: #000106;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
    @media (max-width: 767px) {
      &Popup {
        right: -12px;
      }
    }
    @media (max-width: 767px) {
      &People {
        .header_logo {
          width: 32px;
          height: 32px;
          margin-right: 0;
          //&.active {
          //  outline: 2px solid #ed850a;
          //}
        }
        &Td2,
        &Td3 {
          display: none;
        }
      }
    }
  }
  @media(max-width: 767px){
    &_loginUser {
      position: unset;
      &Popup{
        top: 52px;
        right: 0;
        width: 100vw;
        border-radius: 0 0 6px 6px;
      }
    }
  }
}

@media(max-width: 767px){
  .site_header_td3{
    position: relative;
  }
}


</style>