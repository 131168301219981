<template>
  <div class="sub_block_black_wrapper wrapper-mtb-40">
    <div class="cinema_desc_wrap sub_block_black"
         v-if="dataInfo">
      <container>
        <div class="cinema_desc">
          <div class="cinema_desc_top"
               v-if="dataInfo?.dataCont?.dataTitle && dataInfo?.dataCont?.dataTxt"
          >
            <h2 class="div_title"
                v-if="dataInfo?.dataCont?.dataTitle"
            >
              {{ dataInfo?.dataCont?.dataTitle?.value }}
            </h2>
            <div
                ref="textContent"
                class="div_content"
                :class="{ 'clamped': !btnShow }"
                v-if="dataInfo?.dataCont?.dataTxt"
            >
              {{ dataInfo.dataCont.dataTxt.value }}
            </div>
            <cl-button
                v-if="isClamped"
                class="div_button"
                type="btn-default"
                @click="toggleText"
            >
              <template v-if="!btnShow">Показать все</template>
              <template v-else>Скрыть все</template>
            </cl-button>
          </div>
          <div class="cinema_desc_bot">
            <div class="icon_wrap">
              <div class="div_icon"
                   v-if="dataInfo?.dataList"
                   v-for="(item, index) in dataInfo.dataList"
                   :key="index"
              >
                <div class="div_icon_img">
                  <img
                      v-if="item?.dataIcon"
                      :src="item.dataIcon.value.src"
                  />
                </div>
                <div class="div_icon_txt"
                     v-if="item?.dataIconTxt"
                >
                  {{ item.dataIconTxt.value }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </container>
    </div>
  </div>
</template>

<script>

import ClButton from "@/components/library/ClButton.vue";

export default {
  name: "cinemaDesc",
  components: {
    ClButton
  },
  data() {
    return {
      btnShow: false,
      isClamped: false
    }
  },
  props: {
    dataInfo: {
      type: Object,
      require: true
    },
  },
  methods: {
    /**
     * Метод переключает состояние отображения полного текста.
     * Изменяет переменную `btnShow` с `true` на `false` и наоборот.
     */
    toggleText() {
      this.btnShow = !this.btnShow;
    },
    /**
     * Метод проверяет, превышает ли текст четыре строки.
     * Вычисляет высоту текста и устанавливает флаг `isClamped`,
     * далее определяет, нужно ли отображать кнопку для раскрытия текста.
     */
    checkIfClamped() {
      const lineHeight = parseFloat(window.getComputedStyle(this.$refs.textContent).lineHeight);
      const maxHeight = lineHeight * 4;
      this.isClamped = this.$refs.textContent.scrollHeight > maxHeight;
    }
  },
  mounted() {
    this.checkIfClamped();
    window.addEventListener('resize', this.checkIfClamped);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.checkIfClamped);
  }
}
</script>

<style lang="scss" scoped>

.cinema_desc_wrap {
  padding: 6rem 12rem;

  .container {

    .cinema_desc {
      display: flex;
      flex-direction: column;
      gap: 3.2rem;

      .cinema_desc_top {

        .div_title {
          font-size: 3.2rem;
          font-weight: 500;
          line-height: 4.8rem;
          letter-spacing: 0.02em;
          text-align: center;
          margin-bottom: 3.2rem;
        }

        .div_content {
          font-size: 1.8rem;
          line-height: 2.4rem;
          letter-spacing: 0.015em;
          margin-bottom: 1.6rem;
          overflow: hidden;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          transition: all 0.3s ease;
        }

        .div_content.clamped {
          -webkit-line-clamp: 4;
        }

        .div_button {
          min-height: 3.6rem;
          padding: 0;
          font-size: 1.8rem;
          font-weight: 500;
          line-height: 2.4rem;
          letter-spacing: 0.02em;
          color: #ED850A;
        }
      }

      .cinema_desc_bot {
        .icon_wrap {
          display: flex;
          gap: 2.4rem;
          flex-wrap: wrap;

          .div_icon {
            min-width: 22rem;
            display: flex;
            gap: .8rem;
            align-items: center;

            .div_icon_txt {
              font-size: 1.8rem;
              line-height: 2.4rem;
              letter-spacing: 0.015em;
            }

            .div_icon_img {
              width: 4rem;
              height: 4rem;
              flex: none;

              img {
                object-fit: contain;
                object-position: center;
              }
            }
          }
        }
      }
    }
  }

  @media (max-width: 991px) {
    padding: 6rem 3rem;
  }

  @media (max-width: 767px) {
    padding: 6rem .2rem;
  }
}

</style>