<template>
  <div v-if="dataInfo && Object.keys(dataInfo).length > 0" class="sub_block_black_wrapper cinema_swiper_cards_mos">
    <div class="sub_block_black" :class="{'sub_block_black_1200': dataInfo.container1200}">
      <container>
        <h2 v-if="dataInfo.title" class="div_title">{{dataInfo.title.value}}</h2>
        <swiper
            v-if="widthSite > 767 && dataInfo.cards?.length > 0"
            :pagination="getPagination()"
            class="slide100h"
            :class="getProgressBarLine()"
            :modules="modules"
            :navigation="getNavigation()"
            :space-between="48"
            :allowTouchMove="false"
        >
          <swiper-slide
              v-for="card in dataInfo.cards"
              :key="card"
          >
            <cinema-swiper-card-mos :card="card"/>
          </swiper-slide>
        </swiper>
        <div class="div_cards_block" v-else-if="dataInfo.cards?.length > 0">
          <div class="div_card_wrapper" v-for="card in dataInfo.cards"
               :key="card">
            <cinema-swiper-card-mos :card="card"/>
          </div>
        </div>
      </container>
    </div>
  </div>
</template>

<script setup lang="ts">
import {IDataInfoProps} from "@/interface/props/IDataInfoProps";
import Container from "@/components/library/Container.vue";
import {Swiper, SwiperSlide} from "swiper/vue";
import {SwiperModule} from "swiper/types/shared";
import {Navigation, Pagination} from "swiper";
import CinemaSwiperCardMos from "@/components/cinemaSwiperCardMos.vue";
import {computed, ComputedRef} from "vue";
import store from "@/store";

/**
 * Пропы
 */
const props = defineProps<IDataInfoProps>();

/**
 * Ширина экрана
 * @default  computed(() => {return store.getters.widthSite})
 */
const widthSite: ComputedRef<number> = computed(() => {return store.getters.widthSite});

/**
 * Модули свайпера
 * @default [Pagination, Navigation]
 */
const modules: Array<SwiperModule> = [Pagination, Navigation];

/**
 * Функция получения настройки пагинации для свайпера в зависимости от количества слайдов.
 */
function getPagination(): {type: string} | '' {
  return props.dataInfo.cards?.length > 1 ? {type: 'progressbar'} : '';
}

/**
 * Функция получения настройки навигации для свайпера в зависимости от количества слайдов.
 */
function getNavigation(): boolean {
  return props.dataInfo.cards?.length > 1;
}

/**
 * Функция получения класса 'progress-bar-line' для свайпера в зависимотси от количества слайдов.
 */
function getProgressBarLine(): string {
  return props.dataInfo.cards?.length > 1 ? 'progress-bar-line' : '';
}
</script>

<style lang="scss" scoped>

.cinema_swiper_cards_mos {

  .div_title {
    font-size: 3.2rem;
    font-weight: 500;
    line-height: 1.5;
    letter-spacing: 0.02em;
    text-align: center;
    margin-bottom: 2.4rem;
  }

  .div_cards_block {
    display: flex;
    flex-direction: column;
    gap: 3.2rem;
  }

  @media (max-width: 991px) {
    .div_title {
      font-size: 2.4rem;
      line-height: 1.33;
    }
  }

  @media (max-width: 767px) {
    .div_title {
      font-size: 2.1rem;
      line-height: 1.33;
      letter-spacing: 0.015em;
      text-align: left;
      margin-bottom: 0.8rem;
    }
  }
}

</style>