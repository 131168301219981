<template>
  <div class='block_wrap block_wh smi' v-if="dataInfo.listNews && dataInfo.listNews.length > 0">
    <container>
      <h2 v-if="dataInfo.title">{{ dataInfo.title?.value }}</h2>
      <swiper
          :spaceBetween="24"
          :threshold='10'
          :modules="modules"
          :navigation="{nextEl: '.swiper-button-next', prevEl: '.swiper-button-prev'}"
          :pagination="{ clickable: true, el: '.swiper-pagination' }"
          :loop="true"
          class="slide100h slide_pagination slide100window"
          :slidesPerView="'auto'"
          :breakpoints="{
            '992': {
              slidesPerView: 3
            },
            '768': {
              slidesPerView: 2
            }
          }"
      >
        <swiper-slide v-for='item in dataInfo.listNews' :key="item">
          <card card-type="smi">
            <div class="div_comp">
              <div v-if="item.logo" class='div_logo'>
                <img :src="getImage(item.logo, 'hd')">
              </div>
              <div v-if="item.name" class="div_name fs4">{{ item.name?.value }}</div>
            </div>
            <div v-if="item.title" class='div_tit fw500' v-html="item.title?.value"></div>
            <div v-if="item.text" class='div_text fs16' v-html="item.text?.value"></div>
            <div class="div_btn"><cl-button v-if="item.link" :link="true" type="light" :href="item.link?.value">Подробнее</cl-button></div>

          </card>
        </swiper-slide>
        <div class="swiper-pagination-all">
          <div class="swiper-button-prev"></div>
          <div class="swiper-pagination"></div>
          <div class="swiper-button-next"></div>
        </div>
      </swiper>
    </container>
  </div>
</template>

<script>
import {Swiper, SwiperSlide} from "swiper/vue";
import {Navigation, Pagination} from "swiper";
import {mapGetters} from "vuex";
export default {
  name: 'smiAboutUs',
  components: {SwiperSlide, Swiper},
  data() {
    return {}
  },
  props: {
    dataInfo: {
      type: Object,
      require: true
    },
  },
  computed: {
    ...mapGetters([
      'widthSite'
    ]),
  },
  setup() {
    return {
      modules: [Navigation, Pagination],
    }
  }
}
</script>

<style lang="scss" scoped>

@import '@/assets/scss/variables.scss';

.smi{
  padding: 6rem 0;
  &.smi_black{
    background-color: #000106;
    color: white;

    :deep(.card-smi){
      background: #000106;
      border-color: #17191C;
    }
    :deep(.swiper-button-prev, .swiper-button-next){
      background: #000106;
      &:before{
        filter: invert(0);
        background-image: url("@/assets/img/ico/chevron-left-orange.svg");
      }
    }
    :deep(.swiper-button-next){
      background: #000106;
      &:before{
        filter: invert(0);
        background-image: url("@/assets/img/ico/chevron-right-orange.svg");
      }
    }
  }
}
</style>